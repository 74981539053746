import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";

const UserWalletActiveButton = ({ mainForm, ...props }) => {
  const { status, _id, wallet } = mainForm.values; // User
  const [api] = useServerApi();

  // const isActiveWallet = () => wallet.active;
  const handleClick = async () => {
    const isActive = !wallet.active;
    const activeMessage = "User wallet is updated to active.";
    const inactiveMessage = "User wallet is updated to inactive now.";

    const result = await api.Wallet.setActive(wallet._id, isActive);
    console.log("Wallet set Active", result);

    if (result.success) {
      showNotification({
        title: `User wallet updated to ${!wallet.active}.`,
        message: isActive ? activeMessage : inactiveMessage,
        icon: <IconCircleCheck size={18} />,
        color: "green",
      });
      mainForm.setFieldValue("wallet.active", isActive);
    } else {
      showNotification({
        title: `User status can't updated`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: result.message,
      });
    }
  };

  return (
    <>
      {/* Wallet Status: {wallet.active ? "Active" : "Inactive"} */}
      {wallet && (
        <Button
          fullWidth
          onClick={handleClick}
          color={wallet.active ? "orange" : "green"}
          disabled={status === "TERMINATED"}
        >
          {wallet.active ? "Deactive Wallet" : "Active Wallet"}
        </Button>
      )}
    </>
  );
};

export default UserWalletActiveButton;
