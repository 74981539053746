import React, { useState, useEffect, useCallback } from "react";
import { List, Text } from "@mantine/core";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";

const UserLeaveNonReleaseBid = ({ form }) => {
  const { userId, name } = form.values;
  const [api] = useServerApi();
  const [count, setCount] = useState(0);

  const fetch = async () => {
    try {
      const data = await api.User.getNonSettledBidsCount(userId);
      setCount(data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetch();
  }, [userId]);

  return (
    <>
      {count === 0 && (
        <List>
          <List.Item>There are no bids to handle.</List.Item>
        </List>
      )}

      {count > 0 && (
        <List>
          <List.Item>
            There are total {count} bids have to transfer upward.
          </List.Item>
        </List>
      )}
    </>
  );
};

export default UserLeaveNonReleaseBid;
