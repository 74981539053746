import { Button, Group } from "@mantine/core";
import React from "react";
import _ from "lodash";

import QuoRequestEditButton from "./quoRequestEditButton";
import OrderEditButton from "./orderEditButton";

import NewTicketButton from "./newTicketButton";
import OrderDeliveryPolicyButton from "./orderDeliverPolicyButton";
import OrderCancelButton from "./orderCancelButton";
import QuoRequestCompleteButton from "./quoRequestCompleteButton";
import OrderRedoCancelButton from "./orderRedoCancelButton";
import QuoRequestCopyDnButton from "./quoRequestCopyDnButton";
import QuoConfirmButton from "./quoConfirmButton";

const QUO_REQ_STATUS_ALLOWED_TO_EDIT = ["DFT"];
const QUO_REQ_STATUS_ALLOW_CONSULTANT_TO_EDIT = ["DFT"];

const QuoRequestActionList = ({ form, role }) => {
  // let status = _.get(form.values, "status");
  const { status, code, consultants, _id, policyNumber } = form.values;
  // console.log("QuoRequestActionList", consultants);

  const allowToEdit = () => QUO_REQ_STATUS_ALLOWED_TO_EDIT.includes(status);
  const allowToEditByConsultant = () =>
    QUO_REQ_STATUS_ALLOW_CONSULTANT_TO_EDIT.includes(status);

  const allowToCancel = () => status === "DFT";
  const allowToRedoCancel = () => status === "CAN";
  const allowToComplete = () => status === "DFT";
  const allowToConfirm = () => status === "DONE";
  const allowToCopyDN = () => status === "CONFIRM" || status === "COPIED-DN";

  const showEditOrderButton = role == "OPERATION" || role === "MANAGEMENT";
  const showCompleteButton = role === "OPERATION" || role === "MANAGEMENT";
  const showCopyDNButton = role === "OPERATION" || role === "MANAGEMENT";
  const showEditButtonByConsultant = role == "CONSULTANT";

  const showCancelOrderButton = role === "CONSULTANT" || role === "OPERATION";
  const showRedoCancelOrderButton =
    role === "CONSULTANT" || role === "OPERATION";

  const showReinstaniateOrderButton =
    role === "MANAGEMENT" || role === "OPERATION";

  return (
    <>
      {/* Role: {role}
      status: {status} */}
      <Group>
        {allowToEdit() && showEditOrderButton && (
          <QuoRequestEditButton mainForm={form} />
        )}

        {showEditButtonByConsultant && allowToEditByConsultant() && (
          <QuoRequestEditButton mainForm={form} />
        )}

        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="quoRequest"
          ticketType={"TK-QUOREQ"}
          members={consultants?.map((c) => c._id)}
        />

        {showCompleteButton && allowToComplete() && (
          <QuoRequestCompleteButton
            mainForm={form}
            title="Complete Request"
            confirmText="Are you sure to complete the request?"
            newStatus="DONE"
          />
        )}
        {allowToConfirm() && (
          <QuoConfirmButton mainForm={form} title="Confirm Quotation" />
        )}

        {showCopyDNButton && allowToCopyDN() && (
          <QuoRequestCopyDnButton mainForm={form} title="Copy to Debit Note" />
        )}

        {allowToCancel() && (
          <OrderCancelButton
            mainForm={form}
            title="Cancel Request"
            confirmText="Are you sure to cancel the request?"
          />
        )}
        {showRedoCancelOrderButton && allowToRedoCancel() && (
          <OrderRedoCancelButton
            mainForm={form}
            reactiveStatus="DFT"
            title="Reactive Request"
            confirmText="Are you sure to reactive the request?"
          />
        )}
      </Group>
    </>
  );
};

export default QuoRequestActionList;
