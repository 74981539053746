import React, { useState, useEffect } from "react";
import { Text } from "@mantine/core";
import { useServerApi } from "./userServerApi";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { buildSearchQuery } from "../components/pageList";
import { useAuthUser } from "react-auth-kit";
import { useLocalStorage } from "@mantine/hooks";
import { authName } from "../App";

export const useSpotLight = () => {
  const [api] = useServerApi();
  const Navigate = useNavigate();
  // const [preQuery, setPreQuery] = useState();
  // const [role, setRole] = useState("CONSULTANT");
  // const [userId, setUserId] = useState();

  const [spotLightActions, setSpotLightActions] = useState([]);
  const userState = JSON.parse(localStorage.getItem(authName + "_state"));
  const role = userState?.userRole?.name?.toUpperCase() ?? "CONSULTANT";
  const userId = userState?._id ?? null;
  // const preQuery = role === "CONSULTANT" ? { consultants: userId } : null;

  // useEffect(() => {
  //   const userState = JSON.parse(localStorage.getItem(authName + "_state"));
  //   if (!userState || !userState.userRole || !userState.userRole.name) return;
  //   setRole(userState.userRole?.name?.toUpperCase() ?? "CONSULTANT");
  //   setUserId(userState._id);
  // }, []);

  // useEffect(() => {
  //   switch (role) {
  //     case "CONSULTANT":
  //       //Consultant
  //       const q = { consultants: userId };
  //       setPreQuery(q);
  //       break;
  //     case "OPERATION":
  //       setPreQuery({});
  //       break;
  //     case "MANAGEMENT":
  //       setPreQuery({});
  //       break;
  //   }
  // }, [role, userId]);

  const searchProducts = async (text, limit = 10) => {
    return await getSearchResult({
      queryText: text,
      apiEntity: "product",
      searchFields: ["code", "name", "cname", "provider.code"],
      limit,
    });
  };

  const searchClients = async (text, limit = 10) => {
    return await getSearchResult({
      queryText: text,
      preQuery: role === "CONSULTANT" ? { consultants: userId } : null,
      apiEntity: "client",
      searchFields: ["code", "name", "cname", "email", "phone", "id"],
      limit,
    });
  };

  const searchUsers = async (text, limit = 10) => {
    return await getSearchResult({
      queryText: text,
      apiEntity: "user",
      searchFields: ["aliasName", "staffId", "name", "cname", "email", "phone"],
      limit,
    });
  };

  const searchFiles = async (text, limit = 10) => {
    const searchQuery = buildSearchQuery(text, ["files.name"]);
    const result = await api.search({
      apiEntity: "order",
      pageSize: limit,
      searchQuery,
    });

    return result?.docs ?? [];
  };

  const searchOrders = async (text, limit = 10) => {
    return await getSearchResult({
      queryText: text,
      preQuery: role === "CONSULTANT" ? { consultants: userId } : null,
      apiEntity: "order",
      searchFields: [
        "code",
        "searchText",
        "policyNumber",
        "clientSnapShot.name",
        "clientSnapShot.cname",
        "clientSnapShot.id",
        "clientSnapShot.email",
        "clientSnapShot.phone",
      ],
      limit,
    });
  };

  const onSpotLightQueryChange = async (q) => {
    if (_.isEmpty(q)) return setSpotLightActions([]);

    const products = await searchProducts(q);
    const clients = await searchClients(q);
    const users = await searchUsers(q);
    const orders = await searchOrders(q);

    const productActions = products.map((p) => ({
      group: "Products",
      description: `[ ${p.code} ] ${p.name} ${p.cname}`,
      onTrigger: () => Navigate(`/operation/product?id=${p._id}&mode=edit`),
    }));

    const clientActions = clients.map((p) => ({
      group: "Clients",
      description: `[ ${p.id} ] ${p.name} ${p.cname}`,
      onTrigger: () => Navigate(`/operation/client?id=${p._id}&mode=edit`),
    }));

    const orderActions = orders.map((p) => ({
      group: "Orders",
      description: `[ ${p.code} ] ${p.client ? p.client.name : ""} ${
        p.client ? p.client.cname : ""
      }  ( Policy: ${p.policyNumber ? p.policyNumber : "--"} )`,
      onTrigger: () => Navigate(`/operation/order?id=${p._id}&mode=edit`),
    }));

    const userActions = users.map((p) => ({
      group: "Users",
      description: `[ ${p.staffId} ] ${p.aliasName ? p.aliasName + ", " : ""} ${
        p.name
      } ${p.cname}`,
      onTrigger: () => Navigate(`/operation/user?id=${p._id}&mode=edit`),
    }));

    const actions =
      role === "CONSULTANT"
        ? [...orderActions, ...clientActions, ...productActions]
        : [
            ...orderActions,
            ...clientActions,
            ...userActions,
            ...productActions,
          ];
    setSpotLightActions(actions);
  };

  const getSearchResult = async ({
    queryText,
    apiEntity,
    searchFields,
    preQuery,
    limit = 50,
  }) => {
    const searchQuery = buildSearchQuery(queryText, searchFields);
    const query = _.isEmpty(preQuery)
      ? searchQuery
      : { $and: [preQuery, searchQuery] };

    // console.log("Q= ", apiEntity, query);
    const pResults = await api.search({
      apiEntity,
      searchQuery: query,
      pageSize: limit,
    });

    // console.log(pResults);
    return pResults?.docs ?? [];
  };

  return [
    onSpotLightQueryChange,
    spotLightActions,
    searchProducts,
    searchClients,
    searchUsers,
    searchOrders,
    searchFiles,
    role,
  ];
};
