import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { DatePicker } from '@mantine/dates'
import {IconTrash, IconCheck, IconCircleCheck , IconAlertOctagon} from '@tabler/icons'
import _ from 'lodash'

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from './pageList'
import LocalPageList from './localPageList'
import FileList from './fileList'
import ActionCellRender from './actionCellRender'

import { useServerApi } from '../hooks/userServerApi'

const OrderQuotationList = ({form: mainForm, ...props }) =>{
        
    
    const [rows, setRows] = useState( _.get(mainForm.values,"quotation") || [])
    
    const tableSchema = {
        hidePagination: true,
        columns : [
            // {   field: '_id',       headerName:"ID"            },             
            {   field: 'signDate', headerName:"Type"            ,cellRender: (col,data)=>'AML' }, 
            {   field: 'signDate', headerName:"Signed Date"     ,cellRender: (col,data) => moment(_.get(data, col.field)).format('LL')},
            {   field: 'expiryDate', headerName:"Expiry Date"   ,cellRender: (col,data) => moment(_.get(data, col.field)).format('LL')},
            {   field: 'expiryDate', headerName:"Expired"       ,cellRender: (col,data) => moment().diff(_.get(data, col.field))>0 ? <IconAlertOctagon/>:<IconCircleCheck/> },
            
            {   
                cellRender: ActionCellRender,     
                isAction: true,      
                actions:[
                    {
                        name: 'delete',
                        label: 'Delete data',
                        icon: IconTrash,
                            props:{
                              
                            }
                        }   
                ],
                actionMenu:[]        
            }
        ],
        searchableFields:[
            'code',
            'name',
            'cname',
            'email',
        ]
    }
    
    
    const formSchema = {
        title: "Quotation",
        description:"please fill in the form",

        display:{
            mode: ENUM_FORM_DISPLAY.MODAL,
            size: "xl",
        },
        initialValues:{

        },

        layout:{
            containers:[
                 {
                        key:'basicInfo-g',
                        parent: null,
                        type:ENUM_FORM_LAYOUT_CONTAINER.GRID,
                        cols: [
                            { 
                                key:"basicInfo-g1",
                                props:{
                                    span: 6,
                                }                    
                            },
                            { 
                                key:"basicInfo-g2",
                                props:{
                                    span: 6,
                                }
                            }
                        ]
                        
                    },
                    {
                        key:'file-section',
                        parent: null,
                        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                        props:{
                            title: "Files"
                        }
                    }
            ],

            fields:[
                {
                    name: "signDate",            
                    component: DatePicker, 
                    parent:"basicInfo-g1",
                    type: "datePicker",
                    props:{
                        required: true,
                        label: "Sign Date",
                        zIndex:1000,
                        placeholder: "Pick Date",
                        allowFreeInput: true,
                    }          
                },
                {
                    name: "expiryDate",            
                    component: DatePicker, 
                    parent:"basicInfo-g2",
                    type: "datePicker",
                    props:{
                        required: true,
                        label: "Expiry Date",
                        zIndex:1000,
                        placeholder: "Pick Date",
                        allowFreeInput: true,
                    }          
                },
                { 
                    name: "files",            
                    component: FileList, 
                    parent:"file-section",
                    props:{
                        
                    }            
                },

            ]
        }
    }

    const onFormSubmit = (values) =>{    
        // console.log("onFormSubmit" , values)     
        setRows(values)
        // api.Client.updateRiskAML(clientID, values)
    }


    
    
    return (
        <>
    
         {/* {JSON.stringify(rows)} */}
            <LocalPageList 
                rows={rows}
                onFormSubmit={onFormSubmit}
                tableSchema={tableSchema}
                formSchema={formSchema}
                {...props}
            />
         </>
    )
}


export default OrderQuotationList