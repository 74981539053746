import React, { useState, useRef, useEffect } from "react";
import RemoteSelect2 from "./remoteSelect2";
import { Group, Button } from "@mantine/core";
import _ from "lodash";

const UserInsertBar = ({ onInsert, ...props }) => {
  const [selected, setSelected] = useState();
  const ref = useRef();

  const handleKeyDown = ({ key }) => {
    if (!selected || key != "Enter") return;
    handleInsert();
  };

  const handleInsert = () => {
    onInsert(selected);
    clearSelected();
  };

  const clearSelected = () => {
    setSelected(null);
    ref.current?.clear();
  };

  return (
    <Group {...props} style={{ width: "100%" }} position="apart">
      <RemoteSelect2
        ref={ref}
        apiEntity="user"
        placeholder="Select new members"
        searchFields={["name", "staffId", "cname", "email"]}
        valueField="_id"
        labelField="name"
        onDataChange={(user) => setSelected(user)}
        onKeyDown={handleKeyDown}
        style={{ width: "85%" }}
      />
      {/* <input ref={ref} /> */}
      <Button onClick={handleInsert} disabled={_.isEmpty(selected)}>
        Insert
      </Button>
    </Group>
  );
};

export default UserInsertBar;
