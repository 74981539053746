import React, { useEffect, useState } from "react";
import _ from "lodash";
import { NumberInput, Text, TextInput, Group, ActionIcon } from "@mantine/core";
import UserInsertBar from "./userInsertBar";
import { IconTrash } from "@tabler/icons";
import { useCellRender } from "../hooks/useCellRender";

import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/userServerApi";

const WalletMemberRow = ({ member, onRemove, onChangeShare, ...props }) => {
  const [cellRender] = useCellRender();
  const [api] = useServerApi();
  const [user, setUser] = useState();

  const fetchUser = async () => {
    if (!member.user) return;
    let data = await api.getById({ apiEntity: "user", id: member.user });
    setUser(data);
  };

  useEffect(() => {
    fetchUser();
  }, [member.user]);

  return (
    // <>row</>

    <Group position="apart" {...props}>
      <span>{user && cellRender.User(user)}</span>
      <Group>
        <NumberInput value={member.share} onChange={(v) => onChangeShare(v)} />
        <ActionIcon onClick={() => onRemove(member.user)} size={"xs"}>
          <IconTrash />
        </ActionIcon>
      </Group>
    </Group>
  );
};

const WalletMembers = ({ form, name, label }) => {
  const members = _.get(form.values, name);

  const onMemberInsert = (member) => {
    let m = [...members];
    m.push({
      user: member._id,
      share: 0,
    });

    form.setFieldValue(name, m);
    form.save();
  };

  const onMemberRemove = (index) => {
    members.splice(index, 1);
    form.setFieldValue(name, [...members]);
    form.save();
  };

  const onChangeShare = (index, share) => {
    if (!members || !members[index]) return;
    members[index].share = share;
    form.setFieldValue(name, [...members]);
    form.save();
  };

  return (
    <>
      {/* <ReactJson
        src={form.values}
        collapsed
        name="Wallet"
        style={{ background: "white" }}
      /> */}
      {label && (
        <Text size={"xs"} mb={"xs"} mt={"xs"} weight={500} mt={"xl"}>
          {label}
        </Text>
      )}

      <UserInsertBar onInsert={onMemberInsert} mb="xl" />

      {members &&
        members.map((member, index) => (
          <WalletMemberRow
            member={member}
            onRemove={() => onMemberRemove(index)}
            onChangeShare={(share) => onChangeShare(index, share)}
            mb={"sm"}
          />
        ))}
    </>
  );
};

export default WalletMembers;
