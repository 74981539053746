import React, { useState, useEffect } from "react";

import _ from "lodash";
import ReactJson from "react-json-view";
import { Checkbox, Group, Text } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { useServerApi } from "../hooks/userServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const ServiceCheckList = ({ form: mainForm, name }) => {
  const checkList = _.get(mainForm.values, name);
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();

  //   const initCheckList = async (checkList) => {
  //     try {
  //       if (!_.isEmpty(checkList)) return;
  //       if (!mainForm.values) return;
  //       if (!mainForm.values.serviceType) return;

  //       //Get service type instance
  //       let serviceType = null;
  //       if (_.isObject(mainForm.values.serviceType)) {
  //         serviceType = mainForm.values.serviceType;
  //       } else {
  //         serviceType = await api.getById({
  //           apiEntity: "orderServiceType",
  //           id: mainForm.values.serviceType,
  //         });
  //       }

  //       if (!serviceType) return;

  //       //Set checkList
  //       mainForm.values.checkList = serviceType.checkList?.map((l) => ({
  //         item: l,
  //         checked: false,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const setCheckedItem = (item, checked) => {
    const c = checkList.find((i) => i.item === item);
    c.checked = checked;
    console.log(checkList, item, checked);
    mainForm.setFieldValue(name, checkList);
  };

  //   useDeepCompareEffect(() => {
  //     initCheckList();
  //   }, [checkList]);

  return (
    <>
      {/* <ReactJson
        src={mainForm.values}
        style={{ background: "white" }}
        collapsed
      /> */}

      {checkList?.map((l) => (
        <Group mb="xs">
          <Checkbox
            value={l.checked}
            checked={l.checked}
            size="xs"
            onChange={(event) => {
              setCheckedItem(l.item, event.currentTarget.checked);
            }}
          />
          {/* {l.checked ? "Checked" : "no"} */}
          <Text size="xs">{l.item} </Text>
        </Group>
      ))}
    </>
  );
};

export default ServiceCheckList;
