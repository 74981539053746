import React, { useEffect, useState, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import LineChart from "../components/chartWidget/LineChart";

import _ from "lodash";
import BarChart from "./chartWidget/BarChart";

const PolicyFundAmountChart = ({
  policyNumber,
  onVaulationsChange,
  responseive = true,
  height,
  width,
  showPeriodFilter = true,
}) => {
  const [api] = useServerApi();
  const [valuations, setValuations] = useState([]);
  const [lineAggregation, setLineAggregation] = useState([]);

  const dateStringAggreate = {
    $addFields: {
      valuationDateString: {
        $dateToString: {
          format: "%Y-%m-%d",
          date: "$valuationDate",
        },
      },
    },
  };

  const policyAggreate = {
    $match: { policyNumber: policyNumber?.trim() },
  };

  const projectAggreate = {
    $project: { allocations: 0, batchCode: 0 },
  };

  const sortAggregate = { $sort: { valuationDateString: 1 } };

  const lineChartProps = {
    legends: [
      {
        anchor: "bottom",
        translateX: 0,
        translateY: 80,
      },
    ],
    enableArea: false,
    yScale: { min: "auto" },
    margin: { top: 20, right: 50, bottom: 60, left: 80 },
    enableGridX: true,
    enableGridY: true,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 30,
      legend: "",
      legendOffset: 36,
      legendPosition: "middle",
      truncateTickAt: 0,
    },
  };

  const handlePeriodChange = (periodAggregate) => {
    return _.isEmpty(periodAggregate)
      ? setLineAggregation([
          projectAggreate,
          dateStringAggreate,
          policyAggreate,
          sortAggregate,
        ])
      : setLineAggregation([
          projectAggreate,
          dateStringAggreate,
          policyAggreate,
          periodAggregate,
          sortAggregate,
        ]);
  };

  const getSampleResult = (result, sampleSize = 10) => {
    if (!result || _.isEmpty(result)) return [];

    const data = result?.map((r) => ({
      x: r.valuationDateString,
      y: r.totalValueInPolicyCurrency,
    }));

    if (data.length < sampleSize) return data;

    //Sample the data to every samplesize in array
    const divider = Math.floor(data.length / sampleSize);
    const sampledDate = data.filter((_, i) => i % divider === 0);

    // console.log("sampleResult, data = ", sampledDate);
    return sampledDate;
  };

  const lineMapper = (result) => [
    {
      id: "Fund Amount",
      data: result ? getSampleResult(result) : [],
    },
  ];

  useEffect(() => {
    if (!policyNumber) return setLineAggregation([]);

    setLineAggregation([
      projectAggreate,
      dateStringAggreate,
      policyAggreate,
      sortAggregate,
    ]);
  }, [policyNumber]);

  return (
    <>
      {/* <BarChart
        title={"Performance"}
        description={"The total fund amount"}
        chartProps={{
          keys: ["y"],
          layout: "vertical",
          indexBy: "_id",
        }}
        showPeriodFilter={showPeriodFilter}
        onPeriodChange={handlePeriodChange}
        onDataChange={onVaulationsChange}
        height={height}
        dataAggreation={{
          apiEntity: "policyProfolio",
          aggregations: lineAggregation,
          mapper: lineMapper,
        }}
        responseive={responseive}
        useSampleData={false}
      /> */}
      <LineChart
        title={"Performance"}
        description={"The total fund amount"}
        chartProps={lineChartProps}
        showPeriodFilter={showPeriodFilter}
        onPeriodChange={handlePeriodChange}
        onDataChange={onVaulationsChange}
        responseive={responseive}
        height={height}
        dataAggreation={{
          apiEntity: "policyProfolio",
          aggregations: lineAggregation,
          mapper: lineMapper,
        }}
        useSampleData={false}
      />
    </>
  );
};

export default PolicyFundAmountChart;
