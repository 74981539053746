import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconCheck, IconExclamationMark, IconSettings } from "@tabler/icons";
import { DATA_TERMINATION_TYPE } from "../data/options";

import OrderTerminationConfirm from "./orderTerminationConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import FormConfirmation from "./formConfirmation";

const OrderTerminateActionButton = ({ mainForm }) => {
	const [api] = useServerApi();

	const formSchema = {
		title: "Order Termination",
		description: "Please follow the step to terminate the order",

		display: {
			mode: ENUM_FORM_DISPLAY.MODAL,
			size: "xl", //xs, sm, xl  ....100%
		},

		showSaveBar: false,
		closeAfterSave: true,

		layout: {
			containers: [
				{
					key: "t1",
					parent: null,
					type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
					props: {
						variant: "default", //| pills | outline
						defaultValue: "Details",
						orientation: "vertical",
						style: { minHeight: "400px" },
					},
					steps: [
						{ key: "t1-1", label: "Details", icon: IconSettings },
						{ key: "t1-2", label: "Confirm", icon: IconSettings },
					],
				},
				{
					key: "section-details",
					parent: "t1-1",
					type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
					props: {
						title: "Termination Details",
						description: "Pleaes fill in the termination details",
						withBorder: false,
						mt: 0,
						ml: 20,
					},
				},

				{
					key: "section-confirm",
					parent: "t1-2",
					type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
					props: {
						title: "Confirm Termination",
						description: "Please confirm the termination of order",
						withBorder: false,
						mt: 0,
						ml: 20,
					},
				},
			],

			fields: [
				{
					name: "termination.type",
					component: Select,
					parent: "section-details",
					props: {
						required: true,
						label: "Type of Termination",
						placeholder: "",
						data: DATA_TERMINATION_TYPE,
						defaultValue: "UG",
						searchable: true,
						clearable: true,
					},
				},

				{
					name: "termination.at",
					component: DatePicker,
					parent: "section-details",
					type: "datePicker",
					props: {
						required: true,
						label: "Termination Date",
						zIndex: 1000,
						placeholder: "Pick Date",
						allowFreeInput: true,
					},
				},

				{
					name: "termination.remark",
					component: Textarea,
					parent: "section-details",
					props: {
						required: true,
						label: "Remark",
						placeholder: "Remark",
					},
				},

				{
					name: "confirm",
					component: FormConfirmation,
					parent: "section-confirm",
					props: {
						required: true,
						successText: [
							"This order will be terminated",
							"All pending bids will be disabled from consultant wallet",
							"Commission clawback from provider may applied to consultants.",
							"Consultants will receive corresponding notification",
						],
					},
				},
			],
		},
	};

	const onSubmit = async ({ values, formMode }) => {
		// values.status = "TER";
		// mainForm.setValues(values);
		// mainForm.saveValues(values);
		try {
			console.log("terminate", values._id, values.termination);
			const result = await api.Order.terminate(values._id, values.termination);
			console.log(result);
			if (!result.success) throw result.error;
			formAction.close();

			mainForm.setFieldValue("status", values.termination.type);
			mainForm.setFieldValue("termination", values.termination);

			showNotification({
				title: `Terminated`,
				icon: <IconCheck size={18} />,
				text: `Order ${values.code} is terminated succesfully.`,
			});
		} catch (error) {
			showNotification({
				title: `Termination Error`,
				color: "red",
				icon: <IconExclamationMark size={18} />,
				message: error?.msg ?? error,
			});
		}
	};
	const [renderForm, formAction, formStatus, formSetting] = useFormRender(
		formSchema,
		null,
		onSubmit
	);

	const handleActionClick = () => {
		const id = mainForm?.values._id;
		// console.log('%corderPrintCoverSheetActionButton.js line:86 object', 'color: #007acc;', id);
		if (!id) return;
		formAction.open({ mode: "edit", id, data: mainForm.values });
	};

	return (
		<>
			{/* {JSON.stringify(mainForm.values.termination)} */}
			<Button fullWidth color={"red"} onClick={handleActionClick}>
				Terminate Order
			</Button>
			{renderForm()}
		</>
	);
};

export default OrderTerminateActionButton;
