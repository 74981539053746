import React, { useState } from "react";
import { Checkbox, TextInput, Text, Group, Grid } from "@mantine/core";
import _ from "lodash";

const AutoCodeInput = ({
  form,
  name,
  formMode,
  label,
  placeholder,
  showAsInput = false,
  ...props
}) => {
  const [auto, setAuto] = useState(true);

  const code = _.get(form.values, name);

  const handleCheckAuto = (e) => {
    setAuto(e.currentTarget.checked);
    form.setFieldValue();
  };

  const setValue = (v) => {
    // console.log("SetValue", v);
    if (!v) return;
    form.setFieldValue(name, v);
  };

  return (
    <>
      {formMode != "add" && !showAsInput && <Text> {code ?? "---"}</Text>}
      {formMode != "add" && showAsInput && (
        <TextInput
          value={code}
          label={label}
          disabled={auto}
          placeholder={placeholder}
        ></TextInput>
      )}
      {formMode === "add" && (
        // <Group>
        //   <TextInput
        //     width={"80%"}
        //     value={code}
        //     label={label}
        //     disabled={auto}
        //     placeholder={placeholder}
        //   ></TextInput>
        //   <Checkbox label="Auto" onChange={handleCheckAuto} checked={auto} />
        // </Group>
        <Grid align="center">
          <Grid.Col span={9}>
            <TextInput
              value={code}
              label={label}
              disabled={auto}
              placeholder={placeholder}
              onChange={(e) => setValue(e.target.value)}
            ></TextInput>
          </Grid.Col>
          <Grid.Col span={3}>
            <Checkbox label="Auto" onChange={handleCheckAuto} checked={auto} />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default AutoCodeInput;
