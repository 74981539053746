import { TimeInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import _ from "lodash";

import { IconClock } from "@tabler/icons";
import React, { useRef } from "react";
import { ActionIcon } from "@mantine/core";

const TimeSelect = ({ form, name, label, ...props }) => {
	const formValue = _.get(form.values, name);
	const ref = useRef();

	const [timeInput, setTimeInput] =
		useState();
		// formValue ? new Date(formValue) : new Date()

	const onTimeChange = (v) => {
		setTimeInput(v);
		form.setFieldValue(name, v.toISOString());
	};

	useEffect(() => {
		if (!formValue) return;
		setTimeInput(new Date(formValue));
	}, [formValue]);
	return (
		<>
			{/* {formValue} */}
			{/* {timeInput.toISOString()} */}
			<TimeInput
				ref={ref}
				label={label}
				value={timeInput}
				onChange={onTimeChange}
				rightSection={
					<ActionIcon
						onClick={() => {
							// console.log("time click");
							//Not working
							ref.current.showPicker();
						}}
					>
						<IconClock size="1rem" stroke={1.5} />
					</ActionIcon>
				}
			></TimeInput>
		</>
	);
};

export default TimeSelect;
