import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/userServerApi";
import DebitNoteConfirmPaymentButton from "./debitNoteConfirmPaymentButton";
import DebitNoteSettlePaymentButton from "./debitNoteSettlePaymentButton";
import QuotationConfirmButton from "./quotationConfirmButton";
import { useDisclosure } from "@mantine/hooks";
import ReactJson from "react-json-view";
import DebitNoteEditButton from "./debitNoteEditButton";
import DebitNoteAdjustButton from "./debitNoteAdjustButton";

const STATUS_ALLOWED_TO_EDIT = ["DFT", "WAIT", "CAN"];
const STATUS_ALLOWED_TO_ADJUST = ["PAID", "SETTLED"];
const STATUS_ALLOWED_TO_PRINT_RECEIPT = ["PAID", "SETTLED", "EXP"];
const PrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print DN", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.print(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");
      mainForm.setFieldValue("status", result.data.status);
      setPrinting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print DebitNote"}
    </Button>
  );
};

const PrintReceiptButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print Receipt", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.printReceipt(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");

      setPrinting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Receipt"}
    </Button>
  );
};

const PrintRNButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print DN", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.printRenewalNotice(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");
      mainForm.setFieldValue("status", result.data.status);
      setPrinting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Renewal Notice"}
    </Button>
  );
};

const TerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    mainForm.updateAndSave({ status: "CAN" });
  };

  return (
    <Button fullWidth color={"red"} onClick={() => terminate()}>
      Terminate
    </Button>
  );
};

const DebitNoteActionList = ({ form, role }) => {
  const { status, code, consultants, _id, signedBy, costTotal, total } =
    form.values;

  const showConfirm = status === "WAIT";
  const showPrint = true;
  const showTerminate =
    status === "WAIT" || status === "DFT" || status === "CAN";
  const allowToEdit = STATUS_ALLOWED_TO_EDIT.includes(status);
  const allowToAdjust = STATUS_ALLOWED_TO_ADJUST.includes(status);
  const allowToPrintReceipt = STATUS_ALLOWED_TO_PRINT_RECEIPT.includes(status);
  const showSettle = status === "PAID";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <Group mt={10}>
        {<PrintButton mainForm={form} />}
        {/* {<PrintRNButton mainForm={form} />} */}
        {allowToPrintReceipt && <PrintReceiptButton mainForm={form} />}

        {allowToEdit && <DebitNoteEditButton mainForm={form} />}
        {allowToAdjust && <DebitNoteAdjustButton mainForm={form} />}

        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="debitNote"
          ticketType={"TK-GI-DEBIT"}
          members={consultants?.map((c) => c._id)}
        />

        {showConfirm && <DebitNoteConfirmPaymentButton mainForm={form} />}
        {showSettle && <DebitNoteSettlePaymentButton mainForm={form} />}
        {showTerminate && <TerminateButton mainForm={form} />}
      </Group>
    </>
  );
};

export default DebitNoteActionList;
