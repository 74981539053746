import {
  Text,
  Title,
  Button,
  TextInput,
  ActionIcon,
  Group,
  Table,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons";

import ReactJson from "react-json-view";

import _ from "lodash";
const LinkageList = ({
  form,
  name,
  title = "URLs",
  disabled,
  addLabel = "Add Url",
  label,
  ...props
}) => {
  const forceUpdate = useForceUpdate();
  const NO_LINKS_MESSAGE = "No linkage yet.";
  let linkages = _.get(form.values, name) || [];

  const handleAdd = () => {
    form.setFieldValue(name, [...linkages, { name: "", link: "" }]);
    forceUpdate();
  };

  const handleRemove = (index) => {
    let list = [...linkages];
    list.splice(index, 1);
    form.setFieldValue(name, list);
  };

  const renderTextInput = (row, name, placeholder) => (
    <TextInput
      value={_.get(row, name)}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e) => {
        _.set(row, name, e.target.value);
        forceUpdate();
      }}
    />
  );
  const link = (row, index) => {
    return (
      <tr key={index}>
        <td>
          {disabled ? (
            <Text truncate placeholder="Label">
              {row.name}
            </Text>
          ) : (
            renderTextInput(row, "name", "Label")
          )}
        </td>
        <td>
          {disabled ? (
            <a href={row.link} target="_blank">
              <Text truncate>{row.link}</Text>
            </a>
          ) : (
            renderTextInput(row, "link", "http://www.abc.com")
          )}
        </td>
        <td>
          {!disabled && (
            <ActionIcon onClick={() => handleRemove(index)} disabled={disabled}>
              <IconTrash size={14} />
            </ActionIcon>
          )}
        </td>
      </tr>
    );
  };

  const renderTable = () => (
    <Table {...props}>
      <thead>
        <tr>
          <th>Linkage</th>
          <th>URL</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!linkages || linkages.length === 0 ? (
          <tr></tr>
        ) : (
          linkages.map((row, index) => link(row, index))
        )}
      </tbody>
    </Table>
  );

  return (
    <>
      {/* <ReactJson name={name} src={_.get(form.values, name) } style={{background:'white'}} collapsed/> */}
      {/* <Title order={5}>{ title }</Title> */}
      {!_.isEmpty(linkages) && renderTable()}

      {label}
      {_.isEmpty(linkages) && (
        <Text size="xs" mb={"xl"}>
          {NO_LINKS_MESSAGE}
        </Text>
      )}

      {!disabled && (
        <Button onClick={handleAdd} mt={20} size="xs" variant="default">
          {addLabel}
        </Button>
      )}
    </>
  );
};

export default LinkageList;
