import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { IconSettings } from "@tabler/icons";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";
import { adjustFormSchema } from "../pages/gi/debitNote";
import _ from "lodash";

const DebitNoteAdjustButton = ({ mainForm }) => {
  const onSubmit = async ({ values, formMode }) => {
    await mainForm.updateAndSave(values);
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    adjustFormSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button fullWidth onClick={handleActionClick}>
        Adjust Details
      </Button>
      {renderForm()}
    </>
  );
};

export default DebitNoteAdjustButton;
