import React, { useState, useEffect } from "react";
import { Group, FileButton, Button, Text, Grid, Divider } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useServerApi } from "../hooks/userServerApi";
import {
    IconTrash,
    IconCheck,
    IconSettings,
    IconExclamationMark,
    IconMessageCircle,
    IconCoin,
    IconDownload,
    IconDotsVertical,
    IconCircleCheck,
    IconCircleDashed,
} from "@tabler/icons";

import { List, ThemeIcon } from "@mantine/core";

const ProfolioFileImporter = ({ form, name }) => {
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [api] = useServerApi();

    const handleUploadSuccess = (file, data) => {
        showNotification({
            title: `${file.name} Import successfully`,
            message: "Import Success",
        });
        //For UI display
        setFile(file);
        setFileData(data);

        //Set file content
        const { content } = data;
        console.log(content);
        form.setFieldValue("rows", content.rows);
        form.setFieldValue("total", content.total);
    };

    const handleUploadFail = (file, error) => {
        showNotification({
            title: `${file.name} Import fail`,
            color: "red",
            icon: <IconExclamationMark size={18} />,
            message: error?.msg,
        });
        setFile(null);
        setFileData(null);
        form.setFieldValue(name, null);
        setError(error.msg);
    };

    const handleFileUpload = async (file) => {
        setLoading(true);
        setError(null);
        setFile(file);
        if (!file) return;
        let result = await api.OrderProfolio.import(file);
        let { data, success, error } = result;
        success
            ? handleUploadSuccess(file, data)
            : handleUploadFail(file, error);
        setLoading(false);
    };
    return (
        <>
            <Group style={{ width: 300 }}>
                <FileButton
                    onChange={handleFileUpload}
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                    {(props) => (
                        <Button {...props} size="xs" loading={loading}>
                            Select Profolio File
                        </Button>
                    )}
                </FileButton>
            </Group>
            {/* <Divider mt="xl" mb="xl"></Divider> */}
            {!loading && (
                <List
                    mt={"xl"}
                    spacing="xs"
                    size="sm"
                    center
                    icon={
                        <ThemeIcon color="teal" size={24} radius="xl">
                            <IconCircleCheck size={16} />
                        </ThemeIcon>
                    }
                >
                    {file && (
                        <>
                            <List.Item>File format is valid. [OK]</List.Item>
                            <List.Item>
                                File {file.name} statement read. [OK]
                            </List.Item>
                            <List.Item>
                                Total {fileData?.content?.total} commission rows
                                read. [OK]
                            </List.Item>
                        </>
                    )}

                    {error && (
                        <List.Item
                            icon={
                                <ThemeIcon color="red" size={24} radius="xl">
                                    <IconExclamationMark size={16} />
                                </ThemeIcon>
                            }
                        >
                            {error}
                        </List.Item>
                    )}
                </List>
            )}
        </>
    );
};

export default ProfolioFileImporter;
