import React, { useState, useEffect } from "react";
import { Page } from "../../layout/page";
import RemoteSelect2 from "../../components/remoteSelect2";
import { useFormRender } from "../../hooks/useFormRender";
import { useServerApi } from "../../hooks/userServerApi";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import {
  TextInput,
  Checkbox,
  Switch,
  Select,
  Textarea,
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Grid,
  Tabs,
  ScrollArea,
  FileButton,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import {
  DATA_CLIENT_TYPES,
  DATA_RANK_BY,
  DATA_USER_TYPES,
} from "../../data/options";
import { IconTrash, IconPencil } from "@tabler/icons";
import ActionCellRender from "../../components/actionCellRender";
import { DatePicker, TimeInput } from "@mantine/dates";
import { DATA_CALENDAR_EVENT_TYPE } from "../../data/options";
import TimeSelect from "../../components/timeSelect";
import moment from "moment";
import ImagePicker from "../../components/imagePicker";
import { getEventColor } from "../../components/eventSection";
import { useCellRender } from "../../hooks/useCellRender";

import FileList from "../../components/fileList";
import remoteSelect2 from "../../components/remoteSelect2";

const apiEntity = "topRanking";
const initialValues = {
  productCategories: [],
  productTypes: [],
  clientTypes: [],
  rankBy: "BY_CASE",
  rankNumber: 5,
  order: 1,
  showOnHomePage: true,
  active: true,
};

const formSchema = {
  title: "Top Ranking",
  apiEntity,
  initialValues,
  closeAfterSave: true,

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,

    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  layout: {
    containers: [],
    fields: [
      {
        name: "showOnHomePage",
        component: Switch,
        props: {
          label: "Show on Home Page",
          type: "checkbox",
        },
      },
      {
        name: "name",
        component: TextInput,

        props: {
          required: true,
          label: "Rank Name",
          placeholder: "MPF - Client",
        },
      },
      {
        name: "cname",
        component: TextInput,

        props: {
          required: true,
          label: "Chinese Name",
          placeholder: "MPF - Client",
        },
      },

      {
        name: "productCategories",
        component: RemoteSelect2,
        props: {
          label: "Product Category",
          apiEntity: "productCategory",

          placeholder: "Search Category",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) => `${data.name} ${data.cname}`,
          multiple: true,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "productTypes",
        component: RemoteSelect2,
        props: {
          label: "Product Types",
          apiEntity: "productType",

          placeholder: "Search",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          pageSize: 200,

          labelRender: (data) => `${data.name} ${data.cname}`,
          multiple: true,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "notProductTypes",
        component: RemoteSelect2,
        props: {
          label: "Not Include Product Types",
          apiEntity: "productType",

          placeholder: "Search",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) => `${data.name} ${data.cname}`,
          multiple: true,
          pageSize: 200,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "clientTypes",
        component: MultiSelect,
        props: {
          label: "Client Types",
          placeholder: "",
          data: DATA_CLIENT_TYPES,
        },
      },
      {
        name: "userTypes",
        component: MultiSelect,
        props: {
          label: "User Types",
          placeholder: "",
          data: DATA_USER_TYPES,
        },
      },
      {
        name: "rankBy",
        component: Select,
        props: {
          required: true,
          label: "Rank By",
          placeholder: "",
          data: DATA_RANK_BY,
        },
      },
      {
        name: "rankNumber",
        component: NumberInput,
        props: {
          label: "Rank Number",
        },
      },

      {
        name: "order",
        component: NumberInput,
        props: {
          label: "Display Order",
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          label: "Description",
          placeholder: "",
        },
      },
    ],
  },
};
const TopRanking = () => {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "name",
        sortable: true,
        cellRender: (col, data) => (
          <div>
            <Text>{data.name}</Text> <Text color={"dimmed"}>{data.cname}</Text>
          </div>
        ),
      },

      {
        field: "rankBy",
        headerName: "Rank By",
        sortable: true,
      },
      {
        field: "rankNumber",
        headerName: "Rank Number",
      },

      {
        field: "showOnHomePage",
        headerName: "Show on HomePage",
        cellRender: (col, data) => (
          <Text>{data.showOnHomePage ? "Yes" : "No"}</Text>
        ),
      },
      {
        field: "order",
        headerName: "Order",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: ["name", "cname"],
  };
  return (
    <>
      {
        <PageList
          title={"Top Rankings"}
          apiEntity={apiEntity}
          tableSchema={tableSchema}
          formSchema={formSchema}
        />
      }
    </>
  );
};

export default TopRanking;
