import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import {
  Button,
  Text,
  TextInput,
  Select,
  Textarea,
  Checkbox,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconSettings } from "@tabler/icons";
import DateOffPicker from "./dateOffPicker";
import { useServerApi } from "../hooks/userServerApi";
import { DATA_POLICY_DELIVER_METHODS } from "../data/options";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import DummyWidget from "./dummyWidget";
import ReactJson from "react-json-view";
import remoteSelect2 from "./remoteSelect2";
import OrderClientSelect from "./orderClientSelect";
import ConsultantList from "./consultantList";
import QuoRequestCheckList from "./quoRequestCheckList";
import FormConfirmation from "./formConfirmation";
import _ from "lodash";
import ProductSelection from "./productSelection";

const QuotationCopyDNButton = ({
  mainForm,
  newStatus = "CONFIRMED",
  title = "Copy To Debit Note",
  confirmText = "Are you sure to copy the request to debit note ?",
  color,
}) => {
  const [api] = useServerApi();

  const formSchema = {
    name: "quoRequestToDNSchema",
    title: "Copy To Debit Note",
    description: "Please follow the step to create a New Debit Note",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
    },
    apiEntity: "quoRequest",
    showSaveBar: false,

    initialValues: {},

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Client",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Client", icon: IconSettings },
            { key: "t1-2", label: "Consultant", icon: IconSettings },
            { key: "t1-3", label: "Product", icon: IconSettings },

            { key: "t1-5", label: "Confirmation ", icon: IconSettings },
          ],
        },
      ],
      fields: [
        {
          name: "client",
          component: remoteSelect2,
          parent: "t1-1",
          props: {
            apiEntity: "client",
            required: true,
            label: "Client",
            placeholder: "Search Client By Name / HKID/ BR ",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) =>
              `${data.name} ${data.cname} [ ${data.id ?? " -- "} ]`,
            //   labelRender: (data) => `${data.cname}`,
            multiple: false,
            // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
            // preQuery:  {isConsultant : true} ,
            searchFields: ["name", "id", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              console.log("Client Change", value);
              //Set Consultant
              if (!value || _.isEmpty(value.consultants))
                form.setFieldValue("consultants", []);
              else {
                form.setFieldValue(
                  "consultants",
                  value.consultants.map((c) => c._id)
                );
              }
            },
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "client2",
          component: OrderClientSelect,
          parent: "t1-1",
          props: {},
        },

        {
          name: "consultants",
          component: remoteSelect2,
          parent: "t1-2",
          props: {
            apiEntity: "user",
            required: true,
            label: "Consultants ",
            placeholder: "Please select consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
            multiple: true,
            preQuery: {
              $and: [{ isConsultant: true }, { isActive: true }],
            },
            searchFields: [
              "aliasName",
              "name",
              "staffId",
              "cname",
              "email",
              "phone",
            ],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        {
          name: "consultantList",
          component: ConsultantList,
          parent: "t1-2",
          props: {
            field: "consultants",
          },
        },

        {
          name: "product",
          component: ProductSelection,
          parent: "t1-3",
          props: {
            title: "Product",
            showProductSnapShot: false,
          },
        },

        {
          name: "confirm",
          component: FormConfirmation,
          parent: "t1-5",
          props: {
            successText: [
              "Please confirm only if the provided information is correct.",
              "Operation Team will process your quotation once submitted.",
            ],
          },
        },
      ],
    },
  };
  const onSubmit = async ({ values, formMode }) => {
    // console.log("onSubmit", values);
    // return;

    formAction.close();
    let result = await api.Quotation.copyToDN(values);

    // console.log(result);
    showNotification({
      title: `Quotation ${values.code} has been copied to debit note.`,
      icon: <IconCheck size={18} />,
      message: "Data Saved",
    });
    mainForm.setFieldValue("status", result.quoStatus);

    if (result.dn) window.open(`/gi/debitNote?id=${result.dn._id}&mode=edit`);
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick} color={color}>
        {title}
      </Button>

      {renderForm()}
    </>
  );
};

export default QuotationCopyDNButton;
