import React from "react";
import {
	Text,
	Group,
	Table,
	NumberInput,
	Checkbox,
	Button,
	ActionIcon,
	Divider,
} from "@mantine/core";
import _ from "lodash";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";
import { IconTrash } from "@tabler/icons";

const AllowanceTerms = ({ form: mainForm, name, label = "Details" }) => {
	const terms = _.get(mainForm.values, name);
	const payMode = mainForm.values.payMode;
	const [formatter] = useFormatter();

	const updateForm = () => {
		let v = terms.reduce((a, c) => a + c.amount ?? 0, 0);
		const totalAmount = Number(Number(v.toFixed(2)));
		mainForm.setFieldValue(name, [...terms]);
		mainForm.setFieldValue("payTerm", terms.length);
		mainForm.setFieldValue("totalAmount", totalAmount);
	};

	const updateSequence = () => {
		terms.forEach((t, index) => {
			t.term = index + 1;
		});
	};
	const handleRemove = (index) => {
		terms.splice(index, 1);
		updateSequence();
		updateForm();
	};

	const handleAdd = () => {
		if (!terms || terms.length === 0) {
			terms.push({
				term: 1,
				amount: 0,
				reactiveApproval: false,
				repayment: true,
			});
			return updateForm();
		}

		const lastTerm = terms[terms.length - 1];
		terms.push({
			term: lastTerm.term + 1,
			amount: lastTerm.amount,
			reactiveApproval: false,
		});
		return updateForm();
	};

	const getTermName = (term) => payMode?.slice(0, -2) + " " + term;

	const TableHeader = () => (
		<thead>
			<tr>
				<th> #</th>
				<th>CYCLE</th>
				<th>AMOUNT</th>
				<th>ALLOW REACTIVE</th>
				<th>REPAYMENT</th>
				<th></th>
			</tr>
		</thead>
	);

	const TableBody = () => (
		<tbody>
			{terms?.map((t, index) => (
				<tr key={index}>
					<td> {t.term}</td>
					<td>{getTermName(t.term)}</td>
					<td>
						<NumberInput
							value={t.amount}
							precision={2}
							parser={formatter.currencyParser}
							formatter={formatter.currencyFormat}
							onChange={(v) => (t.amount = v)}
							onMouseLeave={() => updateForm()}
							onBlur={() => updateForm()}
						/>
					</td>
					<td>
						<Checkbox
							size="xs"
							checked={t.reactiveApproval}
							onChange={() => {
								t.reactiveApproval = !t.reactiveApproval;
								updateForm();
							}}
						/>
					</td>
					<td>
						<Checkbox
							size="xs"
							checked={t.repayment}
							onChange={() => {
								t.repayment = !t.repayment;
								updateForm();
							}}
						/>
					</td>
					<td>
						<ActionIcon size={"xs"} onClick={() => handleRemove(index)}>
							<IconTrash></IconTrash>
						</ActionIcon>
					</td>
				</tr>
			))}
		</tbody>
	);

	const AllowanceTermTable = () => {
		return (
			<>
				<Table>
					<TableHeader />
					<TableBody />
				</Table>
			</>
		);
	};
	return (
		<>
			{/* <ReactJson
        src={mainForm.values}
        collapsed
        style={{ background: "white" }}
      /> */}
			{/* <Text> {label}</Text> */}
			<AllowanceTermTable />
			<Button size="xs" onClick={handleAdd} mt="xl">
				Add
			</Button>
		</>
	);
};

export default AllowanceTerms;
