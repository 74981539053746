import { Button, Group, JsonInput } from "@mantine/core";
import React from "react";
import _ from "lodash";

import AllowanceTerminateButton from "./allowanceTerminateButton";
import AllowanceReactiveButton from "./allowanceReactiveButton";

const STATUS_ALLOW_TO_TERMINATE = ["RUNNING"];
const STATUS_ALLOW_TO_REACTIVE = ["TERMINATED"];

const AllowanceActionList = ({ form }) => {
    const status = _.get(form.values, "status");
    const id = _.get(form.values, "_id");

    return (
        <>
            <Group mt={10}>
                {status !== "COMPLETED" && (
                    <AllowanceTerminateButton form={form} id={id} />
                )}

                {status !== "COMPLETED" && (
                    <AllowanceReactiveButton form={form} id={id} />
                )}

                {status === "COMPLETED" && (
                    <Button variant="outline" fullWidth>
                        {" "}
                        Completed{" "}
                    </Button>
                )}
            </Group>
        </>
    );
};

export default AllowanceActionList;
