import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
	IconTrash,
	IconCheck,
	IconCircleCheck,
	IconAlertOctagon,
	IconPencil,
} from "@tabler/icons";
import _ from "lodash";
import Chips from "./chips";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";

import { TextInput, Select, Text } from "@mantine/core";
import { DATA_COUNTRY, DATA_ADDRESS_TYPE } from "../data/options";
import { useCellRender } from "../hooks/useCellRender";

const AddressList = ({ form: mainForm, name, ...props }) => {
	const addresses = _.get(mainForm.values, name) ?? [];
	const [cellRender] = useCellRender();

	const [rows, setRows] = useState([]);
	const [api] = useServerApi();

	const tableSchema = {
		hidePagination: true,
		columns: [
			{
				field: "type",
				headerName: "Type",
				cellRender: (col, data) => cellRender.AddressType(data.type),
			},
			{
				field: "_id",
				headerName: "Details",
				cellRender: (col, data) => cellRender.Address(data, "xs"),
			},
			// { field: "state", headerName: "State" },
			// { field: "country", headerName: "Country" },
			{
				cellRender: ActionCellRender,
				isAction: true,
				actions: [
					{
						name: "edit",
						label: "Edit",
						icon: IconPencil,
						props: {},
					},
					{
						name: "delete",
						label: "Delete",
						icon: IconTrash,
						props: {},
					},
				],
				actionMenu: [],
			},
		],
		searchableFields: [
			// 'line',
			// 'name',
			// 'cname',
			// 'email',
		],
	};

	const formSchema = {
		// title: "Address",
		// description:"please fill in the form",

		display: {
			mode: ENUM_FORM_DISPLAY.MODAL,
			size: "800px",
		},
		initialValues: {
			country: "Hong Kong",
			type: "residental",
		},

		layout: {
			containers: [
				{
					key: "section-address",
					parent: null,
					type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
					props: {
						title: "Address",
						titleOrder: 3,
						description: "Please fill in the address info",
						withBorder: false,
						ml: 10,
						mt: 0,
						mb: 10,
					},
				},
				{
					key: "basicInfo-address-g",
					parent: "section-address",
					type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
					cols: [
						{
							key: "basicInfo-address-g-1",
							props: {
								span: 12,
								mt: 0,
							},
						},
						{
							key: "basicInfo-address-g-2",
							props: {
								span: 6,
							},
						},
						{
							key: "basicInfo-address-g-3",
							props: {
								span: 6,
							},
						},
					],
				},
			],

			fields: [
				{
					name: "type",
					component: Chips,
					parent: "basicInfo-address-g-1",
					props: {
						required: true,
						// label: "type",
						items: DATA_ADDRESS_TYPE,
					},
				},
				{
					name: "line1",
					component: TextInput,
					parent: "basicInfo-address-g-1",
					props: {
						required: true,
						placeholder: "",
						label: "Address Line 1",
					},
				},

				{
					name: "line2",
					component: TextInput,
					parent: "basicInfo-address-g-1",
					props: {
						required: true,
						placeholder: "",
						label: "Address Line 2",
					},
				},
				{
					name: "city",
					component: TextInput,
					parent: "basicInfo-address-g-2",
					props: {
						required: true,
						placeholder: "Kwun Tong",
						label: "City",
					},
				},
				{
					name: "state",
					component: TextInput,
					parent: "basicInfo-address-g-3",
					props: {
						required: true,
						placeholder: "Kowloon",
						label: "State",
					},
				},
				{
					name: "zipCode",
					component: TextInput,
					parent: "basicInfo-address-g-2",
					props: {
						placeholder: "00000",
						label: "Zip Code",
					},
				},
				{
					name: "country",
					component: Select,
					parent: "basicInfo-address-g-3",
					props: {
						required: true,
						label: "Country",
						placeholder: "",
						data: DATA_COUNTRY,
						defaultValue: "Hong Kong",
						searchable: true,
					},
				},
			],
		},
	};

	const onFormSubmit = (values) => {
		// console.log("onFormSubmit" , values)
		mainForm.setFieldValue(name, values);
	};

	return (
		<>
			{/* {JSON.stringify(rows)} */}
			{/* {<ReactJson src={rows} name="rows" collapsed style={{background:'white'}}/>} */}
			{/* {<ReactJson src={addresses} name="Addresses" collapsed style={{background:'white'}}/>} */}

			<LocalPageList
				rows={addresses}
				onFormSubmit={onFormSubmit}
				tableSchema={tableSchema}
				formSchema={formSchema}
				{...props}
			/>
		</>
	);
};

export default AddressList;
