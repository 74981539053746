import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import UsersAvatar from "./usersAvatar";
import { IconCirclePlus, IconSettings } from "@tabler/icons";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { Textarea } from "@mantine/core";
import FunctionList from "./functionList";
import {
  UnstyledButton,
  TextInput,
  Paper,
  Group,
  Title,
  Text,
  Container,
  Button,
  Stack,
  SimpleGrid,
} from "@mantine/core";

import FormConfirmation from "./formConfirmation";
const formSchema = {
  title: "User Role",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },

  showSaveBar: false,
  apiEntity: "userRole",
  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-1", label: "Basic Info", icon: IconSettings },
          { key: "t1-2", label: "API Access", icon: IconSettings },
          { key: "t1-3", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Client Types",
          // description: "Please choose one of the type for client",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },
      //   {
      //     key: "section-right",
      //     parent: "t1-2",
      //     type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      //     props: {
      //       title: "Access Right",
      //       titleOrder: 5,
      //       description: "User access right",
      //       withBorder: false,
      //       ml: 10,
      //       mt: 0,
      //       mb: 10,
      //     },
      //   },
      {
        key: "section-confirm",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirm",
          titleOrder: 5,
          description: "Please confirm the change",
          withBorder: false,
          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],
    fields: [
      {
        name: "name",
        component: TextInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "Administrator",
          label: "English Name",
        },
      },
      {
        name: "description",
        component: Textarea,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "description",
          label: "Description",
        },
      },
      {
        name: "accessRight",
        component: FunctionList,
        parent: "t1-2",
        props: {
          //   ml: 10,
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
      },
    ],
  },
};

const UserRoleCard = ({ role, handleEditUserRole }) => {
  return (
    <UnstyledButton>
      <Paper
        radius="md"
        p="md"
        key={role._id}
        shadow="md"
        style={{ minHeight: 40 }}
      >
        <Stack align="flex-start" justify="space-between">
          <Group position="apart" style={{ width: "100%" }}>
            <div>
              <Text color={"dimmed"} size="xs">
                Total {role.count} users
              </Text>
              <Text weight={500} size="md">
                {role.name}
              </Text>
            </div>
            <UsersAvatar users={role.users} maxDisplayUser={4} size={35} />
          </Group>

          <Group position="apart" style={{ width: "100%" }}>
            <Button
              size="xs"
              variant="subtle"
              p={0}
              onClick={() => handleEditUserRole(role._id)}
            >
              Edit Role
            </Button>
          </Group>
        </Stack>
      </Paper>
    </UnstyledButton>
  );
};

const RoleList = ({ roles, width, handleEditUserRole }) => {
  return (
    <SimpleGrid
      cols={4}
      spacing="lg"
      breakpoints={[
        { maxWidth: "md", cols: 3, spacing: "md" },
        { maxWidth: "sm", cols: 2, spacing: "sm" },
        { maxWidth: "xs", cols: 1, spacing: "sm" },
      ]}
    >
      {roles.map((role) => (
        <UserRoleCard
          key={role._id}
          role={role}
          handleEditUserRole={handleEditUserRole}
        />
      ))}
      {/* <AddCard /> */}
    </SimpleGrid>
  );
};

const fectchAllUserRoles = async (api) => {
  let result = await api.search({
    apiEntity: "userRole",
    pageSize: 100,
    currentPage: 1,
    sort: { by: "name", order: "asc" },
  });
  return result?.docs ?? [];
};

const UserRoleList = ({ width = 300, title, ...props }) => {
  const [api] = useServerApi();
  const [roles, setRoles] = useState([]);
  const apiEntity = "userRole";
  const description =
    "A role provide access to predefined menus and features so that depending on assigned role an administrator can have access to what he need";
  const fetchData = async () => {
    try {
      let userRoles = await fectchAllUserRoles(api);
      let data = await api.User.getListGroupByRole();
      // console.log("User Role List", data);
      userRoles.forEach((role) => {
        let r = data.find((dr) => dr._id === role._id);
        role.count = r ? r.count : 0;
        role.users = r ? r.users : [];
      });
      setRoles(userRoles);
    } catch (error) {
      console.log(error);
    }

    // console.log("User Role List", userRoles);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmitSuccess = ({ values }) => {
    fetchData();
  };

  const onSubmit = async (data) => {
    console.log("onSubmit ", data);
    const { formMode, values } = data;
    if (formMode == "add") await api.add({ apiEntity, values });
    else await api.updateById({ apiEntity, values, id: values._id });
    formAction.close();
    fetchData();
  };
  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit
  );

  const handleAddUser = () => {
    formAction.open({ mode: "add" });
  };

  const handleEditUserRole = (id) => {
    formAction.open({ mode: "edit", id });
  };

  return (
    <>
      {renderForm()}
      <div style={{ padding: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <Title order={2}>{title}</Title>
          <Text color={"dimmed"} size="xs">
            {description}
          </Text>
        </div>

        {roles && (
          <RoleList
            roles={roles}
            width={width}
            handleEditUserRole={handleEditUserRole}
          />
        )}

        <Button size="xs" variant="subtle" onClick={handleAddUser}>
          <IconCirclePlus size={15} />
          New User Role
        </Button>
      </div>
    </>
  );
};

export default UserRoleList;
