import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import {
  Button,
  TextInput,
  Switch,
  Select,
  Textarea,
  ActionIcon,
  UnstyledButton,
  Group,
  Text,
} from "@mantine/core";
import {
  IconCircle,
  IconCirclePlus,
  IconExclamationMark,
  IconSettings,
} from "@tabler/icons";

import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import RemoteSelect2 from "../components/remoteSelect2";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../data/options";
import Chips from "./chips";

import FormConfirmation from "./formConfirmation";
import RemarkList from "./remarkList";
import ReactJson from "react-json-view";

const OrderAddServiceButton = ({ mainForm, onAdded }) => {
  const [basicPlanOrderId, setBasicPlanOrderId] = useState();
  const [api] = useServerApi();
  const apiEntity = "orderService";
  const initialValues = {
    checkList: [],
  };

  const formSchema = {
    name: "AddServiceSchema",
    title: "New Order Service",
    description: "Please follow the step to create a New Order Service",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity,
    initialValues,

    validate: {
      serviceType: (value) =>
        !value ? "Please select the service type" : null,
      error: (value) => {
        // console.log("error", value);
        return value;
      },
    },
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Service Info",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Service Info", icon: IconSettings },
            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-info",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Information",
            description: "Pleaes select the service type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "serviceType",
          component: RemoteSelect2,
          parent: "section-info",
          props: {
            label: "Type",
            apiEntity: "orderServiceType",
            required: true,
            placeholder: "Search Type",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              //Set checkList
              form.setFieldValue(
                "checkList",
                value?.checkList?.map((c) => ({ item: c, checked: false }))
              );
              form.setFieldValue("serviceTypeSnapShot", value);
              return;
            },
          },
        },
        {
          name: "description",
          parent: "section-info",
          component: Textarea,
          props: {
            label: "Description",
          },
        },

        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please click confirm to create the service.",
              "Please goto Service tab and print out the service cover sheet.",
            ],
          },
        },
      ],
    },
  };

  formSchema.title = "Add New Service";

  const onSubmit = async ({ values, formMode }) => {
    try {
      const data = await api.Order.addService({
        service: values,
      });

      formAction.close();
      if (onAdded) onAdded(data);

      showNotification({
        title: `Service added`,
        message: "Service added succesfully",
      });
    } catch (error) {
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: `Service add error`,
        message: error.msg ?? error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    if (!mainForm || !mainForm.values) return;

    const id = mainForm?.values._id;
    if (!id) return;

    const serviceValue = {
      orderId: id,
      serviceType: null,
      checkList: [],
      remark: "",
    };

    formAction.open({ mode: "edit", id, data: serviceValue });
  };

  return (
    <>
      <UnstyledButton onClick={() => handleActionClick()}>
        <Group>
          <ActionIcon>
            <IconCirclePlus size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>Add New Service</Text>
        </Group>
      </UnstyledButton>

      {renderForm()}
    </>
  );
};

export default OrderAddServiceButton;
