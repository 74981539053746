import BarChart from "../components/chartWidget/BarChart";
import _ from "lodash";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/userServerApi";
import { useEffect, useState } from "react";
import { ObjectId } from "bson";

const ForcastPayrollBarChart = ({ memberId }) => {
  const auth = useAuthUser();
  const [api] = useServerApi();
  const userId = memberId || auth().id;
  const [data, setData] = useState();

  const fetchData = async () => {
    let result = await api.User.getForcastPayroll(userId);
    // console.log(result.data);
    setData(result.data);

    // result.data.forEach((d) =>
    //   console.log(d.bids.reduce((a, c) => a + c.amount * c.internalRate, 0))
    // );
  };

  useEffect(() => {
    fetchData();
  }, [userId]);
  return (
    <>
      <BarChart
        data={data}
        title={"Payroll Forecast"}
        height={500}
        description={"The amount of payroll forcast in future 12 months"}
        useSampleData={true}
        chartProps={{
          keys: ["amount"],
          layout: "vertical",
          indexBy: "_id",
        }}
      />
    </>
  );
};

export default ForcastPayrollBarChart;
