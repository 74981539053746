import React from "react";

import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

const AllowanceReactiveButton = ({ form, id }) => {
    const [api] = useServerApi();
    const status = _.get(form.values, "status");

    const handleActionClick = async () => {
        const result = await api.Allowance.reactive(id);
        if (result.status) {
            form.setFieldValue("status", result.status);
        }
    };

    return (
        <>
            <Button
                fullWidth
                onClick={handleActionClick}
                disabled={status === "RUNNING"}
            >
                Reactive
            </Button>
        </>
    );
};

export default AllowanceReactiveButton;
