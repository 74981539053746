import {
  TextInput,
  Select,
  Text,
  Avatar,
  Textarea,
  NumberInput,
} from "@mantine/core";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { DATA_AGE_TYPES, DATA_COUNTRY } from "../../data/options";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import RemoteSelect2 from "../../components/remoteSelect2";
import { useCellRender } from "../../hooks/useCellRender";
import _ from "lodash";

const apiEntity = "cpd";

const formSchema = {
  title: "CPD",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "cpd",

  initialValues: {},

  validate: {
    dateEnroll: (value) =>
      !_.isDate(value) ? "Please fill in the date" : null,
    consultant: (value) =>
      _.isEmpty(value) ? "Please select consultant" : null,
  },

  layout: {
    fields: [
      {
        name: "dateEnroll",
        type: "datePicker",
        component: DatePicker,
        props: {
          required: true,
          label: "Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "consultant",
        component: RemoteSelect2,

        props: {
          label: "Consultant",
          apiEntity: "user",
          required: true,
          placeholder: "Please select consultant",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          onDataChange: (consultant, form) => {
            console.log("ondatachange", consultant);
            if (!consultant) form.setFieldValue("consultantInfo", {});
            else form.setFieldValue("consultantInfo", consultant);
          },
          searchFields: [
            "aliasName",
            "name",
            "staffId",
            "cname",
            "email",
            "phone",
          ],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "courseTitle",
        component: TextInput,
        props: {
          required: true,
          label: "Course Title",
          placeholder: "",
        },
      },

      {
        name: "courseCode",
        component: TextInput,
        props: {
          required: true,
          label: "Course Code",
          placeholder: "",
        },
      },
      {
        name: "IACredit",
        component: NumberInput,
        props: {
          label: "IA Credit",
          placeholder: "",
          precision: 1,
        },
      },
      {
        name: "IAERCredit",
        component: NumberInput,
        props: {
          label: "IAER Credit",
          placeholder: "",
          precision: 1,
        },
      },
      {
        name: "MPFNonCoreCredit",
        component: NumberInput,
        props: {
          label: "MPF Non-Core Credit",
          placeholder: "",
          precision: 1,
        },
      },
      {
        name: "MPFCoreCredit",
        component: NumberInput,
        props: {
          label: "MPF Core Credit",
          placeholder: "",
          precision: 1,
        },
      },
    ],
  },
};

function Cpd() {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "dateEnroll",
        sortable: true,
        headerName: "Course Date",
        cellRender: (col, data) => moment(data.dateEnroll).format("LL"),
      },
      {
        field: "consultant",
        sortable: true,
        headerName: "Consultant",
        cellRender: (col, data) => cellRender.User(data.consultant),
      },
      {
        field: "courseTitle",
        headerName: "Course",
        cellRender: (col, data) => (
          <>
            <Text size={"xs"} lineClamp={1} w={350}>
              {data.courseTitle}
            </Text>
            <Text size={"xs"} color="dimmed">
              {data.courseCode}
            </Text>
          </>
        ),
      },

      {
        field: "IACredit",
        headerName: "IA",
      },
      {
        field: "IAERCredit",
        headerName: "IAER",
      },

      {
        field: "MPFCoreCredit",
        headerName: "MPF (Core)",
      },

      {
        field: "MPFNonCoreCredit",
        headerName: "MPF (Non-Core)",
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: [
      "courseTitle",
      "courseCode",
      "consultantInfo.name",
      "consultantInfo.cname",
      "consultantInfo.email",
      "consultantInfo.staffId",
      "consultantInfo.aliasName",
    ],
  };
  return (
    <PageList
      title={"CPD Records"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      showImport={true}
    />
  );
}

export default Cpd;
