import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Alert, Group, Text, Box } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function QuoRequestDetails({ form, name, textSize = "xs" }) {
  const [cellRender] = useCellRender();
  const quo = form.values;

  const status = DATA_ORDER_STATUS.find((s) => s.value === quo?.status);

  const fields = [
    {
      title: "Code",
      value: quo.code,
    },
    {
      title: "Status",
      value: status ? (
        <Group>
          {cellRender.Order.status(quo?.status)}
          {status?.label}
        </Group>
      ) : (
        "Unknown"
      ),
    },
    { title: "Created By", value: cellRender.User(quo?.createdBy) },

    {
      title: "Product Type",
      value: quo.productType?.name + quo.productType?.cname,
    },

    { title: "Created At", value: moment(quo?.createdAt).format("LLL") },
  ];

  return (
    <>
      {quo.checkList &&
        quo.checkList?.map((l, index) => (
          <Box mb="xs">
            <Text size={textSize} fw={800}>
              {index + 1}. {l.question}{" "}
            </Text>
            {l.answer && (
              <Text size={textSize} ml="md">
                {l.answer}
              </Text>
            )}
            {!l.answer && (
              <Text size={textSize} ml="md">
                --
              </Text>
            )}
          </Box>
        ))}
    </>
  );
}
