import React from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Chip,
  Switch,
  Textarea,
} from "@mantine/core";
import moment from "moment";

import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import UserRoleList from "../../components/userRoleList";
import { useCellRender } from "../../hooks/useCellRender";
import RemoteSelect2 from "../../components/remoteSelect2";
import { DatePicker } from "@mantine/dates";
import AddressList from "../../components/addressList";
import LicenceCheckList from "../../components/licenceCheckList";
import ImagePicker from "../../components/imagePicker";
import FileList from "../../components/fileList";
import ConsultantCertificateList from "../../components/consultantCertificateList";
import UserActionList from "../../components/userActionList";
import ConsultantAllowanceList from "../../components/consultantAllowanceList";
import ConsultantOverridingPlans from "../../components/consultantOverridingPlans";
import Chips from "../../components/chips";
import DummyWidget from "../../components/dummyWidget";
import FormConfirmation from "../../components/formConfirmation";
import UniqueTextInput from "../../components/uniqueTextInput";
import UserClientList from "../../components/userClientList";
import UserCPDDetail from "../../components/userCPDDetail";
import _ from "lodash";
import ProviderLoginInfoRow from "../../components/providerLoginInfoRow.js";
import OrderList from "../../components/orderList.js";

import {
  DATA_GENDERS,
  DATA_MARIAGE_STATUS,
  DATA_EDUCATION_LEVEL,
  DATA_COUNTRY,
  DATA_PAYMENT_METHOD,
  DATA_CONSULTANT_PAYMENT_METHOD,
  DATA_BANK,
  DATA_USER_TYPES,
} from "../../data/options";
import ReactJson from "react-json-view";

import UserOrderHistory from "../../components/userOrderHistory";
import Consultant from "./consultant";
import AutoCodeInput from "../../components/autoCodeInput";
import ActivityLog from "../../components/activityLog";
import UserBasicInfo from "../../components/userBasicInfo";
import UserSettingInfo from "../../components/userSettingInfo";
import UserEmploymentInfo from "../../components/userEmploymentInfo";

const apiEntity = "user";
const initialValues = {
  address: "",
  isConsultant: true,
  files: [],

  consultantInfo: {
    licences: {
      l1: false,
      l2: false,
      l3: false,
      l4: false,
      l5: false,
    },
    isChannel: false,
    certificate: [],
    paymentMethods: {
      type: "",
      accNumber: "",
      name: "",
      bank: "",
    },
  },
};
export const addFormSchema = {
  title: "New User",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "800px", //xs, sm, xl  ....100%
  },
  showSaveBar: false,
  apiEntity,
  initialValues,
  validate: {
    name: (value) =>
      value?.length < 4 ? "Name must have at least 4 letters" : null,
    email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    // personalEmail: (value) =>
    // 	/^\S+@\S+$/.test(value) ? null : "Invalid Personal Email",
    phone: (value) =>
      value?.length < 8 ? "Phone must have at least 8 digits" : null,
    hkid: (value) =>
      value?.length < 8 ? "ID must have at least 8 digits" : null,
    userRole: (value) => (!value ? "User Role must assign to user" : null),
  },
  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "HKID",
          orientation: "vertical",
          // style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-0",
            label: "HKID",
          },
          {
            key: "t1-1",
            label: "Basic Info",
          },
          { key: "t1-2", label: "Address" },
          {
            key: "t1-3",
            label: "Employment",
          },
          // { key: "t1-4", label: "Licence" },
          { key: "t1-5", label: "Confirm" },
        ],
      },
      {
        key: "section-ID",
        parent: "t1-0",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "User Identity",
          titleOrder: 5,
          description: "Please enter the HKID of staff",
          withBorder: false,
          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "User Basic Information",
          description: "Please fill in the basic information of user",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "employment-g",
        parent: "section-employment",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "employment-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "employment-g2",
            props: {
              span: 9,
            },
          },
        ],
      },
      {
        key: "basicInfo-g",
        parent: "section-info",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basicInfo-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 6,
            },
          },
        ],
      },

      {
        key: "section-employment",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Employment Info",
          description: "Please fill in the basic employment info",
          withBorder: false,
          // ml: 10,
          mt: 0,
          // p: 20,
        },
      },
      {
        key: "section-address",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Address",
          description: "Please fill in the address details",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },
      // {
      // 	key: "section-licences",
      // 	parent: "t1-4",
      // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      // 	props: {
      // 		title: "Licences",
      // 		description: "Please fill the licences of consultant",
      // 		withBorder: false,
      // 		ml: 10,
      // 		mt: 0,
      // 	},
      // },
      {
        key: "section-confirm",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirmation",
          titleOrder: 3,
          description:
            "Please confirm to add user or you can go back to revise.",
          withBorder: false,
          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],

    fields: [
      {
        name: "hkid",
        component: UniqueTextInput,
        parent: "section-ID",
        props: {
          required: true,
          uniqueEntity: "user",
          errorMessage: "User is already exist.",
          label: "HKID",
          placeholder: "HKID / PASSPORT / BR",
          transform: "",
        },
      },
      {
        name: "name",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          placeholder: "Chan Tai Man",
          label: "English Name",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          placeholder: "陳大文",
          label: "Chinese Name",
        },
      },
      {
        name: "aliasName",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          placeholder: "Kenny",
          label: "Alias Name",
        },
      },

      // {
      // 	name: "hkid",
      // 	component: TextInput,
      // 	parent: "basicInfo-g1",
      // 	props: {
      // 		required: true,
      // 		label: "HKID",
      // 		placeholder: "HKID / PASSPORT / BR",
      // 		transform: "",
      // 	},
      // },

      {
        name: "phone",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          placeholder: "61210001",
          label: "Phone",
        },
      },

      {
        name: "birthday",
        component: DatePicker,
        parent: "basicInfo-g1",
        type: "datePicker",
        props: {
          required: true,
          label: "Date of Birth",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "userRole",
        component: RemoteSelect2,
        parent: "basicInfo-g2",
        props: {
          label: "User Role",
          apiEntity: "userRole",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "educationLevel",
        component: Select,
        parent: "basicInfo-g2",
        props: {
          required: true,
          label: "Education Level",
          placeholder: "",
          data: DATA_EDUCATION_LEVEL,
          defaultValue: "UG",
          searchable: true,
        },
      },

      {
        name: "gender",
        component: Select,
        parent: "basicInfo-g2",
        props: {
          required: true,
          label: "Gender",
          placeholder: "",
          data: DATA_GENDERS,
          defaultValue: "M",
          searchable: true,
        },
      },
      {
        name: "email",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          required: true,
          placeholder: "taiman@gmail.com",
          label: "Email",
        },
      },
      {
        name: "personalEmail",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          // required: true,
          placeholder: "taiman@gmail.com",
          label: "Personal Email",
        },
      },
      {
        name: "mariage",
        component: Select,
        parent: "basicInfo-g1",
        props: {
          // required: true,
          label: "Mariage Status",
          placeholder: "",
          data: DATA_MARIAGE_STATUS,
          defaultValue: "Single",
          searchable: true,
        },
      },
      {
        name: "address",
        component: Textarea,
        parent: "section-address",
        props: {},
      },

      {
        name: "image",
        component: ImagePicker,
        parent: "employment-g1",
        props: {},
      },

      {
        name: "staffId",
        component: AutoCodeInput,
        parent: "employment-g2",
        props: {
          required: true,
          uniqueEntity: "user",
          errorMessage: "Staff ID is already exist.",
          label: "Staff ID",
          placeholder: "AA001",
        },
      },

      {
        name: "userType",
        component: Select,
        parent: "employment-g2",
        props: {
          // required: true,
          label: "User Type",
          placeholder: "Consultant / Channel / Back office",
          data: DATA_USER_TYPES,
          defaultValue: "Consultant",
          searchable: true,
        },
      },
      {
        name: "consultantInfo.overridingPlan",
        component: RemoteSelect2,
        parent: "employment-g2",
        props: {
          label: "Overriding Plan",
          apiEntity: "commissionOverridingPlan",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "workTitle",
        component: TextInput,
        parent: "employment-g2",
        props: {
          label: "Title",
        },
      },
      {
        name: "employmentDate",
        component: DatePicker,
        parent: "employment-g2",
        type: "datePicker",

        props: {
          label: "Join Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      // {
      // 	name: "isConsultant",
      // 	component: Switch,
      // 	parent: "employment-g1",
      // 	props: {
      // 		type: "checkbox",
      // 		label: "Is Consultant",
      // 		mt: 20,
      // 	},
      // },

      // {
      // 	name: "consultantInfo.isChannel",
      // 	component: Switch,
      // 	parent: "employment-g1",
      // 	props: {
      // 		type: "checkbox",
      // 		label: "Is Channel",
      // 		mt: 20,
      // 	},
      // },

      // {
      // 	name: "consultantInfo.licences",
      // 	component: LicenceCheckList,
      // 	parent: "section-licences",
      // 	props: {
      // 		label: "Licences",
      // 	},
      // },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
    ],
  },
};

const User = ({ role }) => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data),
      },
      {
        field: "staffId",
        headerName: "Staff ID",
        sortable: true,
      },

      {
        field: "userType",
        headerName: "User Type",
        sortable: true,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: true,
      },

      {
        field: "userRole",
        headerName: "User Role",
        sortable: true,
        cellRender: (column, data) => data.userRole?.name,
      },
      {
        field: "employmentDate",
        headerName: "Join Date",
        sortable: true,
        cellRender: (column, data) =>
          moment(data.employmentDate).format("YYYY-MM-DD"),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (column, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          // 	name: "delete",
          // 	label: "Delete",
          // 	icon: IconTrash,
          // 	props: {
          // 		color: "red",
          // 	},
          // },
        ],
      },
    ],

    searchableFields: [
      "code",
      "name",
      "userType",
      "cname",
      "email",
      "phone",
      "staffId",
      "status",
      "aliasName",
    ],
  };

  const editFormSchema = {
    title: "User",
    titleField: "name",
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
      size: "xl", //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 5,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 19,
              },
            },
          ],
        },
        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              icon: IconSettings,
            },
            {
              key: "tab-order",
              label: "Orders",
              icon: IconSettings,
            },
            {
              key: "tab-client",
              label: "Clients",
              icon: IconSettings,
            },
            {
              key: "tab-commission",
              label: "Commission",
              icon: IconSettings,
            },
            {
              key: "tab-licences",
              label: "Licences",
              icon: IconSettings,
            },
            {
              key: "tab-cpd",
              label: "CPD",
              icon: IconSettings,
            },
            {
              key: "tab-allowance",
              label: "Allowance",
              icon: IconSettings,
            },
            {
              key: "tab-providerLogin",
              label: "Provider Login",
              icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              icon: IconSettings,
            },
            {
              key: "tab-logs",
              label: "Logs",
              icon: IconSettings,
            },
          ],
        },
        {
          key: "section-basic",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "User Profile",
            description: "Please fill in the basic information of user",
            withBorder: false,

            mt: 20,
          },
        },

        {
          key: "section-employment-info",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Employment Info",
            description: "Please fill in the basic information of user",
            withBorder: false,

            mt: 20,
          },
        },

        {
          key: "section-providerLogin",
          parent: "tab-providerLogin",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Provider Login List",
            description: "Please fill in the basic information of user",
            withBorder: false,

            mt: 20,
          },
        },
        // {
        //   key: "section-info",
        //   parent: "tab-overview",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "User Profile",
        //     description: "Please fill in the basic information of user",
        //     withBorder: false,

        //     mt: 20,
        //   },
        // },

        // {
        //   key: "section-employment",
        //   parent: "tab-overview",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Employment Info",
        //     description: "The employment information section",
        //     withBorder: false,
        //     mt: 20,
        //   },
        // },

        {
          key: "section-order",
          parent: "tab-order",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order List",
            description: "",
            withBorder: false,
            mt: 20,
          },
        },
        {
          key: "section-client",
          parent: "tab-client",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client List",
            description: "",
            withBorder: false,
            mt: 20,
          },
        },
        {
          key: "section-ops",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Overriding Info",
            description: "",
            withBorder: false,

            mt: 20,
          },
        },

        {
          key: "basicInfo-g",
          parent: "section-info",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            columns: 24,
          },
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 12,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 12,
              },
            },
          ],
        },
        {
          key: "employment-g",
          parent: "section-employment",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "employment-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "employment-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "section-licences",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Licences Application",
            description: "Insurance Authority Application",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },
        {
          key: "section-IA",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "IA",
            description: "IA Registeration Details",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },
        {
          key: "section-MPFA",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "MPFA",
            description: "MPF Registeration Details",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },
        {
          key: "section-certificate",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "IIQE Certificate",
            description: "IIQE Certification Records",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        // {
        // 	key: "section-overriding",
        // 	parent: "tab-overview",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Commission Setting",
        // 		titleOrder: 5,
        // 		// description: "Please assign the corresponding consultant",
        // 		withBorder: false,
        // 		minHeight: "200px",
        // 		mt: 20,
        // 		mb: 20,
        // 	},
        // },

        {
          key: "section-payment",
          parent: "tab-commission",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Method",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "500px",
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-allowance",
          parent: "tab-allowance",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Allowance List",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            //   minHeight: "500px",
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
      ],

      fields: [
        {
          name: "basic",
          component: UserBasicInfo,
          parent: "section-basic",
          props: {},
        },
        // {
        //   name: "basic2",
        //   component: UserSettingInfo,
        //   parent: "section-setting-info",
        //   props: {},
        // },

        {
          name: "basic3",
          component: UserEmploymentInfo,
          parent: "section-employment-info",
          props: {},
        },
        {
          name: "image",
          component: ImagePicker,
          parent: "main-g1",
          props: {
            size: 160,
          },
        },
        {
          name: "UserActionList",
          component: UserActionList,
          parent: "main-g1",
          props: {
            role,
          },
        },

        {
          name: "orders",
          parent: "section-order",
          component: UserOrderHistory,
          props: {},
        },
        {
          name: "clients",
          parent: "section-client",
          component: UserClientList,
          props: {},
        },

        {
          name: "cpd",
          parent: "tab-cpd",
          component: UserCPDDetail,
          props: {},
        },
        {
          name: "name",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "Chan Tai Man",
            label: "English Name (HKID)",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "陳大文",
            label: "Chinese Name",
          },
        },
        {
          name: "aliasName",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "David",
            label: "Alias Name",
          },
        },
        {
          name: "hkid",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "HKID",
            placeholder: "HKID / PASSPORT / BR",
            transform: "",
          },
        },

        {
          name: "phone",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "61210001",
            label: "Phone",
          },
        },

        {
          name: "birthday",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",
          props: {
            required: true,
            label: "Date of Birth",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "userRole",
          component: RemoteSelect2,
          parent: "basicInfo-g1",
          props: {
            label: "User Role",
            apiEntity: "userRole",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        {
          name: "educationLevel",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Education Level",
            placeholder: "",
            data: DATA_EDUCATION_LEVEL,
            defaultValue: "UG",
            searchable: true,
          },
        },

        {
          name: "gender",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Gender",
            placeholder: "",
            data: DATA_GENDERS,
            defaultValue: "M",
            searchable: true,
          },
        },
        {
          name: "email",
          component: TextInput,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            placeholder: "taiman@company.com",
            label: "Email",
          },
        },
        {
          name: "personalEmail",
          component: TextInput,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            placeholder: "taiman@gmail.com",
            label: "Personal Email",
          },
        },
        {
          name: "mariage",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Mariage Status",
            placeholder: "",
            data: DATA_MARIAGE_STATUS,
            defaultValue: "Single",
            searchable: true,
          },
        },
        {
          name: "address",
          component: Textarea,
          parent: "basicInfo-g2",
          props: {
            label: "Address",
          },
        },
        // {
        // 	name: "isConsultant",
        // 	component: Switch,
        // 	parent: "employment-g2",
        // 	props: {
        // 		type: "checkbox",
        // 		label: "Is Consultant",
        // 		mt: 20,
        // 	},
        // },
        {
          name: "staffId",
          component: UniqueTextInput,
          parent: "employment-g1",
          props: {
            required: true,
            uniqueEntity: "user",
            errorMessage: "Staff ID is already exist.",
            label: "Staff ID",
            placeholder: "AA001",
          },
        },
        {
          name: "userType",
          component: Select,
          parent: "employment-g1",
          props: {
            required: true,
            label: "User Type",
            placeholder: "Consultant / Channel / Back office",
            data: DATA_USER_TYPES,
            defaultValue: "Consultant",
            searchable: true,
          },
        },

        // {
        // 	name: "staffId",
        // 	component: TextInput,
        // 	parent: "employment-g1",
        // 	props: {
        // 		label: "Staff ID",
        // 		required: true,
        // 	},
        // },
        {
          name: "workTitle",
          component: TextInput,
          parent: "employment-g2",
          props: {
            required: true,
            label: "Title",
          },
        },

        {
          name: "parent",
          component: RemoteSelect2,
          parent: "section-ops",

          props: {
            label: "Upper Line",
            apiEntity: "user",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `[${data?.staffId}] ${data?.name ?? ""} ${data?.cname ?? ""} ${
                data?.aliasName ?? ""
              }`,
            searchFields: ["name", "staffId", "aliasName"],
            // clearable: true,
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "consultantInfo.overridingPlan",
          component: RemoteSelect2,
          parent: "section-ops",
          props: {
            required: true,
            label: "Current Overriding Plan",
            apiEntity: "commissionOverridingPlan",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "employmentDate",
          component: DatePicker,
          parent: "employment-g2",
          type: "datePicker",
          props: {
            label: "Join Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "providerLogin",
          component: OrderList,
          parent: "section-providerLogin",

          props: {
            onEnterSave: false,
            defaultRowValue: {},
            row: {
              component: ProviderLoginInfoRow,
            },
          },
        },

        {
          name: "consultantInfo.licences",
          component: LicenceCheckList,
          parent: "section-licences",
          props: {
            label: "Licences",
          },
        },
        {
          name: "consultantInfo.iaNumber",
          component: TextInput,
          parent: "section-IA",
          props: {
            label: "IA Number",
          },
        },
        {
          name: "consultantInfo.iaFirstRegisterDate",
          component: DatePicker,
          parent: "section-IA",
          type: "datePicker",

          props: {
            label: "First Registration Date",
            zIndex: 1000,
            placeholder: "First Register Date",
            allowFreeInput: true,
          },
        },
        {
          name: "consultantInfo.iaApplyDate",
          component: DatePicker,
          parent: "section-IA",
          type: "datePicker",

          props: {
            label: "Start Date",
            zIndex: 1000,
            placeholder: "Start Date",
            allowFreeInput: true,
          },
        },

        {
          name: "consultantInfo.iaEffectiveDate",
          component: DatePicker,
          parent: "section-IA",
          type: "datePicker",

          props: {
            label: "End Date",
            zIndex: 1000,
            placeholder: "End Date",
            allowFreeInput: true,
          },
        },

        {
          name: "consultantInfo.mpfaNumber",
          component: TextInput,
          parent: "section-MPFA",
          props: {
            label: "MPFA Number",
          },
        },
        {
          name: "consultantInfo.mpfaApplyDate",
          component: DatePicker,
          parent: "section-MPFA",
          type: "datePicker",

          props: {
            label: "Apply Date",
            zIndex: 1000,
            placeholder: "Apply Date",
            allowFreeInput: true,
          },
        },
        {
          name: "consultantInfo.mpfaEffectiveDate",
          component: DatePicker,
          parent: "section-MPFA",
          type: "datePicker",

          props: {
            label: "Effective Date",
            zIndex: 1000,
            placeholder: "Apply Date",
            allowFreeInput: true,
          },
        },

        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "consultantInfo.certificates",
          component: ConsultantCertificateList,
          parent: "section-certificate",
          props: {},
        },

        {
          name: "consultantInfo.paymentMethods.type",
          component: Chips,
          parent: "section-payment",
          props: {
            required: true,
            // label: "Type",
            defaultValue: "Bank Transfer",
            items: DATA_CONSULTANT_PAYMENT_METHOD,
          },
        },
        {
          name: "consultantInfo.paymentMethods.bank",
          component: Select,
          parent: "section-payment",
          props: {
            required: true,
            label: "Bank",
            data: DATA_BANK,
            dropdownPosition: "bottom",
          },
        },
        {
          name: "consultantInfo.paymentMethods.name",
          component: TextInput,
          parent: "section-payment",
          props: {
            required: true,
            label: "Name on Account",
          },
        },

        {
          name: "consultantInfo.paymentMethods.accNumber",
          component: TextInput,
          parent: "section-payment",
          props: {
            required: true,
            label: "Account Number",
          },
        },
        {
          name: "consultantInfo.allowances",
          component: ConsultantAllowanceList,
          parent: "section-allowance",
          props: {},
        },

        {
          name: "overridingPlans",
          component: ConsultantOverridingPlans,
          parent: "section-ops",
          props: {},
        },
        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },

        {
          name: "confirm",
          component: DummyWidget,
          parent: "section-confirm",
          props: {
            dummy: (
              <Text>
                Please confirm the client information is correct and updated.
              </Text>
            ),
          },
        },
      ],
    },
  };

  const formSchema = {
    add: addFormSchema,
    edit: editFormSchema,
  };

  return (
    <>
      <PageList
        apiEntity={"user"}
        tableSchema={tableSchema}
        formSchema={formSchema}
        showImport={true}
        initSort={{ by: "staffId", order: "asc" }}
        header={{
          component: UserRoleList,
          props: {
            title: "User Management",
          },
        }}
      />
    </>
  );
};

export default User;
