import React from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import { ActionIcon, Button, Group, NumberInput, Select } from "@mantine/core";
import { DATA_NUMBER_COMPARISON } from "../data/options";
import { IconTrash } from "@tabler/icons";

import { useFormatter } from "../hooks/useFomatter";

const TerminateRule = ({
	rule,
	index,
	updateFormValue,
	terminationRules,
	...props
}) => {
	const [formatter] = useFormatter();

	const handleRemove = () => {
		terminationRules.splice(index, 1);
		updateFormValue();
	};

	const handleUpdateCommand = (v) => {
		rule.command = v;
		updateFormValue();
	};

	return (
		<Group {...props}>
			<Select
				data={DATA_NUMBER_COMPARISON}
				value={rule.command}
				onChange={handleUpdateCommand}
			/>

			<NumberInput
				value={rule.amount}
				onChange={(v) => (rule.amount = v)}
				precision={2}
				// parser={formatter.currencyParser}
				// formatter={formatter.currencyFormat}
				onMouseLeave={() => updateFormValue()}
				onBlur={() => updateFormValue()}
			/>

			<ActionIcon size={"xs"} onClick={handleRemove}>
				<IconTrash />
			</ActionIcon>
		</Group>
	);
};

const AutoPlanTerminationRules = ({ form: mainForm, name }) => {
	const terminationRules = _.get(mainForm.values, name);

	const updateFormValue = () => {
		mainForm.setFieldValue(name, [...terminationRules]);
	};

	const handleAdd = () => {
		terminationRules.push({
			command: "",
			amount: 0,
		});
		updateForm();
	};

	const updateForm = () => {
		mainForm.setFieldValue(name, [...terminationRules]);
	};

	return (
		<>
			{/* <ReactJson
                src={mainForm.values}
                collapsed
                name="terminationRules"
                style={{ background: "white" }}
            /> */}
			{!_.isEmpty(terminationRules) &&
				terminationRules?.map((rule, index) => (
					<TerminateRule
						key={index}
						rule={rule}
						index={index}
						mb={"md"}
						updateFormValue={updateFormValue}
						terminationRules={terminationRules}
					/>
				))}
			<Button size="xs" mt={"xl"} onClick={handleAdd}>
				Add Rules
			</Button>
		</>
	);
};

export default AutoPlanTerminationRules;
