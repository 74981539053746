import { TextInput, Select, Text, Avatar, Textarea } from "@mantine/core";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { DATA_AGE_TYPES, DATA_COUNTRY } from "../../data/options";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import ImagePicker from "../../components/imagePicker";

import ContactList from "../../components/contactList";
import { useCellRender } from "../../hooks/useCellRender";
import TextEditor from "../../components/textEditor.js";
import OrderList from "../../components/orderList.js";
import LoginInfoRow from "../../components/loginInfoRow.js";
import LinkageList from "../../components/linkageList.js";

const apiEntity = "provider";

function Provider() {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        sortable: true,
        cellRender: (col, data) => cellRender.ProviderInfo(data),
      },
      // { field: "name", sortable: true },
      { field: "tel", sortable: true },
      { field: "fax", sortable: true },
      { field: "email", sortable: true },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };
  return (
    <PageList
      title={"Provider List"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      showImport={true}
    />
  );
}

export default Provider;

const editFormSchema = {
  title: "Provider",
  titleField: "shortName",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity: "provider",
  showSaveBar: false,

  validate: {
    email: (value) =>
      !value || /^\S+@\S+$/.test(value) ? null : "Invalid email",
  },

  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },
          {
            key: "tab-files",
            label: "Files",
          },
          {
            key: "tab-log",
            label: "Logs",
          },
        ],
      },

      {
        key: "section-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Provider Info",
          description: "Please fill in the basic information of provider",
          withBorder: false,
          mt: 20,
        },
      },

      {
        key: "basicInfo-g",
        parent: "section-info",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        props: {
          columns: 24,
        },
        cols: [
          {
            key: "basicInfo-gap1",
            props: {
              span: 1,
            },
          },
          {
            key: "basicInfo-g0",
            props: {
              span: 4,
            },
          },
          {
            key: "basicInfo-gap2",
            props: {
              span: 1,
            },
          },
          {
            key: "basicInfo-g1",
            props: {
              span: 9,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 9,
            },
          },
        ],
      },

      {
        key: "section-resource",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Resource Info",
          description: "",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "resources",
        parent: "section-resource",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Contact List",
        },
        tab: [
          {
            key: "tab-contact",
            label: "Contact List",
          },
          {
            key: "tab-login",
            label: "Portal Login",
          },
          {
            key: "tab-paymentInfo",
            label: "Payment Info",
          },
          {
            key: "tab-providerFormFile",
            label: "Form Files",
          },
          {
            key: "tab-providerFormLinkage",
            label: "Form Linkage",
          },
        ],
      },

      {
        key: "section-contactList",
        parent: "tab-contact",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please fill in the address details",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-providerLogin",
        parent: "tab-login",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please fill in the provider login",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-paymentInfo",
        parent: "tab-paymentInfo",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please fill in the payment Ifno",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-providerFormFile",
        parent: "tab-providerFormFile",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please fill in the provider form Info",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },
      {
        key: "section-providerFormLinkage",
        parent: "tab-providerFormLinkage",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please fill in the provider form Info",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-files",
        parent: "tab-files",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },
    ],

    fields: [
      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
      {
        name: "image",
        component: ImagePicker,
        parent: "basicInfo-g0",
        props: {
          size: 160,
        },
      },
      {
        name: "code",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          label: "Code",
          placeholder: "AIA",
        },
      },

      {
        name: "shortName",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          label: "Short Name",
          placeholder: "060",
        },
      },

      {
        name: "name",
        component: TextInput,
        parent: "basicInfo-g1",

        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        parent: "basicInfo-g1",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },

      {
        name: "insurerCode",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          label: "Broker Code",
          placeholder: "060",
        },
      },

      {
        name: "tel",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          label: "Primay Contact",
          placeholder: "31052388",
        },
      },
      {
        name: "fax",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          label: "Fax",
          placeholder: "31052399",
        },
      },

      {
        name: "email",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          label: "Email",
          placeholder: "help@aia.com",
        },
      },
      {
        name: "address",
        component: Textarea,
        parent: "basicInfo-g2",
        props: {
          label: "Address",
          placeholder: "",
        },
      },

      {
        name: "ageType",
        component: Select,
        parent: "basicInfo-g1",
        props: {
          required: true,
          label: "Age Type",
          placeholder: "",
          data: DATA_AGE_TYPES,
          default: "PREVIOUS",
        },
      },

      {
        name: "email",
        component: TextInput,

        props: {
          required: false,
          label: "Email",
          placeholder: "your@email.com",
        },
      },

      {
        name: "contacts",
        component: ContactList,
        parent: "section-contactList",
        props: {},
      },
      {
        name: "paymentInfo",
        component: TextEditor,
        parent: "section-paymentInfo",
        props: {
          readOnly: false,
        },
      },
      {
        name: "loginInfo",
        component: OrderList,
        parent: "section-providerLogin",
        props: {
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: LoginInfoRow,
          },
        },
      },

      {
        name: "formFiles",
        component: FileList,
        parent: "section-providerFormFile",
        props: {
          uploadLabel: "Upload Form Files",
        },
      },

      {
        name: "formLinkage",
        component: LinkageList,
        parent: "section-providerFormLinkage",
        props: {
          // label: "Linkage",
        },
      },
    ],
  },
};

const addFormSchema = {
  title: "Provider",
  // titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity: "provider",
  showSaveBar: false,

  initialValues: {
    code: "",
    name: "",
    cname: "",
    ageType: "",
    email: "",
    // termsOfService: true,
  },

  validate: {
    email: (value) =>
      !value || /^\S+@\S+$/.test(value) ? null : "Invalid email",
  },

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "s1", label: "Basic Info", icon: IconSettings },
          { key: "s2", label: "Contact", icon: IconSettings },
          { key: "s4", label: "Confirm", icon: IconSettings },
        ],
      },

      {
        key: "section-basic",
        parent: "s1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic",
          description: "Please fill in the detail of the provider",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },
      {
        key: "basic-g",
        parent: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-g1",
            props: {
              span: 3,
              mt: 0,
            },
          },
          {
            key: "basic-g2",
            props: {
              span: 9,
            },
          },
        ],
      },

      {
        key: "section-contact",
        parent: "s2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Contact Info",
          description: "Please fill in the address details",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-confirmt",
        parent: "s4",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirm",
          description:
            "Thank you. Please click confirm button to apply the changes.",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: TextInput,
        parent: "basic-g2",
        props: {
          required: true,
          label: "Code",
          placeholder: "AIA",
        },
      },

      {
        name: "image",
        component: ImagePicker,
        parent: "basic-g1",
        props: {},
      },
      {
        name: "shortName",
        component: TextInput,
        parent: "basic-g2",
        props: {
          required: true,
          label: "Short Name",
          placeholder: "060",
        },
      },

      {
        name: "name",
        component: TextInput,
        parent: "basic-g2",

        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        parent: "basic-g2",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "insurerCode",
        component: TextInput,
        parent: "basic-g2",
        props: {
          required: true,
          label: "Insurer Code",
          placeholder: "060",
        },
      },
      {
        name: "ageType",
        component: Select,
        parent: "basic-g2",
        props: {
          required: true,
          label: "Age Type",
          placeholder: "",
          data: DATA_AGE_TYPES,
          default: "PREVIOUS",
        },
      },

      {
        name: "tel",
        component: TextInput,
        parent: "section-contact",
        props: {
          label: "Primay Contact",
          placeholder: "31052388",
        },
      },
      {
        name: "fax",
        component: TextInput,
        parent: "section-contact",
        props: {
          label: "Fax",
          placeholder: "31052399",
        },
      },

      {
        name: "email",
        component: TextInput,
        parent: "section-contact",
        props: {
          label: "Email",
          placeholder: "help@aia.com",
        },
      },
      {
        name: "address",
        component: Textarea,
        parent: "section-contact",
        props: {
          label: "Address",
          placeholder: "",
        },
      },

      // {
      // 	name: "contacts",
      // 	component: ContactList,
      // 	parent: "section-contact",
      // 	props: {},
      // },

      {
        name: "ageType",
        component: Select,
        parent: "basicInfo-g2",
        props: {
          required: true,
          label: "Age Type",
          placeholder: "",
          data: DATA_AGE_TYPES,
          default: "BY_BIRTHDAY",
        },
      },

      {
        name: "email",
        component: TextInput,

        props: {
          required: false,
          label: "Email",
          placeholder: "your@email.com",
        },
      },
    ],
  },
};

const formSchema = {
  add: addFormSchema,
  edit: editFormSchema,
};
