import { createStyles, Button, FileInput, Modal } from "@mantine/core";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { IconEdit, IconExclamationCircle } from "@tabler/icons";
import toast from "react-hot-toast";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import { useFormRender } from "../hooks/useFormRender";
import PageListHeader from "./pageListHeader";
import DataTable from "./dataTable";

import { Page } from "../layout/page";
import ReactJson from "react-json-view";
import { useLocalStorage } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  page: {
    // padding: "20px",
    // height: "95%",
    // background: 'red'
  },
}));

export const ENUM_FORM_DISPLAY = {
  DRAWER: 0,
  MODAL: 1,
  FULLSCREEN: 2,
  INSIDE: 3,
  PAGE: 4,
  LINK: 5,
};

export const ENUM_FORM_LAYOUT_CONTAINER = {
  GRID: 0,
  TABS: 1,
  SECTION: 2,
  WIZARD: 3,
  BOX: 4,
  EMPTY: 5,
};

const handleRegexSpecialChar = (text) => {
  const specialTxt = [
    "\\",
    "^",
    "$",
    ".",
    "|",
    "?",
    "*",
    "+",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
  ];

  let result = text;
  specialTxt.forEach((special) => {
    result = result.replace(special, `\\${special}`);
  });
  return result;
};

export const buildSearchQuery = (searchText, searchFields) => {
  const txt = handleRegexSpecialChar(searchText)?.trim();

  let searchArr = searchFields?.map((field) => ({
    [field]: { $regex: txt, $options: "i" },
  }));
  return { $or: searchArr };
};

export function PageList({
  apiEntity,
  title,
  formSchema,
  tableSchema,
  showAddButton = true,

  hideActionButton = false,
  hideSearchBar = false,
  stats = null,
  filter = null,
  filterQuery = null,
  customActions = null,
  header = null,
  showImport = false,
  reloadAfterSave = false,
  preQueryNotEmpty = false,
  onSubmit,
  onChange,
  searchByTextField = false,
  defaultPageSize = 10,
  readOnly = false,

  initSort = { by: "createdAt", order: "desc" },
  tablePadding = "xl",
  tableHeader,
}) {
  const { classes } = useStyles();
  const tableRef = useRef();

  const onSubmitSuccess = () => fetchData();
  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit,
    onChange
  );

  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    totalPage: 1,
    page: 1,
    total: 0,
  });

  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const linkId = query.get("id");
  const linkMode = query.get("mode");
  const linkPath = useLocation().pathname;

  // useEffect(() => {
  // 	console.log("pageList effect", linkId, formSetting.displayMode);
  // 	if (linkId && formSetting.displayMode === ENUM_FORM_DISPLAY.LINK)
  // 		formAction.open({ mode: "edit", id: linkId });
  // }, [linkId, linkMode]);

  // const [pageSize, setPageSize] = useState(defaultPageSize);

  const [pageSize, setPageSize] = useLocalStorage({
    key: "fpro-page-size",
    defaultValue: defaultPageSize,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [openedImport, setOpenedImport] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [sort, setSort] = useState(initSort);
  const [api] = useServerApi();

  const buildQuery = () => {
    //Have SearchQuery Only
    const queries = [tableSchema.preQuery, searchQuery, filterQuery].filter(
      (f) => !_.isEmpty(f)
    );
    // console.log("buildQuery", queries);
    if (_.isEmpty(queries)) return {};
    return { $and: queries };
  };

  //Fetch Remote Data function
  const fetchData = useCallback(async () => {
    try {
      if (preQueryNotEmpty && _.isEmpty(tableSchema.preQuery)) {
        setRows([]);
        setPagination({
          totalPage: 0,
          page: 1,
          total: 0,
        });
        return;
      }

      // if (apiEntity === "client")
      //   console.log("Fettch", preQueryNotEmpty, tableSchema.preQuery);

      const q = buildQuery();
      let data = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort: sort.by ? sort : initSort,
        select: tableSchema.select,
        searchQuery: q,
        searchText,
        searchByTextField,
        preQuery: tableSchema.preQuery,
      });
      // if (apiEntity === "client") console.log("Page List Fetch Data", q);
      setPagination({
        ...pagination,
        total: data.total,
        totalPage: Math.ceil(data.total / data.limit),
      });
      setRows(data.docs ?? []);
    } catch (error) {
      setRows([]);
      toast.error(`Fetch Error`);
    }
  });

  const onPageChange = useCallback((page) => {
    setCurrentPage(page);
    setPagination({ ...pagination, page });
  });

  const onPageSizeChange = useCallback((pageSize) => {
    console.log("onPageSizeChange", pageSize);
    setCurrentPage(1);
    setPageSize(pageSize);
    setPagination({ ...pagination, page: 1 });
  });

  const onSortChange = useCallback((by, reverseSortDirection) => {
    if (!by) return;
    setSort({ by, order: reverseSortDirection ? "desc" : "asc" });
  });

  const onSearchChange = useCallback((searchText) => {
    let text = searchText;
    if (currentPage > 1) {
      setCurrentPage(1);
      setPagination({ ...pagination, page: 1 });
    }
    setSearchText(text);
  });

  useEffect(() => {
    let query =
      searchText === ""
        ? {}
        : buildSearchQuery(searchText, tableSchema.searchableFields);
    setSearchQuery(query);
  }, [searchText]);

  useEffect(() => {
    // console.log(sort);
    fetchData();
  }, [
    searchQuery,
    pageSize,
    sort,
    currentPage,
    tableSchema.preQuery,
    filterQuery,
  ]);

  const copyToNew = async (data) => {
    let newData = await api.copyById({
      apiEntity,
      id: data._id,
      newCode: true,
    });

    return newData;
  };

  const onActionBtnClick = useCallback(async ({ action, data }) => {
    // console.log("onActionBtnClick", action, data)

    switch (action) {
      case "show":
        formAction.open({ mode: "show", id: data._id });
        break;
      case "edit":
        // console.log("location edit", `${location.pathname}?id=${data._id}`);
        // formAction.openIdByLink({ mode: "edit", id: data._id });
        formAction.open({ mode: "edit", id: data._id });
        break;
      case "copy":
        const newRecord = await copyToNew(data);
        formAction.open({ mode: "edit", id: newRecord._id });
        break;
      case "delete":
        if (
          window.confirm(
            `Are you sure to delete item ${data.code || data.name || data._id}`
          )
        ) {
          // console.log("delete", data._id);
          await api.removeById({ apiEntity, id: data._id });
          fetchData();
        }

        break;
    }
  });

  const getTitle = () => {
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return title;
    if (!formStatus.isOpen) return title;
    return formAction.getTitle();
  };

  const showTable = () => {
    // if (formStatus.mode == "add" || formStatus.mode == "edit") return false
    if (!formStatus.isOpen) return true;

    // if (formSetting.displayMode === ENUM_FORM_DISPLAY.MODAL) return false
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return true;

    return false;
  };

  const getPageListAction = () => {
    const actions = [];
    if (showAddButton && !readOnly)
      actions.push({
        label: "Add New",
        onClick: () => formAction.open({ mode: "add" }),
      });

    if (showImport)
      actions.push({
        label: "Import",
        onClick: () => setOpenedImport(true),
        variant: "default",
      });

    actions.push({
      label: "Refresh",
      onClick: () => fetchData(),
      variant: "default",
    });
    // console.log("actions", actions);
    return actions;
  };

  const getPageFormAction = () => {
    const actions = [];
    if (!readOnly)
      actions.push({ label: "Save", onClick: () => formAction.save() });

    actions.push({
      label: "Close",
      onClick: () => {
        formAction.close();
        fetchData();
      },
      variant: "default",
    });
    return actions;
  };

  const pageActionButtons = () => {
    if (!formStatus.isOpen) return getPageListAction();
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return getPageListAction();

    return getPageFormAction();
  };

  const handleImport = async () => {
    // console.log("handleIMport", importFile);
    try {
      if (!importFile) return;
      setImporting(true);
      const result = await api.importBatch({ apiEntity, file: importFile });

      if (!result.success) {
        console.log(result);
        throw result.error;
      }
      showNotification({
        title: `${importFile.name} Import successfully`,
        message: "Import Success",
      });
      setOpenedImport(false);
      setImporting(false);

      fetchData();
    } catch (error) {
      console.log(error);
      showNotification({
        title: `${importFile.name} Import fail`,
        icon: <IconExclamationCircle size={18} />,
        color: "red",
        message: error,
      });
      setOpenedImport(false);
      setImporting(false);
      console.log(error);
    }
  };

  // console.log("Page List Render")

  return (
    <Page>
      {/* <>sort: {JSON.stringify(sort)}</> */}

      {/* <ReactJson
				src={formSchema}
				style={{ background: "white" }}
				collapsed
			></ReactJson> */}
      {/* <ReactJson
        src={formAction.getValues()}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* searchText : {searchText} */}

      <div>
        {showTable() && header && <header.component {...header.props} />}
        {hideActionButton ? null : (
          <PageListHeader
            title={getTitle()}
            actionButtons={pageActionButtons()}
          />
        )}
        {showTable() && stats && <stats.component {...stats.props} />}
        {showTable() && filter && <filter.component {...filter.props} />}
        {/* <Space h={"xl"}/> */}

        {showTable() && (
          <DataTable
            ref={tableRef}
            data={rows}
            columns={tableSchema.columns}
            pagination={pagination}
            hideSearchBar={hideSearchBar}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortChange={onSortChange}
            onSearchChange={onSearchChange}
            onActionBtnClick={onActionBtnClick}
            searchText={searchText}
            padding={tablePadding}
            tableHeader={tableHeader}
          />
        )}
        {/*Import Modal */}
        <Modal
          opened={openedImport}
          onClose={() => setOpenedImport(false)}
          title="Import Data"
          size={"xl"}
        >
          <FileInput
            placeholder="Pick file"
            label="Data File"
            withAsterisk
            onChange={setImportFile}
            disabled={importing}
          />
          <Button onClick={handleImport} mt="xl" size="xs" loading={importing}>
            Import
          </Button>
        </Modal>
        {renderForm()}
      </div>
    </Page>
  );
}
