import React from "react";
import { Card, Grid, Text, Group, Box, Table } from "@mantine/core";
import FormSection from "./formSection";

import { useFormatter } from "../hooks/useFomatter";
import moment from "moment";

import { IconCircleCheck } from "@tabler/icons";
const AllowancePlanDetail = ({ form }) => {
    const { plan } = form.values ?? {};
    const [formatter] = useFormatter();
    return (
        plan && (
            <>
                <FormSection title="Plan Information" mt="xl">
                    <Grid>
                        <Grid.Col span={4}>
                            <Box mb={"xl"}>
                                <Text color="dimmed" size={"xs"}>
                                    Code
                                </Text>
                                <Text> {plan.code}</Text>
                            </Box>

                            <Box mb={"xl"}>
                                <Text color="dimmed" size={"xs"}>
                                    Name
                                </Text>
                                <Text> {plan.name}</Text>
                            </Box>
                            <Box mb={"xl"}>
                                <Text color="dimmed" size={"xs"}>
                                    Chinese Name
                                </Text>
                                <Text> {plan.cname}</Text>
                            </Box>

                            <Box mb={"xl"}>
                                <Text color="dimmed" size={"xs"}>
                                    Pay Mode
                                </Text>
                                <Text> {plan.payTerm}</Text>
                            </Box>

                            <Box mb={"xl"}>
                                <Text color="dimmed" size={"xs"}>
                                    Total Allowance
                                </Text>
                                <Text>
                                    {formatter.currency(
                                        plan.totalAmount,
                                        "HKD"
                                    )}
                                </Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th> Pay Date</th>
                                        <th> Amount</th>
                                        <th> Allow Reactive</th>
                                        <th> Repayment</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {plan.terms?.map((t) => (
                                        <tr>
                                            {/* <td> {moment(t.payDate, "LLL")}</td> */}
                                            <td>
                                                {moment(t.payDate).format("LL")}
                                            </td>
                                            <td>
                                                {formatter.currency(
                                                    t.amount,
                                                    "HKD"
                                                )}
                                            </td>
                                            <td>
                                                {t.reactiveApproval ? (
                                                    <IconCircleCheck color="green" />
                                                ) : (
                                                    "--"
                                                )}
                                            </td>
                                            <td>
                                                {t.repayment ? (
                                                    <IconCircleCheck color="green" />
                                                ) : (
                                                    "--"
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Grid.Col>
                    </Grid>
                </FormSection>

                <FormSection
                    title="Auto Termination Rules"
                    mt="xl"
                    description="When user wallet opening balance meet the below critiera, the allowance plan will be terminate auotmatically"
                >
                    {plan.terminationRules?.map((r) => (
                        <Group spacing="xl" ml="xl">
                            <Text>{r.command}</Text>
                            <Text>{formatter.currency(r.amount, "HKD")}</Text>
                        </Group>
                    ))}
                </FormSection>
            </>
        )
    );
};

export default AllowancePlanDetail;
