import { RangeSlider ,Badge, Group } from '@mantine/core'
import _ from 'lodash'
import React from 'react'

import ReactJson from 'react-json-view'

const AgeRanger  = ({ form, name, label, defaultValue, min=0, max=100, step,...props}) =>{


    const range = () => _.get(form.values, name)
    return (
        <>
        {/* <ReactJson src={_.get(form.values, name)} name='range' style={{background: 'white'}}/>  */}
        <Group position='apart'>
            {label}      
            <Badge variant="dot">
                { range()?.length==2? `${range()[0]} -${range()[1]}`: `${min}-${max}`}
            </Badge>

        </Group>

        <RangeSlider 
            mt={10} 
            min={min}
            max={max}
            step={step}
            {...props}
            {...form.getInputProps( name, { type: props.type})}          
            defaultValue={defaultValue}                  
        />

        </>
    )


}


export default AgeRanger