import React, { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";

const OrderAlert = ({ form, name, title, scrollHeight = 300, ...props }) => {
  const pendingCases = _.get(form.values, "pendingCases");
  const incompleteCases = pendingCases?.filter((c) => !c.completed) ?? [];
  return (
    <>
      {incompleteCases.length > 0 && (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title={`Alert! Order has ${incompleteCases.length} pending 
          ${incompleteCases.length > 1 ? "cases" : "case"}.`}
          color="red"
        ></Alert>
      )}
    </>
  );
};

export default OrderAlert;
