import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
  TextInput,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";
import _ from "lodash";

const MonthSelect = ({ num = 12, onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const mArr = new Array(num);
    const months = [];

    for (const [index, m] of mArr.entries()) {
      months.push(moment().subtract(index, "M").format("YYYYMM"));
    }

    setOptions(months);
  }, [num]);

  return <Select data={options} onChange={onChange} inputMode="search" />;
};

const ReportCommissionStatement = ({}) => {
  const [period, setPeriod] = useState(moment().format("YYYYMM"));
  const [loading, setLoading] = useState(false);

  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      const result = await api.CommissionStatement.getReport({ period });
      setLoading(false);

      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Text size="xs">Please enter the period of records</Text>
      <Group mt="xs">
        {/* <MonthSelect onChange={(p) => setPeriod(p)} /> */}
        <TextInput onChange={(e) => setPeriod(e.target.value)} value={period} />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
        disabled={!period}
      >
        Download
      </Button>
      <></>
    </div>
  );
};

export default ReportCommissionStatement;
