import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";
import { WalletRow } from "./payrollTable";
import { Accordion } from "@mantine/core";

const PayrollPaymentBids = ({ form }) => {
  const { _id } = form.values;
  // console.log(form.values);
  return (
    _id && (
      <Accordion variant="separated" defaultValue={_id}>
        <WalletRow paymentId={_id} />
      </Accordion>
    )
  );
};

export default PayrollPaymentBids;
