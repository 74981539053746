import _ from "lodash";
import { Text, Title, Divider, Group, ThemeIcon, Badge } from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import { IconClick, IconCash, IconHeart } from "@tabler/icons";

export default function UserBasicInfo({ form }) {
  const [cellRender] = useCellRender();
  const user = form.values;
  const dataFontWeight = 600;

  const emptyStr = "-";

  const fields = [
    { title: "Staff Id", value: user.staffId ?? emptyStr },
    { title: "Name", value: user.name ?? emptyStr },
    { title: "Status", value: user.status ?? emptyStr },

    { title: "Alias", value: user.aliasName ?? emptyStr },
    { title: "Chinese Name", value: user.cname ?? emptyStr },

    { title: "Gender", value: user.gender ?? emptyStr },
    {
      title: "ID",
      value: cellRender.Mask(user.hkid, "*", 4, 4, "end") ?? emptyStr,
    },

    { title: "Contact Number", value: user.phone ?? emptyStr },

    {
      title: "Date of Birth",
      value: cellRender.Date(user.birthday, "LL") ?? emptyStr,
    },

    { title: "Education Level", value: user.educationLevel ?? emptyStr },

    { title: "Marital Status", value: user.mariage ?? emptyStr },
  ];
  return user && <InfoGridList fields={fields} values={user} />;
}
