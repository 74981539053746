import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  keyframes,
  List,
  Title,
  Textarea,
  Select,
  NumberInput,
} from "@mantine/core";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import { useDisclosure } from "@mantine/hooks";

import { Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconAlertCircle } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { DATA_PAYMENT_METHOD } from "../data/options";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useFormatter } from "../hooks/useFomatter";

const DebitNoteSettlePaymentButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);

  const {
    _id: id,
    code,
    total,
    costTotal,
    signedBy,
    consultants,
  } = mainForm.values;
  const [formatter] = useFormatter();
  const notValid =
    !id ||
    costTotal <= 0 ||
    total <= 0 ||
    _.isEmpty(signedBy) ||
    _.isEmpty(consultants);

  const formSchema = {
    title: "Settle Payment",
    description: "Settle debit note payment to provider",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,
    initialValues: {
      payDate: moment().toISOString(),
      paymentMethod: "Bank Transfer",
    },

    validate: {
      payDate: (value) => (!value ? "Please select the settlement date" : null),
      amount: (value, values) =>
        value != values.costTotal
          ? `Amount doesn't match ${formatter.currency(values.costTotal)}.`
          : null,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "New Settlement",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
          steps: [
            {
              key: "t1-1",
              label: "New Settlement",
              icon: IconSettings,
            },

            {
              key: "t1-2",
              label: "Confirmation",
              icon: IconSettings,
            },
          ],
        },
      ],
      fields: [
        {
          name: "settleDate",
          component: DatePicker,
          type: "datePicker",
          parent: "t1-1",
          props: {
            required: true,
            label: "Settle Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "amount",
          component: NumberInput,
          parent: "t1-1",
          props: {
            required: true,
            label: "Amount",
            precision: 2,
          },
        },

        {
          name: "paymentMethod",
          component: Select,
          parent: "t1-1",
          props: {
            required: true,
            label: "Payment Method",
            defaultValue: "Bank Transfer",
            data: DATA_PAYMENT_METHOD,
          },
        },

        {
          name: "remark",
          component: Textarea,
          parent: "t1-1",
          props: {
            label: "Remark",
          },
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "t1-2",
          props: {
            successText: [
              "Please confirm only if the settle amount is correct.",
              "The debit note will be marked as settled",
              "Corresponding commission will be dispatch to consultant once payment confirmed.",
            ],
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      // console.log(values);
      formAction.close();
      setLoading(true);
      const result = await api.DebitNote.confirmSettlement({
        id,
        settlement: values,
      });

      setLoading(false);
      if (result.success) {
        mainForm.updateAndSave({ settlement: values, status: "SETTLED" });
        mainForm.reload();
      } else {
        throw result.error;
      }
    } catch (error) {
      setLoading(false);
      showNotification({
        title: `Settlement Fail.`,
        icon: <IconAlertCircle size={18} />,
        message: error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = async () => {
    const settlement = mainForm?.values.settlement ?? {};
    if (!settlement) return;

    settlement.costTotal = mainForm?.values.costTotal;
    formAction.open({ mode: "edit", data: settlement });
  };

  return (
    <>
      <Button
        fullWidth
        color={"green"}
        onClick={handleClick}
        disabled={notValid}
        loading={loading}
      >
        Settle Payment
      </Button>
      {renderForm()}
    </>
  );
};

export default DebitNoteSettlePaymentButton;
