import React,{useRef} from "react"
import _ from "lodash"
import { ActionIcon, Group, List, Text, TextInput, Badge } from "@mantine/core"
import {IconTrash, IconCross, IconX } from "@tabler/icons"

const VariationOptionList = ({form: mainForm, name, label }) => {

    const options =  _.get(mainForm.values, name)
    const inputRef = useRef(null)

    const handleRemoveOption = (index) =>{
        // console.log("handleRemoveOption", index)
        options.splice(index,1)
        mainForm.setFieldValue(name, options)

    }

    const handleKeyDown = async (e) =>{
        if (e.key ==='Enter'){
            options.push({ visible: true, label: e.target.value})
            mainForm.setFieldValue(name, options)
            inputRef.current.value=""
        }
    }
    
    return (
        <>
            <TextInput ref={inputRef} label={label} placeholder="Add New Option" onKeyDown={handleKeyDown} mb={20} />
            
                {
                    <Group mt={10}>
                        {
                            options.map( (option, index)=>(
                                    <Group mr={25}>
                                        <Badge size="lg" mr={-10} variant="dot">{option.label}</Badge>
                                        <ActionIcon size={"xs"}  onClick={()=> handleRemoveOption(index)}>
                                            <IconX size={10} />
                                        </ActionIcon>  
                                    </Group>
                              
                            ))
                        }
                      
                    </Group>
                    
                }
                  

            
        </>
    )
}


export default VariationOptionList