import React, { useState, useEffect } from "react";

import _ from "lodash";
import ReactJson from "react-json-view";
import {
  Box,
  Checkbox,
  Group,
  List,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { useServerApi } from "../hooks/userServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { IconAlertTriangle } from "@tabler/icons";

const NO_PRODUCT_TYPE_SELECT = "Please select product type first.";

const QuoRequestCheckList = ({ form: mainForm, name, title = "Form" }) => {
  const checkList = _.get(mainForm.values, name);
  const productType = _.get(mainForm.values, "productType");
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();

  const fetchCheckList = async () => {
    if (!productType) return;
    if (!_.isEmpty(checkList)) return;

    const type = await api.getById({
      id: _.isObject(productType) ? productType._id : productType,
      apiEntity: "productType",
    });

    if (type.quoCheckList) {
      const list = type.quoCheckList.map((l) => ({
        question: l,
        answer: "",
      }));
      mainForm.setFieldValue("checkList", list);
      forceUpdate();
    }
  };

  useDeepCompareEffect(() => {
    // if (_.isEmpty(checkList)) return;
    console.log("change produc type", productType);
    fetchCheckList();
  }, [productType]);

  return (
    <>
      {/* <ReactJson
        src={mainForm.values}
        style={{ background: "white" }}
        collapsed
      /> */}
      <Text mb="xl" size={"xs"}>
        {title}
      </Text>

      {/* <List type="ordered" size="xs"> */}
      {checkList?.map((l, index) => (
        // <List.Item mb={"md"} style={{ width: "100%", background: "red" }}>
        <Box mb={"md"}>
          <Text size="xs" mb={1}>
            {index + 1}. {l.question}{" "}
          </Text>
          <Textarea
            size="xs"
            value={l.answer}
            onChange={(event) => {
              l.answer = event.currentTarget.value;
              forceUpdate();
            }}
          />
        </Box>
        // </List.Item>
      ))}
      {/* </List> */}

      {!productType && (
        <Group>
          <IconAlertTriangle size={15}></IconAlertTriangle>
          <Text size="xs">{NO_PRODUCT_TYPE_SELECT}</Text>
        </Group>
      )}
    </>
  );
};

export default QuoRequestCheckList;
