import React, { useEffect, useState } from "react";
import { FormSectionCard } from "../layout/page";
import InfoGridList from "../components/infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";

const PolicyFundValueSection = ({ order }) => {
  const { policyNumber } = order;
  const [cellRender] = useCellRender();

  const fields = [
    {
      title: "Total Contribution",
      value: order.totalContributions
        ? cellRender.Currency(order.totalContributions)
        : "--",
    },
    {
      title: "Current Investment Value",
      value: order.currentInvestmentValue
        ? cellRender.Currency(order.currentInvestmentValue)
        : "--",
    },
    {
      title: "Total Withdrawals",
      value: order.totalWithdrawals
        ? cellRender.Currency(order.totalWithdrawals)
        : "--",
    },

    {
      title: "Surrender Value",
      value: order.surrenderValue
        ? cellRender.Currency(order.surrenderValue)
        : "--",
    },
    {
      title: "Max Partial Surrender Value",
      value: order.maxPartialSurrender
        ? cellRender.Currency(order.maxPartialSurrender)
        : "--",
    },
    {
      title: "Last Premium Paid Date",
      value: order.lastPremiumPaidDate
        ? moment(order.lastPremiumPaidDate).format("LL")
        : "--",
    },
  ];

  return (
    <FormSectionCard title="Summary">
      <InfoGridList fields={fields} values={order} />
    </FormSectionCard>
  );
};

export default PolicyFundValueSection;
