import { Text, Title, createStyles, Button, Group, Space } from "@mantine/core";

import { IconPlus } from "@tabler/icons";

const useStyles = createStyles((theme) => ({}));

// function PageListHeader( {title, handleAdd, handleRefresh, handleClose}){
function PageListHeader({ title, actionButtons }) {
	const { classes } = useStyles();
	return (
		<>
			<Group position="apart">
				<Title order={2}>{title}</Title>
				<span>
					<Group>
						{actionButtons.map(({ onClick, label, variant = "filled" }) => (
							<Button size="xs" key={label} onClick={onClick} variant={variant}>
								{label}
							</Button>
						))}
					</Group>
				</span>
			</Group>
			<Space h="lg" />
		</>
	);
}

export default PageListHeader;
