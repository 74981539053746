import React from "react";
import {
    PageList,
    ENUM_FORM_DISPLAY,
    ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { TextInput, NumberInput, Select, Checkbox } from "@mantine/core";
import { DATA_ALLOWANCE_PAY_MODES } from "../../data/options";
import AutoPlanTerminationRules from "../../components/autoPlanTerminationRules";
import AllowanceTerms from "../../components/allowanceTerms";
import AllowancePlanStat from "../../components/allowancePlanStat";
const apiEntity = "allowancePlan";

const tableSchema = {
    showActionColumn: true,
    columns: [
        { field: "code", headerName: "Code" },
        { field: "name", headerName: "Name" },
        { field: "payMode", headerName: "Pay Mode" },
        { field: "payTerm", headerName: "Pay Term" },

        {
            field: "action",
            cellRender: ActionCellRender,
            isAction: true,
            actions: [
                {
                    name: "edit",
                    icon: IconPencil,
                    props: {
                        //   color:"red"
                    },
                },
            ],
            actionMenu: [
                {
                    name: "delete",
                    label: "Delete data",
                    icon: IconTrash,
                    props: {
                        color: "red",
                    },
                },
            ],
        },
    ],

    searchableFields: ["code", "name"],
};
const formSchema = {
    title: "Allowance Plan",
    display: {
        mode: ENUM_FORM_DISPLAY.INSIDE,
    },
    apiEntity,
    initialValues: {
        name: "",
        cname: "",
        terms: [],
        totalAmount: 0,
        payTerms: 0,
        payMode: "MONTHLY",
        terminationRules: [],
        lockPayment: true,
    },
    layout: {
        containers: [
            {
                key: "info-section",
                parent: null,
                type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                props: {
                    title: "Plan Info",
                    description: "Please fill in the basic info of the plan",
                },
            },

            {
                key: "autoterminate-section",
                parent: null,
                type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                props: {
                    title: "Auto Termination Rules",
                    description:
                        "When user wallet opening balance meet the below critiera, the allowance plan will be terminate auotmatically",
                },
            },

            {
                key: "info-g",
                parent: "info-section",
                type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
                props: {
                    grow: true,
                    gutter: 40,
                },
                cols: [
                    {
                        key: "info-g1",
                        props: {
                            span: 5,
                        },
                    },

                    {
                        key: "info-g2",
                        props: {
                            span: 6,
                        },
                    },
                ],
            },
        ],
        fields: [
            {
                name: "code",
                parent: "info-g1",
                component: TextInput,
                props: {
                    required: true,
                    label: "Code",
                    placeholder: "",
                },
            },
            {
                name: "name",
                parent: "info-g1",
                component: TextInput,
                props: {
                    required: true,
                    label: "Plan Name (Eng)",
                    placeholder: "Basic Plan 1",
                },
            },
            {
                name: "cname",
                parent: "info-g1",
                component: TextInput,
                props: {
                    required: true,
                    label: "Plan Name (Chi)",
                    placeholder: "Basic Plan 1",
                },
            },

            {
                name: "payMode",
                parent: "info-g1",
                component: Select,
                props: {
                    required: true,
                    label: "Pay Mode",
                    placeholder: "MONTHLY",
                    data: DATA_ALLOWANCE_PAY_MODES,
                    defaultValue: "UG",
                    searchable: true,
                },
            },
            {
                name: "lockPayment",
                parent: "info-g1",
                component: Checkbox,
                props: {
                    required: true,
                    label: "Lock Payment",
                    type: "checkbox",
                    mt: "xl",
                },
            },

            {
                name: "payTerm",
                parent: "info-g1",
                component: AllowancePlanStat,
                props: {},
            },
            {
                name: "terms",
                parent: "info-g2",
                component: AllowanceTerms,
                props: {},
            },

            {
                name: "terminationRules",
                parent: "autoterminate-section",
                component: AutoPlanTerminationRules,
                props: {},
            },
        ],
    },
};
const AllowancePlan = () => {
    return (
        <PageList
            title={"Allowance Plan"}
            apiEntity={apiEntity}
            tableSchema={tableSchema}
            formSchema={formSchema}
        />
    );
};

export default AllowancePlan;
