import React, { useEffect, useState, useRef } from "react";

import _ from "lodash";
import PieChart from "./chartWidget/PieChart";

const PolicyFundAllocationPieChart = ({ data, height = 340 }) => {
  return (
    <PieChart
      staticData={data}
      height={height}
      description={"The Fund proption of the policy."}
      chartProps={{}}
    />
  );
};

export default PolicyFundAllocationPieChart;
