import React from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { useCellRender } from "../../hooks/useCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { useAuthUser } from "react-auth-kit";
import moment from "moment";
import { Text, TextInput } from "@mantine/core";

import PayrollPaymentInfo from "../../components/payrollPaymentInfo";
import PayrollPaymentBids from "../../components/payrollPaymentBids";
import PayrollPaymentRefers from "../../components/payrollPaymentRefers";

const PayrollPayment = ({}) => {
  const apiEntity = "payrollPayment";
  const [cellRender] = useCellRender();
  const auth = useAuthUser();

  const tableSchema = {
    showActionColumn: true,
    preQuery: { wallet: auth()?.wallet },
    columns: [
      {
        field: "createdAt",
        headerName: "Date",
        sortable: true,
        cellRender: (col, data) => moment(data.createdAt).format("ll"),
      },
      { field: "code", headerName: "Code", sortable: true },

      {
        field: "bids",
        headerName: "Bids",
        sortable: true,
        cellRender: (col, data) => data.bids.length,
      },

      {
        field: "totalRefer",
        headerName: "Refer Payment",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.totalRefer),
      },
      {
        field: "totalSelf",
        headerName: "Consultant Payment",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.totalSelf),
      },
      {
        field: "total",
        headerName: "Total Payment",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.total),
      },

      {
        field: "status",
        cellRender: (col, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          //     name: "delete",
          //     label: "Delete data",
          //     icon: IconTrash,
          //     props: {
          //         color: "red",
          //     },
          // },
        ],
      },
    ],
  };

  const formSchema = {
    titleField: "code",
    display: {
      mode: ENUM_FORM_DISPLAY.INSIDE,
      size: "xl", //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,

    layout: {
      containers: [
        {
          key: "section-info",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          parent: null,
          props: {
            title: "Payroll Info",
            mt: "xl",
          },
        },

        {
          key: "main-tab",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Bid Details",
            mt: "xl",
          },
          tab: [
            {
              key: "tab-bids",
              label: "Bid Details",
              icon: IconSettings,
            },
            {
              key: "tab-referal",
              label: "Refers",
              icon: IconSettings,
            },
          ],
        },
      ],

      fields: [
        {
          name: "info",
          parent: "section-info",
          component: PayrollPaymentInfo,
          props: {},
        },
        {
          name: "info",
          parent: "tab-bids",
          component: PayrollPaymentBids,
          props: {},
        },

        {
          name: "info",
          parent: "tab-referal",
          component: PayrollPaymentRefers,
          props: {},
        },
      ],
    },
  };
  return (
    <PageList
      title={"My Payroll"}
      apiEntity={apiEntity}
      showSaveBut
      tableSchema={tableSchema}
      formSchema={formSchema}
      showAddButton={false}
      readOnly={true}
    />
  );
};

export default PayrollPayment;
