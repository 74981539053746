import React, { useEffect, useState } from "react";
import _ from "lodash";
import CardStat from "./cardStat";
import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Group,
  Space,
} from "@mantine/core";
import {
  IconArrowUpRight,
  IconArrowDownRight,
  IconClick,
  IconCircleCheck,
  IconCircleX,
  IconHeart,
} from "@tabler/icons";
import moment from "moment";

import { useServerApi } from "../hooks/userServerApi";

const ClientRiskStats = ({ form, clientIDField = "_id" }) => {
  const clientID = _.get(form.values, clientIDField);
  const [api] = useServerApi();

  const [riskStats, setRiskStats] = useState([]);

  const mapStatCardStyle = (stat) => {
    stat.icon = stat.valid ? IconCircleCheck : IconCircleX;
    stat.color = stat.valid ? "green" : "orange";
  };

  const fetchClientRiskData = async () => {
    if (!clientID) return;
    const result = await api.Client.getRiskStats(clientID);
    if (!result) return;
    const stats = result.data ?? [];
    stats.forEach(mapStatCardStyle);
    setRiskStats(stats);
  };

  useEffect(() => {
    fetchClientRiskData();
  }, [clientID]);

  return (
    <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
      {riskStats?.map((stat, index) => (
        <CardStat key={index} stat={stat} />
      ))}
    </SimpleGrid>
  );
};

export default ClientRiskStats;
