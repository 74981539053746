import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
  Button,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
  IconSquareDot,
  IconInfoCircle,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const PayrollPaymentInfo = ({ form, cols = 5, ...props }) => {
  const [formatter] = useFormatter();
  const { total, totalSelf, status, bids, totalRefer, payroll } = form.values;
  const [api] = useServerApi();
  const { _id: id } = form.values;

  const printPayrollPayment = async () => {
    const result = await api.PayrollPayment.print(id);
    // console.log("printPayrollPayment", result);
    window.open(result.data.url);
  };

  const stats = [
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Payroll Batch",
      msg: payroll?.code,
    },

    {
      icon: IconSquareDot,
      color: "#ffec00",
      label: "Bids",
      msg: bids?.length,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Total Refer",
      msg: formatter.currency(totalRefer),
    },
    {
      icon: IconCircleCheck,
      color: "#00b341",
      label: "Payments",
      msg: formatter.currency(total),
    },
    {
      icon: IconInfoCircle,
      color: "#00abfb",
      label: "Status",
      msg: status,
    },
  ];
  return (
    <>
      {/* <ReactJson src={stats} /> */}
      <SimpleGrid
        cols={cols}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "md", cols: 2 },
        ]}
        {...props}
      >
        {stats?.map((stat, index) => (
          <CardStat key={index} stat={stat} />
        ))}
      </SimpleGrid>
      <Group position="right" mt={"xl"}>
        <Button size="xs" onClick={printPayrollPayment}>
          Print Payroll
        </Button>
      </Group>
    </>
  );
};

export default PayrollPaymentInfo;
