import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import _ from "lodash";
const OrderProductDetail = ({ form, name }) => {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const product = order?.product;

  const fields = [
    { title: "", value: cellRender.ProductInfo(product) },
    { title: "ID", value: product?.code ?? "-" },
    { title: "Type", value: product?.type?.name ?? "-" },
    { title: "Provider", value: product?.provider?.shortName ?? "-" },
  ];

  return (
    product && (
      <InfoGridList fields={fields} values={product} dataFontWeight={600} />
    )
  );
};

export default OrderProductDetail;
