import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Checkbox, Text, List, Box, Group, Button } from "@mantine/core";
import ReactJson from "react-json-view";

const OrderSubmissionCheckList = ({ form: mainForm, internal = false }) => {
  const [checkList, setCheckList] = useState([]);
  const product = _.get(mainForm.values, "product");

  useEffect(() => {
    try {
      if (!product || !product.checkList) setCheckList([]);

      let list = product?.checkList?.filter((item) =>
        internal ? item.frontier : item.provider
      );

      //Suppose is IDs only
      let submitList = _.get(
        mainForm.values,
        internal ? "receiveCheckList" : "submitCheckList"
      );

      //Search By name for initial issues
      //   if (
      //     _.isEmpty(submitList) &&
      //     mainForm.values &&
      //     mainForm.values.checkList
      //   ) {
      //     const clist = internal
      //       ? mainForm.values?.checkList?.internal
      //       : mainForm.values?.checkList?.provider;

      //     if (_.isArray(clist)) submitList = clist.filter((l) => l.checked);

      //     const newSubList = [];
      //     list?.forEach((item) => {
      //       let index = submitList.findIndex((l) => l.name === item.name);
      //       if (index != -1) newSubList.push(item._id);
      //     });
      //     submitList = newSubList;
      //   }

      //Set checked on product check list
      list?.forEach((item) => {
        let index = submitList.findIndex(
          (l) => l === item._id || l === item.name
        );
        if (index != -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      // console.log("here3", list);
      setCheckList(list);
    } catch (error) {
      console.log(error);
    }
  }, [product?.checkList]);

  const checkAll = (checked) => {
    try {
      checkList.forEach((item) => (item.checked = checked));

      setCheckList([...checkList]);

      //   if (internal) {
      //     mainForm.setFieldValue("mainForm.values.checkList.internal", checkList);
      //   } else {
      //     mainForm.setFieldValue("mainForm.values.checkList.provider", checkList);
      //   }

      mainForm.setFieldValue(
        internal ? "receiveCheckList" : "submitCheckList",
        checkList
          .filter((item) => item.checked === true)
          .map((item) => item._id ?? item.name)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCheck = (checked, item) => {
    try {
      //   console.log("oncheck", checked, item);
      item.checked = checked;
      setCheckList([...checkList]);

      mainForm.setFieldValue(
        internal ? "receiveCheckList" : "submitCheckList",
        checkList
          .filter((item) => item.checked === true)
          .map((item) => item._id ?? item.name)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* {internal ? "Internal" : "Submission"} */}
      {
        /* <ReactJson
        src={mainForm.values.receiveCheckList}
        collapsed
        style={{ background: "white" }}
      />
      <ReactJson
        src={mainForm.values.submitCheckList}
        collapsed
        style={{ background: "white" }}
      />*/
        // <ReactJson src={checkList} collapsed style={{ background: "white" }} />
      }

      <Group position="right" spacing={"xs"}>
        <Button size="xs" variant="light" onClick={() => checkAll(true)}>
          Check All
        </Button>
        <Button
          size="xs"
          variant="light"
          color={"red"}
          onClick={() => checkAll(false)}
        >
          Clear
        </Button>
      </Group>
      {checkList &&
        checkList
          //   ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((item) => (
            <Checkbox
              mb={"sm"}
              key={item._id ?? item.name}
              label={item.name}
              checked={item.checked}
              onChange={(e) => handleOnCheck(e.target.checked, item)}
            />
          ))}
    </>
  );
};

export default OrderSubmissionCheckList;
