import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import ReactJson from "react-json-view";
import { UserCard } from "../../components/consultantList";
import { Page } from "../../layout/page";
import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useDisclosure } from "@mantine/hooks";

import {
  Badge,
  Box,
  Grid,
  Group,
  Text,
  UnstyledButton,
  Drawer,
  Divider,
  ScrollArea,
  Modal,
} from "@mantine/core";
import { useServerApi } from "../../hooks/userServerApi";
import { useFormRender } from "../../hooks/useFormRender";
import { Tabs } from "@mantine/core";

import ForcastPayrollBarChart from "../../components/forcastPayrollBarChart";
import OrderStatics from "../../components/orderStatics";

import MyTeamMemberDetailPage from "../../components/myTeamMemberDetailPage";
import UserOrderList from "../../components/userOrderList";
import UserOrderHistory from "../../components/userOrderHistory";
import UserClientList from "../../components/userClientList";

const TeamMemberDetailPage = ({ memberId }) => {
  const [member, setMember] = useState(null);
  const [api] = useServerApi();

  const fetchMemberDetail = async () => {
    const data = await api.getById({ apiEntity: "user", id: memberId });
    setMember(data);
  };

  useEffect(() => {
    fetchMemberDetail();
  }, [memberId]);

  return (
    <>
      {member && (
        <>
          <Divider mb={"xl"} />
          <UserCard value={member} mb={"xl"} />

          <Tabs defaultValue="stats">
            <Tabs.List>
              <Tabs.Tab value="stats">Order Statistic</Tabs.Tab>
              <Tabs.Tab value="order">Orders</Tabs.Tab>
              <Tabs.Tab value="client">Clients</Tabs.Tab>
              <Tabs.Tab value="forecast">Payroll Forecast</Tabs.Tab>
              <Tabs.Tab value="personal">Personal Info</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="stats" pt="xl">
              <OrderStatics userId={memberId} />
            </Tabs.Panel>

            <Tabs.Panel value="order" pt="xl">
              <UserOrderHistory id={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="client" pt="xl">
              <UserClientList userId={memberId} />
            </Tabs.Panel>

            <Tabs.Panel value="forecast" pt="xl">
              <ForcastPayrollBarChart memberId={memberId} />
            </Tabs.Panel>

            <Tabs.Panel value="personal" pt="xl">
              <MyTeamMemberDetailPage memberId={memberId} />
            </Tabs.Panel>
          </Tabs>
        </>
      )}
    </>
  );
};

const Me = ({ handleMemberSelect, ...props }) => {
  const auth = useAuthUser();

  return (
    <UnstyledButton onClick={() => handleMemberSelect(auth().id)}>
      <Box {...props}>
        <Text transform="uppercase" size="xs" mb="sm">
          Team Lead
        </Text>
        <UserCard value={auth()}></UserCard>
      </Box>
    </UnstyledButton>
  );
};
const TeamLevelMembers = ({ teamMemberLevel, handleMemberSelect }) => {
  const { members, level } = teamMemberLevel;

  return (
    teamMemberLevel && (
      <Box mb={60}>
        <Group mb="lg">
          <Text transform="uppercase" size="xs">
            Level {level}
          </Text>
          <Badge size="xs">{members?.length ?? 0}</Badge>
        </Group>
        <>
          {/* <ReactJson
					src={teamMemberLevel}
					style={{ background: "white" }}
					collapsed
				></ReactJson> */}

          <Grid columns={3}>
            {members.map((m) => (
              <Grid.Col span={1}>
                <UnstyledButton
                  key={m._id}
                  onClick={() => handleMemberSelect(m?._id)}
                >
                  <UserCard value={m} mr="xl" mb="xl" />
                </UnstyledButton>
              </Grid.Col>
            ))}
          </Grid>
        </>
      </Box>
    )
  );
};

const LevelMembers = ({ numLevel = 2, teamLeadId, handleMemberSelect }) => {
  const [teamMembers, setTeamMembers] = useState([]);

  const [api] = useServerApi();

  const fetchTeamMembers = async (teamLeadId, numLevel) => {
    if (!teamLeadId || numLevel < 1) return;
    const data = await api.User.getTeamMembers(teamLeadId, numLevel);
    setTeamMembers(data);
    // console.log("Members", data);
  };

  useEffect(() => {
    fetchTeamMembers(teamLeadId, numLevel);
  }, [teamLeadId]);

  return (
    <>
      {teamMembers?.map((tm, i) => (
        <TeamLevelMembers
          key={i}
          teamMemberLevel={tm}
          handleMemberSelect={handleMemberSelect}
        />
      ))}
    </>
  );
};

const MyTeam = ({}) => {
  const title = "My Team";
  const [api] = useServerApi();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const auth = useAuthUser();

  const handleMemberSelect = async (memberId) => {
    // console.log("member click", memberId);
    setSelectedMember(memberId);
    // await api.User.getTeamMemberDetail();
    open();
  };

  return (
    <Page title={title}>
      <Modal
        opened={opened}
        onClose={close}
        title="Team Member Details"
        position="right"
        size="1000px"
        padding={"xl"}
      >
        <TeamMemberDetailPage memberId={selectedMember} />
      </Modal>

      <Me mb={60} mt={50} handleMemberSelect={handleMemberSelect} />
      <LevelMembers
        numLevel={2}
        teamLeadId={auth().id}
        handleMemberSelect={handleMemberSelect}
      />
    </Page>
  );
};

export default MyTeam;
