import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  Group,
  Grid,
  SimpleGrid,
  Tabs,
} from "@mantine/core";
import { ResponsiveLine, Line } from "@nivo/line";

import { Page } from "../layout/page";

import LineChart from "../components/chartWidget/LineChart";
import PieChart from "../components/chartWidget/PieChart";
import BarChart from "../components/chartWidget/BarChart";
import TreemapChart from "../components/chartWidget/TreemapChart";
import CalendarChart from "../components/chartWidget/CalendarChart";
import FunnelChart from "../components/chartWidget/funnelChart";
import ForcastPayrollBarChart from "../components/forcastPayrollBarChart";
import { IconCircleCaretUp, IconCoinMonero } from "@tabler/icons";

import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/userServerApi";
import { PeriodFilter } from "../components/periodFilter";
import { useFormatter } from "../hooks/useFomatter";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";
import _ from "lodash";

import CardStat from "../components/cardStat";
import OrderStatics from "../components/orderStatics";

function DashboardConsultant({ role, numQuarters = 8 }) {
  const auth = useAuthUser();
  const [api] = useServerApi();

  const [period, setPeriod] = useState(null);
  const [productTypePieData, setproductTypePieData] = useState([]);
  const [productProviderPieData, setproductProviderPieData] = useState([]);
  const [orderSummaryData, setorderSummaryData] = useState([]);
  const [formatter] = useFormatter();

  // const fetchOrderStats = async () => {
  //   if (!period || !auth() || !auth().id) return;

  //   const data = await api.Order.getStats(period, auth().id);
  //   setOrderProductStats(data);
  //   setOrderSummaryStats(data);
  // };

  const resetStatData = () => {
    // console.log("resetStatData");
    setproductTypePieData([]);
    setproductProviderPieData([]);
  };

  const setOrderProductStats = (data) => {
    if (!data || _.isEmpty(data.orderProductStats)) return resetStatData();

    _.isEmpty(data.orderProductStats.productType)
      ? setproductTypePieData([])
      : setproductTypePieData(
          data.orderProductStats.productType.map((c) => ({
            id: c.name,
            value: c.count,
          }))
        );

    setproductProviderPieData(
      data.orderProductStats.productProvider?.map((c) => ({
        id: c.shortName,
        value: c.count,
      })) ?? []
    );
  };

  const setOrderSummaryStats = (data) => {
    try {
      if (!data || _.isEmpty(data.orderSummary)) return setorderSummaryData([]);

      setorderSummaryData([
        {
          icon: IconCircleCaretUp,
          color: "#8278F2",
          label: "Submit Orders",
          msg: data.orderSummary.find((d) => d._id === "ALL")?.count ?? 0,
        },
        {
          icon: IconCoinMonero,
          color: "#8278F2",
          label: "Submit FY Premium",
          msg: formatter.currency(
            data.orderSummary.find((d) => d._id === "ALL")?.yearPremium ?? 0
          ),
        },

        {
          icon: IconCoinMonero,
          color: "#8278F2",
          label: "Submit FY Commission",
          msg: formatter.currency(
            data.orderSummary.find((d) => d._id === "ALL")
              ?.yearConsultantCommission ?? 0
          ),
        },
        {
          icon: IconCircleCaretUp,
          color: "#0ED1EA",
          label: "Inforced Orders",
          msg: data.orderSummary.find((d) => d._id === "INF")?.count ?? 0,
        },
        {
          icon: IconCoinMonero,
          color: "#0ED1EA",
          label: "Inforced FY Premium",
          msg: formatter.currency(
            data.orderSummary.find((d) => d._id === "INF")?.yearPremium ?? 0
          ),
        },
        {
          icon: IconCircleCaretUp,
          color: "#ffec00",
          label: "PENDING Orders",
          msg:
            data.orderSummary.find(
              (d) =>
                d._id === "SUB" ||
                d._id === "RCV" ||
                d._id === "AIP" ||
                d._id === "PND"
            )?.count ?? 0,
        },
        {
          icon: IconCoinMonero,
          color: "#ffec00",
          label: "PENDING FY Premium",
          msg: formatter.currency(
            data.orderSummary.find(
              (d) =>
                d._id === "SUB" ||
                d._id === "RCV" ||
                d._id === "AIP" ||
                d._id === "PND"
            )?.yearPremium ?? 0
          ),
        },

        {
          icon: IconCircleCaretUp,
          color: "#fd0061",
          label: "TERMINATED Orders",
          msg:
            data.orderSummary.find((d) => d._id === "WTH" || d._id === "SUR")
              ?.count ?? 0,
        },
        {
          icon: IconCoinMonero,
          color: "#fd0061",
          label: "TERMINATED FY Premium",
          msg: formatter.currency(
            data.orderSummary.find((d) => d._id === "WTH" || d._id === "SUR")
              ?.yearPremium ?? 0
          ),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  // useDeepCompareEffect(() => {
  //   fetchOrderStats();
  // }, [period]);

  const lineChartProps = {
    axisBottom: {
      legend: "Date",
    },
    axisLeft: {
      legend: "Number of Orders",
    },
  };

  const lineMapper = (result) => [
    {
      id: "Orders",
      data: result ? result.map((r) => ({ x: r._id, y: r.count })) : [],
    },
  ];

  return (
    <>
      {/* <ReactJson src={auth()} style={{ background: "white" }}></ReactJson> */}

      {/* <PeriodFilter
        // handleRefresh={() => fetchOrderStats()}
        handlePeriodChange={(p) => setPeriod(p)}
        numPeriods={numQuarters}
      /> */}

      <OrderStatics
        userId={auth()?.id}
        height="auto"
        InScrollArea={false}
        remark="Order sign date is used as the date range filter. Split case count as 0.5. "
      />

      {/* <LineChart
				title={"Orders"}
				height={500}
				description={"The total number of orders through out the range"}
				chartProps={lineChartProps}
				dataAggreation={{
					apiEntity: "order",
					aggregations: [
						{
							$group: {
								_id: { $substr: ["$signedDate", 0, 7] },
								count: { $sum: 1 },
							},
						},
					],
					mapper: lineMapper,
				}}
				useSampleData={true}
			/> */}

      {/* <Grid>
                <Grid.Col span={6}>
                    <PieChart
                        data={pieData}
                        title={"Fund Profolio"}
                        height={500}
                        description={"The Fund proption of the policy."}
                        chartProps={{}}
                        useSampleData={true}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <BarChart
                        title={"Orders By Quarter"}
                        height={500}
                        description={
                            "The number of order based on order types within quaters."
                        }
                        chartProps={{
                            keys: ["GI", "LIFE", "MPF"],
                            layout: "horizontal",
                            indexBy: "period",
                            groupMode: "grouped",
                        }}
                    />
                </Grid.Col>
            </Grid> */}

      {/* <TreemapChart
				data={treemapData}
				title={"Product Tree Map"}
				height={500}
				description={"The Tree map to show the popularity of products."}
				chartProps={{}}
			/> */}
      {/* 
			<Grid>
				<Grid.Col span={6}>
					<CalendarChart
						data={calendarData}
						title={"Ticket Status"}
						height={300}
						description={"Number of tickets"}
						chartProps={{
							from: "2021-01-01",
							to: "2022-12-12",
						}}
					/>
				</Grid.Col>
				<Grid.Col span={6}>
					<FunnelChart
						data={funnelData}
						title={"Consultant Salary distrubtion"}
						description={"The salary range of consultant distrubtion"}
					/>
				</Grid.Col>
			</Grid> */}

      <ForcastPayrollBarChart />
    </>
  );
}

export default DashboardConsultant;
