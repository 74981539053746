import _ from "lodash";
import {
  Text,
  Title,
  Divider,
  Group,
  ThemeIcon,
  Badge,
  ActionIcon,
} from "@mantine/core";
import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  IconClick,
  IconCash,
  IconHear,
  IconCake,
  IconMailbox,
  IconFaceId,
  IconPhone,
  IconLanguage,
  IconFlag,
  IconSchool,
  IconLink,
  IconIdBadge,
  IconBarcode,
} from "@tabler/icons";

import { useServerApi } from "../hooks/userServerApi";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const OrderApplicantDetail = ({
  form,
  name = "client",
  size = "sm",
  ...props
}) => {
  const [client, setClient] = useState();
  const [api] = useServerApi();
  const navigate = useNavigate();
  let formValue = _.get(form.values, name);

  const auth = useAuthUser();
  const getEntityLink = () => {
    const { userRole } = auth();
    if (!userRole) return null;

    const roleLink = userRole.entityLinks?.find((l) => l.entity === "client");
    if (!roleLink || !roleLink.link) return null;

    return `.${roleLink.link}`;
  };

  const entityLink = getEntityLink();

  useEffect(() => {
    typeof formValue === "object"
      ? setClient(formValue)
      : fetchClient(formValue);
  }, [formValue]);

  const fetchClient = async (clientId) => {
    if (!clientId) return;
    let client = await api.getById({ apiEntity: "client", id: clientId });
    setClient(client);
  };

  const ClientInfo = ({ client }) => {
    const {
      name,
      cname,
      status,
      vulnerable,
      orders,
      email,
      phone,
      address,
      language,
      id,
      idType,
      educationLevel,
      birthday,
      residentialCountry,
      placeOfBirth,
    } = client;

    const iconSize = 18;

    return (
      <>
        <Group noWrap align={"center"}>
          {/* <Text size={size} color="dimmed">Name</Text> */}
          <IconFaceId size={iconSize} />

          <Text size={size} weight={500} transform="capitalize">
            {name}
          </Text>
          {entityLink && (
            <a
              href={`/${entityLink}?id=${client._id}&mode=edit`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <ActionIcon size={"xs"}>
                <IconLink></IconLink>
              </ActionIcon>
            </a>
          )}
        </Group>
        <Group mt={10} noWrap>
          <IconLanguage size={iconSize} />
          <Text size={size} weight={500}>
            {cname}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed" >Contact</Text> */}
          <IconBarcode size={iconSize} />
          <Text size={size} weight={500} transform="uppercase">
            {id}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed">Email</Text> */}
          <IconMailbox size={iconSize} />
          <Text size={size} weight={500} transform="lowercase">
            {email}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed" >Contact</Text> */}
          <IconPhone size={iconSize} />
          <Text size={size} weight={500} transform="lowercase">
            {phone}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed">Language</Text> */}
          <IconLanguage size={iconSize} />
          <Text size={size} weight={500} transform="capitalize">
            {language}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed">Country</Text> */}
          <IconFlag size={iconSize} />
          <Text size={size} weight={500} transform="capitalize">
            {placeOfBirth}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} color="dimmed" weight={500}>Education</Text> */}
          <IconSchool size={iconSize} />
          <Text size={size} weight={500} transform="capitalize">
            {educationLevel}
          </Text>
        </Group>

        <Group mt={10} noWrap>
          {/* <Text size={size} weight={500}>Birthday</Text> */}
          <IconCake size={18} />
          <Text size={size} weight={500}>
            {moment(birthday).isValid() ? moment(birthday).format("LL") : ""}
          </Text>
        </Group>
      </>
    );
  };

  return <>{client && <ClientInfo client={client} />}</>;
};

export default OrderApplicantDetail;
