import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";

import {
  IconCirclePlus,
  IconCircleCheck,
  IconCircleMinus,
} from "@tabler/icons";

import CardStat from "./cardStat";

const AllowancePageStatus = ({ cols = 5, ...props }) => {
  const [api] = useServerApi();

  const [stats, setStats] = useState([]);

  const fetchStats = async () => {
    try {
      let result = await api.Allowance.getStatSummary();

      setStats([
        {
          icon: IconCirclePlus,
          color: "#0ED1EA",
          label: "Running",
          msg: result.stats.RUNNING ?? 0,
        },
        {
          icon: IconCircleMinus,
          color: "#ff4500",
          label: "TERMINATED",
          msg: result.stats.TERMINATED ?? 0,
        },
        {
          icon: IconCircleCheck,
          color: "#00b341",
          label: "COMPLETED",
          msg: result.stats.COMPLETED ?? 0,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <>
      <Accordion defaultValue="grids">
        <Accordion.Item value="grids">
          <Accordion.Control></Accordion.Control>
          <Accordion.Panel>
            <SimpleGrid
              cols={cols}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              {...props}
            >
              {stats?.map((stat, index) => (
                <CardStat key={index} stat={stat} />
              ))}
            </SimpleGrid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AllowancePageStatus;
