import { Button, Group } from "@mantine/core";
import React from "react";
import _ from "lodash";

import OrderConfirmPolicyButton from "./orderConfirmPolicyButton";
import OrderEditPolicyButton from "./orderEditPolicyButton";

import OrderReceiveActionButton from "./orderReceiveActionButton";
import OrderAddRiderActionButton from "./orderAddRiderActionButton";
import OrderAddServiceButton from "./orderAddServiceButton";

import OrderPrintSubmissionCoverSheetActionButton from "./orderPrintSubmissionCoverSheetActionButton";
import OrderPrintReceiveCoverSheetActionButton from "./orderPrintReceiveCoverSheetActionButton";

import OrderTerminateActionButton from "./orderTerminateActionButton";
import OrderEditButton from "./orderEditButton";
import OrderAdjustButton from "./orderAdjustButton";
import OrderReinstantiateButton from "./orderReinstantiateButton";

import NewTicketButton from "./newTicketButton";
import OrderDeliveryPolicyButton from "./orderDeliverPolicyButton";
import OrderCancelButton from "./orderCancelButton";
import OrderRedoCancelButton from "./orderRedoCancelButton";

const ORDER_STATUS_ALLOWED_TO_EDIT = [
  "DFT",
  "CAN",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOW_CONSULTANT_TO_EDIT = ["DFT", "CAN"];
const ORDER_STATUS_ALLOWED_TO_EDIT_POLICY = [
  "INF",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
];

const ORDER_STATUS_ALLOWED_TO_RECEIVE = ["DFT", "RCV"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = [
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOWED_TO_REINSTANT = [
  "TER",
  "WTH",
  "COL",
  "SUR",
  "REJ",
  "LAP",
];

const OrderActionList = ({ form, role }) => {
  // let status = _.get(form.values, "status");
  const { status, code, consultants, _id, policyNumber } = form.values;
  // console.log("OrderActionList", consultants);
  const allowToEdit = () => ORDER_STATUS_ALLOWED_TO_EDIT.includes(status);
  const allowToEditByConsultant = () =>
    ORDER_STATUS_ALLOW_CONSULTANT_TO_EDIT.includes(status);
  const allowToEditPolicy = () =>
    ORDER_STATUS_ALLOWED_TO_EDIT_POLICY.includes(status) &&
    !_.isEmpty(policyNumber);
  const allowToAdjust = () => ORDER_STATUS_ALLOWED_TO_ADJUST.includes(status);
  const allowToConfirm = () => ORDER_STATUS_ALLOWED_TO_CONFIRM.includes(status);
  const allowToReceive = () => ORDER_STATUS_ALLOWED_TO_RECEIVE.includes(status);
  const allowToReinstantiate = () =>
    ORDER_STATUS_ALLOWED_TO_REINSTANT.includes(status);

  const allowToDeliver = () => status === "INF";
  const allowToCancel = () => status === "DFT";
  const allowToRedoCancel = () => status === "CAN";

  const showAdjustButton = role === "OPERATION" || role === "MANAGEMENT";
  const showReceiveButton = role === "OPERATION";
  const showReceiveCoverSheet =
    role === "CONSULTANT" || role === "MANAGEMENT" || role === "OPERATION";
  const showSubmitCoverSheet = role === "OPERATION" || role === "MANAGEMENT";
  const showConfirmPolicy = role === "OPERATION" || role === "MANAGEMENT";
  const showEditOrderButton = role == "OPERATION" || role === "MANAGEMENT";
  const showTerminateOrderButton = role == "OPERATION" || role === "MANAGEMENT";
  const showDeliverPolicy = role == "OPERATION" || role === "MANAGEMENT";
  const showAddRiderButton =
    role == "CONSULTANT" || role == "OPERATION" || role === "MANAGEMENT";

  const showEditButtonByConsultant = role == "CONSULTANT";

  const showReinstaniateOrderButton =
    role === "MANAGEMENT" || role === "OPERATION";

  const showCancelOrderButton = role === "CONSULTANT";
  const showRedoCancelOrderButton = role === "CONSULTANT";

  return (
    <>
      {/* Role: {role} */}
      <Group mt={10}>
        {showAddRiderButton && <OrderAddRiderActionButton mainForm={form} />}
        {/* <OrderAddServiceButton mainForm={form} /> */}
        {allowToReceive() && showReceiveButton && (
          <OrderReceiveActionButton mainForm={form} />
        )}
        {allowToEdit() && showEditOrderButton && (
          <OrderEditButton mainForm={form} />
        )}

        {showEditButtonByConsultant && allowToEditByConsultant() && (
          <OrderEditButton mainForm={form} />
        )}

        {showAdjustButton && allowToAdjust() && (
          <OrderAdjustButton mainForm={form} />
        )}

        {showReceiveCoverSheet && (
          <OrderPrintReceiveCoverSheetActionButton mainForm={form} />
        )}

        {showSubmitCoverSheet && (
          <OrderPrintSubmissionCoverSheetActionButton mainForm={form} />
        )}

        {allowToEditPolicy() && showConfirmPolicy && (
          <OrderEditPolicyButton mainForm={form} />
        )}

        {allowToConfirm() && showConfirmPolicy && (
          <OrderConfirmPolicyButton mainForm={form} />
        )}

        {allowToDeliver() && showDeliverPolicy && (
          <OrderDeliveryPolicyButton mainForm={form} />
        )}

        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="order"
          ticketType={"TK-ORDER"}
          members={consultants?.map((c) => c._id)}
        />

        {showTerminateOrderButton && (
          <OrderTerminateActionButton mainForm={form} />
        )}

        {showCancelOrderButton && allowToCancel() && (
          <OrderCancelButton mainForm={form} />
        )}
        {showRedoCancelOrderButton && allowToRedoCancel() && (
          <OrderRedoCancelButton mainForm={form} />
        )}
        {showReinstaniateOrderButton && allowToReinstantiate() && (
          <OrderReinstantiateButton mainForm={form} />
        )}
      </Group>
    </>
  );
};

export default OrderActionList;
