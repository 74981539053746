import React, { useState, useEffect } from "react";
import moment from "moment";
import { TextInput, Switch } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
    IconTrash,
    IconCheck,
    IconCircleCheck,
    IconAlertOctagon,
} from "@tabler/icons";
import _ from "lodash";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";

const ConsultantCertificateList = ({
    form: mainForm,
    name,
    readOnly,
    ...props
}) => {
    const certificates = _.get(mainForm.values, name);
    const [rows, setRows] = useState([]);

    const tableSchema = {
        hidePagination: true,

        columns: [
            // {   field: '_id',       headerName:"ID"            },
            {
                field: "examDate",
                headerName: "Exam Date",
                cellRender: (col, data) =>
                    moment(_.get(data, col.field)).format("LL"),
            },
            { field: "paper", headerName: "Certificate" },

            {
                field: "expiryDate",
                headerName: "Expiry",
                cellRender: (col, data) =>
                    moment(_.get(data, col.field)).format("LL"),
            },
            { field: "candidateNo", headerName: "Candidate No" },
            {
                field: "pass",
                headerName: "Result",
                cellRender: (col, data) =>
                    _.get(data, col.field) ? "Pass" : "Fail",
            },
        ],
        searchableFields: ["paper"],
    };

    if (!readOnly) {
        tableSchema.columns.push({
            cellRender: ActionCellRender,
            isAction: true,
            actions: [
                {
                    name: "delete",
                    label: "Delete data",
                    icon: IconTrash,
                    props: {},
                },
            ],
            actionMenu: [],
        });
    }

    const formSchema = {
        title: "IIQE Certificate",
        description: "please fill in the form",

        display: {
            mode: ENUM_FORM_DISPLAY.MODAL,
            size: "xl",
        },
        initialValues: {},

        layout: {
            containers: [
                {
                    key: "basicInfo-g",
                    parent: null,
                    type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
                    cols: [
                        {
                            key: "basicInfo-g1",
                            props: {
                                span: 6,
                            },
                        },
                        {
                            key: "basicInfo-g2",
                            props: {
                                span: 6,
                            },
                        },
                    ],
                },
                {
                    key: "file-section",
                    parent: null,
                    type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                    props: {
                        title: "Files",
                    },
                },
            ],

            fields: [
                {
                    name: "paper",
                    component: TextInput,
                    parent: "basicInfo-g1",
                    props: {
                        required: true,
                        label: "Paper",
                        placeholder: "Fill in the paper Name",
                    },
                },
                {
                    name: "examDate",
                    component: DatePicker,
                    parent: "basicInfo-g1",
                    type: "datePicker",
                    props: {
                        required: true,
                        label: "Exam Date",
                        zIndex: 1000,
                        placeholder: "Pick Date",
                        allowFreeInput: true,
                    },
                },
                {
                    name: "expiryDate",
                    component: DatePicker,
                    parent: "basicInfo-g2",
                    type: "datePicker",
                    props: {
                        required: true,
                        label: "Expiry Date",
                        zIndex: 1000,
                        placeholder: "Pick Date",
                        allowFreeInput: true,
                    },
                },
                {
                    name: "candidateNo",
                    component: TextInput,
                    parent: "basicInfo-g1",
                    props: {
                        required: true,
                        label: "Candidate No",
                        placeholder: "Fill in the Candidate Number",
                    },
                },
                {
                    name: "pass",
                    component: Switch,
                    parent: "basicInfo-g2",
                    props: {
                        type: "checkbox",
                        label: "Is Pass?",
                        mt: 20,
                    },
                },

                {
                    name: "files",
                    component: FileList,
                    parent: "file-section",
                    props: {},
                },
            ],
        },
    };

    const onFormSubmit = (values) => {
        // console.log("onFormSubmit", values);
        setRows(values); //Update UI
        _.set(mainForm.values, name, values); //Update MainForm to prevent further override
        mainForm.save();
    };

    const initRowData = async () => {
        setRows(certificates ?? []);
    };

    useEffect(() => {
        initRowData();
    }, [certificates]);

    return (
        <>
            {/* {JSON.stringify(rows)} */}
            {/* {<ReactJson src={rows} name="rows" collapsed style={{background:'white'}}/>} */}
            {/* {
        <ReactJson
          src={mainForm.values.certificates}
          name="Form"
          collapsed
          style={{ background: "white" }}
        />
      } */}

            <LocalPageList
                rows={rows}
                onFormSubmit={onFormSubmit}
                tableSchema={tableSchema}
                formSchema={formSchema}
                readOnly={readOnly}
                {...props}
            />
        </>
    );
};

export default ConsultantCertificateList;
