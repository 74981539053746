import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/userServerApi";

import QuotationConfirmButton from "./quotationConfirmButton";
import QuotationCopyDNButton from "./quotationCopyDNButton";
import QuotationEditButton from "./quotationEditButton";
const ORDER_STATUS_ALLOWED_TO_EDIT = ["DFT", "CAN", "RCV", "SUB", "PND", "AIP"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = ["SUB", "PND", "AIP"];

const QuotationPrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Quotation.print(_id);
      setPrinting(false);
      // console.log(result);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      mainForm.setFieldValue("status", result.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Quotation"}
    </Button>
  );
};

const QuotationPrintRNButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Quotation.print(_id, "RN");
      setPrinting(false);

      // console.log(result);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      mainForm.setFieldValue("status", result.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print as Renewal Notice"}
    </Button>
  );
};

const OuotationTerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    mainForm.updateAndSave({ status: "CAN" });
  };

  return (
    <Button fullWidth color={"red"} onClick={() => terminate()}>
      Terminate
    </Button>
  );
};

const QuotationActionList = ({ form, role }) => {
  const { status, code, consultants, _id } = form.values;

  const showConfirm = true;
  const showPrint = true;
  const showTerminate = true;
  const showCopy = true;
  const showEdit = true;

  return (
    <>
      <Group mt={10}>
        {showPrint && <QuotationPrintButton mainForm={form} />}
        {showPrint && <QuotationPrintRNButton mainForm={form} />}
        {showCopy && (
          <QuotationCopyDNButton mainForm={form} title="Copy to Debit Note" />
        )}
        {showEdit && <QuotationEditButton mainForm={form} />}
        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="quotation"
          ticketType={"TK-GI-QUOTATION"}
          members={consultants?.map((c) => c._id)}
        />

        {showConfirm && <QuotationConfirmButton mainForm={form} />}

        {showTerminate && <OuotationTerminateButton mainForm={form} />}
      </Group>
    </>
  );
};

export default QuotationActionList;
