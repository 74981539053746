import React from "react";
import Order from "../pages/operation/order";
import { PageList } from "./pageList";
import { useCellRender } from "../hooks/useCellRender";
import { useAuthUser } from "react-auth-kit";
const apiEntity = "order";

const UserOrderList = ({ value = {} }) => {
  const { _id } = value;
  const [cellRender] = useCellRender();
  const formSchema = {};
  const auth = useAuthUser();

  const getEntityLink = () => {
    const { userRole } = auth();
    if (!userRole) return null;
    const roleLink = userRole.entityLinks?.find((l) => l.entity === "order");
    if (!roleLink || !roleLink.link) return null;
    return roleLink.link;
    // return `../${roleLink.link}?id=${i}`;
  };

  const tableSchema = {
    showActionColumn: true,

    preQuery: _id ? { consultants: _id } : null,

    columns: [
      {
        field: "code",
        filter: true,
        cellRender: (col, order) => (
          <a
            href={`${getEntityLink()}?id=${order._id}&mode=${"edit"}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {order.code}
          </a>
        ),
      },
      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      {
        field: "client",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client, true),
      },
      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) => cellRender.Users(data.consultants, true),
      },
      // {   field: 'followup', sortable: true,   },
      {
        field: "status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
    ],

    searchableFields: [
      "code",
      "status",
      "applicant.name",
      "applicant.cname",
      "applicant.id",
      "cname",
      "email",
      "phone",
    ],
  };
  return (
    <>
      <PageList
        title={"Orders"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        hideActionButton={true}
        preQueryNotEmpty={true}
      />
    </>
  );
};

export default UserOrderList;
