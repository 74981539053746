import React from "react";
import { Text } from "@mantine/core";
import _ from "lodash";

const DummyWidget = ({ form, name, dummy, dummyRender }) => {
	const renderDummy = () => {
		if (!dummyRender) return;
		return dummyRender(form.values);
	};
	return (
		<>
			{dummyRender && renderDummy()}
			{dummy && dummy}
		</>
	);
};

export default DummyWidget;
