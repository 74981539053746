import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";

const CommissionStatementStat = ({ form, missing, cols = 3, ...props }) => {
  const [formatter] = useFormatter();
  const rows = _.get(form.values, "rows");
  const pending = rows?.filter((r) => r.status === "PENDING")?.length ?? 0;
  const verified = rows?.filter((r) => r.status === "VERIFIED")?.length ?? 0;

  const confirmedRows = rows?.filter((r) => r.status === "CONFIRMED") ?? [];
  const confirmed = confirmedRows.length;

  const total = rows?.length ?? 0;
  const totalCommission = _.get(form.values, "totalCommission");
  const totalCommissionConfirmed = _.isEmpty(confirmedRows)
    ? 0
    : confirmedRows?.reduce((a, c) => a + c.totalRcv, 0) ?? 0;

  const stats = [
    {
      icon: IconNotes,
      color: "#00b341",
      label: "Total Rows",
      msg: total,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Total Commission",
      msg: formatter.currency(totalCommission),
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Confirmed Commission",
      msg: formatter.currency(totalCommissionConfirmed),
    },
    {
      icon: IconAlertCircle,
      color: "red",
      label: "Pending",
      msg: pending,
    },
    {
      icon: IconCircleCheck,
      color: "#00b341",
      label: "Verified",
      msg: verified,
    },
    {
      icon: IconCircleCheck,
      color: "#0ED1EA",
      label: "Confirmed",
      msg: confirmed,
    },

    // {
    //     icon: IconTableImport,
    //     color: "#FF9F43",
    //     label: "TRANSFER IN",
    //     msg: result.stats.INDIVIDUAL ?? 0,
    // },
  ];

  return (
    <>
      {/* <Accordion defaultValue="grids">
                <Accordion.Item value="grids">
                    <Accordion.Control></Accordion.Control>
                    <Accordion.Panel> */}

      <SimpleGrid
        cols={cols}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "md", cols: 2 },
        ]}
        {...props}
      >
        {stats?.map((stat, index) => (
          <CardStat key={index} stat={stat} />
        ))}
      </SimpleGrid>
      {/* </Accordion.Panel>
                </Accordion.Item>
            </Accordion> */}
    </>
  );
};

export default CommissionStatementStat;
