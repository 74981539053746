import React, { useState, useEffect, useRef } from "react";

import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import { useUserFriendlyDate } from "../hooks/useUserFriendlyDate";
import { useFormatter } from "../hooks/useFomatter";
import {
  Timeline,
  Text,
  Avatar,
  TextInput,
  Group,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import ReactJson from "react-json-view";
import { useAuthUser } from "react-auth-kit";

const ActivityLog = ({
  form,
  title,
  commentOnly,
  scrollHeight = 300,
  ...props
}) => {
  const ref = useRef(null);
  const [formatter] = useFormatter();

  const [api] = useServerApi();
  const [logs, setLogs] = useState([]);
  const [userFriendlyDate] = useUserFriendlyDate();

  const auth = useAuthUser();

  const entityId = _.get(form.values, "_id");

  const fetchLog = async () => {
    if (!entityId) return;
    let data = await api.getActivityLog(entityId, commentOnly);
    setLogs(data);
  };

  useEffect(() => {
    fetchLog();
  }, [entityId]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      let result = await api.addCommentLog({
        entityId,
        title: e.target.value,
        by: auth().id,
      });
      if (result.success) {
        fetchLog();
        ref.current.value = "";
      }
    }
  };

  const removeLog = async (log) => {
    if (!log._id) return;
    let result = await api.removeById({
      apiEntity: "activityLog",
      id: log._id,
    });
    if (result.success) {
      fetchLog();
    }
  };

  const getByName = (by) => (by ? formatter.friendlyName(by) : "SYSTEM");

  const getLogTitle = (log) => {
    return log.type != "COMMENT"
      ? `${log.title} by ${getByName(log.by)} `
      : `${log.title}`;
  };

  return (
    <>
      {/* {auth().id} */}
      {/* entityId:{entityId} */}
      {/* <ReactJson src={auth()} style={{ background: "white" }} collapsed /> */}
      <TextInput ref={ref} mb={20} onKeyDown={handleKeyDown} />
      {!_.isEmpty(logs) && (
        <ScrollArea style={{ height: scrollHeight }} mt="xl">
          <Timeline active={logs.length} lineWidth={2} bulletSize={10} ml="md">
            {logs.map((log, index) => (
              <Timeline.Item
                key={index}
                title={getLogTitle(log)}
                titlesize="xs"
                bulletSize={25}
                bullet={
                  <Avatar
                    src={
                      log.by && log.by.image
                        ? `${process.env.REACT_APP_FILE_URL}/${log.by?.image}`
                        : null
                    }
                    size="sm"
                    radius="xl"
                  />
                }
              >
                <Group>
                  <Text color="dimmed" size="sm">
                    {` ${userFriendlyDate(log.createdAt, "LLL")} by ${getByName(
                      log.by
                    )}`}
                  </Text>

                  {log.type === "COMMENT" && auth().id === log.by._id && (
                    <ActionIcon
                      size={15}
                      color="gray"
                      onClick={() => removeLog(log)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  )}
                </Group>
              </Timeline.Item>
            ))}
          </Timeline>
        </ScrollArea>
      )}
    </>
  );
};

export default ActivityLog;
