import React, { useState, useEffect } from "react";

import _ from "lodash";
import ReactJson from "react-json-view";
import {
  Box,
  Button,
  Checkbox,
  FileButton,
  Group,
  List,
  Text,
  Textarea,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { useServerApi } from "../hooks/userServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { IconAlertTriangle, IconExclamationMark } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { Dropzone } from "@mantine/dropzone";

const NO_PRODUCT_TYPE_SELECT = "Please select product type first.";

const QuoRequestFileCheckList = ({ form: mainForm, name, title = "Form" }) => {
  const checkList = _.get(mainForm.values, name);
  const productType = _.get(mainForm.values, "productType");
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();

  const fetchCheckList = async () => {
    if (!productType) return;
    if (!_.isEmpty(checkList)) return;

    const type = await api.getById({
      id: _.isObject(productType) ? productType._id : productType,
      apiEntity: "productType",
    });

    if (type.quoFileCheckList) {
      const list = type.quoFileCheckList.map((l) => ({
        question: l,
        file: null,
      }));
      mainForm.setFieldValue("fileList", list);
      forceUpdate();
    }
  };

  useDeepCompareEffect(() => {
    fetchCheckList();
  }, [productType]);

  const setFile = async (file, item) => {
    let result = await api.uploadFile("files", file);
    let { data, success } = result;

    if (success) {
      item.file = data;
      forceUpdate();
    } else {
      showNotification({
        title: `${file.name} upload unsuccessful`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Upload Fail",
      });
    }
  };

  const openFile = (file) => {
    // if (!file.url) return;
    // window.open(file.url, "_blank");
    if (!file.link) return;
    const url = `${process.env.REACT_APP_FILE_URL}${file.link}`;
    window.open(url, "_blank");
  };
  return (
    <>
      {/* <ReactJson
        src={mainForm.values}
        style={{ background: "white" }}
        collapsed
      /> */}
      <Text mb="xl" size={"xs"}>
        {title}
      </Text>

      {/* <List type="ordered" size="xs"> */}
      {checkList?.map((l, index) => (
        // <List.Item mb={"md"} style={{ width: "100%", background: "red" }}>
        <Box mb={"md"}>
          <Text size="xs" mb={"md"}>
            {index + 1}. {l.question}{" "}
          </Text>
          <Dropzone
            onDrop={(files) => {
              if (files && files[0]) setFile(files[0], l);
            }}
            onReject={(files) => console.log("rejected files", files)}
          >
            <Group noWrap align={"baseline"}>
              <FileButton onChange={(f) => setFile(f, l)}>
                {(props) => (
                  <Button size="xs" {...props} compact>
                    Upload
                  </Button>
                )}
              </FileButton>

              {l.file && (
                <UnstyledButton onClick={() => openFile(l.file)}>
                  <Text size="xs"> {l.file.name} </Text>
                </UnstyledButton>
              )}
            </Group>
          </Dropzone>
        </Box>
        // </List.Item>
      ))}
      {/* </List> */}

      {!productType && (
        <Group>
          <IconAlertTriangle size={15}></IconAlertTriangle>
          <Text size="xs">{NO_PRODUCT_TYPE_SELECT}</Text>
        </Group>
      )}
    </>
  );
};

export default QuoRequestFileCheckList;
