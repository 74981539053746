import React from "react";
import FormSection from "./formSection";
import _ from "lodash";
import { Text } from "@mantine/core";

const DataFeedSummary = ({ form }) => {
  const result = _.get(form.values, "result");

  return (
    <>
      {result &&
        result.map((r) => (
          <FormSection title={r.feedDocument.desc}>
            <Text size="xs" color="dimmed">
              {r.feedDocument.location}
            </Text>
            {r.feedDocument.searchFolder && (
              <Text size="xs" color="dimmed">
                {r.feedDocument.searchFolder}...{r.feedDocument.searchFile}
              </Text>
            )}
            <Text size="xs" color="dimmed">
              {r.totalRecords} records found.
            </Text>
            <Text size="xs" color="dimmed">
              {r.successRecords} policy updated successfully.
            </Text>
            {r.error && (
              <Text size="xs" color="dimmed">
                {r.error}
              </Text>
            )}
          </FormSection>
        ))}
    </>
  );
};
export default DataFeedSummary;
