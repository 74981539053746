import React, { useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import ReactJson from "react-json-view";
import { Page } from "../../layout/page";
import UserCPDDetail from "../../components/userCPDDetail";

const MyCPD = ({}) => {
	const auth = useAuthUser();

	const user = auth();

	return (
		<Page title={"My CPD"}>
			{/* <ReactJson src={user} style={{ background: "white" }} collapsed /> */}
			<UserCPDDetail mainUser={user} />
		</Page>
	);
};

export default MyCPD;
