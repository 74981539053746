import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconNote,
  IconClipboardCopy,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useAuthUser } from "react-auth-kit";

const QuoRequestPageStats = ({ cols = 5, role, ...props }) => {
  const [api] = useServerApi();

  const [stats, setStats] = useState([]);
  const auth = useAuthUser();

  let result = null;
  const fetchStats = async () => {
    try {
      switch (role) {
        case "OPERATION":
          result = await api.QuoRequest.getStatSummary();
          break;
        case "CONSULTANT":
          result = await api.QuoRequest.getStatSummaryByConsultant(auth().id);
          break;
      }
      if (!result) return;

      setStats([
        {
          icon: IconNotes,
          color: "#0ED1EA",
          label: "Total",
          msg: result.stats.TOTAL ?? 0,
        },
        {
          icon: IconCircleMinus,
          color: "#FF9F43",
          label: "DFT",
          msg: result.stats.DFT ?? 0,
        },

        {
          icon: IconCircleCheck,
          color: "#00b341",
          label: "DONE",
          msg: result.stats.DONE ?? 0,
        },

        {
          icon: IconCircleX,
          color: "red",
          label: "CAN",
          msg: result.stats.CAN ?? 0,
        },

        {
          icon: IconClipboardCopy,
          color: "blue",
          label: "DN COPIED",
          msg: result.stats.COPYDN ?? 0,
        },

        // {
        //   icon: IconNotes,
        //   color: "#8278F2",
        //   label: "NEW BIZ",
        //   msg: result.stats.PENDING ?? 0,
        // },
        // {
        //   icon: IconTableImport,
        //   color: "#FF9F43",
        //   label: "TRANSFER IN",
        //   msg: result.stats.INDIVIDUAL ?? 0,
        // },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <>
      <Accordion defaultValue="grids">
        <Accordion.Item value="grids">
          <Accordion.Control></Accordion.Control>
          <Accordion.Panel>
            <SimpleGrid
              cols={cols}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              {...props}
            >
              {stats?.map((stat, index) => (
                <CardStat key={index} stat={stat} />
              ))}
            </SimpleGrid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default QuoRequestPageStats;
