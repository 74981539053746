import {
	Avatar,
	Group,
	Button,
	Divider,
	Text,
	ActionIcon,
	Title,
	Box,
	SimpleGrid,
	UnstyledButton,
} from "@mantine/core";
import {
	IconBuildingBank,
	IconBuildingSkyscraper,
	IconCalendar,
	IconCategory,
	IconCategory2,
	IconCircleLetterG,
	IconCircleNumber1,
	IconComponents,
	IconCurrency,
	IconExchange,
	IconGift,
	IconLadder,
	IconMoneybag,
	IconNumber,
	IconNumber1,
	IconStar,
	IconTicket,
} from "@tabler/icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../layout/page";
import "to-color-string";

const HomepageSettings = [
	{
		icon: IconCalendar,
		label: "Calendar",

		description: "Add or edit company events, training and any special dates.",
		link: "/management/calendarEvent",
	},

	{
		icon: IconMoneybag,
		// color: "#ffec00",
		label: "Consultant Incentive",
		description: "Add or edit latest consultant incentive programs.",
		link: "/management/consultantIncentive",
	},

	{
		icon: IconStar,
		// color: "#ffec00",
		label: "Client Promotion",
		description: "Add or edit latest client promotion programs from providers.",
		link: "/management/clientPromotion",
	},

	{
		icon: IconCircleNumber1,
		// color: "#ffec00",
		label: "Top Rankings",
		description:
			"Add or edit rankings of consultant based on different criteria.",
		link: "/management/topRanking",
	},
];

const ProductSettings = [
	{
		icon: IconComponents,
		// color: "#ffec00",
		label: "Product Category",

		description: "Add or edit product category.",
		link: "/operation/productCategory",
	},

	{
		icon: IconCategory,
		// color: "#ffec00",
		label: "Product Type",

		description: "Add or edit product types.",
		link: "/operation/productType",
	},

	{
		icon: IconBuildingSkyscraper,
		// color: "#ffec00",
		label: "Product Provider",

		description: "Add or edit product providers.",
		link: "/operation/provider",
	},
];

const CommissionSettings = [
	{
		icon: IconLadder,
		// color: "#ffec00",
		label: "Overriding Plan",

		description: "Add or edit commission overriding plans.",
		link: "/management/commissionOverridingPlan",
	},
	{
		icon: IconCircleLetterG,
		label: "User Group",
		link: "/operation/userGroup",
		linkId: "O8",
		group: "Operation",
		description: "Create group of users for commission overriding.",
	},

	{
		icon: IconGift,
		// color: "#ffec00",
		label: "Allowance Plan",

		description: "Add or edit available allowance plans for consultant.",
		link: "/management/allowancePlan",
	},
	{
		icon: IconExchange,
		// color: "#ffec00",
		label: "Exchange Rates",

		description: "Update the latest currency exchange rates.",
		link: "/management/exchangeRate",
	},
];

const OtherSettings = [
	{
		icon: IconBuildingBank,
		// color: "#ffec00",
		label: "Organization",
		description: "Edit the organization details.",
		link: "/management/organization",
	},

	{
		icon: IconTicket,
		// color: "#ffec00",
		label: "Ticket Types",
		description:
			"Add Types of ticket and assign backend office staffs to handle ticket.",

		link: "/management/ticketTypes",
	},
];
const SettingSections = [
	{
		label: "Homepage",
		settings: HomepageSettings,
	},
	{
		label: "Products",
		settings: ProductSettings,
	},
	{
		label: "Commissions",
		settings: CommissionSettings,
	},
	{
		label: "Others",
		settings: OtherSettings,
	},
];

const SettingButton = ({ setting }) => {
	const Navigate = useNavigate();
	const { label, description, color, link } = setting;
	return (
		<UnstyledButton onClick={() => Navigate(link)}>
			<Group maw={300} noWrap align={"start"}>
				<ActionIcon
					size="xl"
					radius="xl"
					variant="light"
					bg={label.toColorString()}
					// bgp={color}
					onClick={() => Navigate(link)}
				>
					<setting.icon size={15} color={"white"} />
				</ActionIcon>
				<span>
					<Title order={6}> {label} </Title>
					<Text size={"xs"} color="dimmed" lineClamp={2}>
						{description}
					</Text>
				</span>
			</Group>
		</UnstyledButton>
	);
};

const SettingSection = ({ section, spacing = 40, cols = 3 }) => {
	const { label, settings } = section;
	return (
		<Box mb={100}>
			<Text size={"sm"} weight={500}>
				{label}
			</Text>
			<Divider mb={"xl"} mt="xs" />

			<SimpleGrid cols={cols} spacing={spacing}>
				{settings.map((setting) => (
					<SettingButton key={setting.label} setting={setting} />
				))}
			</SimpleGrid>
		</Box>
	);
};

const Setting = ({}) => {
	return (
		<Page title={"Settings"}>
			{SettingSections.map((s) => (
				<SettingSection key={s.label} section={s} />
			))}
		</Page>
	);
};

export default Setting;
