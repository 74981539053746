import {
  Button,
  NumberInput,
  Group,
  Text,
  Title,
  Table,
  SimpleGrid,
  Divider,
  Checkbox,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/userServerApi";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons";

const OrderStatsTable = ({
  data,
  showIndividualConsultant = false,
  remark,
  ...props
}) => {
  const [formatter] = useFormatter();
  const forceUpdate = useForceUpdate();
  const defaultCheckStatus = ["INF", "SUB", "RCV", "DFT", "EXP", "AIP"];

  const [rows, setRows] = useState([]);

  const selection = rows.filter((r) => r.checked);

  const toggleAll = () => {
    rows.forEach((r) => (r.checked = !r.checked));
    forceUpdate();
  };

  useEffect(() => {
    if (!data) return setRows([]);
    let newRows = data?.sort((a, b) => b.count - a.count);

    newRows.forEach((r) => {
      if (defaultCheckStatus.includes(r._id)) r.checked = true;
    });
    setRows(newRows);
    forceUpdate();
  }, [data]);

  const TableHeader = () => (
    <thead>
      <tr>
        <th>
          <Checkbox
            onChange={toggleAll}
            checked={selection?.length === data?.length}
            indeterminate={
              selection.length > 0 && selection.length !== data.length
            }
          />
        </th>
        <th>Status</th>
        <th>No. of Case</th>
        <th>FY Premium</th>
        {!showIndividualConsultant && <th>FY Commission (Total)</th>}
        {!showIndividualConsultant && <th>FY Commission (Consultant)</th>}
        {showIndividualConsultant && <th>FY Commission (Individual) </th>}
      </tr>
    </thead>
  );

  const TableRows = ({ data }) => {
    return (
      <tbody>
        {rows?.map((r, index) => (
          <tr key={index}>
            <td>
              <Checkbox
                checked={r.checked}
                onChange={(e) => {
                  r.checked = e.target.checked;
                  forceUpdate();
                }}
              ></Checkbox>
            </td>
            <td>{r._id}</td>
            {!showIndividualConsultant && <td>{r.count}</td>}
            {showIndividualConsultant && <td>{r.indidvidualOrderCount}</td>}

            <td>{formatter.currency(r.yearPremium)}</td>
            {!showIndividualConsultant && (
              <td>{formatter.currency(r.yearCommission)}</td>
            )}
            {!showIndividualConsultant && (
              <td>{formatter.currency(r.yearConsultantCommission)}</td>
            )}
            {showIndividualConsultant && (
              <td>{formatter.currency(r.indidvidualConsultantCommission)}</td>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  const TableFooter = ({ data }) => {
    const checkedRow = rows.filter((r) => r.checked);
    return (
      <tfoot>
        <tr>
          <th></th>
          <th>Total</th>
          {!showIndividualConsultant && (
            <th>{checkedRow?.reduce((a, c) => a + c.count, 0)}</th>
          )}
          {showIndividualConsultant && (
            <th>
              {checkedRow?.reduce((a, c) => a + c.indidvidualOrderCount, 0)}
            </th>
          )}

          <th>
            {formatter.currency(
              checkedRow?.reduce((a, c) => a + c.yearPremium, 0)
            )}
          </th>
          {!showIndividualConsultant && (
            <th>
              {formatter.currency(
                checkedRow?.reduce((a, c) => a + c.yearCommission, 0)
              )}
            </th>
          )}
          {!showIndividualConsultant && (
            <th>
              {formatter.currency(
                checkedRow?.reduce((a, c) => a + c.yearConsultantCommission, 0)
              )}
            </th>
          )}

          {showIndividualConsultant && (
            <th>
              {formatter.currency(
                checkedRow?.reduce(
                  (a, c) => a + c.indidvidualConsultantCommission,
                  0
                )
              )}
            </th>
          )}
        </tr>
      </tfoot>
    );
  };
  return (
    <>
      {/* <ReactJson
        src={data}
        name="result"
        collapsed
        style={{ background: "white" }}
      /> */}

      <Table {...props}>
        <TableHeader />
        <TableRows data={data} />
        <TableFooter data={data} />
      </Table>

      {!_.isEmpty(remark) && (
        <Group noWrap mt="xl">
          <IconAlertTriangle size={12} />
          <Text size="xs">{remark}</Text>
        </Group>
      )}
    </>
  );
};

export default OrderStatsTable;
