import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";
import { DATA_REPORT_PRODUCT_TYPE } from "../data/options";

const ReportClient = ({}) => {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(today);
  const [reportType, setReportType] = useState("original");

  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      const result = await api.Client.getReport({});
      setLoading(false);

      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">
        Please click the below button to download the client report.
      </Text>

      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        Download
      </Button>
      <></>
    </div>
  );
};

export default ReportClient;
