import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";
import { WalletRow, ReferalRow } from "./payrollTable";
import { Accordion } from "@mantine/core";

const PayrollPaymentRefers = ({ form }) => {
    const { referals } = form.values;

    return (
        referals && (
            <Accordion variant="separated">
                {referals.map((referal) => (
                    <ReferalRow key={referal._id} referal={referal} />
                ))}
            </Accordion>
        )
    );
};

export default PayrollPaymentRefers;
