import React, { useState, useEffect } from "react";
import { Group, FileButton, Button, Text, Grid, Divider } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import {
  IconTrash,
  IconCheck,
  IconSettings,
  IconExclamationMark,
  IconMessageCircle,
  IconCoin,
  IconDownload,
  IconDotsVertical,
  IconCircleCheck,
  IconCircleDashed,
} from "@tabler/icons";

import { List, ThemeIcon } from "@mantine/core";
import ReactJson from "react-json-view";

const DataFeedImporter = ({ form, name }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [api] = useServerApi();

  const handleUploadSuccess = (file, data) => {
    showNotification({
      title: `${file.name} Import successfully`,
      message: "Import Success",
    });
    //For UI display
    setFile(file);
    setData(data);
    //Set file content
    if (!data) return;
    form.setFieldValue("link", data.link);
    form.setFieldValue("result", data.result);
    form.setFieldValue("issues", data.issues);
    form.setFieldValue("totalRows", data.totalRows);
    form.setFieldValue("totalSuccess", data.totalSuccess);
    form.setFieldValue("url", data.url);
    form.setFieldValue("files", data.files);
    // console.log("data.files", data);
  };

  const handleUploadFail = (file, error) => {
    showNotification({
      title: `${file.name} Import fail`,
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message: error?.msg,
    });
    setFile(null);
    form.setFieldValue(name, null);
    setError(error.msg);
  };

  const handleFileUpload = async (file) => {
    setLoading(true);
    setError(null);
    setFile(file);
    if (!file) return;
    let result = await api.DataFeed.import(file);
    // console.log(result);
    let { data, success, error } = result;
    success ? handleUploadSuccess(file, data) : handleUploadFail(file, error);
    setLoading(false);
  };
  return (
    <>
      {/* <ReactJson src={data} style={{ background: "white" }} collapsed /> */}
      <Group style={{ width: 300 }}>
        <FileButton onChange={handleFileUpload} accept=".zip">
          {(props) => (
            <Button {...props} size="xs" loading={loading}>
              Select Datafeed Zip
            </Button>
          )}
        </FileButton>
      </Group>
      {/* <Divider mt="xl" mb="xl"></Divider> */}
      {!loading && (
        //   && !_.isEmpty(data)
        <List
          mt={"xl"}
          spacing="xs"
          size="sm"
          center
          icon={
            <ThemeIcon color="teal" size={15} radius="xl">
              <IconCircleCheck size={16} />
            </ThemeIcon>
          }
        >
          {!_.isEmpty(data) && file && (
            <>
              <List.Item>File {file.name} read successfully</List.Item>
              {/* <List.Item>
                Total {data.result?.filter((r) => r.totalRecords)} /{" "}
                {data.result.length} data feed files read successfully.
              </List.Item>*/}
              {data.result && (
                <List.Item>
                  {data.result?.filter((r) => r.totalRecords > 0)?.length ?? 0}{" "}
                  / {data.result?.length ?? 0} datafeed files read successfully.
                </List.Item>
              )}

              <List.Item>
                Total {data.totalRows} records in datafeed files.
              </List.Item>
              <List.Item>
                Total {data.totalSuccess} policy updates successfully.
              </List.Item>
            </>
          )}

          {error && (
            <List.Item
              icon={
                <ThemeIcon color="red" size={24} radius="xl">
                  <IconExclamationMark size={16} />
                </ThemeIcon>
              }
            >
              {error}
            </List.Item>
          )}
        </List>
      )}
    </>
  );
};

export default DataFeedImporter;
