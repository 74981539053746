import React, { useEffect, useState } from "react";
import { RingProgress, Center, ThemeIcon, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons";

const WalletShareChecker = ({ form, name }) => {
    const members = form.values.members;
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let sum = members.reduce((a, c) => a + c.share, 0);
        setTotal(sum);
    }, [members]);
    return (
        <Center style={{ width: "100%", height: "100%" }}>
            <RingProgress
                size={200}
                sections={[
                    { value: total, color: total === 100 ? "teal" : "red" },
                ]}
                label={
                    <Text
                        color={total === 100 ? "teal" : "red"}
                        weight={700}
                        align="center"
                        size="xl"
                    >
                        {total}%
                        <Text color={"dimmed"} size="xs">
                            {"Assigned to members"}
                        </Text>
                    </Text>
                }
            />
        </Center>
    );
};

export default WalletShareChecker;
