import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../../hooks/userServerApi";
import { Tabs, Title } from "@mantine/core";
import { SimpleGrid } from "@mantine/core";

import { Page } from "../../layout/page";
import { PageList } from "../../components/pageList";
import DataTable from "../../components/dataTable";

import { useCellRender } from "../../hooks/useCellRender";
import moment from "moment";
import CardStat from "../../components/cardStat";
import { IconCurrencyDollar, IconGridDots } from "@tabler/icons";
import { useFormatter } from "../../hooks/useFomatter";

const BidList = ({}) => {
  const apiEntity = "bid";
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const tableSchema = {
    showActionColumn: false,

    searchableFields: ["code", "type", "expectedPeriod", "status"],
    columns: [
      {
        field: "type",
        headerName: "Type",
        sortable: true,

        cellRender: (column, data) => cellRender.BidType(data.type),
      },
      { field: "code", headerName: "Bid Code", sortable: true },
      { field: "level", headerName: "Level", sortable: true },

      {
        field: "expectedPeriod",
        headerName: "Expected Period",
        sortable: true,
      },

      {
        field: "createdAt",
        headerName: "Create At",
        sortable: true,
        cellRender: (column, data) => moment(data.createdAt).format("lll"),
      },

      {
        field: "paidAmount",
        headerName: "Amount",
        sortable: true,
        cellRender: (column, data) =>
          cellRender.Currency(data.paidAmount || data.expectedAmount),
      },
      { field: "status", headerName: "Status", sortable: true },
    ],
  };
  const formSchema = {};

  return (
    <PageList
      title={"Bids"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
};

export default BidList;
