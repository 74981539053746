import React, { useState, useEffect } from "react";
import { Page } from "../../layout/page";

import {
	ENUM_FORM_DISPLAY,
	ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useFormRender } from "../../hooks/useFormRender";
import { useServerApi } from "../../hooks/userServerApi";
import _ from "lodash";
import PageListHeader from "../../components/pageListHeader";
import { TextInput } from "@mantine/core";
import ReactJson from "react-json-view";
import ImagePicker from "../../components/imagePicker";

const formSchema = {
	title: "Organization",
	apiEntity: "setting",
	display: {
		mode: ENUM_FORM_DISPLAY.INSIDE,
		size: "xl", //xs, sm, xl  ....100%
		// position:"right"
	},
	layout: {
		containers: [
			{
				key: "basic-g",
				parent: null,
				type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
				cols: [
					{
						key: "basic-g1",
						props: {
							span: 9,
							mt: 0,
						},
					},
					{
						key: "basic-g2",
						props: {
							span: 3,
						},
					},
				],
			},

			{
				key: "section1",
				parent: "basic-g1",
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {
					title: "Basic Info",
					description: "Please fill in details",
				},
			},
			{
				key: "section4",
				parent: "basic-g2",
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {
					title: "Company Logo",
				},
			},
			{
				key: "section2",
				parent: null,
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {
					title: "Contact Info",
					description: "Please fill in details",
				},
			},
			{
				key: "section3",
				parent: null,
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {
					title: "Address",
					description: "Please fill in details",
				},
			},
		],
		fields: [
			{
				name: "organization.image",
				component: ImagePicker,
				parent: "section4",
				props: {
					size: 160,
				},
			},
			{
				name: "organization.name",
				component: TextInput,
				parent: "section1",
				props: {
					label: "English Name",
				},
			},
			{
				name: "organization.cname",
				component: TextInput,
				parent: "section1",
				props: {
					label: "Chinese Name",
				},
			},
			{
				name: "organization.shortName",
				component: TextInput,
				parent: "section1",
				props: {
					label: "Short Name",
				},
			},
			{
				name: "organization.address",
				component: TextInput,
				parent: "section3",
				props: {
					label: "Address",
				},
			},
			{
				name: "organization.caddress",
				component: TextInput,
				parent: "section3",
				props: {
					label: "Address (Chinese)",
				},
			},
			{
				name: "organization.website",
				component: TextInput,
				parent: "section2",
				props: {
					label: "Website",
				},
			},
			{
				name: "organization.email",
				component: TextInput,
				parent: "section2",
				props: {
					label: "Email",
				},
			},
			{
				name: "organization.tel",
				component: TextInput,
				parent: "section2",
				props: {
					label: "Tel.",
				},
			},
			{
				name: "organization.fax",
				component: TextInput,
				parent: "section2",
				props: {
					label: "Fax",
				},
			},
		],
	},
};
const Organization = () => {
	// const [rates, setRates] = useState([]);
	const [api] = useServerApi();
	const [renderForm, formAction] = useFormRender(formSchema);

	const fetchSetting = async () => {
		try {
			const result = await api.search({
				apiEntity: "setting",
				pageSize: 1,
			});

			if (!result || _.isEmpty(result.docs)) throw "No Organization Settings";

			formAction.open({
				mode: "edit",
				id: result.docs[0]._id,
				data: result.docs[0],
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchSetting();
	}, []);

	const handleSave = () => {
		formAction.save();
	};

	const handleClose = () => {
		window.history.back();
	};

	return (
		<Page>
			{/* <ReactJson src={setting} /> */}
			<PageListHeader
				title={"Organization"}
				actionButtons={[
					{ label: "Save", onClick: () => handleSave() },
					{
						label: "Close",
						onClick: () => window.history.back(),
						variant: "default",
					},
				]}
			/>
			{renderForm()}
		</Page>
	);
};

export default Organization;
