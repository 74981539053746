import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function DebitNoteSummary({ form, name }) {
  const [cellRender] = useCellRender();
  const dn = form.values;

  const status = DATA_DN_STATUS.find((s) => s.value === dn?.status);

  const fields = [
    {
      title: "Code",
      value: dn.code,
    },
    {
      title: "Policy Number",
      value: !dn.policyNumber ? "-" : dn.policyNumber,
    },

    {
      title: "Status",
      value: status ? (
        <Group>
          {cellRender.Order.status(dn?.status)}
          {status?.label}
        </Group>
      ) : (
        "Unknown"
      ),
    },

    {
      title: "Product",
      value: dn.product?.name + dn.product?.cname,
    },

    {
      title: "Period From",
      value: dn?.period?.start ? moment(dn?.period?.start).format("LL") : "-",
    },
    {
      title: "Period To",
      value: dn.period?.end ? moment(dn?.period?.end).format("LL") : "-",
    },
    { title: "DN Date", value: moment(dn?.date).format("LL") },

    { title: "Created At", value: moment(dn?.createdAt).format("LLL") },

    { title: "Created By", value: cellRender.User(dn?.createdBy) },
    {
      title: "Quo Request",
      value: dn?.quoRequest ? (
        <a
          href={`/gi/quoRequest?id=${dn.quoRequest._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.quoRequest.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Quotation",
      value: dn?.quotation ? (
        <a
          href={`/gi/quotation?id=${dn.quotation._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.quotation.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Order",
      value: dn?.order ? (
        <a
          href={`/operation/order?id=${dn.order._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.order.code}
        </a>
      ) : (
        "-"
      ),
    },
  ];

  return dn && <InfoGridList fields={fields} values={dn} />;
}
