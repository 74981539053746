import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Title,
  Button,
  TextInput,
  ActionIcon,
  Group,
  Menu,
  Card,
  Space,
  Grid,
  Center,
  FileButton,
  Box,
  UnstyledButton,
  Image,
  Avatar,
  Loader,
  Table,
  Checkbox,
} from "@mantine/core";

import { useForceUpdate, useHotkeys, randomId } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import {
  IconTrash,
  IconCheck,
  IconSettings,
  IconExclamationMark,
  IconMessageCircle,
  IconCoin,
  IconDownload,
  IconDotsVertical,
  IconList,
  IconGridPattern,
  IconPencil,
  IconLayoutGrid,
} from "@tabler/icons";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import ReactJson from "react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faFileImage,
  faFileVideo,
  faFileZipper,
  faFileCsv,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useServerApi } from "../hooks/userServerApi";
import { useDateRender } from "../hooks/useDateRender";
import { useClickOutside } from "@mantine/hooks";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import DataTable from "./dataTable";
import { useAuthUser } from "react-auth-kit";

const filesize = require("file-size");

const PdfPreviewer = ({ fileUrl, pageIndex = 1 }) => {
  const generateThumbnail = async (file) => {
    // console.log("generateThumbnail", file);
    try {
      const thumbnails = await generatePdfThumbnails(file, 150);
      // console.log(thumbnails);
    } catch (error) {
      console.log(error);
    }
    const thumbnails = generatePdfThumbnails(file, 150);
  };

  useEffect(() => {
    if (!fileUrl) return;
    generateThumbnail(fileUrl);
  }, [fileUrl]);
  return <>PDF</>;
};

export const isImageFile = (mimetype) => {
  return [
    "image/png",
    "image/gif",
    "image/jpg",
    "image/jpeg",
    "image/svg+xml",
    "image/webp",
    "video/mp4",
  ].includes(mimetype);
};

export const isPDFFile = (mimetype) => {
  return ["application/pdf"].includes(mimetype);
};

export const supportFiles = [
  { ext: "png", icon: faFileImage, color: "#8479F1", mimetype: "image/png" },
  { ext: "gif", icon: faFileImage, color: "#8479F1", mimetype: "image/gif" },
  {
    ext: "jpeg",
    icon: faFileImage,
    color: "#8479F1",
    mimetype: "image/jpeg",
  },
  { ext: "jpg", icon: faFileImage, color: "#8479F1", mimetype: "image/jpg" },
  { ext: "svg", icon: faFileImage, color: "#aaa", mimetype: "image/svg+xml" },
  { ext: "webp", icon: faFileImage, color: "#aaa", mimetype: "image/webp" },
  { ext: "mp4", icon: faFileVideo, color: "#aaa", mimetype: "video/mp4" },
  {
    ext: "zip",
    icon: faFileZipper,
    color: "#aaa",
    mimetype: "application/zip",
  },
  { ext: "csv", icon: faFileCsv, color: "#aaa", mimetype: "text/csv" },
  {
    ext: "pdf",
    icon: faFilePdf,
    color: "#EA5455",
    mimetype: "application/pdf",
  },
  {
    ext: "doc",
    icon: faFileWord,
    color: "#467FFF",
    mimetype: "application/msword",
  },
  {
    ext: "docx",
    icon: faFileWord,
    color: "#467FFF",
    mimetype:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {
    ext: "xls",
    icon: faFileExcel,
    color: "green",
    mimetype: "application/vnd.ms-excel",
  },
  {
    ext: "xlsx",
    icon: faFileExcel,
    color: "green",
    mimetype:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  {
    ext: "ppt",
    icon: faFilePowerpoint,
    color: "#EA5455",
    mimetype: "application/vnd.ms-powerpoint",
  },
  {
    ext: "pptx",
    icon: faFilePowerpoint,
    color: "#EA5455",
    mimetype:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    ext: "exe",
    icon: faFile,
    color: "black",
    mimetype: "application/vnd.microsoft.portable-executable",
  },
];

export const getFileIcon = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  if (!file) return faFile;
  return file.icon;
};

export const getFileIconColor = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  if (!file) return "#ccc";
  return file.color;
};

const K_FILE_LIST_DISPLAY_MODE = {
  GRID: 1,
  LIST: 2,
};

const QuoFileList = ({
  form,
  name,
  title,
  columnSpan = 4,
  disabled,

  ...props
}) => {
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();
  const [uploading, setUploading] = useState(false);
  const [displayMode, setDisplayMode] = useState(K_FILE_LIST_DISPLAY_MODE.LIST);

  const resetRef = useRef(null);
  let fileCheckList = _.get(form.values, name) || [];
  let files = fileCheckList.map((l) => ({ ...l.file, name: l.question }));

  const NO_FILES_MESSAGE = "No files upload yet.";
  const auth = useAuthUser();

  const role = auth()?.userRole?.name?.toUpperCase() ?? "CONSULTANT";

  const [allowEdit, setAllowEdit] = useState(false);

  useEffect(() => {
    if (role === "CONSULTANT") return setAllowEdit(false);
    setAllowEdit(true);
  }, [role]);

  useHotkeys([]);

  const resetFileButton = () => resetRef.current?.();

  const unSelectAll = () => {
    // console.log("unSelectAll");
    files.forEach((f) => (f.selected = false));
    forceUpdate();
  };

  const downloadAll = () => {
    const links = files.map((f) => f.link);
    downloadZipFiles(links);
  };

  const downloadZipFiles = async (links) => {
    const filename = "product-files";
    let result = await api.downloadZipFiles(filename, links);
    if (result.success) {
      return window.open(result.data.link, "_blank");
    } else {
      console.log(result);
      showNotification({
        title: `Download Zip Fail`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Download Fail",
      });
    }
  };

  const setSelected = (file) => {
    if (file.selected) return openFile(file);
    unSelectAll();
    file.selected = true;
    forceUpdate();
  };

  const FilesActionButton = () => (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon variant="default">
          <IconDotsVertical size="1rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{`Actions`}</Menu.Label>

        <Menu.Item onClick={downloadAll} icon={<IconDownload size={14} />}>
          Download All
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  const openFile = (file) => {
    // console.log("open FIle", file);
    // if (!file.url) return;
    // console.log("openFile", file);
    // window.open(file.url, "_blank");
    if (!file.link) return;
    const url = `${process.env.REACT_APP_FILE_URL}${file.link}`;
    window.open(url, "_blank");
  };

  const handleCardOnClick = (event, file) => {
    if (event.altKey) {
      // console.log("Alt, yay!");
      file.selected = true;
      return forceUpdate();
    }
    setSelected(file);
  };

  const Previewer = ({ file }) => {
    const isImage = isImageFile(file.mimetype);
    const isPdf = isPDFFile(file.mimetype);

    return (
      <>
        {isImage && (
          <div style={{ height: "140px", bakcgorund: "#F8F8F8" }}>
            <Image
              src={`${process.env.REACT_APP_FILE_URL}${file.link}`}
              height={100}
            />

            {/* {`${process.env.REACT_APP_FILE_URL}${file.link}`} */}
          </div>
        )}

        {/* {isPdf && file && file.url && <PdfPreviewer fileUrl={file.url} />} */}

        {!isImage && (
          <Center style={{ height: "140px", background: "#F8F8F8" }}>
            <FontAwesomeIcon
              icon={getFileIcon(file.mimetype)}
              color={getFileIconColor(file.mimetype)}
              size="xl"
            ></FontAwesomeIcon>
          </Center>
        )}
      </>
    );
  };

  const FileCard = ({ file }) => (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      style={{ borderColor: file.selected ? "#A5D8FF" : "" }}
      onClick={(e) => {
        handleCardOnClick(e, file);
      }}
    >
      <Card.Section>
        <Previewer file={file} />
      </Card.Section>
      <Text mt="sm" size="sm">
        {file.name}
      </Text>
      <Group position="apart">
        <Text color="dimmed" size="xs">
          {moment(file.created).format("YYYY/MM/DD")}
        </Text>
        <Text mt="sm" color="dimmed" size="xs">
          {filesize(file.size, { fixed: 0 }).human("si")}
        </Text>
      </Group>
    </Card>
  );

  const FilesGrid = ({ files }) => (
    <Grid mb={50}>
      {files &&
        files?.map((file) => (
          <Grid.Col key={file._id || file.id} span={columnSpan}>
            <div style={{ zIndex: 200 }}>
              <FileCard file={file} />
            </div>
          </Grid.Col>
        ))}
      <Center w={300} h={200}>
        {uploading && <Loader variant="bars" />}
      </Center>
    </Grid>
  );

  const FileTableHeader = ({ textSize = "xs" }) => (
    <thead>
      <tr>
        <th>
          <Text size={textSize}> Item</Text>
        </th>
        <th>
          <Text size={textSize}> File</Text>
        </th>

        <th>
          <Text size={textSize}> Size</Text>
        </th>
        <th>
          <Text size={textSize}> Date</Text>
        </th>
        <th></th>
      </tr>
    </thead>
  );

  const FileTableBody = ({ fileCheckList, textSize = "xs" }) => {
    const [rows, setRows] = useState([]);

    const setFile = async (file, item) => {
      let result = await api.uploadFile("files", file);
      let { data, success } = result;

      if (success) {
        item.file = data;
        forceUpdate();
      }
    };
    return (
      <>
        <tbody>
          {fileCheckList &&
            fileCheckList?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Text size={"xs"}>{item.question} </Text>
                </td>
                <td align="left" style={{ minWidth: 250 }}>
                  {item.file && (
                    <Group noWrap spacing={"xs"}>
                      <FontAwesomeIcon
                        icon={getFileIcon(item.file.mimetype)}
                        color={getFileIconColor(item.file.mimetype)}
                        size="xl"
                      ></FontAwesomeIcon>
                      <UnstyledButton onClick={() => openFile(item.file)}>
                        <Text size={textSize}> {item.file.name} </Text>
                      </UnstyledButton>
                    </Group>
                  )}
                </td>
                <td>
                  <Text size={textSize}>
                    {item.file &&
                      filesize(item.file?.size, { fixed: 0 }).human("si")}
                    {!item.file && "--"}
                  </Text>
                </td>
                <td>
                  <Text size={textSize}>
                    {item.file && item.file.created
                      ? moment(item.file.created).format("LLL")
                      : "--"}
                  </Text>
                </td>
                <td>
                  {!disabled && allowEdit && (
                    <FileButton onChange={(f) => setFile(f, item)}>
                      {(props) => (
                        <Button size="xs" {...props} compact>
                          Upload
                        </Button>
                      )}
                    </FileButton>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };

  const FilesTable = ({ fileCheckList }) => {
    return (
      <>
        {/* <ReactJson src={files} style={{ background: "white" }} /> */}
        <Table mb={300} striped highlightOnHover>
          <FileTableHeader />
          <FileTableBody fileCheckList={fileCheckList} />
        </Table>
      </>
    );
  };

  const renderFiles = () => (
    <>
      <Group position="right" mb={20}>
        {/* <Title order={5} mb={10}>{title}</Title> */}
        <Group spacing="xs">
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.LIST)}
          >
            <IconList size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.GRID)}
          >
            <IconLayoutGrid size="1rem" />
          </ActionIcon>
          <FilesActionButton />
        </Group>
      </Group>
      {displayMode === K_FILE_LIST_DISPLAY_MODE.GRID && (
        <FilesGrid files={files} />
      )}

      {displayMode === K_FILE_LIST_DISPLAY_MODE.LIST && (
        <FilesTable fileCheckList={fileCheckList} />
      )}
    </>
  );

  return (
    <Box>
      {title && <Text> {title}</Text>}
      {!_.isEmpty(files) && renderFiles()}
      {_.isEmpty(files) && (
        <Text size="xs" mb={"xl"}>
          {NO_FILES_MESSAGE}
        </Text>
      )}
    </Box>
  );
};

export default QuoFileList;
