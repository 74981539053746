import _ from "lodash";
import { Text, Title, Divider, Group, ThemeIcon, Badge } from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import { IconClick, IconCash, IconHeart } from "@tabler/icons";

export default function UserEmploymentInfo({ form }) {
  const [cellRender] = useCellRender();
  const user = form.values;
  const dataFontWeight = 600;

  const emptyStr = "-";

  const fields = [
    { title: "Login Email", value: user.email ?? emptyStr },

    { title: "Work Title", value: user.workTitle ?? emptyStr },
    { title: "User Type", value: user.userType ?? emptyStr },
    { title: "User Role", value: user.userRole?.name ?? emptyStr },

    {
      title: "Join Date",
      value: cellRender.Date(user.employmentDate, "LL") ?? emptyStr,
    },
    {
      title: "Created At",
      value: cellRender.Date(user.createdAt, "LL", dataFontWeight),
    },
  ];
  return user && <InfoGridList fields={fields} values={user} />;
}
