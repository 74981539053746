import React from "react";
import FormSection from "./formSection";
import _ from "lodash";
import { Button, Group, ScrollArea, Text } from "@mantine/core";
import DataTable from "./dataTable";
import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";

const DataFeedIssues = ({ form }) => {
  const [api] = useServerApi();

  const issues = _.get(form.values, "issues");
  const id = _.get(form.values, "_id");
  const downloadIssue = async () => {
    try {
      const result = await api.DataFeed.downloadIssues(id);
      console.log(result);
      if (result && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      showNotification({
        title: `Print Issue Error`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error.msg ?? error,
      });
    }
  };

  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "policy",
        headerName: "Policy Number",
        sortable: true,
      },
      {
        field: "issue",
        headerName: "Issue",
      },

      {
        field: "location",
        headerName: "File",
      },

      {
        field: "desc",
        headerName: "Desc",
      },

      // {   field: 'followup', sortable: true,   },
      // {
      // 	field: "status",
      // 	sortable: true,
      // 	cellRender: (col, data) => cellRender.Status(data.status),
      // },
    ],
  };
  return (
    <>
      {/* <ReactJson src={issues} collapsed style={{ background: "white" }} /> */}

      <Group position="right">
        <Button size="xs" onClick={() => downloadIssue()}>
          {" "}
          Download
        </Button>
      </Group>
      <DataTable
        data={issues}
        columns={tableSchema.columns}
        hideSearchBar={true}
        hidePagination={true}
        minHeight={500}
      ></DataTable>
    </>
  );
};

export default DataFeedIssues;
