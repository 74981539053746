import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";

const CpdMPFReport = ({}) => {
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);

  const numYears = 3;
  const [api] = useServerApi();
  const startYear = moment().year();
  // const startYear = moment().isBefore(`${currentYear}-08-01`)
  // ? currentYear
  // : currentYear + 1;

  const [selected, setSelected] = useState(startYear);
  useEffect(() => {
    const yrs = [];

    for (let i = 0; i < numYears; i++) {
      let yr = startYear - i;
      yrs.push({
        label: yr,
        value: yr,
      });
    }
    setYears(yrs);
    setSelected(startYear);
  }, []);

  const downloadReport = async () => {
    try {
      setLoading(true);
      console.log("Donwload MPF", selected);
      const result = await api.Cpd.getMPFYearReport(selected);
      setLoading(false);
      console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">Please select the year of MPF CPD records</Text>
      <Group mt="xs">
        <Select
          data={years}
          with={10}
          defaultValue={startYear}
          size="xs"
          onChange={(v) => setSelected(v)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        Download
      </Button>
      <></>
    </div>
  );
};

export default CpdMPFReport;
