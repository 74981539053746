import {
    Group,
    Paper,
    Space,
    Text,
    SimpleGrid,
    UnstyledButton,
    Accordion,
    Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
    IconUsers,
    IconUser,
    IconCircleCheck,
    IconNotes,
    IconTableImport,
    IconCirclePlus,
    IconUserCircle,
    IconBuildingBank,
    IconCircleMinus,
    IconActivity,
    IconCircleX,
    IconHeart,
    IconReceipt2,
    IconAlertCircle,
    IconCalendar,
    IconMoneybag,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";

const ProfolioStat = ({ form, missing, cols = 3, ...props }) => {
    const [formatter] = useFormatter();

    const { totalRow, code, period, rows, totalFundAmount } = form.values;

    const stats = [
        {
            icon: IconCalendar,
            color: "#00b341",
            label: "Period",
            msg: period,
        },
        {
            icon: IconNotes,
            color: "#8278F2",
            label: "Total Rows",
            msg: totalRow,
        },
        {
            icon: IconMoneybag,
            color: "#8278F2",
            label: "Total Fund Value",
            msg: formatter.currency(totalFundAmount, "HKD"),
        },
    ];

    return (
        <>
            {/* <Accordion defaultValue="grids">
                <Accordion.Item value="grids">
                    <Accordion.Control></Accordion.Control>
                    <Accordion.Panel> */}
            <SimpleGrid
                cols={cols}
                breakpoints={[
                    { maxWidth: "sm", cols: 1 },
                    { maxWidth: "md", cols: 2 },
                ]}
                {...props}
            >
                {stats?.map((stat, index) => (
                    <CardStat key={index} stat={stat} />
                ))}
            </SimpleGrid>
            {/* </Accordion.Panel>
                </Accordion.Item>
            </Accordion> */}
        </>
    );
};

export default ProfolioStat;
