import _ from "lodash";
import { DatePicker } from "@mantine/dates";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { Text } from "@mantine/core";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const DateOffPicker = ({
    form: mainForm,
    name,
    label,
    relatedField,
    daysOff = 1,
    ...props
}) => {
    const value = _.get(mainForm.values, name);
    // const [value, setValue] = useState();
    // const [value, setValue] = useState(new Date())
    const relatedFieldValue = _.get(mainForm.values, relatedField);

    useDeepCompareEffect(() => {
        const d = relatedFieldValue
            ? moment(relatedFieldValue).add(daysOff, "days")
            : null;
        return mainForm.setFieldValue(name, d ? d.toISOString() : null);
    }, [relatedFieldValue]);

    return (
        <>
            {value && (
                <>
                    <Text size={14}> {label}</Text>
                    <Text size={14} weight={700}>
                        {value ? moment(value).format("LL") : "----"}
                    </Text>
                </>
            )}
            {/* <DatePicker {...props} value={value} /> */}
        </>
    );
};

export default DateOffPicker;
