import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import { Group, Table, Text, Title } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import moment from "moment";

import { IconCircleCheck } from "@tabler/icons";
const AllowanceDetails = ({ form: mainForm }) => {
  const [formatter] = useFormatter();
  const plan = _.get(mainForm.values, "plan");
  const startDate = _.get(mainForm.values, "startDate");
  const terms = _.get(mainForm.values, "terms");

  const getDateDiffUnit = (payMode) => {
    switch (payMode) {
      case "WEEKLY":
        return "w";
      case "MONTHLY":
        return "M";
      case "QUARTERLY":
        return "Q";
      case "ANNUALLY":
        return "y";
    }
  };

  const buildTerms = () => {
    console.log("build Terms");
    if (!plan || !startDate) return;
    let terms = [...plan.terms];

    terms.forEach((term, index) => {
      let m = moment(startDate).add(1 * index, getDateDiffUnit(plan.payMode));
      term.payDate = m.toDate();
    });
    mainForm.setFieldValue("terms", terms);
  };

  useDeepCompareEffect(() => {
    buildTerms();
  }, [plan, startDate]);

  const TableHeader = () => (
    <thead>
      <tr>
        <th> #</th>
        <th>Pay Date</th>
        <th>AMOUNT</th>
        <th style={{ textAlign: "center", width: "20%" }}>REACTIVE</th>
      </tr>
    </thead>
  );

  const TableBody = () => (
    <tbody>
      {terms?.map((t, index) => (
        <tr key={index}>
          <td> {t.term}</td>
          <td> {moment(t.payDate).format("yyyy-MM-DD")}</td>
          <td> {formatter.currency(t.amount)}</td>
          <td style={{ textAlign: "center" }}>
            {t.reactiveApproval ? <IconCircleCheck color="green" /> : ""}{" "}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      {/* <ReactJson src={terms} style={{ background: "white" }} collapsed /> */}
      {/* <Title order={5}>{plan.name}</Title> */}
      {/* <Group>
        <Text>Pay Count: {plan.terms?.length ?? 0}</Text>
        <Text>Total Amount: {formatter.currency(plan.totalAmount)}</Text>
      </Group> */}

      <Table>
        <TableHeader />
        <TableBody />
      </Table>
    </>
  );
};

export default AllowanceDetails;
