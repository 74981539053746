import { TextInput, Checkbox, Switch, Textarea, Select } from "@mantine/core";
import { PageList, ENUM_FORM_DISPLAY } from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import LicenceCheckList from "../../components/licenceCheckList";
import { IconTrash, IconPencil } from "@tabler/icons";
import RemoteSelect from "../../components/remoteSelect";
import { DATA_TICKET_ASSIGN_METHODS } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import ArrayTable from "../../components/arrayTable";
import RemarkList from "../../components/remarkList";

const apiEntity = "orderServiceType";

const tableSchema = {
  showActionColumn: true,
  columns: [
    { field: "code", filter: true },
    { field: "name", filter: true },
    { field: "cname", filter: true, headerName: "Chinese Name" },
    {
      field: "action",
      cellRender: ActionCellRender,
      isAction: true,
      actions: [
        {
          name: "edit",
          icon: IconPencil,
          props: {
            //   color:"red"
          },
        },
      ],
      actionMenu: [
        {
          name: "delete",
          label: "Delete data",
          icon: IconTrash,
          props: {
            color: "red",
          },
        },
      ],
    },
  ],
  searchableFields: ["code", "name", "cname"],
};

const formSchema = {
  title: "Order Service Types",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "orderServiceType",

  initialValues: {
    code: "",
    name: "",
    cname: "",
    description: "",
    checkList: [],
  },

  validate: {},

  layout: {
    fields: [
      {
        name: "code",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "TK-ORDER",
        },
      },
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "Name",
          placeholder: "Operation",
        },
      },
      {
        name: "cname",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name ",
          placeholder: "",
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
        },
      },

      {
        name: "checkList",
        component: RemarkList,
        props: {
          label: "CheckList",
        },
      },
    ],
  },
};

function OrderServiceType() {
  return (
    <PageList
      title={"Order Service Types"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
}

export default OrderServiceType;
