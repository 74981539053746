import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";

import { PageList } from "../components/pageList";

import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { useFormatter } from "../hooks/useFomatter";
import _ from "lodash";

const AllowanceBidList = ({ form, id, ...props }) => {
  const apiEntity = "bid";
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const allowanceId = _.get(form?.values, "_id") ?? id;
  const tableSchema = {
    showActionColumn: false,
    preQuery: allowanceId ? { allowance: allowanceId } : null,
    searchableFields: ["code", "type", "expectedPeriod", "status"],
    columns: [
      {
        field: "type",
        headerName: "Type",
        sortable: true,

        cellRender: (column, data) => cellRender.BidType(data.type),
      },
      { field: "code", headerName: "Bid Code", sortable: true },
      //   { field: "level", headerName: "Level", sortable: true },
      {
        field: "expectedPeriod",
        headerName: "Expected Period",
        sortable: true,
      },

      //   {
      //     field: "createdAt",
      //     headerName: "Create At",
      //     sortable: true,
      //     cellRender: (column, data) => moment(data.createdAt).format("lll"),
      //   },

      {
        field: "paidAmount",
        headerName: "Amount",
        sortable: true,
        cellRender: (column, data) =>
          cellRender.Currency(data.paidAmount || data.expectedAmount),
      },
      { field: "status", headerName: "Status", sortable: true },
    ],
  };
  const formSchema = {};

  return (
    <PageList
      title={""}
      apiEntity={apiEntity}
      hideSearchBar={true}
      hideActionButton={true}
      tableSchema={tableSchema}
      formSchema={formSchema}
      initSort={{ by: "code", order: "desc" }}
      preQueryNotEmpty
    />
  );
};

export default AllowanceBidList;
