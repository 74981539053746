import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Group } from "@mantine/core";
import _ from "lodash";

export default function OrderTimeline({ form, name }) {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const dataFontWeight = 600;

  const fields = [
    {
      title: "Sign Date",
      value: cellRender.Date(order?.signedDate, "LL", dataFontWeight),
    },
    {
      title: "Create Date",
      value: cellRender.Date(order?.createdAt, "LL", dataFontWeight),
    },
    {
      title: "Receive Date",
      value: cellRender.Date(order?.receivedDate, "LL", dataFontWeight),
    },
    {
      title: "Submit Date",
      value: cellRender.Date(order?.submissionDate, "LL", dataFontWeight),
    },
  ];

  return <InfoGridList fields={fields} values={order} cols={4} />;
}
