import { TextInput, Checkbox, Switch } from "@mantine/core";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import LicenceCheckList from "../../components/licenceCheckList";
import { IconTrash, IconPencil } from "@tabler/icons";
import RemoteSelect from "../../components/remoteSelect";
import QuoCheckList from "../../components/quoCheckList";
import RemarkList from "../../components/remarkList";
import DragList from "../../components/dragList";
import ActivityLog from "../../components/activityLog";

const apiEntity = "productType";

function ProductType() {
  return (
    <PageList
      title={"Product Types"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
}

export default ProductType;

const tableSchema = {
  showActionColumn: true,
  columns: [
    { field: "code", filter: true },
    { field: "category.name", filter: true, headerName: "Category" },

    { field: "name", filter: true },
    { field: "cname", filter: true, headerName: "Chinese Name" },
    {
      field: "action",
      cellRender: ActionCellRender,
      isAction: true,
      actions: [
        {
          name: "edit",
          icon: IconPencil,
          props: {
            //   color:"red"
          },
        },
      ],
      actionMenu: [
        {
          name: "delete",
          label: "Delete data",
          icon: IconTrash,
          props: {
            color: "red",
          },
        },
      ],
    },
  ],
  searchableFields: ["code", "name", "cname", "email"],
};

const formSchema = {
  title: "Product Types",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "productType",

  initialValues: {
    code: "",
    name: "",
    cname: "",
    licences: {
      l1: false,
      l2: false,
      l3: false,
      l4: false,
      l5: false,
    },
    quoCheckList: [],
  },

  validate: {
    email: (value) =>
      !value || /^\S+@\S+$/.test(value) ? null : "Invalid email",
  },

  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Type Details",
        },

        tab: [
          {
            key: "tab-basic",
            label: "Type Details",
            //icon: IconSettings,
          },
          {
            key: "tab-quo",
            label: "Quotation Setting",
            // icon: IconSettings,
          },
          {
            key: "tab-logs",
            label: "Log",
            // icon: IconSettings,
          },
        ],
      },
      {
        key: "section-log",
        parent: "tab-logs",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Activity",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-basic",
        parent: "tab-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Information",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-licence",
        parent: "tab-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Licences Requirement",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-information-checklist",
        parent: "tab-quo",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Information CheckList",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-file-checklist",
        parent: "tab-quo",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "File CheckList",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "code",
        component: TextInput,
        parent: "section-basic",
        props: {
          required: true,
          label: "Code",
          placeholder: "AIA",
        },
      },
      {
        name: "category",
        component: RemoteSelect,
        parent: "section-basic",
        props: {
          apiEntity: "productCategory",
          required: true,
          label: "Category",
          placeholder: "Select Category",
          valueField: "_id",
          labelField: "name",
          // labelRender: (data) => `${data.name}`,
          multiple: false,
          searchFields: ["name", "code"],
          sort: {
            by: "code",
            order: "desc",
          },
        },
      },
      {
        name: "name",
        parent: "section-basic",
        component: TextInput,
        props: {
          required: true,
          label: "Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        parent: "section-basic",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },

      {
        name: "licences",
        parent: "section-licence",
        component: LicenceCheckList,
        props: {
          label: "Required Licences",
        },
      },
      {
        name: "allowQuoRequest",
        parent: "section-information-checklist",

        component: Switch,
        props: {
          label: "Allow to create quotation request",
          type: "checkbox",
          size: "xs",
        },
      },
      {
        name: "quoCheckList",
        component: DragList,
        parent: "section-information-checklist",
        props: {
          label: "Please enter information item",
          placeholder: "Please enter information item.",
          size: "xs",
          mt: "xl",
        },
      },

      {
        name: "quoFileCheckList",
        component: DragList,
        parent: "section-file-checklist",
        props: {
          // label: "Quotation question checklist",
          label: "Please enter file item.",
          placeholder: "Please enter file item.",
          size: "xs",
          mt: "xl",
        },
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
      //   {
      //     name: "quoCheckList",
      //     parent: "tab-quo",
      //     component: QuoCheckList,
      //     props: {
      //       label: "CheckList",
      //     },
      //   },

      // {
      //     name: "termsOfService",
      //     component: Checkbox,
      //     props:{
      //         mt:"md",
      //         label: "I agree to sell my privacy",
      //         type: 'checkbox'    //only use for checkbox now, otherwise the default value is not checked
      //     }
      // },
    ],
  },
};
