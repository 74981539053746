import React, { useState, useEffect } from "react";
import { Page } from "../../layout/page";

import { useFormRender } from "../../hooks/useFormRender";
import { useServerApi } from "../../hooks/userServerApi";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import {
  TextInput,
  Checkbox,
  Switch,
  Select,
  Textarea,
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Grid,
  Tabs,
  ScrollArea,
} from "@mantine/core";
import { IconTrash, IconPencil } from "@tabler/icons";
import ActionCellRender from "../../components/actionCellRender";
import { DatePicker, TimeInput } from "@mantine/dates";
import { DATA_CALENDAR_EVENT_TYPE } from "../../data/options";
import TimeSelect from "../../components/timeSelect";
import moment from "moment";

import { getEventColor } from "../../components/eventSection";

const apiEntity = "calendarEvent";
const initialValues = {
  type: "companyEvent",
};

const tableSchema = {
  showActionColumn: true,

  columns: [
    {
      field: "date",
      sortable: true,
      cellRender: (col, data) => moment(data.date).format("LL"),
    },
    {
      field: "fromTime",
      sortable: false,
      headerName: "From",
      cellRender: (col, data) =>
        !data.fromTime ? "--" : moment(data.fromTime).format("HH:mm"),
    },
    {
      field: "toTime",
      sortable: false,
      headerName: "To",
      cellRender: (col, data) =>
        !data.toTime ? "--" : moment(data.toTime).format("HH:mm"),
    },

    {
      field: "Event",
      sortable: true,
      cellRender: (col, data) => (
        <div>
          <Group>
            <Indicator size={6} color={getEventColor(data.type)} />

            <div>
              <Text size={"xs"} lineClamp={1}>
                {data.title}
              </Text>
              <Text size={"xs"} color="dimmed">
                {data.venues}
              </Text>
            </div>
          </Group>
        </div>
      ),
    },

    {
      field: "action",
      cellRender: ActionCellRender,
      isAction: true,
      actions: [
        {
          name: "edit",
          icon: IconPencil,
          props: {
            //   color:"red"
          },
        },
      ],
      actionMenu: [
        {
          name: "delete",
          label: "Delete data",
          icon: IconTrash,
          props: {
            color: "red",
          },
        },
      ],
    },
  ],
  searchableFields: ["title", "description"],
};

const formSchema = {
  title: "Calendar Events",
  apiEntity,
  initialValues,
  closeAfterSave: true,

  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,

    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  layout: {
    containers: [],
    fields: [
      {
        name: "type",
        component: Select,
        props: {
          required: true,
          label: "Event Type",
          placeholder: "",
          data: DATA_CALENDAR_EVENT_TYPE,
          // defaultValue: "companyEvent",
          searchable: true,
        },
      },
      {
        name: "title",
        component: TextInput,

        props: {
          required: true,
          label: "Title",
          placeholder: "Annual Dinner",
        },
      },
      {
        name: "date",
        component: DatePicker,
        type: "datePicker",

        props: {
          required: true,
          label: "Date",
          placeholder: "Event Date",
          zIndex: 1000,
          allowFreeInput: true,
        },
      },

      {
        name: "fromTime",
        component: TimeSelect,

        props: {
          required: true,
          label: "From",
          placeholder: "",
        },
      },
      {
        name: "toTime",
        component: TimeSelect,

        props: {
          required: true,
          label: "To",
          placeholder: "",
        },
      },

      {
        name: "venues",
        component: TextInput,
        props: {
          required: true,
          label: "Venues",
          placeholder: "",
        },
      },
      {
        name: "description",
        component: Textarea,
        props: {
          required: true,
          label: "Description",
          placeholder: "",
        },
      },
    ],
  },
};
const CalendarEvent = () => {
  return (
    <>
      <PageList
        title={"Calendar Events"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
      />
    </>
  );
};

export default CalendarEvent;
