import { Tabs, Button, Group } from "@mantine/core";
import React, { useState } from "react";

const FormTab = ({
    getChildContainers,
    getChildFields,
    renderContainer,
    renderField,
    tabs,
    ...props
}) => {
    return (
        <Tabs {...tabs.props} key={tabs.key}>
            <Tabs.List>
                {tabs.tab?.map(({ label, key, iconSize = 14, ...t }) => (
                    <Tabs.Tab
                        key={key}
                        // icon={<t.icon size={iconSize}/>}
                        value={label}
                    >
                        {label}
                    </Tabs.Tab>
                ))}
            </Tabs.List>

            {tabs.tab?.map(({ label, key }, index) => (
                <Tabs.Panel key={key} value={label} pt={"xl"}>
                    {getChildContainers(key).map((c) => renderContainer(c))}
                    {getChildFields(key).map((f) => renderField(f))}
                </Tabs.Panel>
            ))}
        </Tabs>
    );
};

export default FormTab;
