import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  Group,
  Grid,
  SimpleGrid,
  Tabs,
} from "@mantine/core";
import { ResponsiveLine, Line } from "@nivo/line";

import { Page } from "../layout/page";

import LineChart from "../components/chartWidget/LineChart";
import PieChart from "../components/chartWidget/PieChart";
import BarChart from "../components/chartWidget/BarChart";
import TreemapChart from "../components/chartWidget/TreemapChart";
import CalendarChart from "../components/chartWidget/CalendarChart";
import FunnelChart from "../components/chartWidget/funnelChart";
import ForcastPayrollBarChart from "../components/forcastPayrollBarChart";
import { IconCircleCaretUp, IconCoinMonero } from "@tabler/icons";

import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/userServerApi";
import { PeriodFilter } from "../components/periodFilter";
import { useFormatter } from "../hooks/useFomatter";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";
import _ from "lodash";

import CardStat from "../components/cardStat";
import OrderStatsTable from "../components/orderStatsTable";

function DashboardManagement({ role, numQuarters = 10 }) {
  const auth = useAuthUser();
  const [api] = useServerApi();

  const [period, setPeriod] = useState(null);
  const [productTypeDataByCase, setproductTypeDataByCase] = useState([]);
  const [productTypeDataByFYP, setproductTypeDataByFYP] = useState([]);
  const [productTypeDataByFYC, setproductTypeDataByFYC] = useState([]);

  const [productCatPieData, setproductCatPieData] = useState([]);

  const [productProviderDataByCase, setproductProviderDataByCase] = useState(
    []
  );
  const [productProviderDataByFYP, setproductProviderDataByFYP] = useState([]);
  const [productProviderDataByFYC, setproductProviderDataByFYC] = useState([]);

  const [orderStatusPieData, setOrderStatusPieData] = useState([]);
  const [orderTypePieData, setOrderTypePieData] = useState([]);

  const [orderStats, setOrderStats] = useState();
  const [formatter] = useFormatter();

  const fetchOrderStats = async () => {
    if (!period) return;
    const data = await api.Order.getStats(period);

    setOrderProductStats(data);
    // setOrderSummaryStats(data);
    setOrderStats(data.orderSummary);
    setOrderStatusPieData(
      data.orderSummary
        .filter((s) => s._id != "ALL")
        .map((s) => ({
          id: s._id,
          value: s.count,
        }))
    );
  };

  const resetStatData = () => {
    // console.log("resetStatData");
    setproductTypeDataByCase([]);
    setproductCatPieData([]);

    setproductProviderDataByCase([]);
    setproductProviderDataByFYP([]);
    setproductProviderDataByFYC([]);

    setOrderStatusPieData([]);
    setOrderTypePieData([]);
  };

  const setOrderProductStats = (data) => {
    console.log("setOrderProductStats", data);
    if (!data || _.isEmpty(data.orderProductStats)) return resetStatData();

    let typeByCase =
      data.orderProductStats?.productType
        .map((c) => ({
          id: c.name,
          Case: c.count,
        }))
        .sort((a, b) => a.Case - b.Case) ?? [];
    typeByCase.push({ id: "", Case: 0 });
    setproductTypeDataByCase(typeByCase);

    let typeByFYP =
      data.orderProductStats?.productType
        .map((c) => ({
          id: c.name,
          Amount: c.totalYearPremium,
        }))
        .filter((c) => c.Amount > 0)
        .sort((a, b) => a.Amount - b.Amount) ?? [];
    typeByFYP.push({ id: "", Case: 0 });

    setproductTypeDataByFYP(typeByFYP);

    let typeBYFYC =
      data.orderProductStats?.productType
        .map((c) => ({
          id: c.name,
          Amount: c.totalYearCommission,
        }))
        .filter((c) => c.Amount > 0)
        .sort((a, b) => a.Amount - b.Amount) ?? [];

    typeBYFYC.push({ id: "", Case: 0 });
    setproductTypeDataByFYC(typeBYFYC);

    let providerByCase =
      data.orderProductStats?.productProvider
        ?.map((c) => ({
          id: c.shortName ?? c.code,
          case: c.count,
        }))
        .sort((a, b) => a.case - b.case) ?? [];
    providerByCase.push({ id: "", case: 0 });
    setproductProviderDataByCase(providerByCase);

    let providerByFYP =
      data.orderProductStats.productProvider
        ?.map((c) => ({
          id: c.shortName ?? c.code,
          Amount: c.totalYearPremium,
        }))
        .filter((c) => c.Amount > 0)
        .sort((a, b) => a.Amount - b.Amount) ?? [];
    providerByFYP.push({ id: "", case: 0 });
    setproductProviderDataByFYP(providerByFYP);

    let providerByFYC =
      data.orderProductStats.productProvider
        ?.map((c) => ({
          id: c.shortName ?? c.code,
          Amount: c.totalYearCommission,
        }))
        .filter((c) => c.Amount > 0)
        .sort((a, b) => a.Amount - b.Amount) ?? [];
    providerByFYC.push({ id: "", case: 0 });
    setproductProviderDataByFYC(providerByFYC);
    setproductCatPieData(
      data.orderProductStats.productCategory?.map((c) => ({
        id: c.code,
        value: c.count,
      }))
    );
  };

  useDeepCompareEffect(() => {
    fetchOrderStats();
  }, [period]);

  const lineChartProps = {
    axisBottom: {
      legend: "Date",
    },
    axisLeft: {
      legend: "Number of Orders",
    },
  };

  const lineMapper = (result) => [
    {
      id: "Orders",
      data: result ? result.map((r) => ({ x: r._id, y: r.count })) : [],
    },
  ];

  let numFormatter = Intl.NumberFormat("en", { notation: "compact" });

  const renderProductDashboardWidget = () => (
    <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]} mt="50px">
      <BarChart
        title={"Product Providers (Case)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productProviderDataByCase}
        chartProps={{
          keys: ["case"],
          layout: "horizontal",
          indexBy: "id",
          marginLeft: 10,
        }}
      />

      <BarChart
        title={"Product Types (Case)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productTypeDataByCase}
        chartProps={{
          keys: ["Case"],
          layout: "horizontal",
          indexBy: "id",
          marginLeft: 10,
        }}
      />

      <BarChart
        title={"Product Providers (FY Commission)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productProviderDataByFYC}
        chartProps={{
          keys: ["Amount"],
          layout: "horizontal",
          indexBy: "id",
          marginLeft: 10,
          axisBottom: {
            format: (value) => `${numFormatter.format(Number(value))}`,
          },
          valueFormat: (value) => `${numFormatter.format(Number(value))}`,
        }}
      />

      <BarChart
        title={"Product Types (FY Commission)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productTypeDataByFYC}
        chartProps={{
          keys: ["Amount"],
          layout: "horizontal",
          indexBy: "id",
          valueFormat: (value) => `${numFormatter.format(Number(value))}`,

          marginLeft: 10,
          axisBottom: {
            format: (value) => `${numFormatter.format(Number(value))}`,
          },
        }}
      />

      <BarChart
        title={"Product Providers (FY Premium)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productProviderDataByFYP}
        chartProps={{
          keys: ["Amount"],
          layout: "horizontal",

          indexBy: "id",
          marginLeft: 10,
          valueFormat: (value) => `${numFormatter.format(Number(value))}`,

          axisBottom: {
            format: (value) => `${numFormatter.format(Number(value))}`,
          },
        }}
      />

      <BarChart
        title={"Product Types (FY Premium)"}
        height={500}
        description={"The number of order based on order types within quaters."}
        data={productTypeDataByFYP}
        chartProps={{
          keys: ["Amount"],
          layout: "horizontal",
          valueFormat: (value) => `${numFormatter.format(Number(value))}`,
          indexBy: "id",
          marginLeft: 10,
          axisBottom: {
            format: (value) => `${numFormatter.format(Number(value))}`,
          },
        }}
      />
    </SimpleGrid>
  );

  const renderOrderDashboardWidget = () => (
    <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]} mt="50px">
      <PieChart
        title={"Order Status"}
        staticData={orderStatusPieData}
        height={350}
      />

      <PieChart
        title={"Category"}
        staticData={productCatPieData}
        height={350}
      />
    </SimpleGrid>
  );

  return (
    <>
      {/* <ReactJson src={auth()} style={{ background: "white" }}></ReactJson> */}

      <PeriodFilter
        handleRefresh={() => fetchOrderStats()}
        handlePeriodChange={(p) => setPeriod(p)}
        numPeriods={numQuarters}
      />
      <OrderStatsTable
        data={orderStats}
        mt="50px"
        highlightOnHover
        withTableBorder
      />

      <Tabs defaultValue="order" mt="100px">
        <Tabs.List>
          <Tabs.Tab value="order">Order</Tabs.Tab>
          <Tabs.Tab value="product">Product</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="order">{renderOrderDashboardWidget()}</Tabs.Panel>
        <Tabs.Panel value="product">
          {renderProductDashboardWidget()}
        </Tabs.Panel>
      </Tabs>

      {/* <LineChart
				title={"Orders"}
				height={500}
				description={"The total number of orders through out the range"}
				chartProps={lineChartProps}
				dataAggreation={{
					apiEntity: "order",
					aggregations: [
						{
							$group: {
								_id: { $substr: ["$signedDate", 0, 7] },
								count: { $sum: 1 },
							},
						},
					],
					mapper: lineMapper,
				}}
				useSampleData={true}
			/> */}

      {/* <Grid>
                <Grid.Col span={6}>
                    <PieChart
                        data={pieData}
                        title={"Fund Profolio"}
                        height={500}
                        description={"The Fund proption of the policy."}
                        chartProps={{}}
                        useSampleData={true}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <BarChart
                        title={"Orders By Quarter"}
                        height={500}
                        description={
                            "The number of order based on order types within quaters."
                        }
                        chartProps={{
                            keys: ["GI", "LIFE", "MPF"],
                            layout: "horizontal",
                            indexBy: "period",
                            groupMode: "grouped",
                        }}
                    />
                </Grid.Col>
            </Grid> */}

      {/* <TreemapChart
				data={treemapData}
				title={"Product Tree Map"}
				height={500}
				description={"The Tree map to show the popularity of products."}
				chartProps={{}}
			/> */}
      {/* 
			<Grid>
				<Grid.Col span={6}>
					<CalendarChart
						data={calendarData}
						title={"Ticket Status"}
						height={300}
						description={"Number of tickets"}
						chartProps={{
							from: "2021-01-01",
							to: "2022-12-12",
						}}
					/>
				</Grid.Col>
				<Grid.Col span={6}>
					<FunnelChart
						data={funnelData}
						title={"Consultant Salary distrubtion"}
						description={"The salary range of consultant distrubtion"}
					/>
				</Grid.Col>
			</Grid> */}

      {/* <ForcastPayrollBarChart /> */}
    </>
  );
}

export default DashboardManagement;
