import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  Text,
  Badge,
  Tabs,
  Button,
  Group,
  Avatar,
  ScrollArea,
  Card,
  Box,
} from "@mantine/core";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import RemoteSelect2 from "./remoteSelect2";
import { useCellRender } from "../hooks/useCellRender";
import ReactJson from "react-json-view";
import { useFormatter } from "../hooks/useFomatter";
import { useForm } from "@mantine/form";

const ClientReAssignCard = (client) => {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  return (
    <>
      {client && (
        <Card withBorder mb={"xl"}>
          <Group>
            {cellRender.GetClientTypeIcon(client.type)}
            <span>
              <Text>
                {client.name} {client.cname}
              </Text>
              <Group mt={"xs"}>
                <Badge color="yellow">
                  {formatter.currency(client.totalPremimum)}
                </Badge>
                <Badge color="red">{client.totalOrders}</Badge>
              </Group>
            </span>
          </Group>
        </Card>
      )}
    </>
  );
};

const UserLeaveClient = ({ form }) => {
  const { userId, consultantClients, user } = form.values;
  const [api] = useServerApi();
  const [clients, setClients] = useState([]);
  const [cellRender] = useCellRender();
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [formatter] = useFormatter();

  const fetchClients = async () => {
    try {
      const data = await api.User.getClients(userId);
      console.log(data);
      setClients(data.clients);
      setTotalPremium(data.clients.reduce((a, c) => a + c.totalPremimum, 0));
      setTotalOrders(data.clients.reduce((a, c) => a + c.totalOrders, 0));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchConsultants = async () => {
    try {
      if (_.isEmpty(consultantClients)) {
        // form.setFieldValue("consultant"[parent]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateInfo = async () => {
    await fetchClients();
    await fetchConsultants();
  };

  useEffect(() => {
    if (!userId) return;
    updateInfo();
  }, [userId]);

  const handleConsultantChange = (value) => {
    // console.log("handleConsultantChange", value);
    if (consultantClients.find((m) => m.consultant._id === value._id)) return;
    consultantClients.push({
      consultant: value,
      clients: [],
    });
    form.setFieldValue("consultantClients", [...consultantClients]);
  };

  const handleClear = () => {
    form.setFieldValue("consultantClients", []);
  };

  const assignToParent = async () => {
    const parent = await api.getById({
      apiEntity: "user",
      id: user.parent,
    });
    const newMatch = [
      {
        consultant: parent,
        clients,
      },
    ];
    form.setFieldValue("consultantClients", newMatch);
  };

  const assignToSelectedConsultants = () => {
    consultantClients.forEach((m) => (m.clients = []));

    // console.log(consultantClients, clients);
    for (const [cindex, client] of clients.entries()) {
      const pos = cindex % consultantClients.length;
      // console.log("pos", pos);
      consultantClients[pos].clients.push(client);
    }
    form.setFieldValue("consultantClients", [...consultantClients]);
  };

  const handleAutoAssign = () => {
    // console.log("handleAutoAssign", clients, consultantClients);
    if (_.isEmpty(clients)) return;
    if (_.isEmpty(consultantClients)) {
      return assignToParent();
    }
    return assignToSelectedConsultants();
  };

  return (
    <>
      {/* {consultants && <Text>Consultants: {consultants.length}</Text>}
            {clients.length > 0 && <Text> Clients : {clients?.length}</Text>}
            <ReactJson
                name="form"
                src={form.values}
                style={{ background: "white" }}
                collapsed
            /> */}
      <RemoteSelect2
        apiEntity="user"
        label="Please select new consultant(s) for handling the clients"
        placeholder="Search consultant by name, staff ID, email"
        // valueField="_id"
        labelField="name"
        labelRender={(data) => `${data.name} (${data.staffId ?? " -- "})`}
        // multiple={true}
        preQuery={{
          $and: [
            { isConsultant: true },
            { isActive: true },
            { _id: { $ne: userId } },
          ],
        }}
        searchFields={["name", "staffId", "cname", "email"]}
        sort={{
          by: "name",
          order: "desc",
        }}
        clearAfterChange={true}
        onDataChange={(d) => {
          handleConsultantChange(d);
        }}
      />
      <Group>
        <Button size="xs" mt="md" onClick={handleAutoAssign}>
          Auto Assign
        </Button>
        <Button
          size="xs"
          mt="md"
          color="red"
          onClick={handleClear}
          disabled={_.isEmpty(consultantClients)}
        >
          Clear
        </Button>
      </Group>
      {/* {clients.length === 0 && (
				<List>
					<List.Item>There are no client rights have to handle.</List.Item>
				</List>
			)} */}
      <Group mt="50px">
        {clients && (
          <Group>
            <Text size="sm"> All Clients </Text>
            <Badge color="blue"> {clients.length} </Badge>
          </Group>
        )}

        {consultantClients && (
          <Group>
            <Text size="sm"> Consultants </Text>
            <Badge color="green">{consultantClients.length} </Badge>
          </Group>
        )}
        {clients && (
          <Group>
            <Text size="sm"> Year Premium </Text>
            <Badge color="orange">{formatter.currency(totalPremium)}</Badge>
          </Group>
        )}

        {clients && (
          <Group>
            <Text size="sm"> Total Orders </Text>
            <Badge color="red">{totalOrders}</Badge>
          </Group>
        )}
      </Group>
      {consultantClients && (
        <>
          <Tabs defaultValue={"0"} orientation="vertical" mt="md">
            <Tabs.List>
              {consultantClients.map((c, index) => (
                <Tabs.Tab value={`${index}`} key={`T${index}`}>
                  {cellRender.ConsultantClients(c.consultant, c.clients.length)}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {consultantClients.map((c, index) => (
              <Tabs.Panel
                key={`P${index}`}
                value={`${index}`}
                style={{ minHeight: "300px" }}
              >
                <ScrollArea style={{ height: 500 }} pl="xl">
                  <Box spacing="xl">
                    {c.clients.map((c, index) => (
                      <span key={`$C${index}`}>{ClientReAssignCard(c)}</span>
                    ))}
                  </Box>
                </ScrollArea>
              </Tabs.Panel>
            ))}
          </Tabs>
        </>
      )}
      {/* <ReactJson
                name="form"
                src={form.values}
                style={{ background: "white" }}
                collapsed
            /> */}
    </>
  );
};

export default UserLeaveClient;
