import React from "react";

import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

const AllowanceTerminateButton = ({ form, id }) => {
    const [api] = useServerApi();
    const status = _.get(form.values, "status");

    const handleActionClick = async () => {
        const result = await api.Allowance.terminate(id);
        if (result.status) {
            form.setFieldValue("status", result.status);
        }
    };

    return (
        <Button
            fullWidth
            onClick={handleActionClick}
            disabled={status === "TERMINATED"}
            color="red"
        >
            Terminate
        </Button>
    );
};

export default AllowanceTerminateButton;
