import { TextInput, Checkbox, Switch, Textarea, Select } from "@mantine/core";
import { PageList, ENUM_FORM_DISPLAY } from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import LicenceCheckList from "../../components/licenceCheckList";
import { IconTrash, IconPencil } from "@tabler/icons";
import RemoteSelect from "../../components/remoteSelect";
import { DATA_TICKET_ASSIGN_METHODS } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";

const apiEntity = "ticketType";

function TicketType() {
	return (
		<PageList
			title={"Ticket Types"}
			apiEntity={apiEntity}
			tableSchema={tableSchema}
			formSchema={formSchema}
		/>
	);
}

export default TicketType;

const tableSchema = {
	showActionColumn: true,
	columns: [
		{ field: "code", filter: true },
		{ field: "name", filter: true },
		{ field: "cname", filter: true, headerName: "Chinese Name" },
		{
			field: "action",
			cellRender: ActionCellRender,
			isAction: true,
			actions: [
				{
					name: "edit",
					icon: IconPencil,
					props: {
						//   color:"red"
					},
				},
			],
			actionMenu: [
				{
					name: "delete",
					label: "Delete data",
					icon: IconTrash,
					props: {
						color: "red",
					},
				},
			],
		},
	],
	searchableFields: ["code", "name", "cname"],
};

const formSchema = {
	title: "Ticket Types",
	display: {
		mode: ENUM_FORM_DISPLAY.DRAWER,
		size: "xl", //xs, sm, xl  ....100%
		position: "right",
	},
	apiEntity: "ticketType",

	initialValues: {
		code: "",
		name: "",
		cname: "",
		description: "",
		assigMethod: {
			default: "ALL",
		},
	},

	validate: {},

	layout: {
		fields: [
			{
				name: "code",
				component: TextInput,
				props: {
					required: true,
					label: "Code",
					placeholder: "TK-ORDER",
				},
			},
			{
				name: "name",
				component: TextInput,
				props: {
					required: true,
					label: "Name",
					placeholder: "Operation",
				},
			},
			{
				name: "cname",
				component: TextInput,
				props: {
					// required: true,
					label: "Chinese Name ",
					placeholder: "",
				},
			},

			{
				name: "description",
				component: Textarea,
				props: {
					// required: true,
					label: "Description",
					placeholder: "",
				},
			},

			{
				name: "members",
				component: RemoteSelect2,
				props: {
					label: "Staff(s) to handling the ticket",
					apiEntity: "user",
					required: true,
					placeholder: "Please select staff(s)",
					valueField: "_id",
					labelField: "name",
					labelRender: (data) =>
						`${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
					multiple: true,
					searchFields: ["name", "staffId", "cname", "aliasName", "email"],
					sort: {
						by: "name",
						order: "desc",
					},
				},
			},

			{
				name: "assignMethod",
				component: Select,
				props: {
					required: true,
					label: "Ticket Handling Assign Methods",
					placeholder: "Select way to assign ticket",
					data: DATA_TICKET_ASSIGN_METHODS,
					defaultValue: "ALL",
					searchable: true,
				},
			},
		],
	},
};
