import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Grid,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForm } from "@mantine/form";

const QuotationChargeSummary = ({ form, name }) => {
  const total = _.get(form.values, "total");
  const costTotal = _.get(form.values, "costTotal");
  const discountAmount = _.get(form.values, "discountAmount");
  const [formatter] = useFormatter();
  return (
    <>
      <Divider my="xl" />

      <Group position="right" align={"end"}>
        <Group position="apart" w={300}>
          <Text size={"xs"}>Total Premium</Text>
          <Title order={3}>{formatter.currency(total)}</Title>
        </Group>
      </Group>
      <Group position="right">
        <Group position="apart" w={300}>
          <Text size={"xs"}>Net Premium</Text>
          <Title order={3}>{formatter.currency(costTotal)}</Title>
        </Group>
      </Group>
      <Group position="right">
        <Group position="apart" w={300}>
          <Text size={"xs"}>Excl. Discount</Text>
          <Title order={3} color={discountAmount > 0 ? "red" : ""}>
            {discountAmount == 0 ? "-" : formatter.currency(-discountAmount)}
          </Title>
        </Group>
      </Group>
      <Group position="right">
        <Divider my="xs" w={300} />
      </Group>
      <Group position="right">
        <Group position="apart" w={300}>
          <Text size={"xs"}>Profit</Text>
          <Title order={3} color={total - costTotal > 0 ? "green" : "red"}>
            {formatter.currency(total - costTotal - discountAmount)}
          </Title>
        </Group>
      </Group>
    </>
  );
};

export default QuotationChargeSummary;
