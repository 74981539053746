import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../components/pageList";
import moment from "moment";
import ActionCellRender from "../components/actionCellRender";
import { useCellRender } from "../hooks/useCellRender";
import { useAuthUser } from "react-auth-kit";
import RemoteSelect2 from "../components/remoteSelect2";
import {
  Button,
  Text,
  TextInput,
  Select,
  Textarea,
  ActionIcon,
} from "@mantine/core";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import MessageWindow from "../components/messageWindow";

const apiEntity = "ticket";

const MyTicket = ({}) => {
  const [cellRender] = useCellRender();
  const [preQuery, setPreQuery] = useState();

  const auth = useAuthUser();
  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";

  useEffect(() => {
    switch (userRoleName) {
      case "CONSULTANT":
        setPreQuery({ by: auth().id, members: { $in: [auth().id] } });
        break;

      case "OPERATION":
        setPreQuery({ members: { $in: [auth().id] } });
        break;

      case "ACCOUNT":
        setPreQuery({ members: { $in: [auth().id] } });
        break;

      case "MANAGEMENT":
        setPreQuery({ members: { $in: [auth().id] } });
        break;

      case "GI-OPERATION":
        setPreQuery({ members: { $in: [auth().id] } });
        break;
    }
  }, [userRoleName]);

  const addFormSchema = {
    title: "New Ticket",
    description: "Please follow the step to create ticket",
    apiEntity,
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,
    initialValues: {
      subject: "",
      by: auth().id,
      author: auth(),
      members: [],
      type: "",
      itemCode: "",
    },

    layout: {
      containers: [
        {
          key: "main",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            variant: "pills", //default | pills | outline
            defaultValue: "Details",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
        },
      ],

      fields: [
        {
          name: "type",
          component: RemoteSelect2,
          parent: "main",
          props: {
            label: "Ticket Type",
            apiEntity: "ticketType",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "itemCode",
          component: TextInput,
          parent: "main",
          props: {
            label: "Code",
          },
        },

        {
          name: "subject",
          component: TextInput,
          parent: "main",
          props: {
            label: "Subject",
          },
        },

        {
          name: "description",
          component: Textarea,
          parent: "main",
          props: {
            label: "Description",
          },
        },
      ],
    },
  };
  const editFormSchema = {
    title: "Ticket",
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    apiEntity,
    layout: {
      containers: [
        // {
        //   key: "section-basic",
        //   parent: null,
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Ticket Info",
        //     titleOrder: 5,
        //     // description: "Please assign the corresponding consultant",
        //     withBorder: false,
        //     minHeight: 0,
        //     mt: 20,
        //     mb: 20,
        //   },
        // },
        {
          key: "section-message",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
      ],
      fields: [
        {
          name: "message",
          component: MessageWindow,
          parent: "section-message",
          props: {},
        },
      ],
    },
  };

  const formSchema = {
    add: addFormSchema,
    edit: editFormSchema,
  };

  const tableSchema = {
    showActionColumn: true,
    searchableFields: [
      "code",
      "itemCode",
      "subject",
      "status",
      "entity",
      "description",
    ],
    columns: [
      {
        field: "code",
      },
      {
        field: "subject",
      },
      {
        headerName: "Item Code",
        field: "itemCode",
      },
      {
        headerName: "Created At",
        field: "createdAt",
        cellRender: (col, data) => moment(data.createdAt).format("LLL"),
      },
      {
        headerName: "By",
        field: "by",
        cellRender: (col, data) => cellRender.User(data.by),
      },
      {
        field: "status",
        sortable: true,
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
  return (
    preQuery && (
      <PageList
        title={"My Tickets"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        preQueryNotEmpty={userRoleName === "CONSULTANT" ? true : false}
        showImport={true}
      />
    )
  );
};

export default MyTicket;
// Path: client/src/pages/myTicket.js
