import React, { useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Group,
  Text,
  UnstyledButton,
} from "@mantine/core";
import _ from "lodash";

import DataTable from "./dataTable";
import { useFormRender } from "../hooks/useFormRender";
import ReactJson from "react-json-view";
import { IconCirclePlus } from "@tabler/icons";

// const LocalPageList = ({form, fieldName, onFormSubmit, tableSchema, formSchema, ...props}) =>{
const LocalPageList = ({
  rows,
  onFormSubmit,
  tableSchema,
  formSchema = {},
  readOnly = false,
  btnLabel = "Add",

  ...props
}) => {
  // const rows = _.get(form.values, fieldName)

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const onSubmit = ({ values, formMode }) => {
    if (!formMode) return;

    if (formMode === "add") {
      // console.log("add", values);
      rows.unshift(values);
      // form.setFieldValue(fieldName, [...rows])
    }

    if (formMode === "edit") {
      //   console.log("edit", values._id);
      rows[selectedIndex] = { ...values };
      // form.setFieldValue(fieldName, [...rows])
    }
    formAction.close();
    return onFormSubmit([...rows]);
  };

  const handleOnAdd = () => {
    formAction.open({ mode: "add" });
  };

  const handleOnEdit = ({ data, rowIndex }) => {
    // console.log("handleONEdit");
    setSelectedIndex(rowIndex);
    console.log("localPageList open", data);
    formAction.open({ mode: "edit", data });
  };

  const handleOnRemove = (index) => {
    rows.splice(index, 1);
    return onFormSubmit([...rows]);
  };

  const handleActionBtnClick = ({ action, data, rowIndex }) => {
    // console.log(action, data, rowIndex);
    if (action === "delete") return handleOnRemove(rowIndex);
    if (action === "edit") return handleOnEdit({ data, rowIndex });
  };

  const onSubmitSucess = () => {};

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSucess,
    onSubmit
  );

  return (
    <>
      {/* <ReactJson name="localPageList"  src={rows} style={{background:'white'}} collapsed/> */}
      {/* selectedIndex:{selectedIndex} */}
      {_.isEmpty(rows) ? (
        // <Badge color={"red"}> No Data Yet </Badge>
        <></>
      ) : (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          onRowDoubleClick={handleOnEdit}
          onActionBtnClick={handleActionBtnClick}
          minHeight="0px"
        />
      )}

      <div>
        {!readOnly && (
          <>
            <UnstyledButton onClick={handleOnAdd} mt="sm">
              <Group spacing={"xs"}>
                <IconCirclePlus size={15} />
                <Text size="xs">{btnLabel}</Text>
              </Group>
            </UnstyledButton>
          </>
        )}
      </div>

      {renderForm()}
    </>
  );
};

export default LocalPageList;
