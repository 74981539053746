import {
  Text,
  TextInput,
  Textarea,
  Checkbox,
  Select,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import _ from "lodash";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";

import RemoteSelect2 from "../../components/remoteSelect2";
import AgeRanger from "../../components/ageRanger";
import FileList from "../../components/fileList";
import LinkageList from "../../components/linkageList";

import { ProductCommissionTable } from "../../components/productCommissionTable";
import { ProductVariation } from "../../components/productVariation";
import ProductCheckList from "../../components/productCheckList";
import ActivityLog from "../../components/activityLog";

import {
  DATA_CURRENCY,
  DATA_PAY_MODES,
  DATA_JURISDICTION,
  DATA_PRODUCT_STATUS,
  DATA_PRODUCT_DEFAULT_CHECKLIST,
} from "../../data/options";

import { useCellRender } from "../../hooks/useCellRender";
import { useFormatter } from "../../hooks/useFomatter";
import React, { useEffect, useState } from "react";
import ProductFilter from "../../components/productFilter";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import UniqueTextInput from "../../components/uniqueTextInput";
import AutoCodeInput from "../../components/autoCodeInput";
import { DatePicker } from "@mantine/dates";

const apiEntity = "product";

function Product() {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [filterQuery, setFilterQuery] = useState(null);

  const auth = useAuthUser();
  const navigate = useNavigate();

  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const currentURL = window.location.pathname;

  useEffect(() => {
    if (
      userRoleName != "GI OPERATION" &&
      userRoleName != "OPERATION" &&
      userRoleName != "MANAGEMENT" &&
      userRoleName != "ACCOUNTING" &&
      currentURL.includes("operation")
    )
      navigate(currentURL.replace("operation", "consultant"));
  }, [userRoleName, currentURL]);

  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "name",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data),
      },
      {
        field: "code",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductCode(data),
      },

      {
        field: "type.name",
        headerName: "Type",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductType(data.type),
      },

      {
        field: "status",
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconTrash,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    select: "code name cname status provider type",
    searchableFields: [
      "code",
      "name",
      "cname",
      "status",
      "provider.code",
      "provider.cname",
      "provider.shortName",
      "type.name",
      "type.cname",
      "type.code",
    ],
  };

  const formSchema = {
    title: "Product",
    isMainForm: true,
    titleField: "name",

    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
      // size: "xl",     //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,

    initialValues: {
      code: "",
      planCode: "",
      name: "",
      cname: "",
      status: "INACTIVE",

      category: null,
      type: null,
      provider: null,
      // ageType: "PREVIOUS",
      applicantAgeMin: 18,
      applicantAgeMax: 70,
      insuredAgeMin: 0,
      insuredAgeMax: 100,
      currency: "HKD",
      description: "",
      minInitialCharge: 0,
      variations: [],
      checkList: DATA_PRODUCT_DEFAULT_CHECKLIST,

      // termsOfService: true,
    },

    validate: {
      // code: (value) =>
      //   value?.length < 4 ? "Code must have at least 4 letters" : null,

      // planCode: (value) =>
      // 	value?.length < 2 ? "Plan code must have at least 2 letters" : null,

      name: (value) =>
        value?.length < 4 ? "Name must have at least 4 letters" : null,
      insuredWeeklyAge: (value) =>
        value >= 52 ? "Weekly Age must less than 52 week" : null,
      provider: (value) => (!value ? "No provider given" : null),
      type: (value) => (!value ? "No product type given" : null),
      category: (value) => (!value ? "No product category given" : null),
      // jurisdiction: (value) =>
      //   _.isEmpty(value) ? "Jurisdiction is empty" : null,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Details",
          },
          tab: [
            { key: "t1-1", label: "Details", icon: IconSettings },
            {
              key: "t1-2",
              label: "Commission",
              icon: IconSettings,
            },
            { key: "t1-3", label: "CheckList", icon: IconSettings },
            { key: "t1-4", label: "Resources", icon: IconSettings },
            {
              key: "t1-5",
              label: "Activities",
              icon: IconSettings,
            },
          ],
        },
        {
          key: "basicInfo",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Basic Info",
            description: "Please fill in the basic info of product",
          },
        },
        {
          key: "additionalInfo",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Additional Info",
            description: "Please fill in the basic info of product",
          },
        },
        {
          key: "paymentInfo",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Info",
            description: "Please fill in the Payment info of product",
          },
        },
        {
          key: "variations",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Variations",
            description: "Please fill in the base info of product",
          },
        },

        {
          key: "basicInfo-g",
          parent: "basicInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "additional-g",
          parent: "additionalInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "addInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "addInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "paymentInfo-g",
          parent: "paymentInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "paymentInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "paymentInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "variations-g",
          parent: "variations",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "variations-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "variations-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "commissionTable-section",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Tables",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "checkList-section",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "CheckList",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "resource-file-section",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            description: "Please fill in the Payment info of product",
          },
        },
        {
          key: "resource-link-section",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Links",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "activities-section",
          parent: "t1-5",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Activities",
            description: "Please fill in the Payment info of product",
          },
        },
      ],

      fields: [
        {
          name: "code",
          component: UniqueTextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Code (Auto if empty)",
            placeholder: "",
            uniqueEntity: "product",
            errorMessage: "Product code is already exist.",
            mt: 0,
          },
        },

        {
          name: "provider",
          component: RemoteSelect2,
          parent: "basicInfo-g1",
          props: {
            name: "provider",
            apiEntity: "provider",
            required: true,
            label: "Provider",
            placeholder: "AIA",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) => `[${data.code}] ${data.name}`,
            searchFields: ["name", "code"],
            pageSize: 10000,
            sort: {
              by: "code",
              order: "asc",
            },
          },
        },

        {
          name: "name",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "English Name",
            placeholder: "",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Chinese Name",
            placeholder: "",
          },
        },

        {
          name: "status",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Status",
            placeholder: "",
            data: DATA_PRODUCT_STATUS,
            default: "ACTIVE",
          },
        },
        {
          name: "category",
          component: RemoteSelect2,
          parent: "basicInfo-g2",
          props: {
            name: "category",
            apiEntity: "productCategory",
            required: true,
            label: "Category",
            placeholder: "",
            valueField: "_id",
            labelField: "name",
            // labelRender: (data) => data.name,
            searchFields: ["name", "code"],
            clearable: true,
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        {
          name: "type",
          component: RemoteSelect2,
          parent: "basicInfo-g2",
          props: {
            name: "type",
            apiEntity: "productType",
            required: true,
            label: "Product Type",
            placeholder: "",
            valueField: "_id",
            labelField: "name",
            pageSize: 300,
            // labelRender: (data) => data.name,
            searchFields: ["name", "code"],
            clearable: true,
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        // {
        // 	name: "ageType",
        // 	component: Select,
        // 	parent: "basicInfo-g2",
        // 	props: {
        // 		required: true,
        // 		label: "Age Type",
        // 		placeholder: "",
        // 		data: DATA_PRODUCT_AGETYPES,
        // 		default: "ACTIVE",
        // 	},
        // },
        {
          name: "description",
          component: Textarea,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Description",
            placeholder: "",
            autosize: true,
          },
        },
        {
          name: "otherName",
          component: TextInput,
          parent: "addInfo-g1",
          props: {
            required: true,
            label: "Other Name",
            placeholder: "",
          },
        },
        {
          name: "planCode",
          component: TextInput,
          parent: "addInfo-g1",
          props: {
            // required: true,
            label: "Plan Code / Name (Provider)",
            placeholder: "Provider Plan Code",
          },
        },
        {
          name: "currency",
          component: MultiSelect,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Currency",
            placeholder: "",
            data: DATA_CURRENCY,
            default: "HKD",
          },
        },
        {
          name: "allowPrepaid",
          component: Checkbox,
          parent: "addInfo-g1",
          props: {
            type: "checkbox",
            label: "Allow Prepayment",
            mt: 15,
          },
        },

        {
          name: "allowOffShore",
          component: Checkbox,
          parent: "addInfo-g1",
          props: {
            type: "checkbox",
            label: "Allow OffShore",
          },
        },

        {
          name: "allowPreimumFinancing",
          component: Checkbox,
          parent: "addInfo-g1",
          props: {
            type: "checkbox",
            label: "Allow Preimum Finance",
          },
        },

        {
          name: "policyPledge",
          component: Checkbox,
          parent: "addInfo-g1",
          props: {
            type: "checkbox",
            label: "Allow Policy Pledge",
          },
        },

        {
          name: "insuredWeeklyAge",
          component: NumberInput,
          parent: "addInfo-g2",
          props: {
            label: "Minimum Insured Weekly Age",
            min: 0,
            max: 51,
          },
        },

        {
          name: "jurisdiction",
          component: MultiSelect,
          parent: "addInfo-g2",
          props: {
            label: "Jurisdiction",
            required: true,
            placeholder: "",
            data: DATA_JURISDICTION,
            defaultValue: "UG",
            searchable: true,
          },
        },

        {
          name: "inactiveSince",
          component: DatePicker,
          parent: "addInfo-g2",
          type: "datePicker",

          props: {
            label: "Inactive Since",
            required: true,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "payModes",
          component: MultiSelect,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Pay Modes",
            placeholder: "",
            data: DATA_PAY_MODES,
            multiple: true,
            searchable: true,
            clearable: true,
            // dropdownPosition:"top",
          },
        },
        {
          name: "applicantAgeRange",
          component: AgeRanger,
          parent: "addInfo-g2",
          props: {
            label: "Applicatant Age Range",
            min: 0,
            max: 100,
            step: 1,
            defaultValue: [0, 100],
          },
        },

        {
          name: "insuredAgeRange",
          component: AgeRanger,
          parent: "addInfo-g2",
          props: {
            label: "Insured Age Range",
            min: 0,
            max: 100,
            step: 1,
            defaultValue: [0, 100],
          },
        },

        {
          name: "payTermMin",
          component: NumberInput,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Minimum Pay Term",
            placeholder: "",
            defaultValue: 0,
            precision: 0,
          },
        },
        {
          name: "payTermMax",
          component: NumberInput,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Maximum Pay Term",
            placeholder: "",
            defaultValue: 100,
            precision: 0,
          },
        },

        {
          name: "minInitialCharge",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Minimum Initial Charge (%)",
            placeholder: "",
            defaultValue: 0,
            precision: 2,
            parser: formatter.numberParser,
            formatter: formatter.percentage,
          },
        },

        {
          name: "minPremium",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Minimum Premium",

            placeholder: "",
            defaultValue: 0,
            precision: 2,
            parser: formatter.numberParser,
            formatter: formatter.currencyFormat,
          },
        },

        {
          name: "minInsured",
          component: NumberInput,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Minimum Insured",
          },
        },

        {
          name: "variations",
          component: ProductVariation,
          parent: "variations-g1",
          props: {
            placeholder: "",
          },
        },

        {
          name: "commissionTables",
          component: ProductCommissionTable,
          parent: "commissionTable-section",
          props: {
            rateFactor: 1,
          },
        },

        {
          name: "checkList",
          component: ProductCheckList,
          parent: "checkList-section",
          props: {},
        },

        {
          name: "files",
          component: FileList,
          parent: "resource-file-section",
          props: {},
        },

        {
          name: "linkages",
          component: LinkageList,
          parent: "resource-link-section",
          props: {},
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "activities-section",
          props: {},
        },
      ],
    },
  };

  const onFilterChange = (query) => {
    setFilterQuery(query);
  };

  return (
    <PageList
      title={"Products"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      initSort={{ by: "name", order: "asc" }}
      //   filterQuery={filterQuery}
      //   filter={{
      //     component: ProductFilter,
      //     props: {
      //       onChange: onFilterChange,
      //     },
      //   }}
    />
  );
}

export default Product;
