import React, { useState, useEffect, lazy, Suspense } from "react";
import { Text, Grid, TextInput, NumberInput } from "@mantine/core";

const DataDisplayRow = ({
  label,
  value,
  editable = false,
  size = "xs",
  type = "TEXT",
  onChange,
  props,
}) => (
  <Grid>
    <Grid.Col sm={6}>
      <Text size={size}>{label}</Text>
    </Grid.Col>

    {!editable && (
      <Grid.Col sm={6}>
        <Text size={size}>{value}</Text>
      </Grid.Col>
    )}

    {editable && (
      <Grid.Col sm={6}>
        {type == "TEXT" && (
          <TextInput
            size={size}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        )}

        {type == "NUMBER" && (
          <NumberInput
            value={value}
            size={size}
            onChange={(v) => onChange(v)}
            {...props}
          />
        )}
      </Grid.Col>
    )}
  </Grid>
);

export default DataDisplayRow;
