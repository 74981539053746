import { Text } from '@mantine/core'

function Consultant(){

    return ( <Text>Consultant Page</Text>)
}


export default Consultant

