import React from "react";
import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Title,
} from "@mantine/core";
import _ from "lodash";
import { useFormatter } from "../hooks/useFomatter";

const AllowancePlanStat = ({ form: mainForm }) => {
  const [formatter] = useFormatter();

  return (
    <Group grow mt={30} ml={10}>
      <span>
        <Title order={6}>Pay Terms</Title>
        <Text> {mainForm.values.payTerm}</Text>
      </span>

      <span>
        <Title order={6}>Total Amount</Title>

        <Text> {formatter.currency(mainForm.values.totalAmount)}</Text>
      </span>
    </Group>
  );
};

export default AllowancePlanStat;
