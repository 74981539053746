import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
	Button,
	TextInput,
	Grid,
	Table,
	Text,
	ActionIcon,
	Group,
	Textarea,
} from "@mantine/core";
import ReactJson from "react-json-view";
import { useForceUpdate } from "@mantine/hooks";
import {
	IconCirclePlus,
	IconExchange,
	IconMultiplier1x,
	IconTrash,
} from "@tabler/icons";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { Component } from "ag-grid-community";
import ArrayTable from "./arrayTable";

const QuotationTerms = ({ form, name }) => {
	const rowInitValue = {
		title: "",
		detail: "",
	};

	const fields = [
		{
			header: "Term Title",
			headerWidth: "20%",
			fieldName: "title",
			component: TextInput,
			props: {},
		},
		{
			header: "Term Detail",
			headerWidth: "65%",
			fieldName: "detail",
			component: Textarea,
			props: {
				minRows: 1,
				autosize: true,
				py: 0,
			},
		},
	];

	return (
		<>
			<ArrayTable
				form={form}
				name={name}
				rowInitValue={rowInitValue}
				fields={fields}
			/>
		</>
	);
};

export default QuotationTerms;
