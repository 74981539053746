import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";
import { addFormSchema } from "../pages/gi/quotation";

const QuotationEditButton = ({ mainForm }) => {
  let formSchema = { ...addFormSchema };
  formSchema.title = "Quotation Details";

  const onSubmit = async ({ values, formMode }) => {
    // mainForm.setValues(values);
    try {
      await mainForm.saveValues(values);
      await mainForm.reload();
      formAction.close();
    } catch (error) {
      console.log(error);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button fullWidth onClick={handleActionClick}>
        Edit Details
      </Button>
      {renderForm()}
    </>
  );
};

export default QuotationEditButton;
