import React, { useState, useEffect } from "react";
import { Container, Text, Title, Box, useMantineTheme } from "@mantine/core";

import { ResponsiveBar } from "@nivo/bar";

import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useServerApi } from "../../hooks/userServerApi";
import { barData } from "../../data/sampleChartData";
import ReactJson from "react-json-view";

const defaultChartProps = {
  keys: [],
  indexBy: "period",
  margin: { top: 50, right: 130, bottom: 50, left: 80 },
  padding: 0.2,
  groupMode: "grouped",
  valueScale: { type: "linear" },
  indexScale: { type: "band", round: true },
  colors: { scheme: "category10" },
  enableLabel: false,
  layout: "vertical",
  legends: [
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
};

const BarChart = ({
  title,
  height = 500,
  description,
  chartProps = {},
  dataAggreation,
  data = {},
  useSampleData = true,
}) => {
  const theme = useMantineTheme();
  const [api] = useServerApi();
  const [chartData, setChartData] = useState({});

  const fetchData = async () => {
    try {
      if (useSampleData) {
        return setChartData(barData);
      }

      if (dataAggreation) {
        const d = await api.aggregate(dataAggreation);
        console.log("Bar Chart fetchData=", d);
        const { mapper, aggregations } = dataAggreation;
        if (!mapper) return setChartData(d);
        return setChartData(mapper(d));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (_.isEmpty(data)) return;
    setChartData(data);
  }, [data]);

  const chartTheme = {
    textColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[1]
        : theme.colors.dark[8],
    grid: {
      line: {
        stroke:
          theme.colorScheme === "dark"
            ? theme.colors.gray[8]
            : theme.colors.gray[1],
      },
    },
  };

  return (
    <>
      {/* <ReactJson src={chartData} style={{ background: "white" }} /> */}
      <FormSectionCard title={title} h={height} w={"100%"} p="md">
        <Text size={"xs"} color="dimmed">
          {description}
        </Text>
        {!_.isEmpty(chartData) && (
          <ResponsiveBar
            data={chartData}
            theme={chartTheme}
            {...defaultChartProps}
            {...chartProps}
          />
        )}
      </FormSectionCard>
    </>
  );
};

export default BarChart;
