import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";

export default function OrderSummary({ form, name }) {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const dataFontWeight = 600;
  const status = DATA_ORDER_STATUS.find((s) => s.value === order?.status);

  const fields = [
    {
      title: "Order ID",
      value: (
        <Group>
          {order?.code} {cellRender.Order.type(order?.type)}
        </Group>
      ),
    },
    {
      title: "Status",
      value: status ? (
        <Group>
          {cellRender.Order.status(order?.status)}
          {status?.label}
        </Group>
      ) : (
        "Unknown"
      ),
    },
    { title: "Created By", value: cellRender.User(order?.createdBy) },
    { title: "Signed By", value: cellRender.User(order?.signedBy) },
    { title: "Signed By (2nd)", value: cellRender.User(order?.signedBy2) },

    { title: "Signed Place", value: order?.signedPlace },
  ];

  return <InfoGridList fields={fields} values={order} />;
}
