import { Title, Text, Box, Group, UnstyledButton, Badge } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useSpotLight } from "../hooks/useSpotLight";
import { Page } from "../layout/page";

import _ from "lodash";
import { useFormatter } from "../hooks/useFomatter";
import { useCellRender } from "../hooks/useCellRender";
import { getFileIcon } from "../components/fileList";

const OrderSection = ({ result }) => {
  const [formatter] = useFormatter();
  const [cellRender] = useCellRender();
  const Navigate = useNavigate();

  return (
    <>
      {!_.isEmpty(result) &&
        result.map((r) => (
          <Box mb="xl">
            <UnstyledButton
              onClick={() =>
                window.open(`/operation/order?id=${r._id}&mode=edit`, "_SELF")
              }
            >
              <Group>
                <Title order={6}>{r.code}</Title>
                <Group spacing={2}>
                  {cellRender.Order.status(r.status)}
                  {cellRender.Order.type(r.type)}
                </Group>
              </Group>
            </UnstyledButton>
            <Text size={"xs"}>{r.policyNumber}</Text>

            <Text color={"dimmed"} size="xs">
              [Order] {r.searchText}
            </Text>
          </Box>
        ))}
    </>
  );
};

const ClientSection = ({ result }) => {
  const [formatter] = useFormatter();
  const [cellRender] = useCellRender();
  const Navigate = useNavigate();

  return (
    <>
      {!_.isEmpty(result) && (
        <Box mt="xl">
          {result.map((r) => (
            <Box mb="xl">
              <UnstyledButton
                onClick={() =>
                  window.open(`/operation/client?id=${r._id}&mode=edit`)
                }
              >
                <Group>
                  <Title order={6}>
                    {r.name} {r.cname} {r.aliasName}
                  </Title>
                </Group>
              </UnstyledButton>
              <Text size={"xs"}>
                [Client] {r.phone} {r.id} {r.email}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

// const FileSection = ({ result }) => {
//   const [formatter] = useFormatter();
//   const [cellRender] = useCellRender();
//   const Navigate = useNavigate();

//   const files = result.reduce((a, c) => [...c.files, ...a], []);
//   return (
//     <>
//       {!_.isEmpty(files) && (
//         <Group mt="xl">
//           {files.map((f) => (
//             <>{getFileIcon(f.mimetype)}</>
//           ))}
//         </Group>
//       )}
//     </>
//   );
// };

const UserSection = ({ result }) => {
  const [formatter] = useFormatter();
  const [cellRender] = useCellRender();
  const Navigate = useNavigate();

  return (
    <>
      {!_.isEmpty(result) && (
        <Box mt="xl">
          {result.map((r) => (
            <Box mb="xl">
              <Group>
                {cellRender.UserAvatar(r)}
                <UnstyledButton
                  onClick={() =>
                    window.open(`/operation/user?id=${r._id}&mode=edit`)
                  }
                >
                  <Group>
                    <Title order={6}>
                      {r.name} {r.cname} {r.alias}
                    </Title>
                    <Group spacing={2}>
                      {
                        <Badge size="xs" radius={0}>
                          {r.staffId}
                        </Badge>
                      }
                      {cellRender.Status(r.status)}
                    </Group>
                  </Group>
                </UnstyledButton>
              </Group>

              <Text size={"xs"}>
                [User] {r.staffId} {r.name} {r.cname} {r.workTitle} TEL:{" "}
                {r.phone} {r.id}
                EMAIL: {r.email}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

const SearchPage = ({}) => {
  const query = new URLSearchParams(useLocation().search);
  const searchText = query.get("q");
  const [orderResults, setOrderResults] = useState([]);
  const [clientResults, setClientResults] = useState([]);
  const [productResults, setProductResults] = useState([]);
  const [fileResults, setFileResults] = useState([]);
  const [userResults, setUserResults] = useState([]);
  const [resultLength, setResultLength] = useState(0);

  const [
    onSpotLightQueryChange,
    spotLightActions,
    searchProducts,
    searchClients,
    searchUsers,
    searchOrders,
    searchFiles,
    role,
  ] = useSpotLight();

  const fetchResults = async (text) => {
    try {
      let length = 0;

      //   const rfile = await searchFiles(text, 10);
      //   console.log("rfile", rfile);
      //   setFileResults(rfile);
      //   length += rfile?.length ?? 0;

      const r1 = await searchOrders(text, 10);
      setOrderResults(r1);
      length += r1?.length ?? 0;

      const r2 = await searchClients(text, 10);
      setClientResults(r2);
      length += r2?.length ?? 0;

      if (role != "CONSULTANT") {
        const r3 = await searchUsers(text);
        setUserResults(r3);
        length += r3?.length ?? 0;
      }

      const r4 = await searchProducts(text);
      setProductResults(r4);
      length += r4?.length ?? 0;

      setResultLength(length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) return;
    fetchResults(searchText);
  }, [searchText]);

  return (
    <Page>
      {/* {role} */}
      <Title mb="xl"> Search Result </Title>

      {/* <FileSection result={fileResults} /> */}

      <UserSection result={userResults} />
      <ClientSection result={clientResults} />
      <OrderSection result={orderResults} />

      {resultLength == 0 && (
        <Title order={5}> No results found for {searchText}</Title>
      )}
    </Page>
  );
};

export default SearchPage;
