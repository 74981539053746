import React, { useState, useEffect } from "react";
import { Page } from "../../layout/page";
import {
	Table,
	TextInput,
	NumberInput,
	Checkbox,
	Group,
	Button,
	ActionIcon,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import PageListHeader from "../../components/pageListHeader";

import { useServerApi } from "../../hooks/userServerApi";
import _ from "lodash";
import {
	ENUM_FORM_DISPLAY,
	ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import { useFormRender } from "../../hooks/useFormRender";
import { useForceUpdate } from "@mantine/hooks";

const RateTable = ({ form, name, ...props }) => {
	const forceUpdate = useForceUpdate();

	const rates = _.get(form.values, name) || [];

	const handleAdd = () => {
		form.setFieldValue(name, [
			...rates,
			{ code: "", value: 1, externalValue: 1 },
		]);
	};

	const handleRemove = (index) => {
		let list = [...rates];
		list.splice(index, 1);
		form.setFieldValue(name, list);
	};

	const renderRow = (row, index) => {
		// console.log("Render row", row);
		return (
			<tr key={index}>
				<td>
					<TextInput
						value={row.code}
						onChange={(e) => {
							row.code = e.target.value;
							forceUpdate();
						}}
					/>
				</td>

				<td>
					<NumberInput
						value={row.value}
						precision={2}
						onChange={(val) => {
							row.value = val;
							forceUpdate();
						}}
					/>
				</td>

				<td>
					<NumberInput
						value={row.externalValue}
						precision={2}
						onChange={(val) => {
							row.externalValue = val;
							forceUpdate();
						}}
					/>
				</td>

				<td>
					{
						<ActionIcon onClick={() => handleRemove(index)}>
							<IconTrash size={14} />
						</ActionIcon>
					}
				</td>
			</tr>
		);
	};

	const renderTable = () => (
		<Table {...props}>
			<thead>
				<tr>
					<th>Code</th>
					<th>Internal Rate</th>
					<th>External Rate</th>
					<th></th>
				</tr>
			</thead>
			<tbody>{rates.map((row, index) => renderRow(row, index))}</tbody>
		</Table>
	);

	return (
		<Group>
			{/* <ReactJson src={_.get(form.values, name) } style={{background:'white'}} collapsed/> */}
			{!_.isEmpty(rates) && renderTable()}

			<Button onClick={handleAdd}> Add Currency</Button>
		</Group>
	);
};

const formSchema = {
	title: "Exchange Rate",
	apiEntity: "exchangeRate",
	display: {
		mode: ENUM_FORM_DISPLAY.INSIDE,
		size: "xl", //xs, sm, xl  ....100%
		// position:"right"
	},
	layout: {
		containers: [
			{
				key: "section1",
				parent: null,
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {
					title: "Rates Details",
					description: "Please fill in currency rates",
				},
			},
		],
		fields: [
			{
				name: "rates",
				component: RateTable,
				parent: "section1",
				props: {},
			},
		],
	},
};
const ExchangeRate = () => {
	// const [rates, setRates] = useState([]);
	const [api] = useServerApi();
	const [renderForm, formAction] = useFormRender(formSchema);

	const fetchRate = async () => {
		try {
			const result = await api.search({
				apiEntity: "exchangeRate",
				pageSize: 1,
				searchQuery: { active: true },
			});
			if (!result || _.isEmpty(result.docs)) throw "No Exchange Rate Settings";

			const exchangeRates = result.docs[0];
			console.log("ExchangeRAte", exchangeRates);
			formAction.open({
				mode: "edit",
				id: exchangeRates._id,
				data: exchangeRates,
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchRate();
	}, []);

	const handleSave = () => {
		formAction.save();
	};

	return (
		<Page>
			<PageListHeader
				title={"Exchange Rate"}
				actionButtons={[
					{ label: "Save", onClick: () => handleSave() },
					{
						label: "Close",
						onClick: () => window.history.back(),
						variant: "default",
					},
				]}
			/>
			{renderForm()}
		</Page>
	);
};

export default ExchangeRate;
