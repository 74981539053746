import React from "react";
import { Table, ScrollArea } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";

const ProfolioRowTable = ({ form }) => {
	const { rows } = form.values;
	const [formatter] = useFormatter();
	const minHeight = "500px";
	return (
		<ScrollArea sx={{ height: minHeight }}>
			<Table>
				<thead>
					<tr>
						<th>Policy No.</th>
						<th>Fund Code</th>
						<th>Fund Name</th>
						<th>Alloc Ratio</th>
						<th>Unit Price</th>
						<th>No. of units</th>

						<th>Value (F.C)</th>
						<th>Value (A.C)</th>
					</tr>
				</thead>

				<tbody>
					{rows &&
						rows.map((r, index) => (
							<tr key={index}>
								<td>{r.policyNumber}</td>
								<td>{r.fundCode}</td>
								<td>{r.investmentFund}</td>
								<td>{r.allocationRatio}</td>
								<td>{r.unitPrice}</td>
								<td>{r.units}</td>

								<td>
									{formatter.currency(r.valueInFundCurrency, r.fundCurrency)}
								</td>
								<td>
									{formatter.currency(
										r.valueInPolicyCurrency,
										r.policyCurrency
									)}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		</ScrollArea>
	);
};

export default ProfolioRowTable;
