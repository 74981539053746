import React, { useEffect, useState, useRef } from "react";
import { Select, Group, Text, Divider } from "@mantine/core";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";
import { useFormatter } from "../hooks/useFomatter";
import moment from "moment";

const PolicyFundSummary = ({
  options,
  valuation,
  onChangeValuation,
  selected,
}) => {
  const [formatter] = useFormatter();

  return (
    <FormSectionCard title="Fund Allocations">
      <Group position="apart">
        <Select
          size="xs"
          data={options}
          onChange={onChangeValuation}
          value={selected}
        />

        <Group>
          <Divider orientation="vertical"></Divider>
          <div>
            <Text size={"md"}>{valuation.policyNumber}</Text>
            <Text color="dimmied" size="xs">
              Policy Number
            </Text>
          </div>
        </Group>
        <Group>
          <Divider orientation="vertical"></Divider>
          <div>
            <Text size={"md"}>
              {moment(valuation.valuationDate).format("LL")}
            </Text>
            <Text color="dimmied" size="xs">
              Valuation Date
            </Text>
          </div>
        </Group>
        <Group>
          <Divider orientation="vertical"></Divider>
          <div>
            <Text size={"md"}>
              {formatter.currency(
                valuation?.totalValueInPolicyCurrency,
                valuation.policyCurrency
              )}
            </Text>
            <Text color="dimmied" size="xs">
              Total Amount
            </Text>
          </div>
        </Group>
      </Group>
    </FormSectionCard>
  );
};

export default PolicyFundSummary;
