export const theme = {
  colors: {
    // Add your color
    deepBlue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
    // or replace default theme color
  },

  primaryColor: "cyan",
  dimmed: "red",

  components: {
    // Button: {
    //     defaultProps: (theme) => ({
    //         color: theme.colorScheme === "dark" ? "orange" : "cyan",
    //     }),
    // },
    Title: {
      defaultProps: (theme) => ({
        color: theme.colorScheme === "dark" ? "white" : "black",
      }),
    },
    // Text: {
    //   defaultProps: (theme) => ({
    //     color:
    //       theme.colorScheme === "dark"
    //         ? theme.colors.gray[4]
    //         : theme.colors.dark[9],
    //   }),
    // },

    Input: {
      styles: (theme) => ({
        input: {
          // "&:focus-within": {
          //     borderColor: theme.colors.orange[7],
          // },

          "&:disabled": {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.gray[0]
                : theme.colors.dark[9],
          },
        },
        label: {
          color: "red",
        },
      }),
    },

    Checkbox: {
      styles: (theme) => ({
        input: {
          // border: "1px solid red",
          "&:disabled": {
            background: "white",
          },
        },
        label: {
          // color: "red",
          "&:disabled": {
            color: "red",
          },
        },
      }),
    },
  },
};
