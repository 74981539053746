import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Group } from "@mantine/core";

export default function ProductBasicSummary({ form }) {
  const [cellRender] = useCellRender();
  const product = form.values;
  const dataFontWeight = 600;

  const fields = [
    {
      title: "Code",
      value: product.code,
    },
    {
      title: "Provider",
      value: product.provider?.shortName,
    },
    {
      title: "Status",
      value: product.status,
    },

    {
      title: "Name",
      value: product.name,
    },
    {
      title: "Chinese Name",
      value: product.cname,
    },

    {
      title: "Cateogry",
      value: product.category?.name + " " + product.category?.cname,
    },

    {
      title: "Type",
      value: product.type?.name + " " + product.type?.cname,
    },

    {
      title: "Description",
      value: product.description,
    },

    {
      title: "Created At",
      value: cellRender.Date(product.createdAt, "LL", dataFontWeight),
    },
  ];
  return <InfoGridList fields={fields} values={product} />;
}
