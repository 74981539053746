import {
	Group,
	Paper,
	Space,
	Text,
	SimpleGrid,
	UnstyledButton,
	Accordion,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";

import {
	IconUsers,
	IconUser,
	IconCircleCheck,
	IconUserCircle,
	IconBuildingBank,
	IconCircleMinus,
	IconActivity,
	IconCircleX,
	IconHeart,
} from "@tabler/icons";
import CardStat from "./cardStat";
import { useAuthUser } from "react-auth-kit";

const ClientPageStats = ({ cols = 4, role, ...props }) => {
	const [api] = useServerApi();
	const auth = useAuthUser();

	const [stats, setStats] = useState([]);

	const fetchClientsStats = async () => {
		try {
			let result = null;
			switch (role) {
				case "OPERATION":
					result = await api.Client.getStatSummary();
					break;
				case "CONSULTANT":
					result = await api.Client.getStatSummaryByConsultant(auth().id);
					break;
				default:
					result = await api.Client.getStatSummary();
			}
			if (!result) return;

			setStats([
				{
					icon: IconUsers,
					color: "#00b341",
					label: "Total",
					msg: result.stats.TOTAL ?? 0,
				},
				{
					icon: IconCircleCheck,
					color: "#0ED1EA",
					label: "Active",
					msg: result.stats.ACTIVE ?? 0,
				},
				{
					icon: IconCircleX,
					color: "#EA5455",
					label: "In Active",
					msg: result.stats.INACTIVE ?? 0,
				},
				{
					icon: IconCircleMinus,
					color: "#FF9F43",
					label: "PENDING",
					msg: result.stats.PENDING ?? 0,
				},
				{
					icon: IconUser,
					color: "#8278F2",
					label: "Individual",
					msg: result.stats.INDIVIDUAL ?? 0,
				},
				{
					icon: IconBuildingBank,
					color: "red",
					label: "CORPORATE",
					msg: result.stats.CORPORATE ?? 0,
				},
				{
					icon: IconUserCircle,
					color: "#597e8d",
					label: "Staffs",
					msg: result.stats.STAFF ?? 0,
				},
			]);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchClientsStats();
	}, []);

	return (
		<>
			<Accordion defaultValue="grids">
				<Accordion.Item value="grids">
					<Accordion.Control></Accordion.Control>
					<Accordion.Panel>
						<SimpleGrid
							cols={cols}
							breakpoints={[{ maxWidth: "sm", cols: 1 }]}
							{...props}
						>
							{stats?.map((stat, index) => (
								<CardStat key={index} stat={stat} />
							))}
						</SimpleGrid>
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
		</>
	);
};

export default ClientPageStats;
