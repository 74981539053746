import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS, DATA_QUO_REQUEST_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function QuoRequestSummary({ form, name }) {
  const [cellRender] = useCellRender();
  const quo = form.values;

  const status = DATA_QUO_REQUEST_STATUS.find((s) => s.value === quo?.status);

  const fields = [
    {
      title: "Code",
      value: quo.code,
    },
    {
      title: "Status",
      value: status ? (
        <Group>
          {cellRender.QuoRequest.status(quo?.status)}
          {status?.label}
        </Group>
      ) : (
        "Unknown"
      ),
    },
    { title: "Created By", value: cellRender.User(quo?.createdBy) },

    {
      title: "Product Type",
      value: quo.productType?.name + quo.productType?.cname,
    },

    { title: "Created At", value: moment(quo?.createdAt).format("LLL") },
  ];

  return <InfoGridList fields={fields} values={quo} />;
}
