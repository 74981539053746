import _ from "lodash";
import { CommissionCoreFields } from "./commissionRelatedFields";
import {
	Group,
	Text,
	Title,
	Divider,
	Button,
	NumberInput,
	Select,
	Stack,
	ActionIcon,
	Space,
} from "@mantine/core";
import { NumberFilter } from "ag-grid-community";

import { useForceUpdate } from "@mantine/hooks";
import { IconTrash, IconPlus, IconCirclePlus } from "@tabler/icons";
import { DATA_CURRENCY, DATA_NUMBER_COMPARISON } from "../data/options";
import ReactJson from "react-json-view";

const CommissionVariation = ({
	form,
	name,
	variationFields,
	label,
	...props
}) => {
	const { relatedFields } = form.values;
	// console.log(form.values, relatedFields);
	const forceUpdate = useForceUpdate();

	const renderOptionVariation = (field) =>
		field.options.map((o) => (
			<Button variant="default" radius="lg">
				{o.label}
			</Button>
		));

	const renderNumberFieldOption = (option, onRemove, onInsert) => (
		<Group>
			<Select
				searchable
				clearable
				data={DATA_NUMBER_COMPARISON}
				value={option.condition}
				onChange={(v) => {
					option.condition = v;
					forceUpdate();
				}}
			/>
			<NumberInput
				value={option.number1}
				onChange={(v) => {
					option.number1 = v;
					forceUpdate();
				}}
			/>

			{option.condition === "<>" && (
				<NumberInput
					value={option.number2}
					onChange={(v) => {
						option.number2 = v;
						forceUpdate();
					}}
				/>
			)}

			<Group spacing={"sm"}>
				<ActionIcon>
					<IconTrash
						size={18}
						onClick={() => {
							onRemove();
							forceUpdate();
						}}
					/>
				</ActionIcon>

				<ActionIcon>
					<IconCirclePlus
						size={18}
						onClick={() => {
							onInsert();
							forceUpdate();
						}}
					/>
				</ActionIcon>
			</Group>
		</Group>
	);

	const renderMoneyFieldOption = (option, onRemove, onInsert) => (
		<Group>
			<Select
				searchable
				clearable
				data={DATA_NUMBER_COMPARISON}
				value={option.condition}
				onChange={(v) => {
					option.condition = v;
					forceUpdate();
				}}
			/>
			<Select
				searchable
				clearable
				data={DATA_CURRENCY}
				value={option.currency}
				onChange={(v) => {
					option.currency = v;
					forceUpdate();
				}}
			/>
			<NumberInput
				value={option.number1}
				onChange={(v) => {
					option.number1 = v;
					forceUpdate();
				}}
			/>
			{option.condition === "<>" && (
				<NumberInput
					value={option.number1}
					onChange={(v) => {
						option.number2 = v;
						forceUpdate();
					}}
				/>
			)}
			<Group spacing={"sm"}>
				<ActionIcon>
					<IconTrash
						size={18}
						onClick={() => {
							onRemove();
							forceUpdate();
						}}
					/>
				</ActionIcon>

				<ActionIcon>
					<IconCirclePlus
						size={18}
						onClick={() => {
							onInsert();
							forceUpdate();
						}}
					/>
				</ActionIcon>
			</Group>
		</Group>
	);

	const onRemove = (field, index) => {
		field.options?.splice(index, 1);
		forceUpdate();
	};

	const onInsert = (field, index) => {
		field.options.splice(index, 0, { condition: "=", number1: null });
		forceUpdate();
	};

	const renderNumberVariation = (field) => (
		<>
			<Stack style={{ width: "100%" }}>
				{field.options?.map((r, index) =>
					renderNumberFieldOption(
						r,
						() => onRemove(field, index),
						() => onInsert(field, index)
					)
				)}
			</Stack>

			<Button
				size="xs"
				onClick={() => {
					field.options = [...field.options, { condition: "=", number1: null }];
					forceUpdate();
				}}
			>
				Add
			</Button>
			<Button
				size="xs"
				onClick={() => {
					let lastNum = _.isEmpty(field.options)
						? null
						: _.last(field.options).number2 ?? _.last(field.options).number1;
					console.log(field.options, lastNum);
					for (let i = 0; i < 5; i++) {
						field.options = [
							...field.options,
							{ condition: "=", number1: lastNum + i + 1 },
						];
					}
					forceUpdate();
				}}
			>
				Add 5
			</Button>
		</>
	);

	const renderMoneyVariation = (field) => (
		<>
			<Stack style={{ width: "100%" }}>
				{field.options?.map((r, index) =>
					renderMoneyFieldOption(
						r,
						() => onRemove(field, index),
						() => onInsert(field, index)
					)
				)}
			</Stack>
			<Button
				size="xs"
				onClick={() => {
					field.options = [
						...field.options,
						{ condition: "=", currency: "USD", number1: null },
					];
					forceUpdate();
				}}
			>
				Add
			</Button>
		</>
	);

	const renderVariationByTypes = (field) => {
		return (
			<>
				{field.type === "options" && renderOptionVariation(field)}
				{field.type === "number" && renderNumberVariation(field)}
				{field.type === "money" && renderMoneyVariation(field)}
			</>
		);
	};

	const renderVariation = (field) => {
		return (
			<div
				key={field.label}
				style={{
					border: "1px solid #373A40",
					padding: "20px",
					marginBottom: "10px",
					borderRadius: "10px",
				}}
			>
				<Group mb={30}>
					<Title order={5} style={{ width: "100%" }}>
						{field?.label}
					</Title>
					{renderVariationByTypes(field)}

					<Divider my="sm" />
				</Group>
			</div>
		);
	};

	return (
		<>
			{/* Name: {name} <br></br> */}
			{/* <ReactJson
				style={{ background: "white" }}
				name="relatedFields"
				src={relatedFields}
				collapsed
			/>
			<ReactJson
				style={{ background: "white" }}
				name="form.values"
				src={form.values}
				collapsed
			/> */}
			{relatedFields?.map((f) => renderVariation(f))}
		</>
	);
};

export default CommissionVariation;
