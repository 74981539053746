import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button } from "@mantine/core";
import { IconExclamationMark, IconSettings } from "@tabler/icons";

import OrderReceiveCheckList from "./orderReceiveCheckList";
import OrderReceiveConfirm from "./orderReceiveConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { addFormSchema } from "../pages/operation/order";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import ReactJson from "react-json-view";

const OrderAddRiderActionButton = ({ mainForm }) => {
  const [basicPlanOrderId, setBasicPlanOrderId] = useState();
  const [api] = useServerApi();

  const formSchema = { ...addFormSchema };
  formSchema.title = "Add New Rider";

  const onSubmit = async ({ values, formMode }) => {
    console.log("onSubmit", values._id);

    try {
      if (!values) return;
      const data = await api.Order.addRider({
        riderValue: values,
        basicPlanOrderId,
      });

      formAction.close();
      const riders = _.get(mainForm.values, "riders");

      data.rider.product = data.rider.productSnapShot;
      riders.push(data.rider);
      mainForm.setFieldValue("riders", [...riders]);

      showNotification({
        title: ` Rider added`,
        message: "Rider added succesfully",
      });
    } catch (error) {
      console.log(error);

      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: ` Rider add error`,
        message: error.msg ?? error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    if (!mainForm || !mainForm.values) return;

    const id = mainForm?.values._id;
    if (!id) return;

    setBasicPlanOrderId(id);
    const riderValue = _.cloneDeep(mainForm.values);
    if (!riderValue) return;

    console.log("Add New Rider", riderValue);

    riderValue._id = null;
    riderValue.product = null;
    riderValue.productSnapShot = null;
    riderValue.payment = {};
    riderValue.orderRemarks = [];
    formAction.open({ mode: "edit", id, data: riderValue });
  };

  return (
    <>
      {/* <ReactJson
        src={mainForm.values?.insured}
        collapsed
        style={{ background: "white" }}
      /> */}
      <Button fullWidth onClick={() => handleActionClick()}>
        Add New Rider
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderAddRiderActionButton;
