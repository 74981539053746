import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useAuthUser } from "react-auth-kit";

const OrderPageStats = ({ cols = 5, role, ...props }) => {
  const [api] = useServerApi();

  const [stats, setStats] = useState([]);
  const auth = useAuthUser();

  let result = null;
  const fetchStats = async () => {
    try {
      switch (role) {
        case "OPERATION":
          result = await api.Order.getStatSummary();
          break;
        case "MANAGEMENT":
          result = await api.Order.getStatSummary();
          break;
        case "CONSULTANT":
          result = await api.Order.getStatSummaryByConsultant(auth().id);
          break;
      }
      if (!result) return;

      setStats([
        {
          icon: IconUsers,
          color: "#00b341",
          label: "Total",
          msg: result.stats.TOTAL ?? 0,
        },
        {
          icon: IconCirclePlus,
          color: "#0ED1EA",
          label: "DFT",
          msg: result.stats.DFT ?? 0,
        },
        {
          icon: IconCircleCheck,
          color: "#00b341",
          label: "RCV",
          msg: result.stats.RCV ?? 0,
        },
        {
          icon: IconCircleCheck,
          color: "#0ED1EA",
          label: "AIP",
          msg: result.stats.AIP ?? 0,
        },
        {
          icon: IconCircleMinus,
          color: "#FF9F43",
          label: "PND",
          msg: result.stats.PND ?? 0,
        },

        // {
        //   icon: IconNotes,
        //   color: "#8278F2",
        //   label: "NEW BIZ",
        //   msg: result.stats.PENDING ?? 0,
        // },
        // {
        //   icon: IconTableImport,
        //   color: "#FF9F43",
        //   label: "TRANSFER IN",
        //   msg: result.stats.INDIVIDUAL ?? 0,
        // },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [role]);

  return (
    <>
      <Accordion defaultValue="grids">
        <Accordion.Item value="grids">
          <Accordion.Control></Accordion.Control>
          <Accordion.Panel>
            <SimpleGrid
              cols={cols}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              {...props}
            >
              {stats?.map((stat, index) => (
                <CardStat key={index} stat={stat} />
              ))}
            </SimpleGrid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default OrderPageStats;
