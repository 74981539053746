import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconSettings } from "@tabler/icons";
import DateOffPicker from "./dateOffPicker";
import { useServerApi } from "../hooks/userServerApi";
import { DATA_POLICY_DELIVER_METHODS } from "../data/options";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";

const OrderDeliveryPolicyButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const formSchema = {
    title: "Deliver Policy",
    description: "Please follow the step",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,

    initialValues: {
      policyDeliverMethod: "Electronic",
    },

    layout: {
      containers: [
        {
          key: "section-confirm",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Confirm Policy",
            description:
              "Please enter the form to complete the policy confirmation",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "grid",
          parent: "section-confirm",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "g1",
              props: {
                span: 6,
                mt: 0,
              },
            },
            {
              key: "g2",
              props: {
                span: 6,
                mt: 0,
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: "policyDeliverMethod",
          component: Select,
          parent: "g1",
          props: {
            required: true,
            label: "Policy Deliver Method",
            data: DATA_POLICY_DELIVER_METHODS,
            defaultValue: "Electronic",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "policyDeliverDate",
          component: DatePicker,
          parent: "g1",
          type: "datePicker",
          props: {
            required: true,
            label: "Policy Deliver Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },

        {
          name: "policyDeliverRemark",
          component: Textarea,
          parent: "g2",
          props: {
            label: "Delivery Remark",
            minRows: 4,
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // console.log("onSubmit", values._id);
    mainForm.setValues(values);
    await mainForm.saveValues(values);

    formAction.close();

    showNotification({
      title: `Order ${values.code} policy deliver to client`,
      icon: <IconCheck size={18} />,
      message: "Data Saved",
    });
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    // console.log('%corderPrintCoverSheetActionButton.js line:86 object', 'color: #007acc;', id);
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        Deliver Policy
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderDeliveryPolicyButton;
