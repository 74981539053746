import _ from 'lodash'

export const useDateRender = () =>{

    const renderDate = ( value ) => {
        let d = _.isDate(value)? value: new Date(value)
        return d.toLocaleDateString( undefined, { year:'numeric', month: '2-digit', day: '2-digit'})
    }
        
    return [ renderDate ]
}