import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconExclamationCircle,
} from "@tabler/icons";
import _ from "lodash";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import DateOffPicker from "./dateOffPicker";
import { showNotification } from "@mantine/notifications";

const ClientAMLList = ({ clientIDField = "_id", form: mainForm, ...props }) => {
  const clientID = _.get(mainForm.values, clientIDField);

  const [rows, setRows] = useState([]);
  const [api] = useServerApi();

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "signDate",
        headerName: "Type",
        cellRender: (col, data) => "AML",
      },
      {
        field: "signDate",
        headerName: "Signed Date",
        cellRender: (col, data) => moment(_.get(data, col.field)).format("LL"),
      },
      {
        field: "expiryDate",
        headerName: "Expiry Date",
        cellRender: (col, data) => moment(_.get(data, col.field)).format("LL"),
      },
      {
        field: "expiryDate",
        headerName: "Valid",
        cellRender: (col, data) =>
          moment().diff(_.get(data, col.field)) > 0 ? (
            <IconAlertOctagon color={"red"} />
          ) : (
            <IconCircleCheck color={"green"} />
          ),
      },

      {
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            label: "Edit data",
            icon: IconPencil,
            props: {},
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {},
          },
        ],
        actionMenu: [],
      },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };

  const formSchema = {
    title: "AML",
    description: "please fill in the form",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    initialValues: {},

    layout: {
      containers: [
        {
          key: "basicInfo-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },
        {
          key: "file-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
          },
        },
      ],

      fields: [
        {
          name: "signDate",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",

          props: {
            required: true,
            label: "Sign Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },
        {
          name: "expiryDate",
          component: DatePicker,
          parent: "basicInfo-g2",
          type: "datePicker",
          props: {
            required: true,
            relatedField: "signDate",
            daysOff: 365,
            label: "Expiry Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "file-section",
          props: {},
        },
      ],
    },
  };

  const onFormSubmit = async (values) => {
    // console.log("onFormSubmit" , values)
    try {
      setRows(values); //Update UI
      _.set(mainForm.values, "aml", values); //Update MainForm to prevent further override
      await api.Client.updateRiskAML(clientID, values); //update server
      showNotification({
        title: `Client AML record updatd successfully`,
        message: "Update Success",
      });
    } catch (error) {
      console.log(error);
      showNotification({
        title: `Client AML Update fail`,
        icon: <IconExclamationCircle size={18} />,
        color: "red",
        message: error,
      });
    }
  };

  const initRowData = async () => {
    if (!clientID) return;
    let result = await api.Client.getRiskAML(clientID);

    if (!result) return;
    setRows(result.data?.aml ?? []);
  };

  useEffect(() => {
    initRowData();
  }, [clientID]);

  return (
    <>
      {/* {JSON.stringify(rows)} */}
      {/* {<ReactJson src={rows} name="rows" collapsed style={{background:'white'}}/>} */}
      {/* {<ReactJson src={mainForm.aml} name="AML" collapsed style={{ background: 'white' }} />} */}

      <LocalPageList
        rows={rows}
        onFormSubmit={onFormSubmit}
        tableSchema={tableSchema}
        formSchema={formSchema}
        {...props}
      />
    </>
  );
};

export default ClientAMLList;
