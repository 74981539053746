import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Checkbox, Text, List, Box } from "@mantine/core";
import ReactJson from "react-json-view";

const OrderReceiveConfirm = ({
	form: mainForm,
	successText = ["Thank yhou"],
	fontSize = "xs",
}) => {
	const [checkList, setCheckList] = useState([]);
	const product = _.get(mainForm.values, "product");
	const receivedList = _.get(mainForm.values, "receiveCheckList");

	const [missingDoc, setMissingDoc] = useState([]);

	useEffect(() => {
		let list = product?.checkList?.filter((item) => item.frontier);
		let missing = [];
		list?.forEach((item) => {
			if (receivedList.findIndex((received) => received === item._id) === -1) {
				missing.push(item);
			}
		});

		setMissingDoc(missing);
	}, [product?.checkList, receivedList]);

	return (
		<>
			{/* <ReactJson  src={mainForm.values.receiveCheckList} collapsed style={{background:"white"}}/>      */}
			{/* <ReactJson  src={checkList} collapsed style={{background:"white"}}/>      */}

			{missingDoc.length === 0 && (
				<List>
					{successText?.map((txt, index) => (
						<List.Item key={index}>
							<Text size={fontSize}>{txt}</Text>
						</List.Item>
					))}
				</List>
			)}

			{missingDoc.length > 0 && (
				<>
					<Text>There are {missingDoc.length} documents missing:</Text>

					<List withPadding>
						{missingDoc.map((doc, index) => (
							<List.Item key={index}> {doc?.name}</List.Item>
						))}
					</List>
				</>
			)}
		</>
	);
};

export default OrderReceiveConfirm;
