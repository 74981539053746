import React, { useEffect, useState } from "react";
import { Text, Tabs } from "@mantine/core";

import { Page } from "../layout/page";
import _ from "lodash";
import DashboardManagement from "./dashboardManagement";
import DashboardConsultant from "./dashboardConsultant";
import { useSignOut, useAuthUser } from "react-auth-kit";

const dashboardViews = [
  { id: "DM", label: "Management View", component: DashboardManagement },
  { id: "DC", label: "Consultant View", component: DashboardConsultant },
  { id: "DO", label: "Operation View", component: DashboardManagement },
];
function Dashboard({ numQuarters = 8 }) {
  const auth = useAuthUser();
  const { userRole } = auth();

  const availableDashboardViews = dashboardViews.filter((v) =>
    userRole?.dashBoards?.includes(v.id)
  );

  const NO_DASHBOARD_VIEW_MSG =
    "There are no dashboard views for the user role";

  return (
    <Page title={"Dashboard"}>
      {/* <ReactJson
				src={availableDashboardViews}
				style={{ background: "white" }}
			/> */}

      {_.isEmpty(availableDashboardViews) && (
        <Text> {NO_DASHBOARD_VIEW_MSG}</Text>
      )}
      {!_.isEmpty(availableDashboardViews) && (
        <Tabs defaultValue={availableDashboardViews[0].id}>
          <Tabs.List mb={"xl"}>
            {availableDashboardViews.map((v) => (
              <Tabs.Tab key={v.id} value={v.id}>
                {v.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {availableDashboardViews.map((v) => (
            <Tabs.Panel key={v.id} value={v.id}>
              <v.component />
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Page>
  );
}

export default Dashboard;
