import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Checkbox, Text, List, Box } from "@mantine/core";
import ReactJson from "react-json-view";

const OrderSubmissionConfirm = ({
  form: mainForm,
  internal = false,
  fontSize = "xs",
}) => {
  const [checkList, setCheckList] = useState([]);
  const [missingDoc, setMissingDoc] = useState([]);
  const product = _.get(mainForm.values, "product");
  const submitList = _.get(
    mainForm.values,
    internal ? "receiveCheckList" : "submitCheckList"
  );

  useEffect(() => {
    if (!product || !product.checkList) setCheckList([]);

    let missing = [];
    let list = product?.checkList?.filter((item) =>
      internal ? item.frontier : item.provider
    );

    list?.forEach((item) => {
      let index = submitList.findIndex(
        (l) => l === item || l === item._id || l === item.name
      );
      if (index === -1) {
        missing.push(item);
      }
    });

    setMissingDoc(missing);
  }, [product?.checkList, submitList]);

  return (
    <>
      {/* <ReactJson  src={mainForm.values.receiveCheckList} collapsed style={{background:"white"}}/>      */}
      {/* <ReactJson src={submitList} collapsed style={{ background: "white" }} /> */}

      {missingDoc.length === 0 && (
        <Text size={fontSize}>
          All documents are ready. Please confirm and print the cover sheet.
        </Text>
      )}

      {missingDoc.length > 0 && (
        <>
          <Text size={fontSize} mb="md">
            There are {missingDoc.length} document
            {missingDoc.length > 1 ? "s" : ""} missing:
          </Text>

          <List withPadding>
            {missingDoc.map((doc) => (
              <List.Item key={doc._id}>
                <Text size={fontSize}> {doc.name}</Text>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default OrderSubmissionConfirm;
