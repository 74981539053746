import React from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import { PageList } from "./pageList";

import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const UserOrderHistory = ({ form, id, ...props }) => {
  const userId = _.get(form?.values, "_id") ?? id;
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const location = useLocation();
  const auth = useAuthUser();

  const getEntityLink = () => {
    const { userRole } = auth();
    if (!userRole) return null;
    const roleLink = userRole.entityLinks?.find((l) => l.entity === "order");
    if (!roleLink || !roleLink.link) return null;
    return roleLink.link;
    // return `../${roleLink.link}?id=${i}`;
  };

  const orderFormSchema = {};

  const tableSchema = {
    preQuery: userId ? { consultants: userId } : null,
    searchableFields: [
      "code",
      "status",
      "applicant.name",
      "applicant.cname",
      "applicant.id",
      "cname",
      "email",
      "phone",
      "policyNumber",
    ],
    columns: [
      {
        field: "signedDate",
        filter: true,
        headerName: "Sign Date",
        cellRender: (col, order) => moment(order.signedDate).format("ll"),
      },
      {
        field: "code",
        filter: true,
        cellRender: (col, order) => (
          <a
            href={`${getEntityLink()}?id=${order._id}&mode=${"edit"}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {order.code}
          </a>
        ),
      },
      { field: "product.type.name", filter: true, headerName: "Type" },
      { field: "client.name", filter: true, headerName: "Client" },
      { field: "payment.payMode", filter: true, headerName: "Pay Mode" },
      {
        field: "payment.premium",
        filter: true,
        headerName: "Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.premium, order.payment?.currency),
      },

      {
        field: "payment.yearPremium",
        filter: true,
        headerName: "FY Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.yearPremium, "HKD"),
      },

      // { field: 'client',          filter: true },

      {
        field: "status",
        filter: true,
        cellRender: (col, order) => cellRender.Order.status(order.status),
      },
    ],
  };

  return (
    <>
      {/* <ReactJson src={form.values}  /> */}
      {/* {userId} */}

      <PageList
        title=""
        apiEntity="order"
        hideSearchBar={false}
        tableSchema={tableSchema}
        formSchema={orderFormSchema}
        hideActionButton={true}
        tablePadding={-10}
        initSort={{ by: "code", order: "desc" }}
        preQueryNotEmpty={true}
      />
    </>
  );
};

export default UserOrderHistory;
