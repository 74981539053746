import React from "react";
import {
    PageList,
    ENUM_FORM_DISPLAY,
    ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { useCellRender } from "../../hooks/useCellRender";
import { TextInput, Switch, Textarea, Select } from "@mantine/core";
import GroupWallet from "../../components/groupWallet";
import { DATA_CONSULTANT_PAYMENT_METHOD, DATA_BANK } from "../../data/options";
import Chips from "../../components/chips";

const apiEntity = "userGroup";

const formSchema = {
    title: "User Group",
    titleField: "name",

    display: {
        mode: ENUM_FORM_DISPLAY.INSIDE,
        size: "xl", //xs, sm, xl  ....100%
        // position:"right"
    },
    apiEntity,
    initialValues: {
        members: [],
        paymentMethod: {
            type: "",
            accNumber: "",
            name: "",
            bank: "",
        },
    },

    layout: {
        containers: [
            {
                key: "main",
                parent: null,
                type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
            },
            {
                key: "main-section",
                parent: "main",
                type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                props: {
                    title: "User Group Info",
                    titleOrder: 5,
                    description:
                        "Please update the basic info of the user group",
                },
            },

            {
                key: "main-g",
                parent: "main-section",
                type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
                cols: [
                    {
                        key: "main-g1",
                        props: {
                            span: 6,
                        },
                    },
                    {
                        key: "main-g2",
                        props: {
                            span: 6,
                        },
                    },
                ],
            },
            {
                key: "wallet-section",
                parent: "main",
                type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                props: {
                    title: "Group Wallet",
                    titleOrder: 5,
                    description: "Please update the wallet information",
                },
            },
            {
                key: "payment-section",
                parent: "main",
                type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
                props: {
                    title: "Payment Detail",
                    titleOrder: 5,
                    description:
                        "Please fill in payment method if direct payment to account instead of memebers account.",
                },
            },
        ],
        fields: [
            {
                name: "code",
                component: TextInput,
                parent: "main-g1",
                props: {
                    label: "Code",
                    required: true,
                },
            },

            {
                name: "name",
                component: TextInput,
                parent: "main-g1",
                props: {
                    label: "Name",
                    required: true,
                },
            },
            {
                name: "description",
                component: Textarea,
                parent: "main-g2",
                props: {
                    label: "Description ",
                    minRows: 8,
                },
            },

            {
                name: "paymentMethod.type",
                component: Chips,
                parent: "payment-section",
                props: {
                    required: true,
                    // label: "Type",
                    defaultValue: "Bank Transfer",
                    items: DATA_CONSULTANT_PAYMENT_METHOD,
                },
            },
            {
                name: "paymentMethod.bank",
                component: Select,
                parent: "payment-section",
                props: {
                    required: true,
                    label: "Bank",
                    data: DATA_BANK,
                    dropdownPosition: "bottom",
                },
            },
            {
                name: "paymentMethod.name",
                component: TextInput,
                parent: "payment-section",
                props: {
                    required: true,
                    label: "Name on Account",
                },
            },

            {
                name: "paymentMethod.accNumber",
                component: TextInput,
                parent: "payment-section",
                props: {
                    required: true,
                    label: "Account Number",
                },
            },

            //   {
            //     name: "wallet.id",
            //     component: TextInput,
            //     parent: "wallet-section",
            //     props: {
            //       label: "Wallet ID",
            //       required: true,
            //     },
            //   },
            {
                name: "wallet",
                component: GroupWallet,
                parent: "wallet-section",
                props: {},
            },
        ],
    },
};

const UserGroup = () => {
    const [cellRender] = useCellRender();

    const tableSchema = {
        showActionColumn: true,

        columns: [
            {
                field: "name",
                headerName: "Name",
                sortable: true,
            },
            { field: "description", headerName: "Description", sortable: true },

            //   {
            //     field: "members",
            //     sortable: true,
            //     headerName: "Members",
            //     cellRender: (col, data) => cellRender.Users(data.members?.user || []),
            //   },

            { field: "status", headerName: "Status", sortable: true },

            {
                field: "action",
                cellRender: ActionCellRender,
                isAction: true,
                actions: [
                    {
                        name: "edit",
                        icon: IconPencil,
                        props: {
                            //   color:"red"
                        },
                    },
                ],
                actionMenu: [
                    {
                        name: "delete",
                        label: "Delete data",
                        icon: IconTrash,
                        props: {
                            color: "red",
                        },
                    },
                ],
            },
        ],

        searchableFields: ["code", "name", "cname", "email", "phone"],
    };

    return (
        <>
            <PageList
                title={"User Group"}
                apiEntity={apiEntity}
                tableSchema={tableSchema}
                formSchema={formSchema}
                //   stats= {{
                //       component: OrderPageStats,
                //       props:{
                //           mb:35
                //       }
                //   }}
            />
        </>
    );
};

export default UserGroup;
