import moment from "moment";
import _ from "lodash";

export const roundDown = (value, decimals = 2) =>
  Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const percentage = (value) => `${value}%`;

// const currency = (value, c = "") =>
//     !Number.isNaN(parseFloat(value) || value === "-")
//         ? `${c} $ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//         : `${c} $ `;

const currency = (
  value,
  c = "HKD",
  locales = "zh-HK",
  currencyDisplay = "code"
) => {
  try {
    if (value === null || !c || Number.isNaN(parseFloat(value))) return "-";

    // if (!_.isNumber(value)) return value;

    if (_.isEmpty(c) || c == 0 || _.isUndefined(c) || c == "UNDEFINED")
      c = "HKD";

    return value?.toLocaleString(locales, {
      style: "currency",
      currency: c,
      currencyDisplay,
    });
  } catch (error) {
    console.log(c);
    console.log(error);
  }
};

const currencyFormat = (value) =>
  !Number.isNaN(parseFloat(value))
    ? `$ ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : value == "-"
    ? "$ -"
    : "$ ";

const currencyParser = (value) => value?.replace(/[^0-9\-.]/g, "");

const numberParser = (value) => value?.replace(/[^0-9\-.]/g, "");
const nameInitial = (user) => {
  try {
    if (!user) return "";
    const { name, aliasName } = user;

    return aliasName?.charAt(0) + name?.charAt(0);
  } catch (error) {
    console.log(error);
    return "";
  }
};

const friendlyName = (user) => {
  try {
    if (!user) return "";
    const { name, aliasName } = user;

    if (!aliasName) return name;

    const names = name.split(" ");
    if (_.isEmpty(names)) return aliasName;

    return _.startCase(_.toLower(`${aliasName}  ${names[0]}`));
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const useFormatter = () => {
  const formatter = {
    nameInitial,
    friendlyName,
    currency,
    currencyFormat,
    percentage,
    currencyParser,
    numberParser,
  };
  return [formatter];
};
