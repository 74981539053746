import React, { useEffect, useState, useRef } from "react";
import { Table, Text } from "@mantine/core";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";

import { useFormatter } from "../hooks/useFomatter";

const PolicyFundAllocationTable = ({ valuation }) => {
  const [formatter] = useFormatter();

  return (
    <FormSectionCard>
      <Table fontSize={"xs"}>
        <thead>
          <tr>
            <th>
              <Text size="xs">Fund</Text>
            </th>
            <th>
              <Text size="xs"> Allocation </Text>
            </th>
            <th>
              <Text size="xs">No.of Unit </Text>
            </th>
            <th>
              <Text size="xs">Unit price </Text>
            </th>
            <th>
              <Text size="xs">Fund Value </Text>
            </th>
            <th>
              <Text size="xs">Total Value </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {valuation?.allocations
            ?.sort((a, b) => b.allocationRatio - a.allocationRatio)
            .map((a) => (
              <tr>
                <td>
                  <Text lineClamp={1} size="xs">
                    {a.investmentFund}
                  </Text>
                  <Text color="dimmed">{a.fundCode}</Text>
                </td>
                <td> {a.allocationRatio}%</td>
                <td> {a.units?.toFixed(2)}</td>
                <td> {a.unitPrice}</td>
                <td>
                  {formatter.currency(a.valueInFundCurrency, a.fundCurrency)}
                </td>
                <td>
                  {formatter.currency(
                    a.valueInPolicyCurrency,
                    a.policyCurrency
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </FormSectionCard>
  );
};

export default PolicyFundAllocationTable;
