import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  useSignIn,
  useIsAuthenticated,
  useSignOut,
  useAuthUser,
} from "react-auth-kit";
import { useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SocketContext } from "../App";
import _ from "lodash";
import {
  Paper,
  ScrollArea,
  Text,
  Title,
  Box,
  UnstyledButton,
  createStyles,
  Group,
  Divider,
  TextInput,
  Input,
  Stack,
  ActionIcon,
  Button,
  Flex,
  Container,
  Card,
  Textarea,
  TypographyStylesProvider,
  Navbar,
  Center,
  Badge,
  ThemeIcon,
  Image,
} from "@mantine/core";
import { Avatar, AvatarGroup, Tooltip } from "@mantine/core";

import ChatInput2 from "./chatInput2";
import moment from "moment";
import {
  randomId,
  useTimeout,
  useClipboard,
  useForceUpdate,
} from "@mantine/hooks";
import { useServerApi } from "../hooks/userServerApi";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCopy, IconExternalLink, IconUpload } from "@tabler/icons";
import {
  getFileIcon,
  getFileIconColor,
  supportFiles,
  isImageFile,
} from "../components/fileList";
import { useMantineTheme } from "@mantine/core";

import { IconArrowDownCircle, IconDownload } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import InfoGridList from "./infoGridList";

const MessageWindowHeader = ({ ticket }) => {
  const [names, setNames] = useState("");
  const [entity, setEntity] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const auth = useAuthUser();
  const [api] = useServerApi();

  const setOrderFields = (content) => {
    const emptyStr = "";
    const fields = [
      { title: "Policy", value: content.policyNumber ?? emptyStr },
      { title: "Product", value: content.product.name ?? emptyStr },
      { title: "Client", value: content.client.name ?? emptyStr },
    ];
    setOrderData(fields);
  };

  const fetchEntity = async () => {
    try {
      if (!ticket || !ticket.entity || !ticket.entityId) return;
      const content = await api.getById({
        apiEntity: ticket.entity,
        id: ticket.entityId,
      });

      if (content) {
        setEntity(content);

        if (ticket.entity === "order") {
          setOrderFields(content);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!ticket) return;
    const ns = _.uniq([ticket?.by, ...ticket?.members].map((m) => m.name)).join(
      ", "
    );

    setNames(ns);
  }, [ticket]);

  useEffect(() => {
    fetchEntity();
  }, [ticket?.entityId]);

  const getEntityLink = () => {
    if (!ticket) return null;
    if (!ticket.entity || !ticket.entityId) return null;
    const { userRole } = auth();

    if (!userRole) return null;

    const roleLink = userRole.entityLinks?.find(
      (l) => l.entity === ticket.entity
    );
    if (!roleLink || !roleLink.link) return null;

    return `../${roleLink.link}?id=${ticket.entityId}`;
  };

  const entityLink = getEntityLink();
  return (
    <>
      {/* <ReactJson
				src={auth().userRole}
				style={{ background: "white" }}
				collapsed
			/> */}
      {ticket && (
        <Group position="apart" p={"md"} noWrap align="flex-start">
          <span>
            <Group spacing={"xs"} noWrap mb={2}>
              <Badge
                sx={{ paddingLeft: 0 }}
                size="md"
                radius={0}
                leftSection={
                  <Avatar
                    alt="Avatar for badge"
                    size={24}
                    src={
                      ticket?.by?.image
                        ? `${process.env.REACT_APP_FILE_URL}/${ticket?.by?.image}`
                        : null
                    }
                  />
                }
              >
                {ticket.code}
              </Badge>

              <Badge
                radius={0}
                size="md"
                color={ticket.status === "COMPLETED" ? "green" : "red"}
              >
                {ticket.status}
              </Badge>
            </Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
              <Avatar.Group spacing="sm" mt="sm" mb="sm">
                {ticket.members.map((m) => (
                  <Tooltip
                    label={`(${m.staffId}) ${m.name}, ${m.aliasName}`}
                    withArrow
                  >
                    <Avatar
                      src={`${process.env.REACT_APP_FILE_URL}/${m.image}`}
                      radius="xl"
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </Tooltip.Group>

            <Title order={4}> {ticket.subject}</Title>

            {/* <Text color={"dimmed"} size="sm">
              {names}
            </Text> */}
          </span>
          <Box miw={300}>
            <Title order={6}>{ticket.type?.name}</Title>

            <Group align={"right"}>
              <Title order={6}>{ticket.itemCode ? ticket.itemCode : ""}</Title>
              {entityLink && (
                <Link to={entityLink} target="_blank">
                  <ActionIcon size={"xs"}>
                    <IconExternalLink />
                  </ActionIcon>
                </Link>
              )}
            </Group>

            <Text color={"dimmed"} size="xs">
              {moment(ticket.createdAt).format("lll")}
            </Text>
            {entity && ticket.entity === "order" && (
              // <InfoGridList
              //   fields={orderData}
              //   values={entity}
              //   cols={1}
              //   spacing="xs"
              // />
              <div>
                <Text color={"dimmed"} size="xs">
                  Policy : {entity.policyNumber}
                </Text>
                {entity.product && (
                  <Text color={"dimmed"} size="xs">
                    Product : {entity.product?.name}
                  </Text>
                )}
                {entity.client && (
                  <Text color={"dimmed"} size="xs">
                    Client : {entity.client?.name + " " + entity.client.cname}
                  </Text>
                )}
              </div>
            )}
          </Box>
        </Group>
      )}

      {!ticket && (
        <Group position="apart" p={"xs"} h={125} noWrap>
          <span>
            <Text>No Ticket Select </Text>
            <Text color={"dimmed"} size="xs">
              Please select a ticket to view the messages
            </Text>
          </span>
        </Group>
      )}
    </>
  );
};

const FilePreviewer = ({ file }) => {
  return (
    <>
      {isImageFile(file.mimetype) && <Image src={file.url} withPlaceholder />}
    </>
  );
};

const ChatFileContent = ({ file }) => {
  const headers = {
    "X-Access-Token": "1234567890",
    "My-Custom-Header": "my-custom-value",
  };
  return (
    <>
      <UnstyledButton onClick={() => window.open(file.url, "_blank")} my="xs">
        <Group noWrap mb={"sm"}>
          <FontAwesomeIcon
            icon={getFileIcon(file.mimetype)}
            color={getFileIconColor(file.mimetype)}
            size="xs"
          ></FontAwesomeIcon>
          <Text size="xs" lineClamp={1}>
            {file.name}
          </Text>
          <IconArrowDownCircle size={25} />
        </Group>
        <FilePreviewer file={file} />
      </UnstyledButton>
    </>
  );
};

const ChatMessageCard = ({ message, lastMessage }) => {
  const { at, author, text, file, isMe } = message;
  const clipboard = useClipboard({ timeout: 500 });
  const auth = useAuthUser();

  const showMsgDate = (at) => {
    if (!lastMessage) return true;
    return !moment(at).isSame(lastMessage?.at, "day");
  };

  const getNameColor = (name) => {
    return "#007acc";
  };

  const handleCopy = (text) => {
    clipboard.copy(text);
    showNotification({
      title: `Message Copied`,
      // color: "teal",
      // icon: <IconCopy size={18} />,
    });
  };

  return (
    <span>
      {showMsgDate(at) && (
        <Center mt="xl">
          <Text color="dimmed" size="xs">
            {moment(at).format("LL")}
          </Text>
        </Center>
      )}

      <Group m={"xl"} position={isMe ? "right" : "left"} align="flex-start">
        <div>
          <Group align="flex-start">
            {!isMe && (
              <Avatar
                src={`${process.env.REACT_APP_FILE_URL}/${author.image}`}
                alt={author.name}
                radius="xl"
              />
            )}
            <span>
              <Group position="apart" pr={"xs"}>
                {!isMe && (
                  <Text fz="xs" color={getNameColor(author.name)}>
                    {author.name}
                  </Text>
                )}
                {isMe && <span></span>}

                <Text fz="xs" color="dimmed">
                  {moment(at).format("HH:mm")}
                </Text>
              </Group>

              {file && (
                <Card p="xs" maw={300} miw={50} radius="md" mt="xs">
                  {file && <ChatFileContent file={file} />}
                </Card>
              )}
              {text && (
                <>
                  <Card p="xs" miw={50} radius="md" mt="xs">
                    <Text size="sm" sx={{ whiteSpace: "pre-wrap" }} my="xs">
                      {text}
                    </Text>
                  </Card>
                </>
              )}
            </span>
          </Group>
        </div>
      </Group>
    </span>
  );
};

const MessageWindow = ({ height, updateTicket }) => {
  // const { ticketId } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticketId = params.get("id");

  const socket = useContext(SocketContext);
  const auth = useAuthUser();
  const [api] = useServerApi();
  const theme = useMantineTheme();
  const CHAT_PAGE_SIZE = 100;

  const [messages, setMessages] = useState([]);
  const [socketMessage, setSocketMessage] = useState();
  const chatInputRef = useRef();

  const viewport = useRef(null);
  const forceUpdate = useForceUpdate();

  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  // const [currentPage, setCurrentPage] = useState(1);

  const handleScrollAtTop = () => {
    // console.log(scrollPosition.y, viewport.current.scrollHeight);
    loadTicketMessages(false);
  };

  const [ticket, setTicket] = useState();

  const scrollToBottom = (args) => {
    const to = {
      top: viewport?.current?.scrollHeight ?? 100,
      // behavior: "",
    };
    if (args[0]) to.behavior = "smooth";
    viewport.current?.scrollTo(to);
  };

  const { start: startScrollToBottom, clear } = useTimeout(scrollToBottom, 100);

  const handleSocketUpdateTicket = (data, ticket) => {
    updateTicket(data);
    if (ticket && data._id === ticket._id) {
      setTicket(data);
    }
  };
  const msgSort = (a, b) => a.at.localeCompare(b.at);

  const loadTicketMessages = async (reload = true) => {
    if (!ticketId) return;

    const searchQuery = { ticket: ticketId };
    if (!reload && messages.length > 0)
      searchQuery.at = { $lt: messages[0].at };

    let result = await api.search({
      apiEntity: "message",
      searchQuery,
      pageSize: CHAT_PAGE_SIZE,
      sort: { by: "at", order: "desc" },
    });

    if (_.isEmpty(result.docs)) return;

    const newMessages = result.docs?.filter((m) => m.ticket === ticketId);
    newMessages.forEach((msg) => (msg.isMe = msg.author?.id === auth().id));

    if (reload) {
      setMessages(newMessages.sort(msgSort));
      startScrollToBottom();
    } else setMessages([...messages, ...newMessages].sort(msgSort));
  };

  //Add Message from myself
  const addMyMessage = (data) => {
    try {
      if (data.ticket !== ticketId) return;
      data.isMe = true;
      setMessages([...messages, data].sort(msgSort));
      startScrollToBottom(true);
    } catch (error) {
      console.log(error);
    }
  };

  //
  const updateStatus = (status) => {
    ticket.status = status;
    forceUpdate();
  };

  //
  const updateMembers = (members) => {
    ticket.members = members;
    forceUpdate();
  };

  const addSocketMesage = (data, ticketId) => {
    try {
      if (!data) return;
      if (data.ticket !== ticketId) return;

      data.isMe = data.author.id === auth().id;

      if (data.isMe) {
        //todo: Blue tick
      } else {
        setSocketMessage(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTicket = async () => {
    try {
      let data = await api.getById({
        apiEntity: "ticket",
        id: ticketId,
      });
      await setTicket(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMessages([]);
    fetchTicket();
    loadTicketMessages();

    socket.on("messageReceive", (data) => addSocketMesage(data, ticketId));
    socket.on("updateTicketReceive", (data) =>
      handleSocketUpdateTicket(data, ticket)
    );
    return () => {
      socket.off("messageReceive");
      socket.off("updateTicketReceive");
    };
  }, [ticketId]);

  useEffect(() => {
    setMessages([...messages, socketMessage].sort(msgSort));
    startScrollToBottom(true);
  }, [socketMessage]);

  useEffect(() => {
    if (scrollPosition.y === 0) handleScrollAtTop();
  }, [scrollPosition.y]);

  const handleUploadFiles = (files) => {
    console.log("handleUploadFiles", files);
    chatInputRef.current.handleUploadFiles(files);
  };
  return (
    <>
      <Paper>
        {/* ticketId: {ticketId} */}
        <MessageWindowHeader ticket={ticket} />
        <Divider />
        <ScrollArea
          style={{ height }}
          // type="always"
          offsetScrollbars
          scrollbarSize={5}
          onScrollPositionChange={onScrollPositionChange}
          viewportRef={viewport}
        >
          {messages.map(
            (message, index) =>
              message && (
                <ChatMessageCard
                  key={index}
                  message={message}
                  lastMessage={index === 0 ? null : messages[index - 1]}
                />
              )
          )}
        </ScrollArea>

        <ChatInput2
          ref={chatInputRef}
          addMessage={addMyMessage}
          updateStatus={updateStatus}
          updateMembers={updateMembers}
          disabled={!ticket}
          ticket={ticket}
        />
      </Paper>
    </>
  );
};

export default MessageWindow;
