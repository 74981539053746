import React from "react";
import {
  Text,
  TextField,
  Group,
  TextInput,
  Checkbox,
  Divider,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";

const LoginInfoRow = ({ item, form, name }) => {
  const forceUpdate = useForceUpdate();

  return (
    <div>
      <Group grow>
        <TextInput
          placeholder="e.g. AIA Login Page"
          label="Label"
          value={item.content["label"]}
          maw={150}
          onChange={(e) => {
            item.content["label"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder="http://www.aia.com.hk"
          label="Url Address"
          value={item.content["url"]}
          miw={400}
          onChange={(e) => {
            item.content["url"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder="Username"
          label="Login Name / Email"
          value={item.content["username"]}
          onChange={(e) => {
            item.content["username"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder="Password"
          label="Password"
          value={item.content["password"]}
          onChange={(e) => {
            item.content["password"] = e.target.value;
            forceUpdate();
          }}
        />
      </Group>
      <Group grow mt={"sm"}>
        <TextInput
          placeholder="OTP Phone"
          label="OTP Phone"
          value={item.content["otpPhone"]}
          onChange={(e) => {
            item.content["otpPhone"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder="OTP Email"
          label="OTP Email"
          value={item.content["otpEmail"]}
          onChange={(e) => {
            item.content["otpEmail"] = e.target.value;
            forceUpdate();
          }}
        />
        <Checkbox
          label="Is Public"
          checked={item.content["isPublic"]}
          onChange={(e) => {
            item.content["isPublic"] = e.target.checked;
            forceUpdate();
          }}
        />
      </Group>
      <Divider mt="xl" />
    </div>
  );
};

export default LoginInfoRow;
