import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  ScrollArea,
  MediaQuery,
  Burger,
  useMantineTheme,
  Group,
  TextInput,
  Box,
} from "@mantine/core";

import { MantineProvider, ColorSchemeProvider } from "@mantine/core";

import FrontierHeader from "../components/header";
import FNavBar from "../components/navBar";
import SearchBox from "../components/searchBox";
import { useSignOut } from "react-auth-kit";
import { IconSearch } from "@tabler/icons";

export default function Layout() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  // const navigate = useNavigate();
  // const signOut = useSignOut();

  const FHeader = () => {
    return (
      <MediaQuery largerThan="sm">
        <Header height={70} p="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />

            <FrontierHeader />
          </div>
        </Header>
      </MediaQuery>
    );
  };

  return (
    <AppShell
      // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
      navbarOffsetBreakpoint="sm"
      // fixed prop on AppShell will be automatically added to Header and Navbar
      // fixed
      layout="alt"
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      header={
        <Group position="right">
          <MediaQuery largerThan="lg" styles={{ display: "none" }}>
            <Group align="center">
              <SearchBox />
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                m={"sm"}
              />
            </Group>
          </MediaQuery>
        </Group>
      }
      navbar={
        <Navbar
          padding="md"
          // Breakpoint at which navbar will be hidden if hidden prop is true
          hiddenBreakpoint="lg"
          // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
          hidden={!opened}
          // when viewport size is less than theme.breakpoints.sm navbar width is 100%
          // viewport size > theme.breakpoints.sm – width is 300px
          // viewport size > theme.breakpoints.lg – width is 400px
          width={{ sm: 0, lg: 300 }}
          zIndex={100}
        >
          <FNavBar
            hidden={!opened}
            hiddenBreakpoint={1500}
            width={{ sm: opened ? 300 : 0, lg: 300 }}
            setOpened={setOpened}
          />
        </Navbar>
      }
    >
      <>
        <Box mb={30}></Box>
        <Outlet />
      </>
    </AppShell>
  );
}
