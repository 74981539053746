import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import LocalPageList from "./localPageList";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings, IconTrash, IconPencil } from "@tabler/icons";
import RemoteSelect2 from "../components/remoteSelect2";
import { DatePicker } from "@mantine/dates";
import AllowanceDetails from "../components/allowanceDetails";
import { PageList } from "./pageList";
import { useFormatter } from "../hooks/useFomatter";
import moment from "moment";
import ActionCellRender from "./actionCellRender";
import { Button } from "@mantine/core";
import ReactJson from "react-json-view";
import FormConfirmation from "./formConfirmation";

const ConsultantAllowanceList = ({ form: mainForm, name, ...props }) => {
  const userId = _.get(mainForm.values, "_id");
  const apiEntity = "allowance";
  const [rows, setRows] = useState([]);
  const [api] = useServerApi();
  const [formatter] = useFormatter();

  const handlePlanChange = (plan, allowanceForm) => {
    allowanceForm.setFieldValue("plan", plan);
  };

  const tableSchema = {
    hidePagination: true,
    preQuery: { user: userId },
    showActionColumn: true,

    columns: [
      { field: "plan.name", headerName: "Plan" },
      {
        field: "startDate",
        headerName: "Start Date",
        cellRender: (col, data) => moment(data.startDate).format("yyyy-MM-DD"),
      },
      {
        field: "terms",
        headerName: "Pay Terms",
        cellRender: (col, data) => data.terms?.length ?? 0,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        cellRender: (col, data) =>
          formatter.currency(data.terms?.reduce((a, c) => a + c.amount, 0)),
      },

      {
        field: "totalPaid",
        headerName: "Total Paid",
        cellRender: (col, data) => formatter.currency(data.totalPaid),
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
  const formSchema = {
    title: "Allowance",
    description: "please add allowance to the user by follow the instruction",
    apiEntity,
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    showSaveBar: false,
    closeAfterSave: true,
    initialValues: {
      user: userId,
      plan: {},
      totalPaid: 0,
      settledCount: 0,
      terms: [],
      bids: [],
      logs: [],
      status: "RUNNING",
    },

    layout: {
      containers: [
        {
          key: "basicInfo-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Allowance Plan",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            {
              key: "t1-1",
              label: "Allowance Plan",
              icon: IconSettings,
            },
            { key: "t1-2", label: "Details", icon: IconSettings },
            { key: "t1-3", label: "Confirm", icon: IconSettings },
          ],
        },
        {
          key: "section-plan",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Plan Selection",
            description: "Please select allowance plan",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-details",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Details",
            description: "Please fill in the details of plan",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-confirm",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm",
            description: "Please fill in the details of plan",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],
      fields: [
        {
          name: "plan._id",
          component: RemoteSelect2,
          parent: "section-plan",
          props: {
            label: "Allowance Plan",
            apiEntity: "allowancePlan",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            onDataChange: handlePlanChange,
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "startDate",
          component: DatePicker,
          type: "datePicker",

          parent: "section-details",
          props: {
            allowFreeInput: true,
            label: "Start Date",
            placeholder: "Please enter the allowance initial start date",
          },
        },

        {
          name: "allowanceDetail",
          component: AllowanceDetails,
          parent: "section-details",
          props: {},
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: ["Please confirm if above information is correct."],
          },
        },
      ],
    },
  };

  const initRowData = async () => {
    if (!userId) return;
    let result = await api.search({
      apiEntity: "allowance",
      sort: { by: "createdAt", order: "desc" },
      searchQuery: {
        // user: userId,
      },
    });
    if (!result || !result.docs) return setRows([]);
    setRows(result.docs);
    // console.log(result.docs);
  };

  useEffect(() => {
    initRowData();
  }, [userId]);

  const onFormSubmit = async ({ values, formMode }) => {
    // console.log("onFormSubmit", values);

    if (formMode === "add") {
      values.user = userId;
      let result = await api.add({ apiEntity, values });
      console.log("result", result);
    }
  };

  // const autoFulfil = async () => {
  //     const result = await api.Payroll.checkAllowanceAutoFullfill();
  //     console.log(result);
  // };
  return (
    <>
      {/* <ReactJson
        src={mainForm.values}
        name="form"
        collapsed
        style={{ background: "white" }}
      /> */}
      {/* <Button onClick={() => autoFulfil()}>Auto Fulfill</Button> */}
      <PageList
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        hideSearchBar={true}
        onSubmit={onFormSubmit}
        // {...props}
      />
    </>
  );
};

export default ConsultantAllowanceList;
