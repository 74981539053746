import { SocketContext } from "../App";
import React, {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Grid,
  Paper,
  Group,
  ActionIcon,
  Textarea,
  FileButton,
  Text,
} from "@mantine/core";

import {
  IconDotsVertical,
  IconMoodHappy,
  IconPaperclip,
  IconUserPlus,
  IconExclamationMark,
  IconTicket,
  IconCircleCheck,
  IconRepeat,
} from "@tabler/icons";

import moment from "moment";

import _ from "lodash";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const ChatUpdateMemberButton = ({ disabled, ticket, onUpdateMember }) => {
  const formSchema = {
    title: "Select Members",
    description: "Select members who involved in the ticket",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,
    initialValues: ticket,
    layout: {
      containers: [
        {
          key: "main",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            variant: "pills", //default | pills | outline
            defaultValue: "Details",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
        },
      ],
      fields: [
        {
          name: "members",
          component: RemoteSelect2,
          parent: "main",
          props: {
            label: "Join staff(s) to the ticket",
            apiEntity: "user",
            required: true,
            placeholder: "Please select staff(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) => `${data.name} (${data.staffId ?? " -- "})`,
            multiple: true,
            searchFields: ["name", "staffId", "cname", "email"],
            sort: {
              by: "name",
              order: "desc",
            },
          },
        },
      ],
    },
  };

  const onSubmit = ({ values }) => {
    onUpdateMember(values.members);
  };

  const onChange = () => {};
  const onSubmitSuccess = () => console.log("onSubmitSuccess");

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit,
    onChange
  );

  return (
    <>
      <ActionIcon
        disabled={disabled}
        onClick={() => formAction.open({ mode: "edit", data: ticket })}
      >
        <IconUserPlus />
      </ActionIcon>
      {renderForm()}
    </>
  );
};

const ChatInput = forwardRef(
  ({ addMessage, updateStatus, updateMembers, disabled, ticket }, ref) => {
    const [text, setText] = useState("");
    const socket = useContext(SocketContext);
    const auth = useAuthUser();
    const resetRef = useRef(null);
    const [api] = useServerApi();
    const forceUpdate = useForceUpdate();

    const updateTicketMembers = async (members) => {
      try {
        if (!ticket || !ticket._id) return;
        const result = await api.Ticket.updateTicketMembers(
          ticket._id,
          members
        );
        console.log(result);
        if (!result || !result.data) return;
        const { ticket: updated } = result.data;
        if (updateMembers) updateMembers(updated.members);
      } catch (error) {
        console.log(error);
      }
    };

    const sendMessage = async ({ text, file }) => {
      try {
        if (!ticket) return;

        const data = {
          id: `${ticket._id}${Math.random()}`,
          socketID: socket.id,
          text,
          file,
          author: auth(),
          at: moment().toISOString(),
          ticket: ticket._id,
          by: auth().id,
        };
        await api.Ticket.addMessage(ticket._id, data);
        data.isMe = true;
        addMessage(data);
      } catch (error) {
        console.log(error);
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        console.log(text);
        // sendSocketMessage({ text });
        sendMessage({ text });
        setText("");
      }
    };

    const resetFileButton = () => resetRef.current?.();

    useImperativeHandle(ref, () => ({
      async handleUploadFiles(uploadFiles) {
        console.log("handleUploadFiles", uploadFiles);

        try {
          for (const file of uploadFiles) {
            if (!file) continue;
            let result = await api.uploadFile(ticket._id, file);
            if (!result.success) throw new Error(result.error);
            sendMessage({ file: result.data });
          }
          resetFileButton();
          // form.setFieldValue(name, [...files , ...newFiles] )
        } catch (error) {
          showNotification({
            title: `Upload unsuccessful`,
            color: "red",
            icon: <IconExclamationMark size={18} />,
            message: error.msg,
          });
        }
      },
    }));

    const handleUploadFiles = async (uploadFiles) => {
      console.log("handleUploadFiles", uploadFiles);

      try {
        for (const file of uploadFiles) {
          if (!file) continue;
          let result = await api.uploadFile(ticket._id, file);
          if (!result.success) throw new Error(result.error);
          sendMessage({ file: result.data });
        }
        resetFileButton();
        // form.setFieldValue(name, [...files , ...newFiles] )
      } catch (error) {
        showNotification({
          title: `Upload unsuccessful`,
          color: "red",
          icon: <IconExclamationMark size={18} />,
          message: error.msg,
        });
      }
    };
    const updateTicketStatus = async (status) => {
      try {
        if (!ticket) return;
        ticket.status = status;
        const result = await api.Ticket.updateTicketStatus(ticket._id, status);
        // socket.emit("updateStatus", { ticketId: ticket._id, status });
        forceUpdate();
        if (updateStatus) updateStatus(status);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <Paper p={"xs"}>
        {/* ticket: {ticket?._id} / {ticket?.status} */}
        <Grid>
          <Grid.Col span={2}>
            <Group spacing="xs" style={{ height: "100%" }}>
              <ActionIcon disabled={disabled}>
                {ticket?.status === "COMPLETED" ? (
                  <IconRepeat
                    onClick={() => updateTicketStatus("INPROGRESS")}
                  />
                ) : (
                  <IconCircleCheck
                    onClick={() => updateTicketStatus("COMPLETED")}
                  />
                )}
              </ActionIcon>

              <FileButton
                resetRef={resetRef}
                onChange={handleUploadFiles}
                multiple
              >
                {(props) => (
                  <ActionIcon {...props} disabled={disabled}>
                    <IconPaperclip />
                  </ActionIcon>
                )}
              </FileButton>

              <ChatUpdateMemberButton
                disabled={disabled}
                ticket={ticket}
                onUpdateMember={updateTicketMembers}
              />

              {/* <ActionIcon disabled={disabled}>
                            <IconDotsVertical />
                        </ActionIcon> */}
            </Group>
          </Grid.Col>
          <Grid.Col span={10}>
            <Textarea
              placeholder="Enter your message"
              autosize
              minRows={1}
              maxRows={1}
              onKeyPress={handleKeyPress}
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={disabled}
            />
          </Grid.Col>
        </Grid>
      </Paper>
    );
  }
);

export default ChatInput;
