import React from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import { PageList, ENUM_FORM_DISPLAY } from "./pageList";
import { useCellRender } from "../hooks/useCellRender";
import { Group, Text } from "@mantine/core";

import moment from "moment";
import { IconLink } from "@tabler/icons";
const FollowupActionTable = ({ form }) => {
  const id = _.get(form.values, "_id");
  const [api] = useServerApi();
  const [cellRender] = useCellRender();

  const tableSchema = {
    preQuery: { entityId: id },
    searchableFields: ["code", "title"],

    columns: [
      {
        field: "status",
        headerName: "Status",
        cellRender: (col, data) => cellRender.Status(data.status),
        sortable: true,
      },

      {
        field: "code",
        headerName: "Ticket",
        cellRender: (col, data) => (
          <a href={`/chat`} target="_blank" style={{ textDecoration: "none" }}>
            <div>
              <Text lineClamp={3}> {data.subject}</Text>

              <Group>
                <Text color={"dimmed"}> {data.code}</Text>
                <IconLink size="15" />
              </Group>

              <Text color={"dimmed"}>
                {moment(data.createdAt).format("LLL")}
              </Text>
            </div>
          </a>
        ),
      },
      {
        field: "by",
        headerName: "By",
        cellRender: (col, data) => cellRender.User(data.by),
      },
    ],
  };

  const formSchema = {
    display: {},
  };

  return (
    <>
      {id && (
        <PageList
          apiEntity="ticket"
          initSort={{ by: "createdAt", order: "desc" }}
          tableSchema={tableSchema}
          formSchema={formSchema}
          hideActionButton={true}
          hideSearchBar={true}
        />
      )}
    </>
  );
};

export default FollowupActionTable;
