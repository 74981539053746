import _ from "lodash";
import { Text, Title, Divider, Group, ThemeIcon, Badge } from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import { IconClick, IconCash, IconHeart } from "@tabler/icons";

export default function ClientBasicInfo({ form }) {
  const [cellRender] = useCellRender();
  const client = form.values;
  const dataFontWeight = 600;

  const emptyStr = "-";

  const fields = [
    { title: "Name", value: client.name ?? emptyStr },
    { title: "Chinese Name", value: client.cname ?? emptyStr },
    { title: "Client Type", value: client.type ?? emptyStr },
    { title: "Gender", value: client.gender ?? emptyStr },
    { title: "ID", value: client.id ?? emptyStr },

    { title: "Contact Number", value: client.phone ?? emptyStr },
    { title: "Email", value: client.email ?? emptyStr },

    {
      title: "Date of Birth",
      value: cellRender.Date(client.birthday, "LL") ?? emptyStr,
    },
    { title: "Place of Birth", value: client.placeOfBirth ?? emptyStr },
    { title: "Nationality", value: client.nationality ?? emptyStr },

    { title: "Marital Status", value: client.maritalStatus ?? emptyStr },
    { title: "Education", value: client.educationLevel ?? emptyStr },
    { title: "Year Salary", value: client.yearlySalary ?? emptyStr },

    { title: "Status", value: client.status ?? emptyStr },
    { title: "Code", value: client.code ?? emptyStr },

    {
      title: "Created At",
      value: cellRender.Date(client.createdAt, "LL", dataFontWeight),
    },
  ];
  return <InfoGridList fields={fields} values={client} />;
}
