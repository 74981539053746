import React, { useState } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Textarea } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderReceiveCheckList from "./orderReceiveCheckList";
import OrderReceiveConfirm from "./orderReceiveConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import RemoteSelect2 from "../components/remoteSelect2";

import ServiceCheckList from "../components/serviceCheckList";
import FormConfirmation from "../components/formConfirmation";
import moment from "moment";
import { DatePicker } from "@mantine/dates";

const OrderServicePrintSubmitCoverSheetButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const printSubmitCoverSheetFormSchema = {
    name: "printSubmitCoverSheetFormSchema",
    title: "Print Order Service Cover Sheet",
    description: "Please follow the step to create a New Order Service",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "order",
    initialValues: {},

    validate: {},
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Service Info",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Service Info", icon: IconSettings },
            { key: "t1-2", label: "CheckList", icon: IconSettings },
            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-info",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Information",
            description: "Pleaes select the service type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-checkList",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "CheckList",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "serviceType",
          component: RemoteSelect2,
          parent: "section-info",
          props: {
            label: "Type",
            apiEntity: "orderServiceType",
            required: true,
            placeholder: "Search Type",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              //Set checkList
              form.setFieldValue(
                "list",
                value?.checkList?.map((c) => ({ item: c, checked: false }))
              );
              form.setFieldValue("serviceTypeSnapShot", value);
              return;
            },
          },
        },
        {
          name: "receieveDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Receive Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "submissionDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Submission Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "description",
          parent: "section-info",
          component: Textarea,
          props: {
            label: "Description",
          },
        },
        {
          name: "checkList",
          parent: "section-checkList",
          component: ServiceCheckList,
          props: {},
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please click confirm to create the service.",
              "Please goto Service tab and print out the service cover sheet.",
            ],
          },
        },
      ],
    },
  };

  const onPrintSubmissionCoverSheetSubmit = async ({ values, formMode }) => {
    try {
      // console.log(values);
      formAction.close();
      values.internal = false;
      setPrinting(true);
      const result = await api.OrderService.printSubmitCoverSheet(values);
      setPrinting(false);

      if (result.service) {
        mainForm.setValues({
          ...result.service,
        });
      }

      if (result.url) {
        window.open(result.url, "_blank");
      }
    } catch (error) {
      setPrinting(false);
    }
  };

  const printServiceCoverSheet = async (service, internal = true) => {
    // console.log("printServiceCoverSheet", service, internal);
    service.internal = internal;
    formAction.open({
      mode: "edit",
      id: mainForm.values._id,
      data: service,
    });
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    printSubmitCoverSheetFormSchema,
    null,
    onPrintSubmissionCoverSheetSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;

    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {mainForm?.values?.product?._id} */}
      <Button fullWidth onClick={handleActionClick} loading={printing}>
        Print Submission Cover Sheet
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderServicePrintSubmitCoverSheetButton;
