import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/userServerApi";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  Group,
  Chip,
  Grid,
  Select,
  ActionIcon,
  Button,
  SimpleGrid,
  ScrollArea,
} from "@mantine/core";
import CardStat from "./cardStat";
import {
  IconAlertTriangle,
  IconArrowLeft,
  IconArrowLeftBar,
  IconArrowLeftCircle,
  IconCaretLeft,
  IconCaretRight,
  IconChevronUp,
  IconCircleCaretUp,
  IconCircleCheck,
  IconCoinMonero,
  IconTriangle,
} from "@tabler/icons";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import PieChart from "./chartWidget/PieChart";
import { pieData } from "../data/sampleChartData";
import { useFormatter } from "../hooks/useFomatter";
import _ from "lodash";
import { PeriodFilter } from "./periodFilter";
import OrderStatsTable from "./orderStatsTable";

const OrderStatics = ({
  userId,
  numQuarters = 8,
  height = "100vh",
  InScrollArea = true,
  remark = null,
}) => {
  const [stats, setStats] = useState(null);
  const [orderStats, setOrderStats] = useState();

  const [period, setPeriod] = useState(null);
  const [productTypePieData, setproductTypePieData] = useState([]);
  const [productProviderPieData, setproductProviderPieData] = useState([]);
  const [orderSummaryData, setorderSummaryData] = useState([]);
  const [formatter] = useFormatter();
  const [api] = useServerApi();

  const fetchStats = async () => {
    if (!userId || !period) return;
    const data = await api.Order.getStats(period, userId);
    // console.log("Fetch STats", data);

    setOrderStats(data.orderSummary ?? []);
    setOrderProductStats(data);
  };

  const resetStatData = () => {
    // console.log("resetStatData");
    setproductTypePieData([]);
    setproductProviderPieData([]);
  };

  const setOrderSummaryStats = (data) => {
    try {
      //   console.log("setOrderSummaryStats", data);
      if (!data || _.isEmpty(data.orderSummary)) return setorderSummaryData([]);

      setorderSummaryData(
        data.orderSummary
          ? [
              {
                icon: IconCircleCaretUp,
                color: "#8278F2",
                label: "Submit Orders",
                msg:
                  data?.orderSummary?.find((d) => d._id === "ALL")?.count ?? 0,
              },
              {
                icon: IconCoinMonero,
                color: "#8278F2",
                label: "Submit FY Premium",
                msg: formatter.currency(
                  data?.orderSummary?.find((d) => d._id === "ALL")
                    ?.yearPremium ?? 0
                ),
              },
              {
                icon: IconCircleCaretUp,
                color: "#0ED1EA",
                label: "Inforced Orders",
                msg:
                  data?.orderSummary?.find((d) => d._id === "INF")?.count ?? 0,
              },
              {
                icon: IconCoinMonero,
                color: "#0ED1EA",
                label: "Inforced FY Premium",
                msg: formatter.currency(
                  data?.orderSummary?.find((d) => d._id === "INF")
                    ?.yearPremium ?? 0
                ),
              },
            ]
          : []
      );
    } catch (error) {
      console.log(error);
    }
  };

  const setOrderProductStats = (data) => {
    try {
      console.log("setOrderProductStats", data);
      if (!data || _.isEmpty(data.orderProductStats)) return resetStatData();

      _.isEmpty(data.orderProductStats.productType)
        ? setproductTypePieData([])
        : setproductTypePieData(
            data.orderProductStats.productType.map((c) => ({
              id: c.name,
              value: c.count,
            }))
          );

      setproductProviderPieData(
        data.orderProductStats.productProvider?.map((c) => ({
          id: c.shortName,
          value: c.count,
        })) ?? []
      );
    } catch (error) {
      console.log(error);
    }
  };

  useDeepCompareEffect(() => {
    fetchStats();
  }, [userId, period]);

  const MainContent = ({}) => {
    return (
      <>
        {/* <ReactJson src={stats} style={{ background: "white" }}></ReactJson> */}
        {/* <ReactJson
							src={productTypePieData}
							style={{ background: "white" }}
							collapsed
						></ReactJson> */}
        <OrderStatsTable
          data={orderStats}
          mt="50px"
          highlightOnHover
          withTableBorder
          showIndividualConsultant={true}
          remark={remark}
        />

        <Grid mt="xl">
          <Grid.Col span={6}>
            <PieChart
              title={"Product Types"}
              staticData={productTypePieData}
              height={350}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PieChart
              title={"Product Providers"}
              staticData={productProviderPieData}
              height={350}
            />
          </Grid.Col>
        </Grid>
      </>
    );
  };

  return (
    <>
      <PeriodFilter
        handleRefresh={() => fetchStats()}
        handlePeriodChange={(p) => setPeriod(p)}
        numPeriods={numQuarters}
      />

      {InScrollArea && (
        <ScrollArea style={{ height }}>
          <MainContent></MainContent>
        </ScrollArea>
      )}

      {!InScrollArea && <MainContent />}
    </>
  );
};

export default OrderStatics;
