import React from "react";

import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import ActionCellRender from "../../components/actionCellRender";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";

import { TextInput } from "@mantine/core";
import RemoteSelect2 from "../../components/remoteSelect2";
import ProfolioFileImporter from "../../components/profolioFileImporter";
import FileList from "../../components/fileList";
import CommissionStatementTable from "../../components/commissionStatementTable";
import ProfolioStat from "../../components/profolioStat";
import ProfolioRowTable from "../../components/profolioRowTable";
import ActivityLog from "../../components/activityLog.js";
import AutoCodeInput from "../../components/autoCodeInput";
import moment from "moment";

const apiEntity = "profolioImport";
const initialValues = {};

const addFormSchema = {
  title: "Import Fund Profolio",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },
  showSaveBar: false,
  apiEntity,
  initialValues,
  validate: {},

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Profolio Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-1",
            label: "Profolio Info",
            icon: IconSettings,
          },
          { key: "t1-2", label: "Import File", icon: IconSettings },

          { key: "t1-3", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Profolio Basic Information",
          description:
            "Please fill in the basic information of the profolio data",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "section-import",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Import File",
          description: "Please upload the profolio file",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirm ",
          description: "Please confirm the details",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: AutoCodeInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "FUND-AIA-2302",
          label: "Patch ID",
        },
      },
      // {
      //     name: "code",
      //     component: TextInput,
      //     parent: "section-info",
      //     props: {
      //         required: true,
      //         placeholder: "FUND-AIA-2302",
      //         label: "Patch ID",
      //     },
      // },
      {
        name: "provider",
        component: RemoteSelect2,
        parent: "section-info",

        props: {
          name: "provider",
          apiEntity: "provider",
          required: true,
          label: "Provider",
          placeholder: "AIA",
          valueField: "_id",
          labelField: "name",
          // labelRender: (data) => data.name,
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "period",
        component: TextInput,
        parent: "section-info",
        props: {
          placeholder: "202302",
          label: "Period",
        },
      },
      {
        name: "importRemark",
        component: TextInput,
        parent: "section-info",
        props: {
          placeholder: "",
          label: "Remark",
        },
      },
      {
        name: "rows",
        component: ProfolioFileImporter,
        parent: "section-import",
        props: {},
      },
    ],
  },
};
const editFormSchema = {
  // title: "Commission Statement",
  titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.INSIDE,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Import Data",
        },
        tab: [
          {
            key: "tab1",
            label: "Import Data",
            icon: IconSettings,
          },

          {
            key: "tab2",
            label: "Files",
            icon: IconSettings,
          },

          {
            key: "tab3",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },

      {
        key: "section-files",
        parent: "tab2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-stat",
        parent: "tab1",
        type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
        props: {
          mt: 50,
        },
      },

      {
        key: "section-rows",
        parent: "tab1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Data Rows",
          mt: 50,
        },
      },

      {
        key: "section-log",
        parent: "tab3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Log",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 0,

          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "stats",
        parent: "section-stat",
        component: ProfolioStat,
        props: {},
      },
      {
        name: "rows",
        parent: "section-rows",
        component: ProfolioRowTable,
        props: {},
      },
      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },

      {
        name: "logs",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
    ],
  },
};

const formSchema = {
  add: addFormSchema,
  edit: editFormSchema,
};

const ProfolioImport = ({}) => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,
    select: "-rows",
    columns: [
      { field: "code", headerName: "Code", sortable: true },
      { field: "period", headerName: "Period", sortable: true },
      {
        field: "provider",
        headerName: "Provider",
        sortable: true,
        cellRender: (col, data) => data.provider?.name ?? "--",
      },

      {
        field: "total",
        headerName: "Total Records",
        sortable: true,
        cellRender: (col, data) => data.totalRow,
      },

      {
        field: "totalFundAmount",
        headerName: "Fund Amount",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.Currency(data.totalFundAmount, "HKD"),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };

  return (
    <PageList
      title={"Profolio Import"}
      apiEntity={apiEntity}
      initSort={{ by: "createdAt", order: "desc" }}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
};

export default ProfolioImport;
