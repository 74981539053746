import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import {
  Button,
  Table,
  Group,
  Text,
  Chip,
  Space,
  Title,
  Grid,
  Card,
  Divider,
  Tabs,
  Badge,
  LoadingOverlay,
  Menu,
  Switch,
  Stack,
  Center,
} from "@mantine/core";

import { useCellRender } from "../hooks/useCellRender";

import {
  IconPhoto,
  IconPrinter,
  IconCameraSelfie,
  IconFile,
  IconUser,
  IconBuildingSkyscraper,
  IconAlertCircle,
  IconAlertTriangle,
  IconExclamationMark,
  IconCoin,
  IconRefresh,
} from "@tabler/icons";
import { Accordion, useMantineTheme } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/userServerApi";
import { useForm } from "@mantine/form";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";

const RowHeader = ({ payment }) => {
  const { wallet, bids, total } = payment ?? {};
  const { user, members } = wallet ?? {};
  const users = members?.map((m) => m.user);
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const resignAmount = bids
    .filter((b) => b.code.includes("B-USER-RESIGN"))
    .reduce((a, c) => a + c.paidAmount, 0);
  return (
    <>
      {wallet && (
        <Grid justify="space-between">
          <Grid.Col span={3}>
            <>
              {user && (
                <div>
                  {/* {user.staffId && (
										<Badge mb={"xs"} color="green">
											{user.staffId}
										</Badge>
									)} */}
                  {/* <Badge mb={"xs"}>{wallet._id}</Badge> */}
                  {cellRender.User(user)}
                </div>
              )}

              {!user && wallet.name && (
                <div>
                  {wallet.name && <Badge mb={"xs"}>{wallet.name}</Badge>}
                  {cellRender.Users(users)}
                  {/* <Text size={"xs"} color="dimmed" mt={"xs"}>
                                     {wallet.name}
                                 </Text> */}
                </div>
              )}
            </>
          </Grid.Col>
          <Grid.Col span={3}>
            <Stack align={"flex-start"} spacing="xs">
              <Title order={3}>{bids.length}</Title>
              <Text size={"xs"} color="dimmed">
                Total bids
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={3}>
            {user && (
              <div>
                <Title order={3}>
                  {cellRender.Mask(
                    user?.consultantInfo?.paymentMethods.accNumber,
                    "*",
                    3,
                    4
                  )}
                </Title>
                <Text size={"xs"} color="dimmed">
                  Bank Account
                </Text>
              </div>
            )}
          </Grid.Col>
          <Grid.Col span={3}>
            <div>
              <Title order={3}>{formatter.currency(total)}</Title>

              <Text size={"xs"} color="dimmed">
                Total Payoll Amount
              </Text>
              {resignAmount < 0 && (
                <Text size={"xs"} color={"red"}>
                  Incld. Team Resign {formatter.currency(resignAmount)}
                </Text>
              )}
            </div>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

const RowContent = ({ payment }) => {
  const { bids } = payment;
  const [cellRender] = useCellRender();
  return (
    <>
      <Divider
        mt={"xl"}
        mb={"xl"}
        my="xs"
        label="Bid Details"
        labelPosition="center"
        variant="dashed"
      />
      <Table fontSize={"sm"} striped highlightOnHover>
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Bid ID</th>
            <th>Level</th>

            <th>Amount</th>
            <th>Refer </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {bids &&
            bids.map((b, index) => (
              <tr key={index}>
                <td>{index + 1}</td>

                <td>{b.type}</td>
                <td>{b.code}</td>
                <td>{b.level}</td>

                <td>{cellRender.Currency(b.paidAmount, b.paidCurrency)}</td>
                <td>{b.referFrom ? b.referFrom.user?.name : "--"}</td>

                <td>{cellRender.Status(b.status)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export const ReferalRow = ({ referal }) => {
  return (
    referal && (
      <Accordion.Item value={referal._id}>
        <Accordion.Control>
          <RowHeader payment={referal} />
        </Accordion.Control>
        <Accordion.Panel>
          <RowContent payment={referal} />
        </Accordion.Panel>
      </Accordion.Item>
    )
  );
};

export const WalletRow = ({ paymentId }) => {
  const [payment, setPayment] = useState(null);
  const [api] = useServerApi();
  const fetch = async () => {
    const data = await api.Payroll.getPaymentDetail(paymentId);
    if (!data) return setPayment(null);
    setPayment(data.payment);
  };

  useEffect(() => {
    fetch();
  }, [paymentId]);

  return (
    payment && (
      <Accordion.Item value={payment._id}>
        <Accordion.Control>
          <RowHeader payment={payment} />
        </Accordion.Control>
        <Accordion.Panel>
          <RowContent payment={payment} />
        </Accordion.Panel>
      </Accordion.Item>
    )
  );
};

const WalletList = ({ payments }) => {
  // const rows = payments?.filter(({ wallet: w }) => w.type === type) ?? [];
  return (
    <>
      {payments && (
        <>
          {payments.length === 0 && (
            <Card>
              <Title order={6}>{`There are no payments`}</Title>
            </Card>
          )}
          {payments.map((payment, index) => (
            <WalletRow paymentId={payment} />
          ))}
        </>
      )}
    </>
  );
};

const PaymentTab = ({ payments }) => {
  // const [loading, setLoading] = useState(false);
  // const [api] = useServerApi();
  // const [payments, setPayments] = useState([]);

  // const fetchPaymentDetails = async () => {
  //     setLoading(true);
  //     let result = await api.Payroll.getPaymentDetails(paymentIds);
  //     console.log(result);

  //     setLoading(false);
  // };

  // useDeepCompareEffect(() => {
  //     fetchPaymentDetails();
  // }, [paymentIds]);
  const sorted = payments; //?.sort((a, b) => a.bids.length - b.bids.length);

  return (
    <Tabs defaultValue="pending">
      <Tabs.List>
        <Tabs.Tab value="pending" icon={<IconUser size={14} />}>
          Payments
        </Tabs.Tab>
        {/* <Tabs.Tab
                    value="missing"
                    icon={<IconBuildingSkyscraper size={14} />}
                >
                    Group
                </Tabs.Tab> */}
      </Tabs.List>

      <Tabs.Panel value="pending" pt="xl">
        <Accordion variant="separated">
          <WalletList payments={sorted} />
        </Accordion>
      </Tabs.Panel>

      {/* <Tabs.Panel value="missing" pt="xl">
                <Accordion variant="separated">
                    {!loading && (
                        <WalletList type="GROUP" payments={payments} />
                    )}
                </Accordion>
            </Tabs.Panel> */}
    </Tabs>
  );
};
const PayrollTable = ({ form, name }) => {
  const { payments, _id, status } = form.values;
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [exporting, setExporting] = useState(false);
  const [gettingPayment, setGettingPayment] = useState(false);
  const [exportingDetail, setExportingDetail] = useState(false);
  const [exportingOrderDetail, setExportingOrderDetail] = useState(false);
  const [exportingBankFile, setExportingBankFile] = useState(false);

  const [api] = useServerApi();

  const handleExport = async () => {
    if (!_id || exporting) return;
    try {
      const ans = window.confirm(
        "Are you sure to export the bank file? Once exported, all related bids will be settled and the operation can't undo. Please Cancel if you are not sure."
      );
      if (!ans) return;
      setExporting(true);
      let result = await api.Payroll.export(_id);
      window.open(result.url, "_blank");
      form.setFieldValue("status", "COMPLETED");
      setExporting(false);
    } catch (error) {
      //   console.log(error);
      setExporting(false);
    }
  };

  const handleExportDetail = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setExportingDetail(true);
      let result = await api.Payroll.exportDetail(_id);
      setExporting(false);
      setExportingDetail(false);

      // console.log(result);
      if (result.url) return window.open(result.url, "_blank");
      if (result.error) throw result.error;
    } catch (error) {
      console.log(error);
      setExporting(false);
      setExportingDetail(false);
    }
  };

  const handleExportDetailByOrder = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setExportingOrderDetail(true);

      let result = await api.Payroll.exportDetailByOrder(_id);
      setExporting(false);
      setExportingOrderDetail(false);
      console.log(result);
      if (result.url) return window.open(result.url, "_blank");
      if (result.error) throw result.error;
    } catch (error) {
      console.log(error);
      setExporting(false);
      setExportingOrderDetail(false);
    }
  };
  const handleGetPending = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setGettingPayment(true);
      let result = await api.Payroll.getPendingPayroll(_id);
      //   console.log(result.payroll);
      form.setValues(result.payroll);

      setExporting(false);
      setGettingPayment(false);
    } catch (error) {
      //   console.log(error);
      setExporting(false);
      setGettingPayment(false);
    }
  };

  const isGetPendingDisable = () => status === "COMPLETED";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <Group position="apart" mt="xl">
        <span></span>
        <Group position="right" mb={"xl"}>
          <Button
            disabled={isGetPendingDisable() || exporting}
            loading={gettingPayment}
            onClick={() => handleGetPending()}
            leftIcon={<IconRefresh size={14} />}
          >
            Get Pending Payment
          </Button>
          <Button
            // color={"green"}
            disabled={exporting}
            loading={exportingDetail}
            onClick={() => handleExportDetail()}
            leftIcon={<IconFile size={14} />}
          >
            Print Detail (Consultant)
          </Button>

          <Button
            // color={"green"}
            disabled={exporting}
            loading={exportingOrderDetail}
            onClick={() => handleExportDetailByOrder()}
            leftIcon={<IconFile size={14} />}
          >
            Print Detail (Order)
          </Button>

          <Button
            color={"red"}
            disabled={exporting}
            loading={exportingBankFile}
            onClick={() => handleExport()}
            leftIcon={<IconFile size={14} />}
          >
            Export Bank File
          </Button>
        </Group>
      </Group>
      <PaymentTab payments={payments} />
    </>
  );
};

export default PayrollTable;
