import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
	IconTrash,
	IconCheck,
	IconCircleCheck,
	IconAlertOctagon,
	IconPencil,
	IconCross,
} from "@tabler/icons";
import _ from "lodash";

import {
	Switch,
	TextInput,
	Select,
	Textarea,
	NumberInput,
} from "@mantine/core";
import { DATA_GENDERS, DATA_RELATIONSHIP } from "../data/options";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";
import { useFormatter } from "../hooks/useFomatter";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";

const OrderBeneficiaryList = ({ form: mainForm, ...props }) => {
	const orderId = _.get(mainForm.values, "_id");
	const [formatter] = useFormatter();

	const [rows, setRows] = useState([]);
	const [api] = useServerApi();

	const tableSchema = {
		hidePagination: true,
		columns: [
			// {   field: '_id',       headerName:"ID"            },
			{
				field: "name",
			},
			{
				field: "cname",
				headerName: "Chinese Name",
			},

			{
				field: "phone",
			},

			{
				field: "percentage",
				cellRender: (col, data) => formatter.percentage(data.percentage),
			},

			{
				field: "isBackup",
				headerName: "Is backup",
				cellRender: (col, data) =>
					data.isBackup ? <IconCircleCheck size={15} /> : "--",
			},

			{
				cellRender: ActionCellRender,
				isAction: true,
				actions: [
					{
						name: "edit",
						label: "Edit",
						icon: IconPencil,
						props: {},
					},
					{
						name: "delete",
						label: "Delete data",
						icon: IconTrash,
						props: {},
					},
				],
				actionMenu: [],
			},
		],
		searchableFields: ["code", "name", "cname", "email"],
	};

	const formSchema = {
		title: "Beneficiary Detail",
		description: "Please fill in the form",

		display: {
			mode: ENUM_FORM_DISPLAY.MODAL,
			size: "xl",
		},
		initialValues: {},

		layout: {
			containers: [
				{
					key: "main-tab",
					parent: null,
					type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
					props: {
						variant: "default", //default | pills | outline
						defaultValue: "Basic Info",
					},
					tab: [
						{
							key: "tab-basic",
							label: "Basic Info",
							//icon: IconSettings,
						},
						{
							key: "tab-files",
							label: "Files",
							// icon: IconSettings,
						},
					],
				},
				{
					key: "pre",
					parent: "tab-basic",
					type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
				},

				{
					key: "main",
					parent: "tab-basic",
					visibleOnly: (data) => !data.sameAsClient,

					type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
					cols: [
						{
							key: "g1",
							props: {
								span: 6,
							},
						},
						{
							key: "g2",
							props: {
								span: 6,
							},
						},
					],
				},
				{
					key: "file-section",
					parent: "tab-files",
					type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
					props: {
						title: "Files",
					},
				},
			],

			fields: [
				{
					name: "sameAsClient",
					parent: "pre",

					component: Switch,
					props: {
						label: "Same as applicant",
						type: "checkbox",

						mb: 30,
					},
				},
				{
					name: "name",
					component: TextInput,
					parent: "g1",
					props: {
						required: true,
						label: "English Name",
					},
				},

				{
					name: "cname",
					component: TextInput,
					parent: "g1",
					props: {
						required: true,
						label: "Chinese Name",
					},
				},

				{
					name: "percentage",
					component: NumberInput,
					parent: "g2",
					props: {
						required: true,
						label: "Percentage",
						placeholder: "",
					},
				},
				{
					name: "id",
					component: TextInput,
					parent: "g1",
					props: {
						required: true,
						label: "ID (HKID/PASSPORT/BR)",
					},
				},

				{
					name: "relationship",
					component: Select,
					parent: "g1",
					props: {
						required: true,
						label: "Relationship with applicant",
						placeholder: "",
						data: DATA_RELATIONSHIP,
						multiple: false,
						searchable: true,
						clearable: true,
					},
				},

				{
					name: "remark",
					component: Textarea,
					parent: "g1",
					props: {
						required: true,
						label: "Remark",
					},
				},
				{
					name: "gender",
					component: Select,
					parent: "g2",
					props: {
						required: true,
						label: "Gender",
						placeholder: "",
						data: DATA_GENDERS,
						multiple: true,
						searchable: true,
						clearable: true,
					},
				},
				{
					name: "phone",
					component: TextInput,
					parent: "g2",
					props: {
						required: true,
						label: "Contact Tel.",
						placeholder: "",
					},
				},
				{
					name: "birthday",
					component: DatePicker,
					parent: "g2",
					type: "datePicker",
					props: {
						required: true,
						label: "Date of Birth",
						zIndex: 1000,
						placeholder: "Pick Date",
						allowFreeInput: true,
					},
				},
				{
					name: "isBackup",
					component: Switch,
					parent: "g2",
					props: {
						type: "checkbox",
						required: true,
						label: "Is Backup Beneficiary",
					},
				},
				{
					name: "files",
					component: FileList,
					parent: "file-section",
					props: {},
				},
			],
		},
	};

	const onFormSubmit = (values) => {
		// console.log("beneficiary submit", values);
		setRows(values); //Update UI
		mainForm.setFieldValue("beneficiary", values);

		// _.set(mainForm.values, "beneficiary", values); //Update MainForm to prevent further override

		// api.Client.updateRiskAML(clientID, values); //update server
	};

	const initRowData = async () => {
		if (!orderId) return;
		setRows(mainForm.values?.beneficiary);
	};

	useEffect(() => {
		initRowData();
	}, [orderId]);

	return (
		<>
			{/* <ReactJson
				src={mainForm.values}
				collapsed
				style={{ background: "white" }}
			/> */}
			<LocalPageList
				rows={rows}
				onFormSubmit={onFormSubmit}
				tableSchema={tableSchema}
				formSchema={formSchema}
				{...props}
			/>
		</>
	);
};

export default OrderBeneficiaryList;
