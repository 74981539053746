import React from 'react'
import { Button ,Text, TextInput, Chip, Select} from '@mantine/core'

import _ from 'lodash'

const CLIENT_STATUS = ['PENDING', 'ACTIVE', 'INACTIVE']

const ClientActiveEnableButton = ({mainForm , ...props}) =>{

    const clientStatus = _.get( mainForm.values, 'status' )

    const handleClick = () =>{
        console.log("handleClick", mainForm.values.status)
        if (clientStatus ==='ACTIVE') {
            mainForm.values.status = 'INACTIVE'
        }else{
            mainForm.values.status = 'ACTIVE'
        }
        mainForm.saveValues(mainForm.values)
    }

    const isActive = () =>{
        return clientStatus==='ACTIVE'
    }

    return (
        <>
         {/* Status: {clientStatus} */}
        <Button fullWidth onClick={handleClick} color={isActive()?"red":"green"}>
            { isActive() ? "Deactive": "Active Account" }
        </Button>

        </>
    )
}

export default ClientActiveEnableButton