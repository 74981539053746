import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  Center,
  Transition,
} from "@mantine/core";

import { ResponsivePie } from "@nivo/pie";

import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useServerApi } from "../../hooks/userServerApi";
import { pieData } from "../../data/sampleChartData";

const defaultChartProps = {
  margin: { top: 40, right: 80, bottom: 80, left: 80 },
  cornerRadius: 5,
  innerRadius: 0.6,
  padAngle: 0.5,
  activeOuterRadiusOffset: 8,
  colors: { scheme: "category10" },
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["darker", 0.2]],
  },
  arcLinkLabelsSkipAngle: 10,
  arcLinkLabelsTextColor: "#333333",
  arcLinkLabelsThickness: 2,
  arcLinkLabelsColor: { from: "color" },
  arcLabelsSkipAngle: 10,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
  defs: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ],

  legends: [
    {
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateX: 0,
      translateY: 50,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
};
const PieChart = ({
  title,
  height = 500,
  description,
  chartProps = {},
  dataAggreation,
  useSampleData = false,
  staticData,
}) => {
  const theme = useMantineTheme();
  const [api] = useServerApi();
  const [data, setData] = useState([]);

  const chartTheme = {
    textColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[1]
        : theme.colors.dark[8],
    grid: {
      line: {
        stroke:
          theme.colorScheme === "dark"
            ? theme.colors.gray[8]
            : theme.colors.gray[1],
      },
    },
  };

  chartProps.arcLinkLabelsTextColor =
    theme.colorScheme === "dark" ? theme.colors.gray[1] : theme.colors.dark[8];

  const fetchData = async () => {
    try {
      if (useSampleData) {
        return setData(pieData);
      }
      if (!dataAggreation) return;

      const d = await api.aggregate(dataAggreation);
      const { mapper } = dataAggreation;
      // console.log("fetchData=", d, mapper);
      if (!mapper) return setData(d);
      return setData(mapper(d));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataAggreation?.aggregations]);

  useEffect(() => {
    // if (_.isEmpty(staticData)) return;
    setData(staticData);
  }, [staticData]);

  return (
    // <div
    //   style={{
    //     "@media print": {
    //       display: "block",
    //     },
    //   }}
    // >
    <FormSectionCard title={title} h={height} display="block">
      <Text size={"xs"} color="dimmed">
        {description}
      </Text>
      <Transition
        mounted={_.isEmpty(data)}
        transition="fade"
        duration={100}
        timingFunction="ease"
      >
        {(styles) => (
          <Center height={height} mt="xl">
            <Text color={"dimmed"} size="xs">
              No Data Available
            </Text>
          </Center>
        )}
      </Transition>

      {/* {_.isEmpty(data) && (
				<Center height={height} mt="xl">
					<Text color={"dimmed"} size="xs">
						No Data Available
					</Text>
				</Center>
			)} */}

      <ResponsivePie
        data={data}
        theme={chartTheme}
        {..._.merge(defaultChartProps, chartProps)}
      />

      {/* <Center> */}
    </FormSectionCard>
    // </div>
  );
};

export default PieChart;
