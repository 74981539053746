import { Chip, createStyles } from "@mantine/core";
import { useState, useEffect } from "react";

import { DATA_PAY_MODES } from "../data/options";
import ReactJson from "react-json-view";
import _ from "lodash";

const useStyles = createStyles((theme, _params, getRef) => ({
  label: {
    "&[data-checked]": {
      "&, &:hover": {
        backgroundColor: theme.colors.blue[theme.fn.primaryShade()],
        color: theme.white,
      },

      [`& .${getRef("iconWrapper")}`]: {
        color: theme.white,
      },
    },
  },

  iconWrapper: {
    ref: getRef("iconWrapper"),
  },
}));

//Commission Related Order/ Product Fields
export const CommissionCoreFields = [
  {
    label: "Pay Term",
    key: "payTerm",
    type: "number",
    selected: false,
  },
  {
    label: "Premium",
    key: "preimum",
    type: "money",
    selected: false,
  },
  {
    label: "Pay Mode",
    key: "paymode",
    type: "options",
    options: DATA_PAY_MODES,
    selected: false,
  },
  {
    label: "Initial Charge",
    key: "initialCharge",
    type: "money",
    selected: false,
  },

  {
    label: "Insured Age",
    key: "insuredAge",
    type: "number",
    selected: false,
  },
];

const CommissionRelatedFields = ({
  form,
  name,
  variationFields, //From product variation definition
  label,
  ...props
}) => {
  const { classes } = useStyles();
  const [selected, setSelected] = useState(
    _.get(form.values, name)?.map((f) => f.key) || []
  );

  const { relatedFields } = form.values;

  /**
   * @description
   *  variationFields + coreRelatedFields => relatedFields of products
   *  related fields => used for generate product commisssion variation table
   *
   */

  //Check whether selected Key is a core field
  const isCoreField = (selectedKey) =>
    CommissionCoreFields.findIndex((core) => core.key === selectedKey) !== -1;

  //When selected change
  useEffect(() => {
    let value = selected.map((s) => {
      let field =
        relatedFields.find((f) => f.key === s) ||
        CommissionCoreFields.find((core) => core.key === s);

      if (!field) {
        field = variationFields.find((variation) => variation.key === s);
      }

      if (field.type === "number" && !field.options) field.options = [];
      if (field.type === "money" && !field.options) field.options = [];

      //Remove unselected pay term
      return field;
    });

    // console.log("selected changes", value);

    form.setFieldValue(name, value);
  }, [selected]);
  // useEffect(() => {
  // 	let value = selected.map((s) => {
  // 		let field = CommissionCoreFields.find((core) => core.key === s);

  // 		if (!field) {
  // 			field = variationFields.find((variation) => variation.key === s);
  // 		}

  // 		if (field.type === "number" && !field.options) field.options = [];

  // 		//Remove unselected pay term
  // 		return field;
  // 	});
  // 	console.log("selected changes", value);

  // 	form.setFieldValue(name, value);
  // }, [selected]);

  return (
    <>
      {/* <ReactJson src={ form.values } style={{backgroundColor:"white"}} collapsed/> */}
      {/* <ReactJson
				style={{ background: "white" }}
				name={"relatedFields"}
				src={relatedFields}
				collapsed
			/> */}

      {/* <ReactJson
				style={{ background: "white" }}
				name="variationFields"
				src={variationFields}
				collapsed
			/>
			<ReactJson
				style={{ background: "white" }}
				name={"commissionTable"}
				src={form.values}
				collapsed
			/> */}

      <Chip.Group
        multiple
        // {...form?.getInputProps(name)}
        {...props}
        value={selected}
        onChange={setSelected}
        // onChange={console.log}
      >
        {CommissionCoreFields.map((field) => (
          <Chip key={field.key} classNames={classes} value={field.key}>
            {field.label}
          </Chip>
        ))}

        {variationFields?.map((field) => (
          <Chip key={field.key} classNames={classes} value={field.key}>
            {field.label}
          </Chip>
        ))}
      </Chip.Group>

      {/* Value: {JSON.stringify(form.values)} <br></br> */}
      {/* Selected: {JSON.stringify(selected)}<br></br> */}
      {/* Product Variations: { JSON.stringify(variationFields)} */}
    </>
  );
};

export default CommissionRelatedFields;
