import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  Select,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Pagination,
  Space,
  Button,
  Menu,
  ActionIcon,
  Badge,
  Paper,
  Box,
  Container,
  Flex,
} from "@mantine/core";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconDots,
  IconCross,
  IconClearAll,
  IconLetterX,
  IconAdjustments,
  IconSquareLetterX,
  IconX,
} from "@tabler/icons";
import _ from "lodash";
import { useDebouncedValue, useForceUpdate } from "@mantine/hooks";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";

const useStyles = createStyles((theme) => ({
  // search:{
  //     marginBottom: '30px'
  // },

  // header: {
  //   position: 'sticky',
  //   top: 0,
  //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  //   transition: 'box-shadow 150ms ease',
  //   zIndex:100,

  //   '&::after': {
  //     content: '""',
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //     borderBottom: `1px solid ${
  //       theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
  //     }`,
  //   },
  // },

  scrollArea: {
    // border:"1px solid #373A40"
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  // th:{
  //     width: "fit-content"
  // }
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="xs">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

const SearchBar = ({ onChange = console.log, searchText, w = 500 }) => {
  const { classes, cx } = useStyles();

  return (
    //fullWidth

    <TextInput
      placeholder="Search"
      w={w}
      icon={<IconSearch size={14} stroke={1.5} />}
      value={searchText}
      clearable={true}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      rightSection={
        searchText && (
          <UnstyledButton
            onClick={() => {
              onChange("");
            }}
          >
            <IconX size={14} stroke={1.5} />{" "}
          </UnstyledButton>
        )
      }
      // className={classes.search}
    />
  );
};

const PaginationBar = ({
  pageSize,
  onPageSizeChange,
  pagination,
  onPageChange,
}) => {
  return (
    <>
      {/* {JSON.stringify(pagination)} */}
      {pagination && (
        <Group position="right">
          <Group>
            <Pagination
              size="xs"
              page={pagination.page}
              total={pagination.totalPage}
              siblings={1}
              onChange={onPageChange}
            />
            <Select
              data={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 40, label: "40" },
                { value: 50, label: "50" },
                //   { value: 100, label: "100" },
              ]}
              value={pageSize}
              onChange={onPageSizeChange}
              size="xs"
            />
            <Text size="xs" color="dimmed">
              {pagination.total} Records
            </Text>
          </Group>
        </Group>
      )}
    </>
  );
};

const DataTable = (
  {
    data = [],
    columns = [],
    hidePagination = false,
    hideSearchBar = false,
    minHeight = "200px",
    pageSize = 10,
    pagination = { total: 10 },
    searchText = "",
    onPageSizeChange = () => {},
    onPageChange = () => {},
    onSearchChange = () => {},
    onSortChange = () => {},
    onActionBtnClick = () => {},
    onRowDoubleClick = () => {},

    padding = "xl",
    tableHeader,
    showRefreshButton = false,
  },
  ref
) => {
  useImperativeHandle(ref, () => {
    return {
      // refresh: ()=>fetchData()
    };
  });

  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [rows, setRows] = useState([]);

  const forceUpdate = useForceUpdate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const setDataRow = () => {
    // console.log("datatable effect", data);
    if (!data) return;
    setRows(
      data?.map((d, index) => (
        <tr
          key={index}
          // onDoubleClick={() => onRowDoubleClick({ data: d, rowIndex: index })}
          // onDoubleClick={() => console.log("click")}
        >
          {columns?.map((col, index_c) => (
            <td key={index_c}>
              {!col.cellRender && _.get(d, col.field)}
              {col.cellRender && !col.isAction && col.cellRender(col, d)}
              {col.cellRender &&
                col.isAction &&
                col.cellRender(col, index, d, onActionBtnClick)}
            </td>
          ))}
        </tr>
      ))
    );
    forceUpdate();
  };
  useEffect(() => {
    setDataRow();
  }, [data]);

  useEffect(() => {
    onSortChange(sortBy, reverseSortDirection);
  }, [sortBy, reverseSortDirection]);

  const TableHeader = () => (
    <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
      <tr>
        {columns.map((col, index) => {
          return col.sortable ? (
            <Th
              key={col.field + index}
              sorted={sortBy === col.field}
              reversed={reverseSortDirection}
              onSort={() => setSorting(col.field)}
            >
              {col.headerName ?? _.capitalize(col.field)}
            </Th>
          ) : (
            <th key={col.field + index}>
              {col.headerName ?? _.capitalize(col.field)}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  return (
    <>
      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(columns)} */}
      {/* {sortBy} - {reverseSortDirection?'true':'false'} */}
      {/* <ReactJson src={data} collapsed style={{ background: "white" }} /> */}

      <Paper p={padding}>
        {!hideSearchBar && (
          <Flex w={"100%"} align="center" mb="xl" gap="xl">
            <SearchBar
              onChange={onSearchChange}
              searchText={searchText}
              w={"100%"}
            />
            {/* <ActionIcon>
              <IconAdjustments size={18} />
            </ActionIcon> */}
          </Flex>
        )}
        {tableHeader && <tableHeader.component {...tableHeader.props} />}
        {/* <ScrollArea sx={{ minHeight }} className={classes.scrollArea}> */}
        {/* onScrollPositionChange={({ y }) => setScrolled(y !== 0)}> */}
        <Table
          //striped
          highlightOnHover
          // horizontalSpacing="md"
          // verticalSpacing="xs"
          //sx={{ tableLayout: "fixed", minWidth: 700 }}
          fontSize={"xs"}
        >
          <TableHeader />
          <tbody>{rows}</tbody>
        </Table>
        {/* </ScrollArea> */}
        {showRefreshButton && (
          <Button
            onClick={() => setDataRow()}
            size="xs"
            mt="xl"
            variant="light"
          >
            Refresh
          </Button>
        )}

        <Space h={"xl"} />
        {!hidePagination && (
          <PaginationBar
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pagination={pagination}
            onPageChange={onPageChange}
          />
        )}
      </Paper>
    </>
  );
};

export default React.forwardRef(DataTable);
