import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Checkbox, Text, List, Box, Group, Button } from "@mantine/core";
import ReactJson from "react-json-view";

const OrderReceiveCheckList = ({ form: mainForm }) => {
  const [checkList, setCheckList] = useState([]);
  const product = _.get(mainForm.values, "product");

  useEffect(() => {
    if (!product || !product.checkList) setCheckList([]);

    let list = product?.checkList?.filter((item) => item.frontier);

    const receivedList = _.get(mainForm.values, "receiveCheckList");

    list?.forEach((item) => {
      if (receivedList.findIndex((received) => received === item._id) != -1) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    setCheckList(list);
  }, [product?.checkList]);

  const checkAll = (checked) => {
    checkList.forEach((item) => (item.checked = checked));
    mainForm.setFieldValue(
      "receiveCheckList",
      checkList.filter((item) => item.checked === true).map((item) => item._id)
    );
  };

  const handleOnCheck = (checked, item) => {
    item.checked = checked;

    setCheckList([...checkList]);
    mainForm.setFieldValue(
      "receiveCheckList",
      checkList.filter((item) => item.checked === true).map((item) => item._id)
    );
  };

  return (
    <>
      {/* <ReactJson
        src={mainForm.values.receiveCheckList}
        collapsed
        style={{ background: "white" }}
      /> */}
      {/* <ReactJson
				src={product?.checkList}
				collapsed
				style={{ background: "white" }}
			/> */}
      <Group position="right" spacing={"xs"}>
        <Button size="xs" variant="light" onClick={() => checkAll(true)}>
          Check All
        </Button>
        <Button
          size="xs"
          variant="light"
          color={"red"}
          onClick={() => checkAll(false)}
        >
          Clear
        </Button>
      </Group>
      {checkList &&
        checkList?.map((item) => (
          <Checkbox
            mb={"sm"}
            key={item._id}
            label={item.name}
            checked={item.checked}
            onChange={(e) => handleOnCheck(e.target.checked, item)}
          />
        ))}
    </>
  );
};

export default OrderReceiveCheckList;
