import { useState, useEffect } from "react";
import {
  Text,
  Title,
  Table,
  NumberInput,
  Button,
  Grid,
  Group,
  ActionIcon,
  Textarea,
  Divider,
} from "@mantine/core";
import {
  IconAdjustments,
  IconAlertCircle,
  IconBulb,
  IconExclamationMark,
  IconTrash,
} from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

import _ from "lodash";
import { useForceUpdate, useHotkeys } from "@mantine/hooks";
import ReactJson from "react-json-view";
// import { IconExclamationMark, IconSettings } from "@tabler/icons";

import parseClip from "../functions/parseClip";
const CommissionRateTable = ({ form, name, rateFactor = 1, ...props }) => {
  // const [table, setTable] = useState(_.get(form.values, name) || {});
  const table = form.values[name] || [];
  const relatedFields = _.get(form.values, "relatedFields");
  const forceUpdate = useForceUpdate();
  useHotkeys([
    ["ctrl+V", () => loadPasteData()],
    ["mod+V", () => loadPasteData()],
  ]);

  const setTable = (table) => {
    // console.log("Setable", table);
    form.setFieldValue(name, table);
    forceUpdate();
  };

  useEffect(() => {
    form.setFieldValue(name, table);
  }, [table]);

  const getNumberOptionLabel = (option) => {
    let { condition, number1, number2 } = option;
    switch (condition) {
      case "=":
        return number1;
      case "<>":
        return number1 + " - " + number2;
      default:
        return condition + " " + number1;
    }
  };

  const getMoneyOptionLabel = (option) => {
    let { condition, number1, number2, currency = "USD" } = option;
    switch (condition) {
      case "=":
        return currency + " " + number1;
      case "<>":
        return currency + " " + number1 + " - " + currency + " " + number2;
      default:
        return condition + " " + currency + " " + number1;
    }
    return currency + " " + getNumberOptionLabel(option);
  };

  const getOptionLabel = (type, option) => {
    // console.log("getOptionLabel", option);
    if (type === "options") return option.label;
    if (type === "number") return getNumberOptionLabel(option);
    if (type === "money") return getMoneyOptionLabel(option);
    return "";
  };

  const createRows = () => {
    let rows = [];
    if (relatedFields.length == 0) {
      rows.push({ key: "no-condition" });
      return rows;
    }
    relatedFields.forEach((field, level) => {
      let { key, options, type } = field;

      let fieldRows = options
        .filter((o) => o.visible ?? true)
        .map((o) => ({
          key: `${field.key}-${getOptionLabel(o)}`,
          [key]: getOptionLabel(field.type, o),
        }));
      if (rows.length === 0) rows = fieldRows;
      else {
        let newRows = [];
        rows.forEach((row) => {
          fieldRows.forEach((fieldRow) => {
            let newRow = { ...row, ...fieldRow };
            newRow.key = row.key + "-" + fieldRow.key;
            newRows.push(newRow);
          });
        });
        rows = newRows;
      }
    });
    // console.log("createRows", rows);
    return rows;
  };

  const createColumns = () => {
    let fieldLabels = relatedFields?.map((f) => ({
      label: f.label,
      key: f.key,
    }));
    return [
      ...fieldLabels,
      { key: "yr1", label: "YR 1", type: "year" },
      { key: "yrSubsequent", label: "Subsequent", type: "year" },
    ];
  };

  const createStandardColumns = (num) => {
    let fieldLabels = relatedFields?.map((f) => ({
      label: f.label,
      key: f.key,
    }));

    let cols = [...fieldLabels];
    for (let i = 1; i <= num; i++) {
      cols.push({
        key: `yr${i}`,
        label: `YR ${i}`,
        type: "year",
        year: i,
      });
    }
    cols.push({ key: "yrSubsequent", label: "Subsequent", type: "year" });
    return cols;
  };

  const loadPasteData = async () => {
    const text = await navigator.clipboard.readText();
    // console.log("loadpaste data", text);
    parseValue(text);
  };

  const parseValue = (value) => {
    try {
      const data = parseClip(value);
      // console.log(data);
      if (!data || !data[0]) return;

      let columns = createStandardColumns(data[0].length);
      let rows = table.rows;

      if (rows.length != data.length) throw "Rows Number not match";

      for (let r = 0; r < data.length; r++) {
        for (let c = 0; c < data[r].length; c++) {
          if (data[r].length > columns.length) throw "Columns Number not Match";
          rows[r][columns[c + relatedFields.length].key] = parseFloat(
            data[r][c]
          );
        }
        rows[r].yrSubsequent = rows[r].yrSubsequent ?? 0;
      }
      setTable({ columns, rows });
    } catch (error) {
      showNotification({
        title: `Paste unsuccessful`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error,
      });
    }

    //Paste to table
  };

  const createTable = () => {
    let columns = createColumns();
    let rows = createRows();
    rows.forEach((row) => {
      row.yr1 = 0;
      row.yrSubsequent = 0;
    });
    setTable({ columns, rows });
  };

  const handleYearRateChange = (row, col, v) => {
    _.set(row, col.key, v);
    // forceUpdate();
  };

  const handleAddYear = () => {
    let index = table.columns.length - 1;
    let columns = [
      ...table.columns.slice(0, index),
      { key: `yrR${index}`, start: 0, end: 0, type: "withinYear" },
      ...table.columns.slice(index),
    ];
    //console.log(col)
    setTable({ ...table, columns });
    // forceUpdate();
  };

  const handleRemoveColumn = (index) => {
    table.columns.splice(index, 1);
    setTable({ ...table });

    // forceUpdate();
  };

  const removeRow = (index) => {
    table.rows.splice(index, 1);
    setTable({ ...table });
  };

  return (
    <>
      {/* { JSON.stringify(relatedFields)} */}
      {/* <ReactJson
        style={{ background: "white" }}
        name="table"
        src={table}
        collapsed
      /> */}
      {/* <ReactJson style={{background: 'white'}} name='relatedField' src={relatedFields} collapsed/> */}
      <Group position="apart" mb={50}>
        <Button variant="default" onClick={createTable}>
          Generate New Table
        </Button>
        <Button variant="default" onClick={handleAddYear}>
          Add Year
        </Button>
      </Group>
      <Table striped highlightOnHover>
        <thead>
          <tr>
            {table.columns?.map((col, index) =>
              col.type === "withinYear" ? (
                <th>
                  <Group>
                    YR
                    <NumberInput
                      size="xs"
                      style={{ width: "60px" }}
                      value={col.start}
                      onChange={(v) => {
                        col.start = v;
                        col.label = `YR ${col.start ? col.start : ""} ${
                          col.end ? " to " + col.end : ""
                        }`;
                        // forceUpdate();
                      }}
                    />
                    -
                    <NumberInput
                      size="xs"
                      style={{ width: "60px" }}
                      value={col.end}
                      onChange={(v) => {
                        col.end = v;
                        col.label = `YR ${col.start ? col.start : ""} ${
                          col.end ? " to " + col.end : ""
                        }`;
                        // forceUpdate();
                      }}
                    />
                    <ActionIcon
                      variant="filled"
                      onClick={() => handleRemoveColumn(index)}
                    >
                      <IconTrash size={13}></IconTrash>
                    </ActionIcon>
                  </Group>
                </th>
              ) : (
                <th> {col.label}</th>
              )
            )}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {table.rows?.map((row, index) => (
            <tr key={index}>
              {table.columns?.map((col) =>
                col.type === "year" || col.type === "withinYear" ? (
                  <td>
                    <NumberInput
                      precision={4}
                      value={_.get(row, col.key)}
                      onChange={(v) => handleYearRateChange(row, col, v)}
                    />
                  </td>
                ) : (
                  <td>{_.get(row, col.key)}</td>
                )
              )}
              <td>
                <ActionIcon onClick={() => removeRow(index)}>
                  <IconTrash size={16} stroke={1.5} />
                </ActionIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Group mt="xl">
        <IconBulb />
        <Text size={"xs"}>
          You can copy excel table value and PASTE (Ctrl+V) values directly to
          here.
        </Text>
      </Group>
      {/* <Divider my="xl" />
			<Text>Clip Parser</Text>
			<Textarea onChange={(e) => parseValue(e.target.value)} /> */}
    </>
  );
};

export default CommissionRateTable;
