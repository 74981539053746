import {
	Table,
	Text,
	Switch,
	Badge,
	Divider,
	Title,
	Group,
	Button,
	Select,
	Grid,
	Space,
	ActionIcon,
} from "@mantine/core";
import {
	IconAdjustments,
	IconTrash,
	IconPencil,
	IconX,
	IconCircleCheck,
	IconCircleX,
	IconChecklist,
	IconCheckbox,
} from "@tabler/icons";
import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { TextInput } from "@mantine/core";
import RemoteSelect from "./remoteSelect";
import VariationOptionList from "./variationOptionList";
import ReactJson from "react-json-view";

import { useForceUpdate } from "@mantine/hooks";

const formSchema = {
	title: "Variation",
	display: {
		mode: ENUM_FORM_DISPLAY.MODAL,
		size: "xl", //xs, sm, xl  ....100%
		// position:"right"
	},
	apiEntity: "productVariation",
	initialValues: {
		name: "",
		options: [],
	},
	closeAfterSave: true,

	layout: {
		containers: [
			{
				key: "main",
				parent: null,
				title: "Variation",
				type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
				props: {},
			},
		],

		fields: [
			{
				name: "name",
				component: TextInput,
				parent: "main",
				props: {
					required: true,
					label: "Variation Name",
					placeholder: "Variation Name",
					mt: "xl",
				},
			},

			{
				name: "options",
				component: VariationOptionList,
				parent: "main",
				props: {
					label: "Options",
				},
			},
		],
	},
};

export const ProductVariation = ({
	form,
	name,
	disabled,
	variant,
	...props
}) => {
	let variations = _.get(form.values, name);
	const [selected, setSelected] = useState(null);
	const selectRef = useRef(null);
	const ref2 = useRef(null);

	const forceUpdate = useForceUpdate();

	const onFormSubmit = () => {};
	const onFormChange = () => {};

	const handleVisibleChange = (vname, label, checked) => {
		console.log("handleVisibleChange", vname, label, checked, variations);

		let tmp = variations?.map((v) =>
			v.name != vname
				? v
				: {
						name: vname,
						_id: v._id,
						options: v.options.map((o) =>
							o.label != label
								? o
								: {
										label: o.label,
										_id: o._id,
										visible: checked,
								  }
						),
				  }
		);
		console.log(tmp);
		form.setFieldValue(name, tmp);
	};

	const handleInsertVariation = () => {
		selectRef.current.value = "";
		if (!selected) return;
		variations.push({ ...selected });
		form.setFieldValue(name, variations);
		setSelected(null);
	};

	const onSubmitSucces = ({ values }) => {
		// console.log("onSubmitSucces", values)
		for (let i = 0; i < variations.length; i++) {
			if (variations[i]._id === values._id) {
				variations[i] = values;
			}
		}

		form.setFieldValue(name, [...variations]);
		console.log("onSubmitSucces", variations);
		setSelected(null);
	};

	const handleRemoveVariation = (id) => {
		form.setFieldValue(
			name,
			variations.filter((v) => v._id != id)
		);
		setSelected(null);
	};

	const handleAddVariation = () => {
		// console.log("handleAddVariation")
		formAction.open({ mode: "add" });
	};

	const handleEditVariation = (id) => {
		formAction.open({ mode: "edit", id });
	};

	const handleSelectAllVariation = (v, visisble) => {
		if (!v || !v.options) return;

		v.options.forEach((option) => {
			option.visible = visisble;
		});
		forceUpdate();
	};

	const [renderForm, formAction, formStatus, formSetting] = useFormRender(
		formSchema,
		onSubmitSucces,
		null,
		onFormChange
	);

	const VariationTable = (v) => (
		<span key={v.name}>
			<Group position="apart" mb={10}>
				<Badge size="lg">{v.name}</Badge>

				{!disabled && (
					<Group spacing={"xs"}>
						<ActionIcon
							variant="filled"
							onClick={() => handleSelectAllVariation(v, true)}
						>
							<IconCheckbox size={18} />
						</ActionIcon>
						<ActionIcon
							variant="filled"
							onClick={() => handleSelectAllVariation(v, false)}
						>
							<IconX size={18} />
						</ActionIcon>

						<ActionIcon
							variant="filled"
							onClick={() => handleEditVariation(v._id)}
						>
							<IconPencil size={18} />
						</ActionIcon>

						<ActionIcon
							variant="filled"
							onClick={() => handleRemoveVariation(v._id)}
						>
							<IconTrash size={18} />
						</ActionIcon>
					</Group>
				)}
			</Group>

			<Table>
				<thead>
					<tr>
						<th>Option</th>
						<th>Visible</th>
					</tr>
				</thead>
				<tbody>
					{v.options?.map((o, index) => (
						<tr key={index}>
							<td width={"82%"}> {o.label}</td>
							{/* <td> <Switch checked={o.visible} onChange={(event) => o.visible = event.currentTarget.checked} ></Switch></td> */}
							<td>
								{disabled && o.visible && <IconCircleCheck size={20} />}
								{disabled && !o.visible && <IconCircleX size={20} />}

								{!disabled && (
									<Switch
										checked={o.visible}
										disabled={disabled}
										// {...form.getInputProps(`variations`)}
										onChange={(e) =>
											handleVisibleChange(
												v.name,
												o.label,
												e.currentTarget.checked
											)
										}
									/>
								)}
							</td>
						</tr>
					))}
					<tr></tr>
				</tbody>
			</Table>
			<Space h={"xl"} />
		</span>
	);

	return (
		<>
			{/* variations: {JSON.stringify(variations)}<br></br> */}
			{/* selected:  {JSON.stringify(selected)} <br></br> */}

			{/* <ReactJson src={selected} style={{background:'white'}} collapsed/>
            <ReactJson src={selectRef.current} style={{background:'white'}} collapsed/> */}

			{renderForm()}

			{variations?.length === 0 && <Badge color={"red"}> No variations</Badge>}
			{variations?.length > 0 && variations?.map((v) => VariationTable(v))}

			{!disabled && (
				<>
					{
						<Title order={6} mt={50}>
							Add Variations
						</Title>
					}
					{<Divider mt={10} mb={30} />}

					{
						<Grid justify={"space-between"}>
							<Grid.Col span={8}>
								<RemoteSelect
									ref={selectRef}
									apiEntity="productVariation"
									valueField="_id"
									labelField="name"
									searchFields={["name"]}
									clearable={true}
									// onChange={(c) => console.log(c)}
									onDataChange={(c) => {
										// console.log(c)
										setSelected(c);
									}}
								/>
							</Grid.Col>
							<Grid.Col span={2}>
								<Button disabled={!selected} onClick={handleInsertVariation}>
									Insert
								</Button>
							</Grid.Col>
							<Grid.Col span={2}>
								<Button disabled={selected} onClick={handleAddVariation}>
									New
								</Button>
							</Grid.Col>
						</Grid>
					}
				</>
			)}
		</>
	);
};
