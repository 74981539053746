import React from "react";

import AllowancePageStats from "../../components/allowancePageStats";
import { useCellRender } from "../../hooks/useCellRender";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";

import ActivityLog from "../../components/activityLog";
import LicenceCheckList from "../../components/licenceCheckList";
import ConsultantCertificateList from "../../components/consultantCertificateList";
import OrderConsultantList from "../../components/orderConsultantList";
import { UserCard } from "../../components/consultantList";
import AllowanceActionList from "../../components/allowanceActionList";
import AllowancePlanDetail from "../../components/allowancePlanDetail";
import AllowanceBidsList from "../../components/allowanceBidsList";

import UserOrderList from "../../components/userOrderList";

import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

const apiEntity = "allowance";

const AllowanceList = ({}) => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "user.name",
        headerName: "User",
        sortable: true,
        cellRender: (col, allowance) => cellRender.User(allowance.user),
      },

      {
        field: "plan.code",
        headerName: "Plan Code",
        sortable: true,
        // cellRender: (col, allowance) =>
        //     cellRender.Allowance.name(allowance),
      },
      {
        field: "name",
        headerName: "Allowance Name",
        sortable: true,
        cellRender: (col, allowance) => cellRender.Allowance.name(allowance),
      },

      // {   field: 'followup', sortable: true,   },

      {
        field: "startDate",
        headerName: "Start at",
        sortable: true,
        cellRender: (col, allowance) =>
          cellRender.Date(allowance.startDate, "LLL"),
      },

      {
        field: "status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        // actionMenu: [
        //     {
        //         name: "delete",
        //         label: "Delete data",
        //         icon: IconTrash,
        //         props: {
        //             color: "red",
        //         },
        //     },
        // ],
      },
    ],

    searchableFields: ["code", "status"],
  };

  const formSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.INSIDE,
    },

    title: "Allowance",
    apiEntity,
    initialValues: {},

    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "main-g1",
              props: {
                span: 3,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 9,
              },
            },
          ],
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Allowance",
            color: "grey",
          },
          tab: [
            {
              key: "tab-allowance",
              label: "Allowance",
              icon: IconSettings,
            },
            {
              key: "tab-order",
              label: "Orders",
              icon: IconSettings,
            },
            {
              key: "tab-licences",
              label: "Licences",
              icon: IconSettings,
            },

            {
              key: "tab-bids",
              label: "Bids",
              icon: IconSettings,
            },

            { key: "tab-logs", label: "Logs", icon: IconSettings },
          ],
        },

        {
          key: "section-licences",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Licences Application",
            description: "Insurance Authority Application",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Consultant",
            minHeight: 0,

            mt: 0,
            mb: 0,
          },
        },
        {
          key: "section-order",
          parent: "tab-order",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Orders",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-certificate",
          parent: "tab-licences",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "IIQE Certificate",
            description: "IIQE Certification Records",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },
        {
          key: "section-bids",
          parent: "tab-bids",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Bids",
            description: "Allowance Bids",
            withBorder: false,
            ml: 0,
            mt: 20,
          },
        },
        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Activity Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-action",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Actions",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
            minHeight: 150,
          },
        },
      ],
      fields: [
        {
          name: "logs",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },

        {
          name: "user",
          component: UserCard,
          parent: "section-consultant",
          props: {},
        },

        {
          name: "plan",
          component: AllowancePlanDetail,
          parent: "tab-allowance",
          props: {},
        },

        {
          name: "user.consultantInfo.licences",
          component: LicenceCheckList,
          parent: "section-licences",
          props: {
            readOnly: true,
          },
        },
        {
          name: "user.consultantInfo.certificates",
          component: ConsultantCertificateList,
          parent: "section-certificate",
          props: {
            readOnly: true,
          },
        },

        {
          name: "user",
          component: UserOrderList,
          parent: "section-order",
          props: {
            readOnly: true,
          },
        },
        {
          name: "actions",
          component: AllowanceActionList,
          parent: "section-action",
          props: {},
        },

        {
          name: "bids",
          component: AllowanceBidsList,
          parent: "section-bids",
          props: {},
        },
      ],
    },
  };

  return (
    <PageList
      title={"Allowance"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      showAddButton={false}
      initSort={{ by: "createdAt", order: "desc" }}
      stats={{
        component: AllowancePageStats,
        props: {
          mb: 35,
        },
      }}
    />
  );
};

export default AllowanceList;
