import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Title,
  Text,
  Grid,
  Group,
  Badge,
  Avatar,
} from "@mantine/core";
import { UserCard } from "./consultantList";

import { IconCircleCheck, IconCross } from "@tabler/icons";
import { useCellRender } from "../hooks/useCellRender";

import { FormSectionCard } from "../layout/page";
import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";

const OrderConsultantCard = ({
  form,
  consultant,
  product,
  isSplit,
  index,
  isPrimary,
  clientTrans,
}) => {
  // const title = getTitle();
  const [cellRender] = useCellRender();
  const [api] = useServerApi();
  const [hasLicence, setHasLicence] = useState(true);
  const [title, setTitle] = useState("");

  const getTitle = () => {
    if (!consultant) return "";
    if (consultant.userType == "CHANNEL") {
      return "Channel";
    }

    if (!hasLicence) return "Refer";
    return "Consultant";
  };
  const checkLicences = async () => {
    try {
      // console.log("checkLicences", consultant._id, product.type._id);
      if (!consultant || !consultant._id) return;
      let result = await api.User.checkLicence(
        consultant._id,
        product?.type._id
      );
      // console.log("checkLicences", result);
      setHasLicence(result.hasLicence ?? true);
      // console.log(getTitle());
      setTitle(getTitle());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkLicences();
  }, [consultant?._id]);

  return (
    <FormSectionCard
    // title={title}
    >
      <Grid mb={"md"} justify="space-between">
        <Group spacing={3}>
          {isPrimary ? (
            <Badge size="xs" color={"green"} radius={0}>
              Primary
            </Badge>
          ) : (
            <Badge size="xs" color={"red"} radius={0}>
              Secondary
            </Badge>
          )}
          {clientTrans ? (
            <Badge size="xs" radius={0} color="red">
              Transfer
            </Badge>
          ) : (
            <Badge size="xs" radius={0}>
              Direct
            </Badge>
          )}
        </Group>

        <Group spacing={3}>
          {isSplit && (
            <Badge size="xs" radius={0}>
              Split {index + 1}
            </Badge>
          )}

          {!hasLicence && (
            <Badge
              color={"blue"}
              size="xs"
              radius={0}
              // leftSection={
              //     <IconCross size={18} style={{ paddingTop: 5 }} />
              // }
            >
              Referer
            </Badge>
          )}

          {hasLicence && (
            <Badge
              color={"green"}
              size="xs"
              radius={0}
              leftSection={
                <IconCircleCheck size={18} style={{ paddingTop: 5 }} />
              }
            >
              Licences
            </Badge>
          )}
        </Group>
      </Grid>

      <UserCard value={consultant} />
    </FormSectionCard>
  );
};

const OrderConsultantList = ({ form }) => {
  const { consultants, product, client } = form.values;

  return (
    <>
      {/* <ReactJson src={form.values} collapsed style={{ background: "white" }} /> */}
      {consultants &&
        consultants.map((consultant, index) => (
          <OrderConsultantCard
            key={index}
            index={index}
            consultant={consultant}
            product={product}
            isSplit={consultants.length > 1}
            isPrimary={index == 0}
            clientTrans={client?.isTransfer}
          />
        ))}
    </>
  );
};

export default OrderConsultantList;
