import React, { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  Timeline,
  Text,
  Avatar,
  TextInput,
  Group,
  ActionIcon,
  ScrollArea,
  List,
  Badge,
  Grid,
  Button,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleMinus,
  IconCircleX,
  IconTrash,
} from "@tabler/icons";
import moment from "moment";
import ReactJson from "react-json-view";
import { useAuthUser } from "react-auth-kit";

const OrderPendingCase = ({
  form,
  name,
  title,

  scrollHeight = 300,
  ...props
}) => {
  const [allowInput, setAllowInput] = useState(false);
  const pendingcases = _.get(form.values, name) || [];
  const ref = useRef(null);
  const auth = useAuthUser();
  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";

  useEffect(() => {
    if (
      userRoleName === "GI OPERATION" ||
      userRoleName === "OPERATION" ||
      userRoleName === "MANAGEMENT"
    )
      setAllowInput(true);
  }, [userRoleName]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      pendingcases.push({
        description: e.target.value,
        createdAt: moment().format("YYYY-MM-DD"),
        completed: false,
      });
      form.updateAndSave({ [name]: pendingcases });
      ref.current.value = "";
    }
  };

  const remove = (index) => {
    console.log(pendingcases, index);
    // pendingcases.findIndex();
    pendingcases.splice(index, 1);
    form.updateAndSave({ [name]: pendingcases });
    ref.current.value = "";
  };

  const completeItem = (index) => {
    const c = pendingcases[index];
    if (!c) return;
    c.completed = !c.completed;
    form.updateAndSave({ [name]: pendingcases });
  };

  const incompleteCases = pendingcases.filter((c) => !c.completed);
  return (
    <div id="pendingSection">
      {/* entityId:{userRoleName} */}
      {/* <ReactJson src={pendingcases} style={{ background: "white" }} /> */}
      {allowInput && <TextInput ref={ref} mb={20} onKeyDown={handleKeyDown} />}
      <Text size={"xs"} mb="xl">
        {`${incompleteCases.length} case${
          incompleteCases.length > 1 ? "s" : ""
        } ${incompleteCases.length > 1 ? "are" : "is"} pending.`}
      </Text>
      {!_.isEmpty(pendingcases) && (
        <>
          {pendingcases.map((c, index) => (
            <Grid col>
              <Grid.Col span={9}>
                <Group noWrap>
                  <Badge
                    size="xs"
                    color={c.completed ? "green" : "red"}
                    radius={0}
                  >
                    {c.completed ? "DONE" : "PENDING"}
                  </Badge>
                  <Text size={"xs"} td={c.completed ? "line-through" : ""}>
                    {c?.createdAt
                      ? `[ ${moment(c?.createdAt).format("ll")} ]`
                      : ""}{" "}
                    {c?.description}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={2}>
                <Group spacing={2}>
                  {allowInput && (
                    <ActionIcon
                      mx={"sm"}
                      size={15}
                      color="dark"
                      onClick={() => remove(index)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  )}

                  <ActionIcon
                    mx={"sm"}
                    size={15}
                    color="dark"
                    onClick={() => completeItem(index)}
                  >
                    {!c.completed && <IconCircleCheck />}
                    {c.completed && <IconCircleX />}
                  </ActionIcon>
                </Group>
              </Grid.Col>
            </Grid>
          ))}
        </>
      )}
    </div>
  );
};

export default OrderPendingCase;
