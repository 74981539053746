import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import LocalPageList from "./localPageList";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings, IconTrash, IconPencil } from "@tabler/icons";
import RemoteSelect2 from "../components/remoteSelect2";
import { DatePicker } from "@mantine/dates";
import AllowanceDetails from "../components/allowanceDetails";
import { PageList } from "./pageList";
import { useFormatter } from "../hooks/useFomatter";
import moment from "moment";
import ActionCellRender from "./actionCellRender";
import { Button, Group, NumberInput, TextInput, Text } from "@mantine/core";
import ReactJson from "react-json-view";
import FormConfirmation from "./formConfirmation";

const ConsultantOverridingPlans = ({ form: mainForm, name, ...props }) => {
  const userId = _.get(mainForm.values, "_id");
  const [rows, setRows] = useState([]);
  const [formatter] = useFormatter();

  const initRowData = async () => {
    if (!userId) return;
    setRows(mainForm.values?.consultantInfo?.overridingPlans ?? []);
  };

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "plan",
        headerName: "Plan Name",
        cellRender: (col, data) => data.plan?.name,
      },

      {
        field: "plan",
        headerName: "Description",
        cellRender: (col, data) => data.plan?.desc,
      },

      {
        field: "startDate",
        headerName: "From",
        cellRender: (col, data) =>
          data.startDate ? moment(data.startDate).format("LL") : "",
      },

      {
        field: "endDate",
        headerName: "To",
        cellRender: (col, data) =>
          data.endDate ? moment(data.endDate).format("LL") : "",
      },
      {
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            label: "Edit",
            icon: IconPencil,
            props: {},
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {},
          },
        ],
        actionMenu: [],
      },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };

  const formSchema = {
    title: "Overriding Plans History",
    description: "Please fill in the form",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    initialValues: {
      // fromDate: "",
      // toDate: "",
    },

    validate: {
      startDate: (value) => (!value ? "Please select start date" : null),
      endDate: (value) => (!value ? "Please select end date" : null),
      plan: (value) => (!value ? "Please select overriding plan" : null),
    },

    layout: {
      containers: [
        {
          key: "basicInfo-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 8,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 4,
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: "startDate",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",

          props: {
            required: true,
            label: "From",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "endDate",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",

          props: {
            required: true,
            label: "To",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },
        {
          name: "plan",
          component: RemoteSelect2,
          parent: "basicInfo-g1",
          props: {
            label: "Overriding Plan",
            apiEntity: "commissionOverridingPlan",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            sort: {
              by: "name",
              order: "asc",
            },
            onDataChange: (value, f) => {
              // console.log("onDataChange", value);
              f.setFieldValue("plan", value);
            },
          },
        },
      ],
    },
  };

  const onFormSubmit = (values) => {
    console.log("consulant overiding plans onFormSubmit", values);
    const r = values.filter((v) => {
      return !_.isEmpty(v);
    });

    setRows(r); //Update UI
    mainForm.setFieldValue("consultantInfo.overridingPlans", r);
  };

  useEffect(() => {
    initRowData();
  }, [userId]);

  return (
    <>
      {/* <ReactJson src={rows} style={{ background: "white" }} /> */}
      <Text size={"xs"} mt="xl">
        Overriding History
      </Text>
      <Text size={"xs"} color="dimmed">
        Old overriding plans will be executed within the signed dates.
      </Text>
      <LocalPageList
        rows={rows}
        onFormSubmit={onFormSubmit}
        tableSchema={tableSchema}
        formSchema={formSchema}
        {...props}
      />
    </>
  );
};

export default ConsultantOverridingPlans;
