import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
	Button,
	TextInput,
	Grid,
	Table,
	Text,
	ActionIcon,
	Group,
	Textarea,
} from "@mantine/core";
import ReactJson from "react-json-view";
import { useForceUpdate } from "@mantine/hooks";
import {
	IconCircleMinus,
	IconCirclePlus,
	IconExchange,
	IconMultiplier1x,
	IconTrash,
} from "@tabler/icons";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { Component } from "ag-grid-community";
import { useForm } from "@mantine/form";

const Row = ({
	data,
	onRemoveRow,
	onInsertRow,
	index,
	fields,
	onDataChange,
}) => {
	return (
		<tr valign="top" width="100%">
			<td>
				<Text size={"xs"}> {index + 1}</Text>
			</td>

			{fields.map((f) => (
				<td>
					<f.component
						value={_.get(data, f.fieldName)}
						onChange={(e) => {
							const newV = e?.currentTarget ? e.currentTarget.value : e;
							_.set(data, f.fieldName, newV);
							onDataChange(index, data);
						}}
						{...f.props}
					/>
				</td>
			))}

			<td>
				<Group ml={"xl"} spacing={5}>
					<ActionIcon size="xs" onClick={() => onRemoveRow(index)}>
						<IconCircleMinus />
					</ActionIcon>
					<ActionIcon size="xs" onClick={() => onInsertRow(index)}>
						<IconCirclePlus />
					</ActionIcon>
				</Group>
			</td>
		</tr>
	);
};

const ArrayTable = ({ form, name, rowInitValue, fields, onDataChange }) => {
	const [arrayValues, setArrayValues] = useState([]);
	const formValue = _.get(form.values, name);

	// const forceUpdate = useForceUpdate();

	const addRow = () => {
		setArrayValues([...arrayValues, { ...rowInitValue }]);
	};

	const removeRow = (index) => {
		const d = [...arrayValues];
		d.splice(index, 1);
		setArrayValues(d);
	};

	const insertRow = (index) => {
		const d = [...arrayValues];
		d.splice(index, 0, {
			...rowInitValue,
		});
		setArrayValues(d);
	};

	const dataChange = (index, data) => {
		const d = [...arrayValues];
		d.splice(index, 1, data);
		setArrayValues(d);
		if (onDataChange) return onDataChange(d);
	};

	useEffect(() => {
		// console.log(formValue);
		if (_.isEmpty(formValue)) return;
		setArrayValues(formValue);
	}, [formValue]);

	useDeepCompareEffect(() => {
		form.setFieldValue(name, arrayValues);
		// forceUpdate();
	}, [arrayValues]);

	const ths = (
		<tr>
			<th width="5%" align="left">
				<Text size="xs">#</Text>
			</th>
			{fields.map((f) => (
				<th width={f.headerWidth} align="left">
					<Text size="xs">{f.header}</Text>
				</th>
			))}
			<th width="10%"></th>
		</tr>
	);

	return (
		<>
			<Table style={{ width: "100%" }}>
				<thead>{ths}</thead>
				<tbody>
					{arrayValues?.map((v, index) => (
						<Row
							key={index}
							data={v}
							index={index}
							onRemoveRow={removeRow}
							onInsertRow={insertRow}
							onDataChange={dataChange}
							fields={fields}
						/>
					))}
				</tbody>
			</Table>

			<Button size="xs" onClick={() => addRow()} mt="xl">
				Add
			</Button>
		</>
	);
};

export default ArrayTable;
