import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import { useCellRender } from "../hooks/useCellRender";
import InfoGridList from "./infoGridList";
const MyTeamMemberDetailPage = ({ memberId }) => {
	const [api] = useServerApi();
	const [fields, setFields] = useState({});
	const [user, setUser] = useState({});
	const [cellRender] = useCellRender();

	const fetchData = async () => {
		const user = await api.getById({ apiEntity: "user", id: memberId });

		const fields = [
			{ title: "English Name", value: user.name } ?? "--",
			{ title: "Chinese Name", value: user.cname } ?? "--",
			{ title: "Alias Name", value: user.aliasName } ?? "--",
			{ title: "Work Title", value: user.workTitle } ?? "--",

			{ title: "Gender", value: user.gender } ?? "--",
			{ title: "Contact Number", value: user.phone } ?? "--",
			{ title: "Contact Number 2", value: user.phone2 } ?? "--",

			{ title: "Email", value: user.email } ?? "--",

			{
				title: "Date of Birth",
				value: cellRender.Date(user.birthday, "LL") ?? "--",
			},

			{ title: "Education", value: user.educationLevel ?? "--" },
			{ title: "Emergency Contact", value: user.emergencyContact ?? "--" },
			{ title: "Emergency ContactNo", value: user.emergencyContactNo ?? "--" },

			{ title: "Status", value: user.status ?? "--" },

			{
				title: "Employment Date",
				value: cellRender.Date(user.employmentDate, "LL", 100),
			},
			// {
			// 	title: "Last Login",
			// 	value: cellRender.Date(user.lastLogin, "LLL", 100),
			// },
		];
		setFields(fields);
		setUser(user);
	};

	useEffect(() => {
		fetchData();
	}, [memberId]);
	return (
		<>
			<InfoGridList fields={fields} values={user} p={"xl"} />
			{/* {memberId} */}
		</>
	);
};

export default MyTeamMemberDetailPage;
