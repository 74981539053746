import React, { useEffect, useState, useRef } from "react";
import { Text, Box } from "@mantine/core";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";
import moment from "moment";

const PolicyFundDetails = ({ order, size = "xs" }) => {
  return (
    <FormSectionCard title={"Policy Details"}>
      {/* <ReactJson src={order} /> */}
      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Client
        </Text>
        <Text size={size}>{order.client?.name ?? ""}</Text>
        {/* </> */}
      </Box>

      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Principal
        </Text>
        <Text size={size}>{order.product.provider?.name ?? ""}</Text>
      </Box>

      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Product Name
        </Text>
        <Text size={size}>{order.product.name ?? ""}</Text>
      </Box>

      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Terms of Year
        </Text>
        <Text size={size}>{order.payment?.paymentTerm ?? ""}</Text>
      </Box>
      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Policy Number
        </Text>
        <Text size={size}>{order.policyNumber ?? ""}</Text>
      </Box>
      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Commence. Date
        </Text>
        <Text size={size}>
          {order.policyCommenceDate
            ? moment(order.policyCommenceDate).format("YYYY-MM-DD")
            : ""}
        </Text>
      </Box>
      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Primary Consultant
        </Text>
        <Text size={size}>{order.consultants[0]?.name ?? ""}</Text>
      </Box>
      <Box mb={"xs"}>
        <Text size={size} color={"dimmed"}>
          Secondary Consultant
        </Text>
        <Text size={size}>{order.consultants[1]?.name ?? ""}</Text>
      </Box>
      <Box>
        <Text size={size} color={"dimmed"}>
          Status
        </Text>
        <Text size={size}>{order.status}</Text>
      </Box>
    </FormSectionCard>
  );
};

export default PolicyFundDetails;
