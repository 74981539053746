import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderSubmissionCheckList from "./orderSubmissionCheckList";
import OrderSubmissionConfirm from "./orderSubmissionConfirm";
import { showNotification } from "@mantine/notifications";

import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import { DatePicker } from "@mantine/dates";
const OrderPrintSubmissionCoverSheetActionButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);

  const formSchema = {
    title: "Print Submit Cover Sheet",
    description: "Please follow the step to print the cover sheet",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Submission Date",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-0", label: "Submission Date", icon: IconSettings },
            { key: "t1-1", label: "Checklist", icon: IconSettings },
            { key: "t1-2", label: "Confirm", icon: IconSettings },
          ],
        },
        // {
        // 	key: "section-date",
        // 	parent: "t1-0",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		// title: "Submission Date",
        // 		description:
        // 			"Please make sure the below documents are ready before submit to provider",
        // 		withBorder: false,
        // 		mt: 0,
        // 		ml: 20,
        // 	},
        // },
        {
          key: "section-checklist",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Document CheckList",
            description:
              "Please make sure the below documents are ready before submit to provider",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm Submission",
            description: "Please confirm the order submission to provider",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],
      fields: [
        {
          name: "submissionDate",
          component: DatePicker,
          parent: "t1-0",
          type: "datePicker",
          props: {
            required: true,
            label: "Submission Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "checkList",
          component: OrderSubmissionCheckList,
          parent: "section-checklist",
          props: { internal: false },
        },

        {
          name: "checkList",
          component: OrderSubmissionConfirm,
          parent: "section-confirm",
          props: { internal: false },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // values.status = "SUB";
    // if (!_.isEmpty(values.riders)) {
    //   values.riders.forEach((r) => (r.status = "SUB"));
    // }

    // mainForm.setValues({ ...values });
    // await mainForm.saveValues(values);
    printCoverSheet(values);
    // formAction.close();
  };

  const printCoverSheet = async (values) => {
    try {
      const list = values.product.checkList;
      setLoading(true);

      // console.log("Print Cover Sheet", list);
      const checkList = {
        provider: list
          .filter((item) => item.provider)
          .map((item) => ({
            name: item.name,
            checked: item.checked,
            optional: item.optional,
            _id: item._id,
          })),
      };
      formAction.close();

      let result = await api.Order.printSubmissionCoverSheet(
        values._id,
        checkList,
        "provider",
        values.submissionDate
      );

      setLoading(false);

      if (result && result.url) {
        window.open(result.url, "_blank");
        formAction.close();

        mainForm.setFieldValue("status", result.order.status);
        mainForm.setFieldValue("submissionDate", result.order.submissionDate);
        mainForm.setFieldValue("riders", result.order.riders);
        mainForm.setFieldValue("submitCheckList", result.order.submitCheckList);
        showNotification({
          title: `${values?.code} Submitted Successfully`,
          message: `Order submitted.`,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    console.log(
      "%corderPrintCoverSheetActionButton.js line:86 object",
      "color: #007acc;",
      id
    );
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick} loading={loading}>
        {loading ? "Printing" : "Print Submit Cover Sheet"}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderPrintSubmissionCoverSheetActionButton;
