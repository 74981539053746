import React, { useState } from "react";
import { Button, Checkbox, Group, List, Title } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";

import { useDisclosure } from "@mantine/hooks";

import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark } from "@tabler/icons";
import ConfirmationModal from "./confirmationModal";

const QuotationConfirmButton = ({ mainForm }) => {
	const [api] = useServerApi();
	const { _id: id, code } = mainForm.values;
	const [opened, { open, close }] = useDisclosure(false);

	const confirm = async () => {
		try {
			const result = await api.Quotation.confirm(id);
			close();

			if (result.success) {
				mainForm.setFieldValue("status", "CONFIRM");
				showNotification({
					title: `Quotation Confirmed`,
					color: "Green",
					icon: <IconCircleCheck size={18} />,
					message: "This quotation is confirmed.",
				});
			} else {
				throw result.error;
			}
		} catch (error) {
			showNotification({
				title: `Confirm Error`,
				color: "red",
				icon: <IconExclamationMark size={18} />,
				message: error,
			});
		}
	};

	return (
		<>
			<ConfirmationModal
				opened={opened}
				onClose={close}
				withCloseButton={false}
				size="lg"
				title={`Are you ready to confirm the quotation ${code}?`}
				confirmList={[
					"Please make sure the quotation details are correct and agreed by client.",
					" Debit Note will be created once confirmed.",
				]}
				onConfirm={confirm}
			/>

			<Button fullWidth color={"green"} onClick={open}>
				Confirm Quotation
			</Button>
		</>
	);
};

export default QuotationConfirmButton;
