import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  const total = charges.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const QuotationCostItems = ({ form, name }) => {
  const costItems = _.get(form.values, "costItems");
  const chargeItems = _.get(form.values, "chargeItems");
  // const commissionRate = _.get(form.values, "commissionRate")
  const forceUpdate = useForceUpdate();

  const [total, setTotal] = useState(0);
  const [commissionRate, setCommissionRate] = useState(
    _.get(form.values, "commissionRate")
  );

  const [formatter] = useFormatter();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: TextInput,
      props: {},
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    {
      header: "Currency",
      headerWidth: "12%",
      fieldName: "currency",
      component: Select,
      props: {
        data: DATA_CURRENCY,
        defaultValue: "HKD",
        searchable: true,
      },
    },
    {
      header: "Unit Price",
      headerWidth: "30%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        parser: formatter.currencyParser,
        formatter: formatter.currencyFormat,
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(costItems);
  }, [costItems]);

  useEffect(() => {
    form.setFieldValue("costTotal", total);
  }, [total]);

  const applyCommissionRate = () => {
    console.log("commissionRate", commissionRate, chargeItems);

    const premimumItem = chargeItems.find((c) => c.item === "Premium");
    if (!premimumItem) return;

    const price = premimumItem.unitPrice * premimumItem.qty;

    const costItem = costItems.find(
      (c) => c.item === "Net Premium" || c.item === "Premium"
    );
    if (!costItem) return;

    costItem.unitPrice = premimumItem.unitPrice * (1 - commissionRate * 0.01);
    costItem.qty = premimumItem.qty;
    costItem.price = costItem.unitPrice * costItem.qty;

    form.setFieldValue("commissionRate", commissionRate);
    console.log("costItem", costItem, commissionRate);

    forceUpdate();
  };
  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <Group align={"end"} mb="xl">
        <NumberInput
          precision={2}
          label="Commission Rate(%)"
          placeholder="30"
          value={_.get(form.values, "commissionRate")}
          onChange={setCommissionRate}
        ></NumberInput>

        <Button size="xs" color="green" onClick={() => applyCommissionRate()}>
          Apply
        </Button>
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group position="right">
        <Group>
          <Title order={3}>{formatter.currency(total)}</Title>
        </Group>
      </Group>
    </>
  );
};

export default QuotationCostItems;
