import React, { useEffect, useState } from "react";
import {
  Title,
  Text,
  Box,
  Grid,
  Paper,
  Group,
  Avatar,
  Badge,
  Select,
} from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons";

import { FormSectionCard } from "../layout/page";
import { UserCard } from "./consultantList";
import { useAuthUser } from "react-auth-kit";
import { Carousel } from "@mantine/carousel";
import { useServerApi } from "../hooks/userServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import _ from "lodash";
import { PeriodFilter } from "./periodFilter";

import moment from "moment";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";
import { DATA_RANK_BY } from "../data/options";

const EmptyRankCard = ({ rank }) => {
  return (
    <Group w={"100%"} mih={80}>
      <Title order={5} mr="xl" ml="md">
        {rank}
      </Title>
      <Group>
        <Text> --- </Text>
      </Group>
    </Group>
  );
};
const RankUserCard = ({ user, rank, rankBy, rankValue }) => {
  const { image, name, workTitle, aliasName, cname, phone, email } = user;
  const [formatter] = useFormatter();
  const r = DATA_RANK_BY.find((rb) => rb.value === rankBy);
  const value =
    rankBy === "BY_CASE" ? rankValue : formatter.currency(rankValue);
  return (
    <Group w={"100%"} noWrap>
      <Title order={5} mr="xl" ml="md">
        {rank}
      </Title>
      <Group noWrap>
        <Avatar
          src={`${process.env.REACT_APP_FILE_URL}/${image}`}
          size={80}
          radius="md"
        />
        <div>
          <Text
            size="xs"
            sx={{ textTransform: "uppercase" }}
            color="dimmed"
            lineClamp={1}
          >
            {workTitle}
          </Text>

          <Title order={6}>
            {`${aliasName} ${name ? name?.split(" ")[0] : ""}`} {`${cname}`}
          </Title>

          {/* <Group position="apart"> */}
          <Text size="xs" sx={{ textTransform: "capitalize" }} color="dimmed">
            {r?.shortName}: {value}
          </Text>

          {/* </Group> */}
        </div>
      </Group>
    </Group>
  );
};

const getRankValue = (rankBy, data) => {
  switch (rankBy) {
    case "BY_CASE":
      return data.count;
    case "BY_FYP":
      return data.amount;
    case "BY_FYCC":
      return data.amount2;
  }
};

const RankingCard = ({ ranking }) => {
  const auth = useAuthUser();
  const { name, rankBy, rankNumber, data } = ranking;
  return (
    <>
      {/* <ReactJson src={ranking} style={{ background: "white" }} /> */}
      <Title transform="uppercase" order={6} mb="xs">
        {name}
      </Title>
      <Paper shadow="md" p={"xs"} radius={0}>
        <Group spacing={"xl"} my="xl">
          {_.range(0, rankNumber).map((n) => {
            const d = data[n];
            if (d) {
              return (
                <RankUserCard
                  key={n}
                  user={d.consultant}
                  rank={n + 1}
                  rankBy={rankBy}
                  rankValue={getRankValue(rankBy, d)}
                />
              );
            } else {
              return <EmptyRankCard key={n} rank={n + 1} />;
            }
          })}
          {/* {data &&
					data?.map((d, index) => (
						<RankUserCard
							user={d.consultant}
							rank={index + 1}
							rankBy={rankBy === "BY_CASE" ? "Case" : "FY Preimum"}
							rankValue={rankBy === "BY_CASE" ? d.count : d.amount}
						/>
					))} */}
        </Group>
      </Paper>
    </>
  );
};
const TopRankSection = ({
  title,
  cardWidth = "300px",
  showOnHomepage = true,
  ...props
}) => {
  const [api] = useServerApi();
  const [periodFilterOptions, setPeriodFilterOptions] = useState([]);
  const [q, setQ] = useState(0);
  const [period, setPeriod] = useState(null);
  const numQuarters = 4;
  const [rankings, setRankings] = useState([]);

  const fetchRankings = async () => {
    const data = await api.TopRanking.getStats(period, showOnHomepage);
    console.log("Fetch rank data", data);
    setRankings(data ?? []);
  };

  const setPeriodOptions = () => {
    const filters = [];
    for (let i = 0; i < numQuarters; i++) {
      const d = moment().subtract(i, "Q");
      filters.push({ label: d.format("YYYY-[Q]Q"), value: i });
    }
    setPeriodFilterOptions(filters);
  };

  const handlePeriodChange = (v) => {
    setQ(v);
  };

  useEffect(() => {
    const startDate = moment()
      .subtract(q + 1, "Q")
      .endOf("quarter")
      .format("YYYY-MM-DD");

    const endDate = moment()
      .subtract(q, "Q")
      .endOf("quarter")
      .format("YYYY-MM-DD");

    setPeriod({ startDate, endDate });
  }, [q]);

  useEffect(() => {
    setPeriodOptions();
  }, []);

  useDeepCompareEffect(() => {
    fetchRankings();
  }, [period]);

  return (
    !_.isEmpty(rankings) && (
      <FormSectionCard title={title} withPaper={false} {...props}>
        <Group position="left" mb={"xl"}>
          {/* <Select
					size={"xs"}
					data={periodFilterOptions}
					value={q}
					onChange={handlePeriodChange}
					defaultValue={0}
				></Select> */}
          <PeriodFilter
            // handleRefresh={() => fetchOrderStats()}
            handlePeriodChange={(p) => setPeriod(p)}
            numPeriods={numQuarters}
          />
        </Group>

        <Carousel
          withIndicators
          height={"100%"}
          slideSize="33.333333%"
          slideGap="xl"
          loop={false}
          align="start"
          breakpoints={[
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
          ]}
        >
          {/* <Box style={{ width: cardWidth, marginLeft: gap }}> */}
          {!_.isEmpty(rankings) &&
            rankings?.map((r) => (
              <Carousel.Slide w={cardWidth} key={r._id}>
                <RankingCard ranking={r} />
              </Carousel.Slide>
            ))}
        </Carousel>
      </FormSectionCard>
    )
  );
};

export default TopRankSection;
