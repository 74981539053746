import React, { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import cx from "clsx";
import classes from "./DndListHandle.module.css";

import {
  Timeline,
  Text,
  Avatar,
  TextInput,
  Group,
  ActionIcon,
  ScrollArea,
  Box,
  Grid,
  List,
} from "@mantine/core";
import {
  IconCircleMinus,
  IconTrash,
  IconGripVertical,
  IconPencil,
} from "@tabler/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useForceUpdate, useListState } from "@mantine/hooks";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const DragList = ({
  form,
  name,
  label,
  scrollHeight = 300,
  onEnterSave = true,
  ...props
}) => {
  const remarks = _.get(form.values, name) || [];
  const [items, setItems] = useState(
    remarks.map((r, index) => ({
      id: `item-${index}`,
      content: r,
      edit: false,
    }))
  );
  const ref = useRef(null);
  const forceUpdate = useForceUpdate();

  useDeepCompareEffect(() => {
    setItems(
      remarks.map((r, index) => ({
        id: `item-${index}`,
        content: r,
        edit: false,
      }))
    );
  }, [remarks]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      remarks.push(e.target.value);
      ref.current.value = "";
      if (onEnterSave) form.updateAndSave({ [name]: remarks });
      else form.setFieldValue(name, remarks);
      setItems(
        remarks.map((r, index) => ({
          id: `item-${index}`,
          content: r,
          edit: false,
        }))
      );
    }
  };

  const remove = (index) => {
    // remarks.findIndex();
    remarks.splice(index, 1);
    form.updateAndSave({ [name]: remarks });
    ref.current.value = "";
    setItems(
      remarks.map((r, index) => ({
        id: `item-${index}`,
        content: r,
        edit: false,
      }))
    );
  };

  const edit = (index) => {
    items[index].edit = !items[index].edit;
    forceUpdate();
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    form.setFieldValue(
      name,
      newItems.map((i) => i.content)
    );
  };

  return (
    <Box {...props}>
      {/* entityId:{entityId} */}
      {label && (
        <Text size={props.size ?? "xs"} mb="xs">
          {label}
        </Text>
      )}
      <TextInput ref={ref} mb={20} onKeyDown={handleKeyDown} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={`item-${index}`}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid columns={24}>
                        <Grid.Col span={1}>
                          <IconGripVertical size={15}></IconGripVertical>
                        </Grid.Col>
                        <Grid.Col span={20}>
                          {!item.edit && (
                            <Text
                              size={"xs"}
                              onClick={() => edit(index)}
                              style={{ cursor: "auto" }}
                            >
                              {item.content}
                            </Text>
                          )}
                          {item.edit && (
                            <TextInput
                              size={"xs"}
                              width={600}
                              value={item.content}
                              fw={true}
                              autoFocus={true}
                              onChange={(e) => {
                                item.content = e.currentTarget.value;
                                forceUpdate();
                              }}
                              onBlur={() => {
                                item.edit = false;
                                forceUpdate();
                              }}
                              onMouseLeave={() => {
                                item.edit = false;
                                forceUpdate();
                              }}
                            />
                          )}
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <ActionIcon
                            mx={"sm"}
                            size={15}
                            color="dark"
                            onClick={() => remove(index)}
                          >
                            <IconCircleMinus />
                          </ActionIcon>
                        </Grid.Col>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* {!_.isEmpty(remarks) && (
        
        <List>
          {remarks.map((remark, index) => (
            <List.Item key={index}>
              <Group position="apart" miw={600}>
                <Text size={"xs"}>{remark}</Text>
                <ActionIcon
                  mx={"sm"}
                  size={15}
                  color="dark"
                  onClick={() => remove(index)}
                >
                  <IconCircleMinus />
                </ActionIcon>
              </Group>
            </List.Item>
          ))}
        </List>
        
      )} */}
    </Box>
  );
};

export default DragList;
