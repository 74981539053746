import { Button, Select, MultiSelect, Box } from "@mantine/core";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
} from "react";
import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";

import _ from "lodash";
import { useForceUpdate } from "@mantine/hooks";
const RemoteSelect = (
  {
    form,
    name,
    label,
    apiEntity,
    sort,
    valueField = "_id",
    labelField,
    labelRender,
    clearable = true,
    searchFields = ["code"],
    preQuery,
    multiple = false,
    onDataChange,
    ...props
  },
  ref
) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [api] = useServerApi();
  const [searchQuery, setSearchQuery] = useState({});
  const forceUpdate = useForceUpdate();

  const pageSize = 20; //max number of data to select once
  const currentPage = 1; //Always display first page

  const singleRef = useRef();
  const multiRef = useRef();

  const fetchData = async () => {
    try {
      let result = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort,
        searchQuery,
      });
      // console.log(`RemoteSelect ${apiEntity}=>Fetch Data Result`, result)
      handleFetchResult(result.docs);
    } catch (e) {
      setOptions([]);
      // console.log(e)
    }
  };

  const getLabel = (data) => {
    if (labelRender) return labelRender(data);
    if (labelField) return _.get(data, labelField);
    return "";
  };

  const handleFetchResult = (rows) => {
    setData(rows);
    let options = rows.map((r) => ({
      value: _.get(r, valueField),
      label: getLabel(r),
    }));

    // console.log("Options", options)

    setOptions(options);
  };

  const buildSearchQuery = (searchText, searchFields) => {
    let searchArr = searchFields?.map((field) => ({
      [field]: { $regex: searchText, $options: "i" },
    }));

    if (!preQuery) return { $or: searchArr };

    return { $and: [preQuery, { $or: searchArr }] };
  };

  const handleSearchChange = (searchText) => {
    let query = buildSearchQuery(searchText, searchFields);
    setSearchQuery(query);
  };

  const handleOnChange = (value) => {
    // console.log("handleOnChange", value)

    if (!_.isEmpty(form) && name) form.setFieldValue(name, value);

    if (_.isFunction(onDataChange))
      onDataChange(data.find((d) => _.get(d, valueField) === value));

    // clearValue();
  };

  const clearValue = () => {
    console.log("clearValue", singleRef);
    singleRef.current.value = null;
    return;
    if (multiRef && multiRef.current) multiRef.current.value = "";
    if (singleRef && singleRef.current) _.set(singleRef.current, "value", "");
  };

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  return (
    <Box ref={ref}>
      {/* {_.get(form?.values, name)} */}
      {/* {  typeof onDataChange} */}
      {/* {JSON.stringify(options)} */}
      {/* <ReactJson src={props} style={{background: 'white'}}/>  */}

      {multiple ? (
        <MultiSelect
          label={label}
          ref={multiRef}
          searchable
          onSearchChange={handleSearchChange}
          data={options}
          value={_.get(form?.values, name)}
          // {...form?.getInputProps(name)}
          // dropdownPosition="bottom"
          allowDeselect
          {...props}
          clearable={true}
          onChange={handleOnChange}
        />
      ) : (
        <>
          <Select
            label={label}
            // ref={singleRef}
            searchable
            onSearchChange={handleSearchChange}
            data={options}
            value={_.get(form?.values, name)}
            // {...form?.getInputProps(name)}
            // dropdownPosition="bottom"
            allowDeselect
            {...props}
            clearable={true}
            onChange={handleOnChange}
          />
        </>
      )}
    </Box>
  );
};

export default React.forwardRef(RemoteSelect);
