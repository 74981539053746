import React, { useState, useEffect, useCallback } from "react";
import { Group, List, Text } from "@mantine/core";
import { NumberInput } from "@mantine/core";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";
import { DatePicker } from "@mantine/dates";
import moment from "moment";

const UserLeaveOpeningBalance = ({ form }) => {
  const { userId, recruiterInstallment } = form.values;
  const [api] = useServerApi();
  const [balance, setBalance] = useState(null);
  const [formatter] = useFormatter();

  const fetchBalance = async () => {
    const result = await api.Wallet.getOpeningBalance(userId);
    if (!result.success) return setBalance(0);
    setBalance(result.data.balance);
  };

  const handleOnChangeInstallment = (num) => {
    form.setFieldValue("recruiterInstallment", num);
  };

  const handleOnChangeInstallmentDate = (d) => {
    form.setFieldValue("firstInstallmentDate", d);
  };

  useEffect(() => {
    if (!userId) return;
    fetchBalance();
  }, [userId]);

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      {balance >= 0 && (
        <List>
          <List.Item>There are no negative opening balance.</List.Item>
        </List>
      )}

      {balance < 0 && (
        <List>
          <List.Item>
            <Text size={"xs"}>
              There are total {formatter.currency(balance)} negative opening
              balance.
            </Text>
          </List.Item>
          <List.Item>
            <Text size={"xs"}>
              50% {formatter.currency(balance * 0.5)} will be transfer to
              company.
            </Text>
          </List.Item>
          <List.Item>
            <Text size={"xs"}>
              50% {formatter.currency(balance * 0.5)} will be transfer to
              recruiter.
            </Text>
          </List.Item>
          <List.Item>
            <div>
              <NumberInput
                label="Recruiter Installment"
                mt="sm"
                size="xs"
                defaultValue={recruiterInstallment}
                onChange={handleOnChangeInstallment}
              />

              <DatePicker
                label="First Installment Date"
                mt="sm"
                size="xs"
                defaultValue={new Date()}
                onChange={handleOnChangeInstallmentDate}
                allowFreeInput={true}
              />
            </div>
          </List.Item>
        </List>
      )}
    </>
  );
};

export default UserLeaveOpeningBalance;
