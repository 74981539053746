import PolicyFundAllocationTable from "./policyFundAllocationTable";
import PolicyFundAllocationPieChart from "./policyFundAllocationPieChart";
import React, { useEffect, useState, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import { useFormatter } from "../hooks/useFomatter";
import _ from "lodash";
import PolicyFundSummary from "./policyFundSummary";
import moment from "moment";

const PolicyFundAllocation = ({ valuations, printing = false }) => {
  const [selected, setSelected] = useState(null);
  const [api] = useServerApi();
  const [pieData, setPieData] = useState([]);
  const [valuation, setValuation] = useState();
  const [formatter] = useFormatter();
  const [options, setOptions] = useState([]);

  const fetchValuationDetail = async () => {
    const result = await api.getById({
      apiEntity: "policyProfolio",
      id: selected,
    });
    setValuation(result);
    const allocationData = result.allocations.map((a) => ({
      id: a.investmentFund,
      label: a.fundCode,
      value: a.allocationRatio,
    }));
    // console.log(allocationData);
    setPieData(allocationData);
  };

  useEffect(() => {
    if (!selected) return;
    fetchValuationDetail();
    // console.log("Selected", selected);
  }, [selected]);

  useEffect(() => {
    if (_.isEmpty(valuations)) return setSelected(null);
    let os = valuations.map((v) => ({
      value: v._id,
      label: moment(v.valuationDate).format("YYYY-MM-DD"),
    }));

    os = _.orderBy(os, ["label"], ["desc"]);

    setOptions(os);
    setSelected(os[0].value);
  }, [valuations]);

  return (
    valuation && (
      <>
        <PolicyFundSummary
          options={options}
          valuation={valuation}
          onChangeValuation={setSelected}
          selected={selected}
        />
        <PolicyFundAllocationTable valuation={valuation} />

        {!printing && <PolicyFundAllocationPieChart data={pieData} />}
      </>
    )
  );
};

export default PolicyFundAllocation;
