import { Switch, Group, Text, Box } from "@mantine/core";
import { useState } from "react";
import _ from "lodash";

import { IconCircleCheck, IconAlertCircle } from "@tabler/icons";
import { FormSectionCard } from "../layout/page";
const LicenceCheckList = ({
  form,
  name,

  label,
  readOnly = false,
  title = "Licences Required",

  ...props
}) => {
  const licences = _.get(form.values, name);

  const allLicences = [
    { name: "l1", label: "I    -  Principles & Practices of Insurance" },
    { name: "l2", label: "II   -  General Insurance" },
    { name: "l3", label: "III  -  Long Term Insurance" },
    { name: "l4", label: "IV   -  MPF" },
    { name: "l5", label: "V    -  Investment Link Long Term Insurance" },
  ];

  // console.log(form)

  return (
    licences && (
      <>
        {/* {JSON.stringify(props, null, 2) } */}
        {/* {JSON.stringify(licences)} */}
        <Text mt="xl" mb="sm">
          {title}
        </Text>
        {allLicences.map((lic) => (
          <Box key={lic.name} mb="sm">
            {!readOnly && (
              <Switch
                key={`licences.${lic.name}`}
                label={lic.label}
                value={licences[lic.name]}
                checked={licences[lic.name]}
                disabled={readOnly}
                {...form.getInputProps(`${name}.${lic.name}`)}
              />
            )}
            {readOnly && (
              <Group>
                {licences[lic?.name] && <IconCircleCheck color="#00b341" />}
                {!licences[lic.name] && <IconAlertCircle color="#ff4500" />}
                <Text> {lic.label}</Text>
              </Group>
            )}
          </Box>
        ))}
      </>
    )
  );
};

export default LicenceCheckList;
