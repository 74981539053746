import React from 'react'
import { Button ,Text, TextInput, Chip, Select} from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import { useFormRender } from '../hooks/useFormRender';
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER} from './pageList';
import { IconExclamationMark, IconSettings } from '@tabler/icons'
import { DATA_COUNTRY, DATA_EDUCATION_LEVEL } from '../data/options';

import RemoteSelect from './remoteSelect';
import ConsultantList from './consultantList'
import FileList from './fileList';
import RemoteSelect2 from './remoteSelect2';

import FormConfirmation from './formConfirmation';
import { useCellRender } from '../hooks/useCellRender';
import { useServerApi } from '../hooks/userServerApi';
import { showNotification } from '@mantine/notifications';
import _ from 'lodash'

const ClientChangeServiceRightButton = ({  mainForm , color }) =>{

    const [cellRender] = useCellRender()
    const [api] = useServerApi()

    const formSchema = {
        title:"Change Client Service Right",
        display:{
            mode: ENUM_FORM_DISPLAY.MODAL,
            size: "xl",     //xs, sm, xl  ....100%                    
        },

        closeAfterSave: true,
        showSaveBar: false,

        layout:{
            containers :[
                {
                    key: "t1",
                    parent: null,
                    type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
                    props: {
                        variant: "default", //default | pills | outline
                        defaultValue: "Assign To",
                        orientation: "vertical",
                        style: { minHeight: "400px" },
                    },
                    steps: [
                        { key: "t1-1", label: "Assign To", icon: IconSettings },
    
                        { key: "t1-2", label: "Files", icon: IconSettings },
                        
                        { key: "t1-3", label: "Confirm", icon: IconSettings },
                    ],
                },
              

                
            ],

            fields:[
                {
                
                    name: "consultants",            
                    component: RemoteSelect2, 
                    parent:"t1-1",
                    props:{
                        apiEntity: 'user',
                        required: true,
                        label: "Please select the new consultants",
                        placeholder: "Assign consultant(s)",
                        valueField: '_id',
                        labelField: 'name',
                        labelRender: (data) => `${data.name} ${data.cname}, ${data.aliasName} (${data.staffId??' -- '})`, 
                        multiple: true,
                        preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
                        // preQuery:  {isConsultant : true} ,
                        searchFields: ['name' ,'staffId', 'aliasName','cname', 'email'], 
                        sort:{
                            by:'name',
                            order:'desc'
                        }            
                    }                        
                },
    
                {                
                    name: "consultantList",            
                    component: ConsultantList, 
                    parent:"t1-1",
                    props:{
                        field: 'consultants'
                    }                        
                },

                {
					name: "files",
					component: FileList,
					parent: "t1-2",
					props: {},
				},
                {
                    name: "confirm",
                    component: FormConfirmation,
                    parent: "t1-3",
                    props: {
                        successText: [
                            "Please confirm only if the information is correct.",
                            "All Orders will be assigned to the new consultant.",
                            "Exisiting Bids will keep in wallet."
                        ]
                    },
                },
            ]
        }

    }

    const onSubmit = async ({ values, formMode }) => {
        try {
            // console.log("change service right", values._id , values.consultants)        
            if (_.isEmpty(values.consultants)) throw 'No consultants selected.'
            console.log("changeServer", values.files)
            let result = await api.Client.changeServiceRight({ id: values._id, files:values.files, consultants: values.consultants.map(c=>c._id ??c )})
            mainForm.setFieldValue("consultants", values.consultants )                
            mainForm.setFieldValue("files", values.files )                

            formAction.close()

        } catch (error) {
            console.log(error)
            showNotification({
				title: `Change Right Error`,
				color: "red",
				icon: <IconExclamationMark size={18} />,
				message: error.msg ?? error,
			});
        }
        
        
    }

    const [renderForm, formAction, formStatus, formSetting ] = useFormRender(formSchema, null, onSubmit)
  
    const handleOnClick = ()=>{
        const id = mainForm?.values._id
        if (!id) return 
        formAction.open({ mode:'edit' , id , data:mainForm.values })
    }

    return (
        <>
            <Button fullWidth onClick={handleOnClick} color={color}>
                Change Service Right
            </Button>

            {   
                renderForm()        
            }
        </>
    )

}

export default ClientChangeServiceRightButton
