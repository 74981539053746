import React, { useEffect, useState } from "react";
import { IconCaretLeft, IconCaretRight } from "@tabler/icons";
import { Group, Select, ActionIcon, Button } from "@mantine/core";
import moment from "moment";

export const PeriodFilter = ({
  numPeriods = 8,
  handlePeriodChange,
  handleRefresh,
  position = "right",
  forward = false,
  by = "Q",
  ...props
}) => {
  const [q, setQ] = useState(0);
  const [periodFilterOptions, setPeriodFilterOptions] = useState([]);

  //Set Period Options when initialize
  useEffect(() => {
    setPeriodOptions();
  }, []);

  const setPeriodOptions = () => {
    const filters = [];
    for (let i = 0; i < numPeriods; i++) {
      const d = forward ? moment().add(i, by) : moment().subtract(i, by);
      if (by === "Q") filters.push({ label: d.format(`YYYY-[Q]Q`), value: i });
      if (by === "M") filters.push({ label: d.format(`YYYY-MM`), value: i });
    }
    setPeriodFilterOptions(filters);
    hanldeQChange(0);
  };

  const hanldeQChange = (q) => {
    let startDate = forward
      ? moment().add(q - 1, by)
      : moment().subtract(q + 1, by);

    // console.log("HandleQChange", startDate, q, by);

    startDate = startDate.endOf(by).format();
    // console.log("HandleQChange", startDate);

    let endDate = forward ? moment().add(q, by) : moment().subtract(q, by);
    endDate = endDate.endOf(by).format();

    handlePeriodChange({ startDate, endDate });
    // console.log("HandleQChange", startDate, endDate);

    setQ(q);
  };

  return (
    <Group position={position} {...props}>
      <ActionIcon
        size={"xs"}
        onClick={() => hanldeQChange(q - 1 < 0 ? 0 : q - 1)}
      >
        <IconCaretLeft />
      </ActionIcon>
      <Select
        size={"xs"}
        data={periodFilterOptions}
        value={q}
        onChange={hanldeQChange}
        defaultValue={0}
      ></Select>
      <ActionIcon
        size={"xs"}
        onClick={() =>
          hanldeQChange(q + 1 >= numPeriods - 1 ? numPeriods - 1 : q + 1)
        }
      >
        <IconCaretRight />
      </ActionIcon>

      {handleRefresh && (
        <Button size="xs" onClick={handleRefresh}>
          Refresh
        </Button>
      )}
    </Group>
  );
};
